import { Card, Skeleton, Stack } from '@mui/material';
import React, { memo } from 'react';
import { randomNumberRange } from 'src/_mock/funcs';

function SkeletonHistoryCard({ sx }: { sx?: any }) {
  return (
    <Card sx={{ p: 3 }}>
      <Stack direction="row" spacing={3}>
        <Skeleton variant="rectangular" sx={{ width: 80, height: 80, borderRadius: 10 }} />
        <Stack spacing={1}>
          <Skeleton variant="text" sx={{ width: randomNumberRange(40, 180) }} />
          <Skeleton variant="text" sx={{ width: randomNumberRange(40, 180) }} />
          <Skeleton variant="text" sx={{ width: randomNumberRange(40, 180) }} />
        </Stack>
      </Stack>
    </Card>
  );
}

export default memo(SkeletonHistoryCard);
