// @mui
import { Stack, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

const SkeletonStripeInvoice = () => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: 1 }}>
    <Skeleton variant="text" width={120} />
    <Skeleton variant="text" width={80} />
    <Skeleton variant="text" width={40} />
  </Stack>
);

// ----------------------------------------------------------------------

export default function SkeletonStripeInvoices() {
  // return a list of 5 skeleton invoices
  return (
    <>
      {[0, 1, 2, 3, 4, 5].map((item) => (
        <SkeletonStripeInvoice key={item} />
      ))}
    </>
  );
}
