import { useContext } from 'react';
import { ExerciseDetailsContext } from 'src/contexts/ExerciseDetailsContext';

// ----------------------------------------------------------------------

export default function useExerciseDetailsModal() {
  const context = useContext(ExerciseDetailsContext);

  return context;
}
