import { Workout } from 'src/@types/program';
import uuidv4 from 'src/utils/uuidv4';

type Props = {
  programId: string;
  programWeekId: string;
  index: number;
  name?: string;
  description?: string;
};

const handleCreateWorkout = ({
  programId,
  programWeekId,
  index,
  name = '',
  description = '',
}: Props) => {
  const newWorkout: Workout = {
    id: uuidv4(),
    programId: programId,
    programWeekId: programWeekId,
    index: index,
    name: '',
    description: '',
    descriptionVisible: false,
    notes: '',
    dateCreated: new Date(),
    lastUpdated: new Date(),
  };
  return newWorkout;
};

export default handleCreateWorkout;
