import { createSlice, createEntityAdapter, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { Exercise_WithID } from 'src/@types/firebase';
import { RootState } from 'src/redux/store';
import { exercisesResetAction } from '../exercises';
import { programResetAction } from './program';

const exerciseToAddAdapter = createEntityAdapter<Exercise_WithID>({
  // selectId: (collapsedItem: string) => collapsedItem,
  // Sort by dateAdded
  // sortComparer: (a, b) => new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime(),
});

const initialState = exerciseToAddAdapter.getInitialState();

export const slice = createSlice({
  name: 'exercisesToAdd',
  initialState,
  reducers: {
    toggleExerciseSelected: (state, action: PayloadAction<Exercise_WithID>) => {
      const exercise = action.payload;
      const exerciseId = exercise.id;
      const selected = state.ids.includes(exerciseId);
      if (selected) {
        exerciseToAddAdapter.removeOne(state, exerciseId);
      } else {
        exerciseToAddAdapter.addOne(state, exercise);
      }
    },
    reset: () => initialState,
  },
  extraReducers: {
    [programResetAction.type]: () => initialState,
    [exercisesResetAction.type]: () => initialState,
  },
});

export const { toggleExerciseSelected, reset } = slice.actions;

export default slice.reducer;

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectExercisesToAdd,
  selectById: selectExerciseToAddById,
  selectIds: selectExerciseToAddIds,
  selectTotal: selectExercisesToAddTotal,
} = exerciseToAddAdapter.getSelectors((state: RootState) => state.exercisesToAdd);

export const isExerciseSelected = createSelector(
  [selectExerciseToAddIds, (_: RootState, exerciseId: string) => exerciseId],
  (currentlySelectedExerciseIds, exerciseId) => currentlySelectedExerciseIds.includes(exerciseId)
);

// export const getAllSelectedExercises = createSelector(
//   [
//     selectExerciseToAddIds,
//     (state: RootState) =>
//       Object.values(state.exercises.entities).filter((e): e is ExerciseType => !!e),
//   ],
//   (currentlySelectedExerciseIds, exercises) =>
//     currentlySelectedExerciseIds
//       .map((id) => exercises.find((e) => e.id === id))
//       .filter((e): e is ExerciseType => !!e) // Removes any potential undefined values

//   // exercises.filter((e) => currentlySelectedExerciseIds.includes(e.id))
// );
