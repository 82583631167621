import { ReactNode } from 'react';
// import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
// import { PATH_PAGE } from '../routes/paths';
// util
// import useUserAccess from 'src/hooks/useUserAccess';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function DashboardGuard({ children }: Props) {
  const {
    // isAuthenticated,
    isInitialized,
  } = useAuth();

  // const { hasDashboardAccess } = useUserAccess();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  // if (isAuthenticated) {
  //   // If the user is a client, redirect them to the client dashboard
  //   if (!hasDashboardAccess) {
  //     return <Navigate to={PATH_PAGE.download} />;
  //   }
  // }

  return <>{children}</>;
}
