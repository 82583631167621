import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { programResetAction } from './program';

export type ExerciseSwapModal = {
  visible: boolean;
  workoutExerciseId: string;
  workoutExerciseName: string;
};

const initialState: ExerciseSwapModal = {
  visible: false,
  workoutExerciseId: '',
  workoutExerciseName: '',
};

export const slice = createSlice({
  name: 'exerciseSwapModal',
  initialState,
  reducers: {
    openExerciseSwapModal: (state, action: PayloadAction<ExerciseSwapModal>) => action.payload,
    closeExerciseSwapModal: () => initialState,
  },
  extraReducers(builder) {
    builder
      // Reset case
      .addCase(programResetAction, () => initialState);
  },
});

export const { openExerciseSwapModal, closeExerciseSwapModal } = slice.actions;

export default slice.reducer;

// ################################################################
// SELECTORS
// ################################################################

export const getExerciseSwapModal = (state: RootState) => state.exerciseSwapModal;
export const getExerciseSwapModalVisible = (state: RootState) => state.exerciseSwapModal.visible;
