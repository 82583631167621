import React, { memo } from 'react';
import { SkeletonExerciseCard } from '.';

function SkeletonExerciseCardList({
  length,
  checkbox = false,
  sx,
}: {
  length: number;
  checkbox?: boolean;
  sx?: any;
}) {
  return (
    <>
      {Array.from({ length: length }).map((_, index) => (
        <SkeletonExerciseCard key={index} checkbox={checkbox} sx={sx} />
      ))}
    </>
  );
}

export default memo(SkeletonExerciseCardList);
