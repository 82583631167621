import { useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  // ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { Notification_WithID } from 'src/@types/firebase';
import { NOTIFICATION_TYPE_ENUM } from 'src/@types/enums';
import useNotifications from 'src/hooks/useNotifications';
import { NavigateFunction, useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useDispatch } from 'src/redux/store';
import { resetClientProfileAction } from 'src/redux/slices/clientProfile';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { notifications, handleMarkAllAsRead, handleMarkItemAsRead } = useNotifications();
  // const notifications = [];

  const totalUnRead = notifications.filter((item) => item.seen === false).length;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const dispatch = useDispatch();
  const resetUser = () => dispatch(resetClientProfileAction());

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread notifications
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {/* No higher than the window height */}
        <Scrollbar sx={{ maxHeight: 'calc(100vh - 200px)', height: 'auto' }}>
          <List
            disablePadding
            // subheader={
            //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            //     New
            //   </ListSubheader>
            // }
          >
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                closeMenu={handleClose}
                handleMarkItemAsRead={handleMarkItemAsRead}
                resetUser={resetUser}
              />
            ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, notifications.length).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                closeMenu={handleClose}
                handleMarkItemAsRead={handleMarkItemAsRead}
              />
            ))}
          </List> */}
        </Scrollbar>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({
  notification,
  closeMenu,
  handleMarkItemAsRead,
  resetUser,
}: {
  notification: Notification_WithID;
  closeMenu: () => void;
  handleMarkItemAsRead: (id: string) => void;
  resetUser: () => void;
}) {
  const navigate = useNavigate();

  const { avatar, title, handleClick } = renderContent(
    notification,
    navigate,
    closeMenu,
    handleMarkItemAsRead,
    resetUser
  );

  return (
    <ListItemButton
      onClick={handleClick}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.seen && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.dateCreated)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(
  notification: Notification_WithID,
  navigate: NavigateFunction,
  closeMenu: () => void,
  handleMarkItemAsRead: (id: string) => void,
  resetUser: () => void
) {
  let avatar = notification.imageUrl ? <img alt="title" src={notification.imageUrl} /> : null;
  let boldTitle = '';
  let description = '';
  // null or void function
  let onClick: (() => void) | null = null;

  const clientNameExists = notification.client?.firstName && notification.client?.lastName;

  if (notification.type === NOTIFICATION_TYPE_ENUM.WORKOUT_LOG) {
    boldTitle = clientNameExists
      ? `${notification.client?.firstName} ${notification.client?.lastName}`
      : 'New Workout';
    description =
      clientNameExists && notification.item?.value
        ? 'logged ' + notification.item?.value
        : 'There is a new workout logged';
    avatar = <img alt={boldTitle} src={notification.imageUrl} />;

    onClick = () => {
      if (notification.client?.id) {
        if (notification.item?.id) {
          // Workout logs
          navigate(
            PATH_DASHBOARD.clients.profileWorkoutLog(notification.client.id, notification.item.id)
          );
        } else {
          // Measurment logs AND coach accept
          navigate(PATH_DASHBOARD.clients.profile(notification.client.id));
        }
        resetUser();
        closeMenu();
      }
    };
  } else if (notification.type === NOTIFICATION_TYPE_ENUM.MEASUREMENT_LOG) {
    boldTitle = clientNameExists
      ? `${notification.client?.firstName} ${notification.client?.lastName}`
      : 'New Measurement';
    description = notification.item?.value
      ? 'new log, ' + notification.item?.value
      : 'logged a new measurement';
    avatar = (
      <Iconify icon={'entypo:ruler'} />
      // <img
      //   alt={boldTitle}
      //   src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_shipping.svg"
      // />
    );

    onClick = () => {
      if (notification.client?.id) {
        navigate(PATH_DASHBOARD.clients.profile(notification.client.id));
        resetUser();
      }
      closeMenu();
    };
  } else if (notification.type === NOTIFICATION_TYPE_ENUM.COACH_CLIENT_ACCEPT) {
    boldTitle = clientNameExists
      ? `${notification.client?.firstName} ${notification.client?.lastName}`
      : 'Client accepted';
    description = clientNameExists ? 'accepted your coaching invite' : 'You have a new client';
    avatar = <img alt={boldTitle} src={notification.client?.profilePictureUrl} />;

    onClick = () => {
      if (notification.client?.id) {
        navigate(PATH_DASHBOARD.clients.profile(notification.client.id));
        resetUser();
      }
      closeMenu();
    };
  }

  const title = (
    <Typography variant="subtitle2">
      {boldTitle}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp;{description}
      </Typography>
    </Typography>
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    handleMarkItemAsRead(notification.id);
  };

  return {
    avatar,
    title,
    handleClick,
  };
}
