import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { CopyToModal, CopyToModalBasics, CopyToDestination } from 'src/@types/copyToModal';
import { COPY_ITEM_ENUM, FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { ProgramWeek_WithID, Workout_WithID } from 'src/@types/firebase';
import { Changes } from 'src/@types/program_redux';
import { AppDispatch, RootState } from 'src/redux/store';
import { handleCopyTo } from './functions/copyTo';
import { handleLoadProgramWeeks } from './functions/load';
import { programResetAction } from './program';

const initialState: CopyToModal = {
  visible: false,
  copyItemId: '',
  copyItemName: '',
  copyItemType: COPY_ITEM_ENUM.WEEK,

  selectedDestinations: [],

  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  copyToStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  weeks: [],
  workouts: [],
};

export const fetchProgramWeeks = createAsyncThunk<
  {
    programWeeks: ProgramWeek_WithID[];
    workouts?: Workout_WithID[];
  },
  { programId: string; weeksOnly?: boolean }
>('copyToModal/fetchProgramWeeks', async ({ programId, weeksOnly }) => {
  const { programWeeks, workouts } = await handleLoadProgramWeeks({ programId, weeksOnly });

  if (!programWeeks) {
    throw new Error('Program weeks is undefined');
  }

  if (!workouts) {
    return { programWeeks };
  }

  return { programWeeks, workouts };
});

export const copyToCurrentProgram = createAction<Changes>('copyToModal/copyToCurrentProgram');
export const closeCopyToModalAction = createAction('copyToModal/closeCopyToModal');

export const slice = createSlice({
  name: 'copyToModal',
  initialState,
  reducers: {
    openCopyToModal: (state, action: PayloadAction<CopyToModalBasics>) => ({
      ...state,
      ...action.payload,
      visible: true,
    }),
    // Selected Items
    toggleSelectedItem: (state, action: PayloadAction<CopyToDestination>) => {
      const { id, type } = action.payload;
      const selectedDestinations = state.selectedDestinations.filter(
        (destination) => destination.id !== id || destination.type !== type
      );
      if (selectedDestinations.length === state.selectedDestinations.length) {
        selectedDestinations.push(action.payload);
      }
      return { ...state, selectedDestinations };
    },
    removeSelectedItem: (state, action: PayloadAction<CopyToDestination>) => {
      const { id, type } = action.payload;
      const selectedDestinations = state.selectedDestinations.filter(
        (destination) => destination.id !== id || destination.type !== type
      );
      return { ...state, selectedDestinations };
    },
    // Copy to status
    copyToLoading: (state) => ({ ...state, copyToStatus: FETCH_STATUS_TYPES_ENUM.LOADING }),
    copyToSuccess: (state) => ({ ...state, copyToStatus: FETCH_STATUS_TYPES_ENUM.SUCCEEDED }),
    copyToFailed: (state, action: PayloadAction<string>) => ({
      ...state,
      copyToStatus: FETCH_STATUS_TYPES_ENUM.FAILED,
      error: action.payload,
    }),
  },
  extraReducers(builder) {
    builder
      // Reset case
      .addCase(programResetAction, () => initialState)
      .addCase(closeCopyToModalAction, () => initialState)

      // Internal
      .addCase(fetchProgramWeeks.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
        state.error = null;
      })
      .addCase(fetchProgramWeeks.fulfilled, (state, action) => {
        const { programWeeks, workouts } = action.payload;

        state.error = null;
        state.weeks = [...state.weeks, ...programWeeks];
        if (workouts && workouts.length) {
          state.workouts = [...state.workouts, ...workouts];
        }
        state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      })
      .addCase(fetchProgramWeeks.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      });
  },
});

export const {
  openCopyToModal,
  toggleSelectedItem,
  removeSelectedItem,
  copyToLoading,
  copyToSuccess,
  copyToFailed,
} = slice.actions;

export default slice.reducer;

// -------------------------------------------------------------------
// THUNKS
// -------------------------------------------------------------------

export const copyToDestinations =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(copyToLoading());

      const state = getState();

      const {
        copyItemId: itemId,
        copyItemType: itemType,
        selectedDestinations,
      } = state.copyToModal;

      const changes: Changes = {};

      await handleCopyTo({
        selectedDestinations,
        itemId,
        itemType,
        state,
        changes,
      });

      dispatch(copyToCurrentProgram(changes));

      dispatch(copyToSuccess());
    } catch (error) {
      dispatch(copyToFailed(error.message));
    }
  };

// -------------------------------------------------------------------
// SELECTORS
// -------------------------------------------------------------------

export const getCopyToModal = (state: RootState) => state.copyToModal;
export const getCopyToModalType = (state: RootState) => state.copyToModal.copyItemType;
export const getCopyToModalVisible = (state: RootState) => state.copyToModal.visible;
export const getCopyToModalFetchStatus = (state: RootState) => state.copyToModal.status;
export const getCopyToModalSelectedDestinations = (state: RootState) =>
  state.copyToModal.selectedDestinations;
export const getWeeks = (state: RootState) => state.copyToModal.weeks;
export const getWorkouts = (state: RootState) => state.copyToModal.workouts;
export const getProgramId = (state: RootState, programId: string) => programId;
export const getProgramWeekId = (state: RootState, programWeekId: string) => programWeekId;

export const getProgramWeeks = createSelector([getWeeks, getProgramId], (weeks, programId) =>
  weeks.filter((week) => week.programId === programId).sort((a, b) => a.index - b.index)
);
export const getProgramWorkouts = createSelector(
  [getWorkouts, getProgramWeekId],
  (workouts, programWeekId) =>
    workouts
      .filter((workout) => workout.programWeekId === programWeekId)
      .sort((a, b) => a.index - b.index)
);
