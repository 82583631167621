import { Box, Dialog, Slide, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
  createContext,
  useState,
  useCallback,
  forwardRef,
  ReactElement,
  Ref,
  ReactNode,
  useEffect,
} from 'react';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import {
  fetchExercise,
  getExercisesFetchStatus,
  resetStatus,
  selectExerciseById,
} from 'src/redux/slices/exercises';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { ExerciseDetails } from 'src/sections/@dashboard/exercises';

// ----------------------------------------------------------------------

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

export type ExerciseDetailsContextProps = {
  openExerciseDetailsModal: (exerciseId: string) => Promise<void>;
  closeExerciseDetailsModal: () => void;
};

const initialState: ExerciseDetailsContextProps = {
  openExerciseDetailsModal: async () => {},
  closeExerciseDetailsModal: () => {},
};

const ExerciseDetailsContext = createContext(initialState);

type ExerciseDetailsProviderProps = {
  children: ReactNode;
};

function ExerciseDetailsProvider({ children }: ExerciseDetailsProviderProps) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [visible, setVisible] = useState<boolean>(false);
  const [exerciseId, setExerciseId] = useState<string | null>(null);

  const exercise = useSelector((state: RootState) =>
    selectExerciseById(state, exerciseId as string)
  );
  const fetchStatus = useSelector(getExercisesFetchStatus);

  useEffect(() => {
    if (!exercise && exerciseId && fetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(fetchExercise({ exerciseId: exerciseId }));
    }
  }, [exercise, exerciseId, fetchStatus, dispatch]);

  // Clean up when screen unmounts
  useEffect(() => {
    const unsubscribe = () => {
      dispatch(resetStatus());
    };
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = useCallback(async (exerciseId: string) => {
    console.log('openExerciseDetailsModal');
    setVisible(true);
    setExerciseId(exerciseId);
  }, []);

  const handleClose = useCallback(() => {
    setExerciseId(null);
    setVisible(false);
  }, []);

  return (
    <ExerciseDetailsContext.Provider
      value={{
        openExerciseDetailsModal: handleOpen,
        closeExerciseDetailsModal: handleClose,
      }}
    >
      {children}
      <Dialog
        keepMounted={false}
        // fullScreen
        open={visible}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.grey[700]
                : theme.palette.background.default,
          },
        }}
      >
        {/* <IconButton
          onClick={handleClose}
          sx={{
            zIndex: 9,
            position: 'absolute',
            top: -10,
            right: -10,
            backgroundColor: alpha('#fff', 0.8),
          }}
        >
          <Iconify
            icon={'fe:close'}
            color="text.primary"
            width={30}
            height={30}
            sx={{ backgroundColor: '#fff', opacity: 0.5, ':hover': { opacity: 1 } }}
          />
        </IconButton> */}
        <Box>{!!exercise && <ExerciseDetails exercise={exercise} />}</Box>
      </Dialog>
    </ExerciseDetailsContext.Provider>
  );
}

export { ExerciseDetailsProvider, ExerciseDetailsContext };
