// contexts
import { DB } from 'src/contexts/FirebaseContext';
// types
import { doc, WriteBatch } from 'firebase/firestore';
import { Workout_WithID } from 'src/@types/firebase';
import { PROGRAM_DATA_KEYS_ENUM } from 'src/@types/enums';
import { Workout } from 'src/@types/program';
import { RootState } from 'src/redux/store';
// util
import uuidv4 from 'src/utils/uuidv4';
import { omitUnnecessaryProperties } from '../util';

type Props = {
  destinationProgramWeekId: string;
  workout: Workout;
  batch: WriteBatch;
  state: RootState;
};

/**
 * Duplicates a Workout to a program week
 *
 * @param destinationProgramWeekId - The id of the program week to add the workout to.
 * @param workout - Workout
 * @param batch - The Firestore batch to write to
 * @param state - The current state of the store
 *
 */
const handleCopyWorkoutToOtherProgram = ({
  destinationProgramWeekId,
  workout,
  batch,
  state,
}: Props) => {
  const destinationItem = state.copyToModal.weeks.find(
    (item) => item.id === destinationProgramWeekId
  );
  if (!destinationItem)
    throw new Error(`Could not find destination program week with id ${destinationProgramWeekId}`);

  // Convert to Firebase Workout
  const workoutFB = omitUnnecessaryProperties({
    key: PROGRAM_DATA_KEYS_ENUM.WORKOUTS,
    item: workout,
    state: state,
  }) as Workout_WithID;

  const duplicatedName = workoutFB.name
    ? `${workout.name} (copy)`
    : `Workout ${workout.index + 1} (copy)`;

  const duplicate: Workout_WithID = {
    ...workoutFB,
    id: uuidv4(),
    programWeekId: destinationProgramWeekId,
    programId: destinationItem.programId,
    name: duplicatedName,
    index: 0,
  };

  const { id, ...duplicateData } = duplicate;
  const ref = doc(
    DB,
    'programs',
    destinationItem.programId,
    'programWeeks',
    destinationProgramWeekId,
    'workouts',
    id
  );
  batch.set(ref, duplicateData);

  // Update the index of any item after the newly added item

  const affectedItems = state.copyToModal.workouts.filter(
    (item) => item.programWeekId === destinationProgramWeekId && item.index >= duplicate.index
  );

  const updatedItems = affectedItems.map((item) => ({
    ...item,
    index: item.index + 1,
  }));

  updatedItems.forEach((item) => {
    const ref = doc(
      DB,
      'programs',
      item.programId,
      'programWeeks',
      item.programWeekId,
      'workouts',
      item.id
    );
    batch.update(ref, { index: item.index });
  });
};

export default handleCopyWorkoutToOtherProgram;
