import { useSelector } from 'src/redux/store';
import { getUserType } from 'src/redux/slices/user';
import { USER_TYPE_ENUM } from 'src/@types/enums';

// ----------------------------------------------------------------------

export default function useUserAccess() {
  const userTypes = useSelector(getUserType);

  const hasDashboardAccess = !!(
    userTypes?.includes(USER_TYPE_ENUM.CLIENT) ||
    userTypes?.includes(USER_TYPE_ENUM.COACH) ||
    userTypes?.includes(USER_TYPE_ENUM.ADMIN)
  );

  const hasAdminAccess = !!userTypes?.includes(USER_TYPE_ENUM.ADMIN);

  return {
    userTypes,
    hasDashboardAccess,
    hasAdminAccess,
  };
}
