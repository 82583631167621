import {
  createSlice,
  createAction,
  createEntityAdapter,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  WorkoutDragItem,
  WorkoutExercise as WorkoutExerciseType,
  WorkoutExerciseGroup,
  WorkoutExerciseMetric,
  WorkoutExerciseMetricValue,
} from 'src/@types/program';
import { ChangeData, Changes, UpdateType } from 'src/@types/program_redux';
import { applyChanges } from 'src/redux/functions/applyChanges';
import { AppDispatch, RootState } from 'src/redux/store';
import {
  createWorkoutExercise,
  createWorkoutExerciseGroup,
  createWorkoutExerciseOptions,
} from 'src/utils/createDragItems';
import uuidv4 from 'src/utils/uuidv4';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';
import { DEFAULT_REPS_METRIC_ID } from '../exerciseMetrics';
import {
  duplicateWorkoutExerciseGroupAction,
  removeExerciseGroupAction,
} from './workoutExerciseGroups';
import { handleDuplicateWorkoutExercise } from './functions/duplicate';
import { programResetAction } from './program';
import { duplicateWeekAction, fetchProgramWeeks, removeWeekAction } from './programWeeks';
import { groupingAction, reorderExercisesAction } from './workoutDragItems';
import { duplicateWorkoutAction, removeWorkoutAction } from './workouts';
import { WORKOUT_EXERCISES } from './constants/keys';
import { Exercise_WithID } from 'src/@types/firebase';
import { copyToCurrentProgram } from './copyToModal';

const workoutExerciseAdapter = createEntityAdapter<WorkoutExerciseType>({
  // Sort by index
  sortComparer: (a: WorkoutExerciseType, b: WorkoutExerciseType) => a.index - b.index,
});

const initialState = workoutExerciseAdapter.getInitialState({
  currentActiveWorkoutExerciseId: null, // The currently active workout exercise e.g. changing a value
} as {
  currentActiveWorkoutExerciseId: string | null;
});

export const updateSetsAction = createAction<Changes>('workoutExercises/updateSets');
export const toggleSetsAction = createAction<Changes>('workoutExercises/toggleSets');
export const addExercisesAction = createAction<Changes>('workoutExercises/addExercises');
export const updateExerciseAction = createAction<Changes>('workoutExercises/updateExercise');
export const removeExerciseAction = createAction<Changes>('workoutExercises/removeExercise');
export const duplicateWorkoutExerciseAction = createAction<Changes>(
  'workoutExercises/duplicateWorkoutExercise'
);
export const swapWorkoutExerciseAction = createAction<Changes>(
  'workoutExercises/swapWorkoutExercise'
);

export const slice = createSlice({
  name: WORKOUT_EXERCISES,
  initialState,
  reducers: {
    toggleHeader: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const workoutExercise = state.entities[id];

      if (workoutExercise) {
        const update = { id: id, changes: { headerVisible: !workoutExercise.headerVisible } };
        workoutExerciseAdapter.updateOne(state, update);
      }
    },
    setActiveWorkoutExercise: (state, action: PayloadAction<string | undefined>) => {
      const id = action.payload;
      if (!id) {
        state.currentActiveWorkoutExerciseId = null;
        return;
      }

      state.currentActiveWorkoutExerciseId = id;
    },
    toggleCoachNotes: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const workoutExercise = state.entities[id];
      if (workoutExercise) {
        const update = {
          id: id,
          changes: { coachNotesVisible: !workoutExercise.coachNotesVisible },
        };
        workoutExerciseAdapter.updateOne(state, update);
      }
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    const handleChangesReducer = (state: any, action: PayloadAction<Changes>) => {
      const items = action.payload.workoutExercises;
      if (items) {
        applyChanges(items, workoutExerciseAdapter, state);
      }
    };

    builder
      // Internal
      .addCase(updateSetsAction, handleChangesReducer)
      .addCase(toggleSetsAction, handleChangesReducer)
      .addCase(addExercisesAction, handleChangesReducer)
      .addCase(updateExerciseAction, handleChangesReducer)
      .addCase(removeExerciseAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseAction, handleChangesReducer)
      .addCase(swapWorkoutExerciseAction, handleChangesReducer)
      // External
      .addCase(removeExerciseGroupAction, handleChangesReducer)
      .addCase(removeWorkoutAction, handleChangesReducer)
      .addCase(removeWeekAction, handleChangesReducer)
      .addCase(reorderExercisesAction, handleChangesReducer)
      .addCase(groupingAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseGroupAction, handleChangesReducer)
      .addCase(duplicateWorkoutAction, handleChangesReducer)
      .addCase(duplicateWeekAction, handleChangesReducer)
      .addCase(copyToCurrentProgram, handleChangesReducer)

      .addCase(programResetAction, () => initialState)
      .addCase(fetchProgramWeeks.fulfilled, (state, action) => {
        const items = action.payload[WORKOUT_EXERCISES];

        if (items.length) {
          workoutExerciseAdapter.addMany(state, items);
        }
      });
  },
});

export const { toggleHeader, setActiveWorkoutExercise, toggleCoachNotes, reset } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

export const updateWorkoutExercise =
  ({ id, updates }: { id: string; updates: Partial<WorkoutExerciseType> }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const workoutExercise = state.workoutExercises.entities[id];

    if (!workoutExercise) {
      console.error(`WorkoutExercise with id ${id} not found`);
      return;
    }

    // Used to update workout in database
    const workouts: ChangeData = {
      updated: [{ id: workoutExercise.workoutId, changes: {} }],
    };

    const update = { id: id, changes: updates };
    const changes: Changes = {
      workouts,
      workoutExercises: {
        updated: [update],
      },
    };
    dispatch(updateExerciseAction(changes));
  };

export const toggleSets = (id: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const workoutExercise = state.workoutExercises.entities[id];
  if (workoutExercise) {
    const workouts: ChangeData = {
      updated: [{ id: workoutExercise.workoutId, changes: {} }],
    };
    const update = { id: id, changes: { setsVisible: !workoutExercise.setsVisible } };
    const changes: Changes = {
      workouts,
      workoutExercises: {
        updated: [update],
      },
    };
    dispatch(toggleSetsAction(changes));
  }
};

export const updateSets =
  ({ workoutExerciseId, sets }: { workoutExerciseId: string; sets: string }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const workoutExercise = state.workoutExercises.entities[workoutExerciseId];
    if (!workoutExercise) {
      console.error('Workout exercise not found');
      return;
    }

    // convert sets to a number
    const newSets = Number(sets);
    const oldSets = Number(getWorkoutExerciseSets(getState(), workoutExerciseId));
    // Check if sets is a number and isn't lesser than 1
    const setsValid = !isNaN(newSets) && newSets > 0 && newSets !== oldSets;
    // Exit if invalid
    if (!setsValid) {
      console.error('Sets must be a number and greater than 0');
      return;
    }

    const workouts: ChangeData = {
      updated: [{ id: workoutExercise.workoutId, changes: {} }],
    };
    const workoutExercises: ChangeData = {};
    const workoutExerciseMetricValues: ChangeData = {};

    workoutExercises.updated = [{ id: workoutExerciseId, changes: { sets: newSets } }];

    // Find the difference between the old and new sets
    const difference = newSets - (oldSets ? oldSets : 0);
    // Is there more or less sets than before
    const moreSets = difference > 0;

    // If moreSets is true then we add new WorkoutExerciseMetricValues foreach WorkoutExerciseMetric
    if (moreSets && oldSets) {
      const workoutExerciseMetrics = Object.values(
        getState().workoutExerciseMetrics.entities
      ).filter(
        (metric): metric is WorkoutExerciseMetric =>
          !!metric && metric.workoutExerciseId === workoutExerciseId
      );

      const valuesToAdd = workoutExerciseMetrics.reduce(
        (acc: WorkoutExerciseMetricValue[], metric: WorkoutExerciseMetric) => {
          // Create a new value for each set

          const newValues = [];
          for (let i = 0; i < difference; i++) {
            const valueIndex = i + oldSets;
            const set = valueIndex + 1;

            const newWorkoutExerciseMetricValue: WorkoutExerciseMetricValue = {
              id: uuidv4(),
              workoutExerciseId: workoutExerciseId,
              exerciseId: workoutExercise.exerciseId,
              workoutExerciseMetricId: metric.id,
              exerciseMetricId: metric.exerciseMetricId,
              value: '',
              index: valueIndex,
              set: set,
              workoutId: workoutExercise.workoutId,
            };
            newValues.push(newWorkoutExerciseMetricValue);
          }
          return [...acc, ...newValues];
        },
        []
      );
      workoutExerciseMetricValues.added = valuesToAdd;
    }

    // If moreSets is false then we remove WorkoutExerciseMetricValues whose set is greater than newSets
    if (!moreSets) {
      const valuesToRemove = Object.values(getState().workoutExerciseMetricValues.entities)
        .filter(
          (value): value is WorkoutExerciseMetricValue =>
            !!value && value.workoutExerciseId === workoutExerciseId && value.set > newSets
        )
        .map((value) => value.id);
      workoutExerciseMetricValues.removed = valuesToRemove;
    }

    // Dispatch the updated WorkoutExercise and new or removed Values
    dispatch(
      updateSetsAction({
        workouts,
        workoutExercises,
        workoutExerciseMetricValues,
      })
    );
  };

export const addExercises =
  ({
    exercises,
    workoutId,
    dragIndex,
    groupId,
    groupIndex,
    groupAboveIndex,
    exerciseAboveId,
    exerciseAboveIndex,
    isExerciseAboveLastInGroup,
  }: {
    exercises: Exercise_WithID[];
    workoutId: string;
    dragIndex?: number;
    groupId?: string;
    groupIndex?: number;
    groupAboveIndex?: number;
    exerciseAboveId?: string;
    exerciseAboveIndex?: number;
    isExerciseAboveLastInGroup?: boolean;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('addExercises', {
        groupId,
        groupIndex,
        groupAboveIndex,
      });
    }

    const workouts: ChangeData = {
      updated: [{ id: workoutId, changes: {} }],
    };
    const workoutExerciseGroups: ChangeData = {};
    const workoutExercises: ChangeData = {};
    const workoutDragItems: ChangeData = {};
    const workoutExerciseMetrics: ChangeData = {};
    const workoutExerciseMetricValues: ChangeData = {};

    const groupLength = Object.values(getState().workoutDragItems.entities).filter(
      (item) =>
        !!item &&
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
        item.workoutId === workoutId &&
        item.groupId === groupId
    ).length;

    const sets = 1;
    const partOfExistingGroup = groupId !== undefined;

    const insertDragIndex = dragIndex !== undefined ? dragIndex + 1 : 0;

    let newDragIndex = insertDragIndex;
    let newDragItemCount = 0;
    const newExerciseIndex = exerciseAboveIndex !== undefined ? exerciseAboveIndex + 1 : 0;
    const newGroupIndex = groupAboveIndex !== undefined ? groupAboveIndex + 1 : 0;
    let newGroupHeaderAndFooter = false;

    // If added to solo exercise
    if (groupLength === 1 && groupId !== undefined && groupIndex !== undefined) {
      // Create group header and footer
      newGroupHeaderAndFooter = true;
      // Create a group header
      const newGroupHeader = createWorkoutExerciseGroup({
        id: groupId,
        index: groupIndex,
        dragIndex: insertDragIndex,
        workoutId: workoutId,
        type: WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER,
        inGroup: true,
      });
      newDragIndex++;
      newDragItemCount++;

      // Create a group footer
      const newGroupFooter = createWorkoutExerciseGroup({
        id: groupId,
        index: groupIndex,
        dragIndex: insertDragIndex + 2 + exercises.length,
        workoutId: workoutId,
        type: WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER,
        inGroup: true,
      });
      newDragItemCount++;

      workoutDragItems.added = workoutDragItems?.added
        ? [...workoutDragItems.added, newGroupHeader, newGroupFooter]
        : [newGroupHeader, newGroupFooter];
    }

    // Convert exercises to WorkoutExercises
    workoutExercises.added = exercises.map((exercise, i) => {
      // If the exercises weren't added within a group then we need to create a new group for each
      const workoutExerciseGroup: WorkoutExerciseGroup | null = partOfExistingGroup
        ? null
        : {
            id: uuidv4(),
            index: newGroupIndex ? newGroupIndex + i : i,
            name: '',
            workoutId,
          };
      // Add the new group if it exists
      if (workoutExerciseGroup) {
        workoutExerciseGroups.added = workoutExerciseGroups?.added
          ? [...workoutExerciseGroups.added, workoutExerciseGroup]
          : [workoutExerciseGroup];
      }

      // Create a new workoutExercise
      const workoutExercise: WorkoutExerciseType = {
        id: uuidv4(),
        exerciseId: exercise.id,
        workoutExerciseGroupId: partOfExistingGroup
          ? groupId
          : workoutExerciseGroup?.id
          ? workoutExerciseGroup.id
          : '',
        sets: sets,
        index: newExerciseIndex ? newExerciseIndex + i : 0,
        setsVisible: true,
        coachNotes: '',
        coachNotesVisible: false,
        name: exercise.name,
        thumbnailUrl: exercise.thumbnailUrl,
        youtubeId: exercise.youtubeId,
        workoutId: workoutId,
        header: '',
        headerVisible: false,
      };

      // Create a workoutDragItem for each exercise
      const dragItemData = {
        id: workoutExercise.id,
        index: workoutExercise.index,
        dragIndex: newDragIndex,
        groupId: workoutExercise.workoutExerciseGroupId,
        workoutId: workoutExercise.workoutId,
        inGroup: partOfExistingGroup,
        groupIndex: newGroupIndex ? newGroupIndex + i : i,
        lastInGroup: !partOfExistingGroup ? true : isExerciseAboveLastInGroup,
      };

      const exerciseDragItem: WorkoutDragItem = createWorkoutExercise(dragItemData);
      // Add the new item
      workoutDragItems.added = workoutDragItems?.added
        ? [...workoutDragItems.added, exerciseDragItem]
        : [exerciseDragItem];
      // Increment the dragIndex and dragItemCount
      newDragIndex++;
      newDragItemCount++;

      // If exercise was not added within the workout then exercise was added from the bottom of a workout
      // Check if is the last exercise in the workout
      // const lastExerciseInTheWorkout = !addedWithinWorkout && i === exercises.length - 1;
      // Exclude drag items within groups and the very last drag item in a workout
      if (!exerciseDragItem?.inGroup) {
        // Add Exercise options for new Drag Items
        const exerciseOptionsDragItem: WorkoutDragItem = createWorkoutExerciseOptions({
          ...dragItemData,
          index: dragItemData.groupIndex as number,
          dragIndex: newDragIndex,
          groupIndex: newGroupIndex ? newGroupIndex + i : i,
        });
        // Add the new item
        workoutDragItems.added.push(exerciseOptionsDragItem);
        // Increment the dragIndex and dragItemCount
        newDragIndex++;
        newDragItemCount++;
      }

      //Handle instances where default exercise metrics aren't set
      const defaultExerciseMetrics = [...exercise.defaultExerciseMetrics];

      if (!defaultExerciseMetrics.length) {
        const repsMetric = getState().exerciseMetrics.entities[DEFAULT_REPS_METRIC_ID];
        if (repsMetric) {
          defaultExerciseMetrics.push(repsMetric);
        } else {
          console.error(
            'No default exercise metrics found for exercise',
            exercise.name,
            'id:',
            exercise.id
          );
        }
      }

      // Create exercise metrics from the default exercise metrics
      const addedWorkoutExerciseMetrics = defaultExerciseMetrics.map((metric, metricIndex) => {
        const workoutExerciseMetric: WorkoutExerciseMetric = {
          id: uuidv4(),
          workoutExerciseId: workoutExercise.id,
          exerciseMetricId: metric.id,
          index: metricIndex,
          name: metric.name,
          color: metric.color,
          unitOfMeasurement: metric.unitOfMeasurement,
          workoutId: workoutId,
        };

        // Create workoutExerciseMetricValues for each set
        const newValues: WorkoutExerciseMetricValue[] = [];
        for (let i = 0; i < sets; i++) {
          const workoutExerciseMetricValue: WorkoutExerciseMetricValue = {
            id: uuidv4(),
            workoutExerciseId: workoutExercise.id,
            exerciseId: workoutExercise.exerciseId,
            workoutExerciseMetricId: workoutExerciseMetric.id,
            exerciseMetricId: workoutExerciseMetric.exerciseMetricId,
            value: '',
            index: i,
            set: i + 1,
            workoutId: workoutId,
          };
          newValues.push(workoutExerciseMetricValue);
        }
        workoutExerciseMetricValues.added = workoutExerciseMetricValues?.added
          ? [...workoutExerciseMetricValues.added, ...newValues]
          : [...newValues];

        return workoutExerciseMetric;
      });

      // Add the new workoutExerciseMetrics
      workoutExerciseMetrics.added = workoutExerciseMetrics?.added
        ? [...workoutExerciseMetrics.added, ...addedWorkoutExerciseMetrics]
        : [...addedWorkoutExerciseMetrics];

      return workoutExercise;
    });

    // Update lastInGroup when applicable
    if (isExerciseAboveLastInGroup && exerciseAboveId) {
      const lastInGroupUpdates: Partial<WorkoutDragItem> = { lastInGroup: false };

      if (newGroupHeaderAndFooter) {
        lastInGroupUpdates.dragIndex = insertDragIndex + 1;
        lastInGroupUpdates.inGroup = true;
      }

      const workoutDragItemUpdate = { id: exerciseAboveId, changes: lastInGroupUpdates };

      // Update workout exercise too
      workoutDragItems.updated = workoutDragItems?.updated
        ? [...workoutDragItems.updated, workoutDragItemUpdate]
        : [workoutDragItemUpdate];
    }

    // Update index/ dragIndex for any dragItems/ exercises below in the same group
    // Update dragIndex for any item below in the same workout
    // Find all drag items below the newly created drag items
    const affectedWorkoutDragItems = Object.values(getState().workoutDragItems.entities).filter(
      (item): item is WorkoutDragItem =>
        !!item && item.dragIndex >= insertDragIndex && item.workoutId === workoutId
    );
    // Update the drag index for all the affected items
    // If there are new groups update the group index of the affected items also
    const workoutExerciseGroupUpdates: UpdateType[] = [];
    const workoutDragItemUpdates: UpdateType[] = [];

    affectedWorkoutDragItems.forEach((item) => {
      const updatedDragIndex = item.dragIndex + newDragItemCount;

      const dragItemChanges: Partial<WorkoutDragItem> = {
        dragIndex: updatedDragIndex,
      };
      const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};

      // Update group indexes for Exercises and Exercise Options
      // Update indexes for Group headers and footers
      // If there are new groups update the group index of the affected items
      if (!partOfExistingGroup) {
        // if (
        //   item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE ||
        //   item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
        // ) {
        //   if (item.groupIndex !== undefined) {
        //     // If the exercises aren't part of an existing group each new exercise has a new exercise group
        //     const updatedGroupIndex = item.groupIndex + exercises.length;
        //     changes.groupIndex = updatedGroupIndex;
        //   }
        // }
        // // Else it is a Group header or footer
        // else {
        //   const updatedGroupIndex = item.index + exercises.length;
        //   changes.index = updatedGroupIndex;
        // }

        // If the exercises aren't part of an existing group each new exercise has a new exercise group
        const updatedGroupIndex = item.groupIndex + exercises.length; // + exercises.length because each exercise has a new group

        // Drag item changes
        if (item.groupIndex !== undefined) {
          dragItemChanges.groupIndex = updatedGroupIndex;
        }
        // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
        if (
          item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
          item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
          item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
        ) {
          dragItemChanges.index = updatedGroupIndex;
        }

        // ExerciseGroup changes
        // If the item is a group header or a solo exercise
        // Update the exercise group index
        if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
          exerciseGroupChanges.index = updatedGroupIndex;
        } else if (
          item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
          !item.inGroup &&
          item.groupIndex !== undefined
        ) {
          exerciseGroupChanges.index = updatedGroupIndex;
        }
      }

      // Update index for drag items and workout exercises if the new exercises were added to the same group
      if (item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE && item.groupId === groupId) {
        // If the item is in the same group as the new drag item then update the index
        const updatedIndex = item.index + exercises.length;
        dragItemChanges.index = updatedIndex;
        const workoutExerciseUpdate = { id: item.id, changes: { index: updatedIndex } };

        // Update workout exercises
        workoutExercises.updated = workoutExercises?.updated
          ? [...workoutExercises.updated, workoutExerciseUpdate]
          : [workoutExerciseUpdate];
      }

      // Push updates
      workoutDragItemUpdates.push({
        id: item.id,
        changes: dragItemChanges,
      });
      if (exerciseGroupChanges?.index !== undefined) {
        workoutExerciseGroupUpdates.push({
          id: item.groupId,
          changes: exerciseGroupChanges,
        });
      }
    });

    // Update drag items
    workoutDragItems.updated = workoutDragItems?.updated
      ? [...workoutDragItems.updated, ...workoutDragItemUpdates]
      : workoutDragItemUpdates;
    // Update drag items
    workoutExerciseGroups.updated = workoutExerciseGroups?.updated
      ? [...workoutExerciseGroups.updated, ...workoutExerciseGroupUpdates]
      : workoutExerciseGroupUpdates;

    // Dispatch the new WorkoutExercises, new WorkoutExerciseMetrics and new WorkoutExerciseMetricValues
    if (process.env.NODE_ENV === 'development') {
      console.log('Changes', {
        workouts,
        workoutExerciseGroups,
        workoutExercises,
        workoutDragItems,
        workoutExerciseMetrics,
        workoutExerciseMetricValues,
      });
    }

    dispatch(
      addExercisesAction({
        workouts,
        workoutExerciseGroups,
        workoutExercises,
        workoutDragItems,
        workoutExerciseMetrics,
        workoutExerciseMetricValues,
      })
    );
  };

export const removeExercise =
  ({ workoutExerciseId }: { workoutExerciseId: string }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const workoutExerciseDragItem = Object.values(state.workoutDragItems.entities).find(
      (item): item is WorkoutDragItem => !!item && item.id === workoutExerciseId
    );

    if (!workoutExerciseDragItem) {
      console.error(`WorkoutExerciseDragItem with id ${workoutExerciseId} not found`);
      return;
    }

    const { workoutId } = workoutExerciseDragItem;

    const workouts: ChangeData = {
      updated: [{ id: workoutId, changes: {} }],
    };
    const workoutExerciseGroups: ChangeData = {};
    const workoutExercises: ChangeData = {};
    const workoutDragItems: ChangeData = {};
    const workoutExerciseMetrics: ChangeData = {};
    const workoutExerciseMetricValues: ChangeData = {};

    const exerciseDragIndex = workoutExerciseDragItem.dragIndex;
    const { groupId } = workoutExerciseDragItem;
    const exerciseIndex = workoutExerciseDragItem.index;
    const wasExerciseLastInGroup = workoutExerciseDragItem?.lastInGroup;
    const workoutExerciseGroupId = workoutExerciseDragItem.groupId;

    // Calculate how many items are removed
    let totalRemovedDragItems = 1;
    let groupRemoved = false;
    const numberOfExercisesInGroup = Object.values(state.workoutExercises.entities).filter(
      (item) => item?.workoutExerciseGroupId === workoutExerciseGroupId
    ).length;
    const soloExercise = numberOfExercisesInGroup === 1;
    const becomingSoloGroup = numberOfExercisesInGroup - 1 === 1; // E.g. a group of two becomes one

    if (process.env.NODE_ENV === 'development') {
      console.log(`becomingSoloGroup: ${becomingSoloGroup}`);
    }

    // If the workout exercise is the only exercise in it's group remove the group
    if (soloExercise) {
      workoutExerciseGroups.removed = [workoutExerciseGroupId];
      groupRemoved = true;
    }

    // Remove the workout exercise
    workoutExercises.removed = [workoutExerciseId];

    // Remove the exercise drag item
    workoutDragItems.removed = [workoutExerciseId];

    // If group removed, remove the exercise options drag item
    if (groupRemoved) {
      const optionsKey = `${workoutExerciseGroupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;
      workoutDragItems.removed.push(optionsKey);
      // Add 1 to the total number of items removed
      totalRemovedDragItems += 1;
    }

    // If becoming a solo exercise remove drag items group header and footer
    if (becomingSoloGroup) {
      const groupHeaderKey = workoutExerciseGroupId;
      const groupFooterKey = `${workoutExerciseGroupId}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`;
      workoutDragItems.removed.push(groupHeaderKey, groupFooterKey);
      // Add 2 to the total number of items removed
      totalRemovedDragItems += 2;
    }

    // Remove all the workout exercise metrics belonging to the workout exercise
    const workoutExerciseMetricIds = Object.values(state.workoutExerciseMetrics.entities)
      .filter(
        (metric): metric is WorkoutExerciseMetric =>
          !!metric && metric.workoutExerciseId === workoutExerciseId
      )
      .map((metric) => metric.id);
    workoutExerciseMetrics.removed = workoutExerciseMetricIds;

    // Remove all the workout exercise metric values belonging to the workout exercise metrics
    const workoutExerciseMetricValueIds = Object.values(
      getState().workoutExerciseMetricValues.entities
    )
      .filter(
        (metricValue): metricValue is WorkoutExerciseMetricValue =>
          !!metricValue && metricValue.workoutExerciseId === workoutExerciseId
      )
      .map((metricValue) => metricValue.id);
    workoutExerciseMetricValues.removed = workoutExerciseMetricValueIds;

    // If the last exercise in the group was removed and the group wasn't removed then assign lastInGroup to exercise drag item above it
    if (wasExerciseLastInGroup && !groupRemoved) {
      // Find the exercise drag item above the removed exercise
      const aboveExercise = Object.values(state.workoutDragItems.entities).find(
        (item): item is WorkoutDragItem =>
          !!item &&
          item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
          item.groupId === groupId &&
          item.dragIndex === exerciseDragIndex - 1
      );

      if (!!aboveExercise) {
        const changes: Partial<WorkoutDragItem> = {
          lastInGroup: true,
          dragIndex: aboveExercise.dragIndex - 1,
        };
        // If there is only one exercise remaining in the group then update the inGroup to false
        if (becomingSoloGroup) {
          changes.inGroup = false;
        }

        const aboveExerciseUpdate = {
          id: aboveExercise.id,
          changes,
        };

        // Update drag items
        workoutDragItems.updated = workoutDragItems?.updated
          ? [...workoutDragItems.updated, aboveExerciseUpdate]
          : [aboveExerciseUpdate];
      }
    }

    // Find all the drag items below the removed item
    const affectedWorkoutDragItems = Object.values(state.workoutDragItems.entities).filter(
      (item): item is WorkoutDragItem =>
        !!item && item.dragIndex > exerciseDragIndex && item.workoutId === workoutId
    );

    // Update the drag index for all the affected items
    // If there are new groups update the group index of the affected items also
    const workoutExerciseGroupUpdates: UpdateType[] = [];
    const workoutDragItemUpdates: UpdateType[] = [];

    affectedWorkoutDragItems.forEach((item) => {
      let updatedDragIndex = item.dragIndex - totalRemovedDragItems;
      // When an exercise becomes a solo exercise the header and footer are removed
      // Because only the header is above this exercise in the group we only add 1
      // For all other items below the group we add 2
      if (
        becomingSoloGroup &&
        item.groupId === workoutExerciseGroupId &&
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
      ) {
        updatedDragIndex += 1;
      }

      const dragItemChanges: Partial<WorkoutDragItem> = {
        dragIndex: updatedDragIndex,
      };
      const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};

      // Update group indexes for Exercises and Exercise Options
      // Update indexes for Group headers and footers
      // If a group was removed then update the group index for any groups below the removed group
      if (groupRemoved) {
        // if (
        //   item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE ||
        //   item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
        // ) {
        //   if (item.groupIndex !== undefined) {
        //     // If the exercises aren't part of an existing group each new exercise has a new exercise group
        //     const updatedGroupIndex = item.groupIndex - 1;
        //     dragItemChanges.groupIndex = updatedGroupIndex;
        //   }
        // }
        // // If it is a Group header, footer or exercise options
        // if (
        //   item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS ||
        //   item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
        //   item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER
        // ) {
        //   const updatedGroupIndex = item.index - 1;
        //   dragItemChanges.index = updatedGroupIndex;
        // }

        // If the exercises aren't part of an existing group each new exercise has a new exercise group
        const updatedGroupIndex = item.groupIndex - 1; // -1 because the group was removed

        // Drag item changes
        if (item.groupIndex !== undefined) {
          dragItemChanges.groupIndex = updatedGroupIndex;
        }
        // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
        if (
          item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
          item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
          item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
        ) {
          dragItemChanges.index = updatedGroupIndex;
        }

        // ExerciseGroup changes
        // If the item is a group header or a solo exercise
        // Update the exercise group index
        if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
          exerciseGroupChanges.index = updatedGroupIndex;
        } else if (
          item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
          !item.inGroup &&
          item.groupIndex !== undefined
        ) {
          exerciseGroupChanges.index = updatedGroupIndex;
        }
      }
      // Else if a group wasn't removed
      else {
        // If part of the same group
        if (
          item.groupId === workoutExerciseGroupId &&
          item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
        ) {
          // If there is only one exercise remaining in the group then update the inGroup to false
          if (becomingSoloGroup) {
            dragItemChanges.inGroup = false;
          }

          // Update the index of any drag item or exercise below the removed exercise
          if (item.index > exerciseIndex) {
            const updatedIndex = item.index - 1;
            dragItemChanges.index = updatedIndex;

            // Update workout exercises
            const workoutExerciseUpdate = { id: item.id, changes: { index: updatedIndex } };
            workoutExercises.updated = workoutExercises?.updated
              ? [...workoutExercises.updated, workoutExerciseUpdate]
              : [workoutExerciseUpdate];
          }
        }
      }

      // Push updates
      workoutDragItemUpdates.push({
        id: item.id,
        changes: dragItemChanges,
      });
      if (exerciseGroupChanges?.index !== undefined) {
        workoutExerciseGroupUpdates.push({
          id: item.groupId,
          changes: exerciseGroupChanges,
        });
      }
    });

    // Update drag items
    workoutDragItems.updated = workoutDragItems?.updated
      ? [...workoutDragItems.updated, ...workoutDragItemUpdates]
      : workoutDragItemUpdates;
    // Update drag items
    workoutExerciseGroups.updated = workoutExerciseGroups?.updated
      ? [...workoutExerciseGroups.updated, ...workoutExerciseGroupUpdates]
      : workoutExerciseGroupUpdates;

    // Dispatch removed and updated data
    dispatch(
      removeExerciseAction({
        workouts,
        workoutExerciseGroups,
        workoutExercises,
        workoutDragItems,
        workoutExerciseMetrics,
        workoutExerciseMetricValues,
      })
    );
  };

export const duplicateWorkoutExercise =
  ({ workoutExerciseId }: { workoutExerciseId: string }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const changes: Changes = {};

    handleDuplicateWorkoutExercise({
      state,
      changes,
      workoutExerciseId,
    });

    dispatch(duplicateWorkoutExerciseAction(changes));
  };

export const swapWorkoutExercise =
  ({
    workoutExerciseId,
    newExercise,
  }: {
    workoutExerciseId: string;
    newExercise: Exercise_WithID;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    // Find the workout exercise
    const workoutExercise = state.workoutExercises.entities[workoutExerciseId];
    // Find the workout exercise metric values
    const values = Object.values(state.workoutExerciseMetricValues.entities).filter(
      (workoutExerciseMetricValue): workoutExerciseMetricValue is WorkoutExerciseMetricValue =>
        !!workoutExerciseMetricValue &&
        workoutExerciseMetricValue.workoutExerciseId === workoutExerciseId
    );

    // Exit if the workout exercise doesn't exist
    if (!workoutExercise) {
      return;
    }

    const { workoutId } = workoutExercise;

    const workoutExercises: ChangeData = {};
    const workoutExerciseMetricValues: ChangeData = {};
    const workouts: ChangeData = {
      updated: [{ id: workoutId, changes: {} }],
    };

    // Update the workout exercise
    const workoutExerciseUpdates: Partial<WorkoutExerciseType> = {
      exerciseId: newExercise.id,
      name: newExercise.name,
      youtubeId: newExercise.youtubeId,
      thumbnailUrl: newExercise.thumbnailUrl,
    };
    // Update the workout exercise metric values
    const workoutExerciseMetricValueUpdates: UpdateType[] = values.map((value) => ({
      id: value.id,
      changes: {
        exerciseId: newExercise.id,
      },
    }));

    // Add the changes to the change data
    workoutExercises.updated = [{ id: workoutExerciseId, changes: workoutExerciseUpdates }];
    workoutExerciseMetricValues.updated = workoutExerciseMetricValueUpdates;

    dispatch(
      swapWorkoutExerciseAction({
        workouts,
        workoutExercises,
        workoutExerciseMetricValues,
      })
    );
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllWorkoutExercises,
  selectById: selectWorkoutExerciseById,
  selectEntities: selectWorkoutExerciseEntities,
  // Pass in a selector that returns the posts slice of state
} = workoutExerciseAdapter.getSelectors((state: RootState) => state[WORKOUT_EXERCISES]);

const selectWorkoutExerciseId = (state: RootState, workoutExerciseId: string) => workoutExerciseId;

const selectWorkoutExerciseGroupId = (state: RootState, workoutExerciseGroupId: string) =>
  workoutExerciseGroupId;

export const getActiveWorkoutExerciseId = (state: RootState) =>
  state.workoutExercises.currentActiveWorkoutExerciseId;

export const getWorkoutExerciseSets = createSelector(
  [selectWorkoutExerciseEntities, selectWorkoutExerciseId],
  (entities, workoutExerciseId) => {
    const workoutExercise = entities[workoutExerciseId];

    if (workoutExercise) {
      return workoutExercise.sets;
    }

    return undefined;
  }
);

export const getWorkoutExerciseHeaderVisible = createSelector(
  [selectWorkoutExerciseEntities, selectWorkoutExerciseId],
  (entities, workoutExerciseId) => {
    const workoutExercise = entities[workoutExerciseId];

    if (workoutExercise) {
      return workoutExercise.headerVisible;
    }

    return undefined;
  }
);

export const getWorkoutExerciseCoachNotesVisible = createSelector(
  [selectWorkoutExerciseEntities, selectWorkoutExerciseId],
  (entities, workoutExerciseId) => {
    const workoutExercise = entities[workoutExerciseId];

    if (workoutExercise) {
      return workoutExercise.coachNotesVisible;
    }

    return undefined;
  }
);

export const getWorkoutExerciseSetsVisible = createSelector(
  [selectWorkoutExerciseEntities, selectWorkoutExerciseId],
  (entities, workoutExerciseId) => {
    const workoutExercise = entities[workoutExerciseId];

    if (workoutExercise) {
      return workoutExercise.setsVisible;
    }

    return undefined;
  }
);

export const isWorkoutExerciseInAGroup = createSelector(
  [selectAllWorkoutExercises, selectWorkoutExerciseGroupId],
  (workoutExercises, workoutExerciseGroupId) =>
    workoutExercises.filter((e) => e.workoutExerciseGroupId === workoutExerciseGroupId).length > 1
);

export const selectWorkoutExerciseGroupLength = createSelector(
  [selectAllWorkoutExercises, selectWorkoutExerciseGroupId],
  (workoutExercises, workoutExerciseGroupId) =>
    workoutExercises
      .filter((e) => e.workoutExerciseGroupId === workoutExerciseGroupId)
      .map((e) => e.id).length
);
