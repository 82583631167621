import { IconButton, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import Iconify from 'src/components/Iconify';
// components
import Image from 'src/components/Image';
import { CollapseButton, TextField, WorkoutExerciseMenu } from '.';

type Props = {
  thumbnailUrl?: string;
  name: string;
  hover: boolean;
  collapsed: boolean;
  headerText: string | null;
  headerVisible: boolean;
  toggleCollapsed: () => void;
  handleHeaderTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDuplicateExercise: () => void;
  handleExerciseSwap: () => void;
  handleExerciseCopyTo: () => void;
  handleRemoveExercise: () => void;
  handleOpenExerciseDetailsModal: () => void;
};

function WorkoutExerciseHeader({
  thumbnailUrl,
  name,
  hover,
  collapsed,
  headerText,
  headerVisible,
  toggleCollapsed,
  handleHeaderTextChange,
  handleDuplicateExercise,
  handleExerciseSwap,
  handleExerciseCopyTo,
  handleRemoveExercise,
  handleOpenExerciseDetailsModal,
}: Props) {
  const renderHeader = () => {
    if (headerVisible) {
      return (
        <Stack direction="row" alignItems="center" sx={{ px: 1, py: 1 }}>
          <TextField
            font="body2"
            fullWidth
            value={headerText}
            placeholder="Add a header"
            onChange={handleHeaderTextChange}
          />
          <CollapseButton active={hover} collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
          <WorkoutExerciseMenu
            active={hover}
            collapsed={collapsed}
            handleDuplicateExercise={handleDuplicateExercise}
            handleExerciseSwap={handleExerciseSwap}
            handleExerciseCopyTo={handleExerciseCopyTo}
            handleRemoveExercise={handleRemoveExercise}
          />
        </Stack>
      );
    }
  };

  const imageStyles = { mr: 2, minWidth: 80, width: 80, height: 80, cursor: 'pointer' };

  return (
    <>
      {renderHeader()}
      <Stack direction="row" alignItems="flex-start" sx={{ backgroundColor: 'background.neutral' }}>
        {!collapsed && <Image src={thumbnailUrl} alt={name} sx={imageStyles} />}
        <Stack
          direction="row"
          alignItems="center"
          // justifyContent="center"
          sx={{ flex: 1, p: 1, minHeight: 45 }}
        >
          <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
            <Typography
              sx={{ fontWeight: collapsed ? '600' : '300', ml: collapsed ? 1 : 0 }}
              variant={collapsed ? 'caption' : 'body2'}
            >
              {name}
            </Typography>
            <IconButton
              tabIndex={-1}
              onClick={handleOpenExerciseDetailsModal}
              sx={{ m: 0, mx: 1, p: 0 }}
            >
              <Iconify
                icon={'mdi:information'}
                color={hover ? 'text.primary' : 'action.disabledBackground'}
                width={20}
                height={20}
                sx={{ opacity: 0.2, ':hover': { opacity: 1 } }}
              />
            </IconButton>
          </Stack>

          <CollapseButton active={hover} collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
          <WorkoutExerciseMenu
            active={hover}
            collapsed={collapsed}
            handleDuplicateExercise={handleDuplicateExercise}
            handleExerciseSwap={handleExerciseSwap}
            handleExerciseCopyTo={handleExerciseCopyTo}
            handleRemoveExercise={handleRemoveExercise}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default memo(WorkoutExerciseHeader);
