import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit';
import { UserLoggedExercise } from 'src/@types/typesense';
import useTypesense from 'src/hooks/useTypesense';
import { RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents';
import { ExerciseGraphOption } from 'src/@types/exerciseGraphOptions';
import { resetClientProfileAction } from '../clientProfile';

const FETCH_LIMIT = 250;

const exerciseGraphOptionsAdapter = createEntityAdapter<ExerciseGraphOption>({
  // Sort by name
  sortComparer: (a, b) => a.exerciseName.localeCompare(b.exerciseName),
});

const initialState = exerciseGraphOptionsAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null });

export const fetchExerciseGraphOptions = createAsyncThunk<
  ExerciseGraphOption[],
  {
    userId: string;
    searchPhrase?: string;
  }
>('exerciseGraphOptions/fetchExerciseGraphOptions', async ({ userId, searchPhrase }) => {
  const client = useTypesense();
  const items: {
    [key: string]: ExerciseGraphOption;
  } = {};

  const searchParameters: SearchParams = {
    q: '*',
    query_by: 'exerciseName', // could be anything
    filter_by: `userId:=${userId}`,
    // sort_by: 'exerciseName:desc',
    per_page: FETCH_LIMIT,
  };

  if (searchPhrase) {
    searchParameters.q = searchPhrase;
    delete searchParameters.sort_by;
  }

  const res = await client.collections('userLoggedExercises').documents().search(searchParameters);

  const response = res as unknown as SearchResponse<UserLoggedExercise>;
  if (response?.hits && response.hits.length > 0) {
    const docs = response.hits.map((hit) => hit.document);
    docs.forEach((doc) => {
      items[doc.exerciseId] = {
        id: doc.exerciseId,
        exerciseName: doc.exerciseName,
        exerciseImageUrl: doc?.exerciseImageUrl,
      };
    });
  }

  return Object.values(items);
});

export const slice = createSlice({
  name: 'exerciseGraphOptions',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      // Reset case
      .addCase(fetchExerciseGraphOptions.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchExerciseGraphOptions.fulfilled, (state, action) => {
        // Upsert all the added exercise metrics
        const items = action.payload;

        exerciseGraphOptionsAdapter.upsertMany(state, items);
        // Change status
        state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      })
      .addCase(fetchExerciseGraphOptions.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // External
      .addCase(resetClientProfileAction, () => initialState);
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllExerciseGraphOptions } =
  exerciseGraphOptionsAdapter.getSelectors((state: RootState) => state.exerciseGraphOptions);

export const getExerciseGraphOptionsFetchStatus = (state: RootState) =>
  state.exerciseGraphOptions.status;
