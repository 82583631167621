import { WorkoutDragItem, WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { createWorkoutExerciseGroup } from 'src/utils/createDragItems';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  movedItem: WorkoutDragItem;
  destinationItem: WorkoutDragItem;
  movedDown: boolean;
  movedUp: boolean;
};

// If solo exercise was moved to a new group (combined)
const handleMoveGroupToNewGroupNewWorkout = ({
  state,
  movedItem,
  destinationItem,
  movedDown,
  movedUp,
}: Props) => {
  const changes: Changes = {};

// Make sure updates get pushed to database
  const workoutUpdates = [{ id: movedItem.workoutId, changes: {} }, { id: destinationItem.workoutId, changes: {} }];
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutDragItems = {};
  changes.workoutExercises = {};

  // Find the exercise ids belonging to the moved group
  const exerciseIds = Object.values(state.workoutExercises.entities)
    .filter(
      (exercise): exercise is WorkoutExercise =>
        !!exercise && exercise.workoutExerciseGroupId === movedItem.groupId
    )
    .map((exercise) => exercise.id);
  const numberOfNewExercises = exerciseIds.length;

  const from = movedItem.dragIndex;
  const to = destinationItem.dragIndex;

  const destinationGroupIndex =
    destinationItem?.groupIndex !== undefined ? destinationItem.groupIndex : destinationItem.index;

  const newGroupIndex = destinationGroupIndex;
  const newDragIndex = destinationItem.dragIndex;

  let numberOfMovedItems = 0;

  // Create a group header
  const newGroupHeader = createWorkoutExerciseGroup({
    id: destinationItem.groupId,
    index: newGroupIndex,
    dragIndex: newDragIndex,
    workoutId: destinationItem.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER,
    inGroup: true,
  });
  // Increment numberOfMovedItems
  numberOfMovedItems += 1;

  // Update destinationItem
  const destinationDragItemChanges: Partial<WorkoutDragItem> = {
    dragIndex: newDragIndex + numberOfMovedItems,
    inGroup: true,
    lastInGroup: false,
    groupIndex: newGroupIndex,
  };
  // Increment numberOfMovedItems
  numberOfMovedItems += 1;

  // Update moved drag items
  const movedExerciseDragItemChanges: UpdateType[] = exerciseIds.map((exerciseId, i) => {
    const changes: Partial<WorkoutDragItem> = {
      index: 1 + i,
      groupId: destinationItem.groupId,
      groupIndex: newGroupIndex,
      dragIndex: newDragIndex + numberOfMovedItems,
      workoutId: destinationItem.workoutId,
    };

    // Increment numberOfMovedItems
    numberOfMovedItems += 1;

    return {
      id: exerciseId,
      changes: changes,
    };
  });

  // Update moved exercises
  const movedExercisesChanges: UpdateType[] = exerciseIds.map((exerciseId, i) => {
    const changes: Partial<WorkoutExercise> = {
      index: 1 + i,
      workoutExerciseGroupId: destinationItem.groupId,
    };

    return {
      id: exerciseId,
      changes: changes,
    };
  });

  // Create a group footer
  const newGroupFooter = createWorkoutExerciseGroup({
    id: destinationItem.groupId,
    index: newGroupIndex,
    dragIndex: newDragIndex + numberOfMovedItems,
    workoutId: destinationItem.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER,
    inGroup: true,
  });
  // Increment numberOfMovedItems
  numberOfMovedItems += 1;

  // Update destination exercise options
  const destinationExerciseOptionsKey = `${destinationItem.groupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;
  const destinationExerciseOptionsUpdate = {
    inGroup: true,
    dragIndex: newDragIndex + numberOfMovedItems,
  };
  // Increment numberOfMovedItems
  numberOfMovedItems += 1;

  // Remove movedItem group header and footer
  const groupHeaderKey = movedItem.groupId;
  const groupFooterKey = `${movedItem.groupId}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`;

  // Remove movedItem group
  const removedGroupKey = movedItem.groupId;

  // Remove exercise options
  const exerciseOptionsKey = `${movedItem.groupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;

  // Push the changes
  changes.workoutDragItems.added = [newGroupHeader, newGroupFooter];
  changes.workoutDragItems.updated = [
    {
      id: destinationItem.id,
      changes: destinationDragItemChanges,
    },
    ...movedExerciseDragItemChanges,
    {
      id: destinationExerciseOptionsKey,
      changes: destinationExerciseOptionsUpdate,
    },
  ];
  changes.workoutExercises.updated = [...movedExercisesChanges];
  changes.workoutDragItems.removed = [groupHeaderKey, groupFooterKey, exerciseOptionsKey];
  changes.workoutExerciseGroups.removed = [removedGroupKey];

  // Find all the affected drag items
  // If between to and from
  // If moved item group index > item group index
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      // If the item is undefined or the workout id isn't the same as movedItem or destinationItem then skip
      if (
        !item ||
        (item.workoutId !== movedItem.workoutId && item.workoutId !== destinationItem.workoutId)
      ) {
        return false;
      }

      // Exclude items from the moved group
      if (item.groupId === movedItem.groupId) {
        return false;
      }

      // Find all items below the destination item
      if (item.workoutId === destinationItem.workoutId && item.dragIndex >= to) {
        return true;
      }
      // Find all items below the moved item
      else if (item.workoutId === movedItem.workoutId && item.dragIndex > from) {
        return true;
      }

      return false;
    }
  );

  const workoutExerciseGroupUpdates: UpdateType[] = [];
  const workoutDragItemUpdates: UpdateType[] = [];
  // Update the affected items
  affectedItems.forEach((item) => {
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    const dragItemChanges: Partial<WorkoutDragItem> = {};

    // If item was below the moved item
    if (item.workoutId === movedItem.workoutId && item.dragIndex > from) {
      dragItemChanges.dragIndex = item.dragIndex - (numberOfNewExercises + 3); // +3 for exercise options & the group header and footer
    }

    // If item was below the destination item
    if (item.workoutId === destinationItem.workoutId && item.dragIndex > to) {
      dragItemChanges.dragIndex =
        (dragItemChanges?.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) +
        numberOfNewExercises +
        2; // +2 because of new header and footer
    }

    // If item belongs to the moved item workout
    if (item.workoutId === movedItem.workoutId) {
      // Update any groups below the removed moved item group
      const itemGroupIndex = item.groupIndex !== undefined ? item.groupIndex : item.index;
      if (itemGroupIndex > movedItem.index) {
        // Update group indexes
        if (item.groupIndex !== undefined) {
          dragItemChanges.groupIndex = item.groupIndex - 1;
        }
        // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
        if (
          item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
          item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
          item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
        ) {
          dragItemChanges.index = item.index - 1;
        }

        // If the item is a group header or a solo exercise
        // Update the exercise group index
        if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
          exerciseGroupChanges.index = item.index - 1;
        } else if (
          item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
          !item.inGroup &&
          item.groupIndex !== undefined
        ) {
          exerciseGroupChanges.index = item.groupIndex - 1;
        }
      }
    }

    // Push updates
    workoutDragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });
    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...workoutDragItemUpdates);

  if (workoutExerciseGroupUpdates.length) {
    changes.workoutExerciseGroups.updated = [...workoutExerciseGroupUpdates];
  }

  return changes;
};

export default handleMoveGroupToNewGroupNewWorkout;
