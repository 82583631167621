import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { Changes, ChangeTackerData, CHANGE_TRACKER_TYPE } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { programResetAction, updateProgramAction } from './program';
import { trackChanges } from 'src/redux/functions/trackChanges';
import {
  addWorkoutExerciseMetricAction,
  removeWorkoutExerciseMetricAction,
  reorderWorkoutExerciseMetricsAction,
} from './workoutExerciseMetrics';
import {
  removeExerciseGroupAction,
  duplicateWorkoutExerciseGroupAction,
  updateExerciseGroupAction,
} from './workoutExerciseGroups';
import {
  removeWeekAction,
  duplicateWeekAction,
  addWeekAction,
  reorderWeeksAction,
  updateWeekAction,
} from './programWeeks';
import {
  updateSetsAction,
  addExercisesAction,
  removeExerciseAction,
  duplicateWorkoutExerciseAction,
  updateExerciseAction,
  toggleSetsAction,
  swapWorkoutExerciseAction,
} from './workoutExercises';
import {
  removeWorkoutAction,
  duplicateWorkoutAction,
  addWorkoutAction,
  reorderWorkoutsAction,
  updateWorkoutAction,
} from './workouts';
import { groupingAction, reorderExercisesAction } from './workoutDragItems';
import { updateWorkoutExerciseMetricValueAction } from './workoutExerciseMetricValues';
import { PROGRAM_DATA_KEYS_ENUM } from 'src/@types/enums';
import { copyToCurrentProgram } from './copyToModal';

const programChangeTackerAdapter = createEntityAdapter<ChangeTackerData>({
  // Sort by index
  // sortComparer: (a: ProgramWeekWithStatus, b: ProgramWeekWithStatus) => a.index - b.index,
});

const initialState = programChangeTackerAdapter.getInitialState();

export const slice = createSlice({
  name: 'programChangeTacker',
  initialState,
  reducers: {
    removeChanges: (state, action: PayloadAction<ChangeTackerData[]>) => {
      const changesToRemoveIds = action.payload.map((change) => change.id);
      programChangeTackerAdapter.removeMany(state, changesToRemoveIds);
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    const handleChangesReducer = (state: any, action: PayloadAction<Changes>) => {
      const changes = action.payload;
      trackChanges(changes, programChangeTackerAdapter, state);
    };

    builder
      // External
      // Program
      .addCase(updateProgramAction, (state, action) => {
        const changeTrackerData: ChangeTackerData = {
          changeKeyType: PROGRAM_DATA_KEYS_ENUM.PROGRAM,
          id: action.payload.id,
          type: CHANGE_TRACKER_TYPE.UPDATED,
        };
        programChangeTackerAdapter.addOne(state, changeTrackerData);
      })
      .addCase(copyToCurrentProgram, handleChangesReducer)
      // Program Weeks
      .addCase(addWeekAction, handleChangesReducer)
      .addCase(updateWeekAction, handleChangesReducer)
      .addCase(removeWeekAction, handleChangesReducer)
      .addCase(duplicateWeekAction, handleChangesReducer)
      .addCase(reorderWeeksAction, handleChangesReducer)
      // Workouts
      .addCase(addWorkoutAction, handleChangesReducer)
      .addCase(updateWorkoutAction, handleChangesReducer)
      .addCase(removeWorkoutAction, handleChangesReducer)
      .addCase(duplicateWorkoutAction, handleChangesReducer)
      .addCase(reorderWorkoutsAction, handleChangesReducer)
      // Workout Exercise Groups
      .addCase(updateExerciseGroupAction, handleChangesReducer)
      .addCase(removeExerciseGroupAction, handleChangesReducer)
      .addCase(groupingAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseGroupAction, handleChangesReducer)
      // Workout Exercises
      .addCase(addExercisesAction, handleChangesReducer)
      .addCase(updateExerciseAction, handleChangesReducer)
      .addCase(removeExerciseAction, handleChangesReducer)
      .addCase(reorderExercisesAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseAction, handleChangesReducer)
      .addCase(swapWorkoutExerciseAction, handleChangesReducer)
      .addCase(updateSetsAction, handleChangesReducer)
      .addCase(toggleSetsAction, handleChangesReducer)
      // Exercise metrics
      .addCase(addWorkoutExerciseMetricAction, handleChangesReducer)
      .addCase(removeWorkoutExerciseMetricAction, handleChangesReducer)
      .addCase(reorderWorkoutExerciseMetricsAction, handleChangesReducer)
      // Exercise metric values
      .addCase(updateWorkoutExerciseMetricValueAction, handleChangesReducer)

      .addCase(programResetAction, () => initialState);
  },
});

export const { removeChanges, reset } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllProgramChangeTackerItems,
  selectById: selectProgramChangeTackerItemById,
  selectEntities: selectProgramChangeTackerEntities,
  // Pass in a selector that returns the posts slice of state
} = programChangeTackerAdapter.getSelectors((state: RootState) => state.programChangeTracker);

export const programHasChanges = (state: RootState) => {
  const programChangeTackerItems = selectAllProgramChangeTackerItems(state);
  return programChangeTackerItems.length > 0;
};
