// @mui
import { styled } from '@mui/material/styles';
import { Typography, CardProps, Stack } from '@mui/material';
// utils
import cssStyles from 'src/utils/cssStyles';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  color: theme.palette.primary.darker,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const StyledBg = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: -1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: theme.palette.common.white,
  '&:before': {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: -2,
    content: '""',
    opacity: 0.2,
    ...cssStyles(theme).bgGradient({
      direction: '135deg',
      startColor: theme.palette.primary.light,
      endColor: theme.palette.primary.main,
    }),
  },
}));

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  description?: string;
  action?: React.ReactNode;
}

/*
 * This component is used to display a banner for inactive clients
 * @param {string} title - The title of the banner
 * @param {string} description - The description of the banner
 * @param {React.ReactNode} action - The action to be displayed on the banner
 * @param {CardProps} other - The other props to be passed to the card
 * @returns {React.ReactNode} - The component
 */

/*
  Program users can be inactive clients, active clients, non-clients.

  We want to handle inactive clients and non-clients differently.

  Show reactivate button and remove button if the user is an inactive client.
  Show add button and remove button if the user is a non-client.
*/

export default function InactiveClientBanner({ title, description, action, ...other }: Props) {
  return (
    <StyledRoot {...other}>
      <Stack
        flexGrow={1}
        justifyContent="center"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        sx={{
          pl: 5,
          py: { xs: 5, md: 5 },
          pr: { xs: 5, md: 5 },
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography paragraph variant="h4" sx={{ whiteSpace: 'pre-line' }}>
          {title}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            opacity: 0.8,
            mb: { xs: 3, xl: 5 },
          }}
        >
          {description}
        </Typography>

        {action && action}
      </Stack>

      <StyledBg />
    </StyledRoot>
  );
}
