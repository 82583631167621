import { IconButton, Stack, Typography } from '@mui/material';
import React, { memo } from 'react';
import { Exercise_WithID } from 'src/@types/firebase';
import Image from 'src/components/Image';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { toggleExerciseSelected } from 'src/redux/slices/program/exercisesToAdd';
import { useDispatch } from 'src/redux/store';

type Props = {
  exercise: Exercise_WithID;
};

function ExerciseSelectedCard({ exercise }: Props) {
  const { name, thumbnailUrl } = exercise;
  const dispatch = useDispatch();

  const toggleSelected = () => {
    dispatch(toggleExerciseSelected(exercise));
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{
        px: 4,
        py: 2,
        borderBottom: 0.1,
        borderColor: 'divider',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: 'background.neutral',
        },
      }}
    >
      <Image src={thumbnailUrl} alt={name} sx={{ width: 75, height: 75, borderRadius: 1 }} />
      <Typography variant="body2" sx={{ flex: 1 }}>
        {name}
      </Typography>
      <IconButton onClick={toggleSelected} sx={{ ml: 1 }}>
        <SvgIconStyle
          src={`/icons/ic_trash.svg`}
          sx={{
            width: 20,
            height: 20,
            color: 'text.primary',
            opacity: 0.5,
            ':hover': { opacity: 1 },
          }}
        />
      </IconButton>
    </Stack>
  );
}

export default memo(ExerciseSelectedCard);
