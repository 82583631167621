import { PROGRAM_DATA_KEYS_ENUM } from 'src/@types/enums';

export const {
  PROGRAM,
  PROGRAM_WEEKS,
  WORKOUTS,
  WORKOUT_EXERCISE_GROUPS,
  WORKOUT_EXERCISES,
  WORKOUT_EXERCISE_METRICS,
  WORKOUT_EXERCISE_METRIC_VALUES,
  WORKOUT_DRAG_ITEMS,
} = PROGRAM_DATA_KEYS_ENUM;
