import { Program_WithID } from 'src/@types/firebase';

const cleanProgramData = (program: Program_WithID) => {
  // Clean program data, remove unnecessary properties
  const cleanProgram: Program_WithID = {
    id: program.id,
    dateCreated: program.dateCreated,
    title: program.title,
    imageUrl: program.imageUrl,
    description: program.description,
    published: program.published,
    lastUpdated: program.lastUpdated,
    creators: program.creators,
    creatorIds: program.creatorIds,
    users: program.users,
    userIds: program.userIds,
  };

  // if featured
  if (program.featured) {
    cleanProgram.featured = program.featured;
  }

  return cleanProgram;
};

export default cleanProgramData;
