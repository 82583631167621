import _ from 'lodash';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';
import {
  WorkoutDragItem as WorkoutDragItemType,
  WorkoutExercise as WorkoutExerciseType,
  WorkoutExerciseGroup as WorkoutExerciseGroupType,
} from 'src/@types/program';
import {
  createWorkoutExerciseGroup,
  createWorkoutExercise,
  createWorkoutExerciseOptions,
} from './createDragItems';

// Converts group exercises to drag items
const convertExercisesToDragItems = (
  exercises: WorkoutExerciseType[],
  group: WorkoutExerciseGroupType,
  dragIndex: number,
  inLastGroup: boolean
) =>
  exercises
    .sort((a, b) => a.index - b.index)
    .reduce((acc: WorkoutDragItemType[], workoutExercise: WorkoutExerciseType) => {
      const soloExercise = exercises.length === 1;
      // Check if this is the last exercise from the last group
      const lastExercise = workoutExercise.index === exercises.length - 1;
      // Create workout exercise drag item
      const exerciseData = {
        id: workoutExercise.id,
        index: workoutExercise.index,
        dragIndex: dragIndex,
        groupId: group.id,
        workoutId: group.workoutId,
        inGroup: soloExercise ? false : true,
        groupIndex: group.index,
        lastInGroup: lastExercise,
      };
      const exerciseItem = createWorkoutExercise({ ...exerciseData });
      // Increment drag index
      dragIndex++;
      // If solo exercise and not the last exercise of the last group
      if (
        soloExercise
        // && !inLastGroup
      ) {
        const exerciseOptionsItem = createWorkoutExerciseOptions({ ...exerciseData, dragIndex });
        // Increment drag index
        dragIndex++;
        // Return just the exercise item and exercise options item
        return [...acc, exerciseItem, exerciseOptionsItem];
      } else {
        return [...acc, exerciseItem];
      }
    }, []);

const convertWorkoutExerciseGroupToDragItems = (
  group: WorkoutExerciseGroupType,
  groupExercises: WorkoutExerciseType[],
  dragIndex: number,
  lastGroup: boolean
) => {
  const groupData = {
    id: group.id,
    index: group.index,
    dragIndex: dragIndex,
    workoutId: group.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER,
  };
  // Converts a group into a drag item header
  const dragItemGroupHeader = createWorkoutExerciseGroup({ ...groupData });
  // Increment drag index
  dragIndex++;

  // Converts the group exercises to drag items
  const dragItemExercises = convertExercisesToDragItems(
    groupExercises,
    group,
    dragIndex,
    lastGroup
  );

  // Increment drag index
  dragIndex += dragItemExercises.length;
  // Converts a group into a drag item footer
  const dragItemGroupFooter = createWorkoutExerciseGroup({
    ...groupData,
    dragIndex,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER,
  });

  // if (lastGroup) {
  //   return [dragItemGroupHeader, ...dragItemExercises, dragItemGroupFooter];
  // } else {
  // Increment drag index
  dragIndex++;
  const exerciseOptionsItem = createWorkoutExerciseOptions({
    ...groupData,
    groupId: group.id,
    groupIndex: groupData.index,
    dragIndex,
  });
  // Return the group header, group exercises, group footer, and exercise options item
  return [dragItemGroupHeader, ...dragItemExercises, dragItemGroupFooter, exerciseOptionsItem];
  // }
};

export const createWorkoutDragData = (
  exerciseGroups: Array<WorkoutExerciseGroupType>,
  workoutExercises: Array<WorkoutExerciseType>
) => {
  // Group exercise groups by workout id
  const workouts = _.groupBy(exerciseGroups, 'workoutId');

  // Array for all the drag items to be added to
  const workoutDragItems: WorkoutDragItemType[] = [];
  // Loop through each workout
  Object.values(workouts).forEach((workoutGroups, index) => {
    // workout drag item
    // eslint-disable-next-line prefer-const
    // let dragIndex = 0;

    const items = workoutGroups
      .sort((a, b) => a.index - b.index)
      .reduce((acc: Array<WorkoutDragItemType>, group: WorkoutExerciseGroupType, index: number) => {
        const lastGroup = index === workoutGroups.length - 1;
        const groupExercises = workoutExercises.filter(
          (workoutExercise) => workoutExercise.workoutExerciseGroupId === group.id
        );
        const soloExercise = groupExercises.length === 1;
        const dragIndex = acc.length;

        if (soloExercise) {
          // Return only the solo exercise. No header or footer
          return [
            ...acc,
            ...convertExercisesToDragItems(groupExercises, group, dragIndex, lastGroup),
          ];
        }
        // If there are two or more exercises return a group else return the solo exercise
        else {
          // Return the group header, exercises, and group footer
          const groupDragItems = convertWorkoutExerciseGroupToDragItems(
            group,
            groupExercises,
            dragIndex,
            lastGroup
          );
          // Add group drag items to the array
          return [...acc, ...groupDragItems];
        }
      }, []);

    workoutDragItems.push(...items);
  });

  return workoutDragItems;
};
