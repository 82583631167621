import { Link, Alert, Typography, Stack } from '@mui/material';
import useCurrentUser from 'src/hooks/useCurrentUser';

const HeaderAlert = () => {
  const { user } = useCurrentUser();

  const renderFailedInvoices = () => {
    const failedInvoices = user?.stripe?.failedInvoices
      ? Object.values(user.stripe.failedInvoices)
      : undefined;

    if (!failedInvoices || failedInvoices.length === 0) {
      return null;
    }

    return failedInvoices.map((invoice) => (
      <Link key={invoice.id} href={invoice.hostedInvoiceUrl} target="_blank">
        <Alert severity="error">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2" sx={{ mr: 1 }}>
              You have a failed payment. Please pay the failed invoice to continue using ONI
            </Typography>
            <Link href={invoice.hostedInvoiceUrl} target="_blank">
              Pay Invoice
            </Link>
          </Stack>
        </Alert>
      </Link>
    ));
  };

  return <>{renderFailedInvoices()}</>;
};

export default HeaderAlert;
