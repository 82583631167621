// types
import { ProgramWeek_WithID } from 'src/@types/firebase';
import { Changes } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
// functions
import handleDuplicateWeek from '../duplicate/handleDuplicateWeek';

type Props = {
  week: ProgramWeek_WithID;
  state: RootState;
  changes: Changes;
};

/**
 * Duplicates a Week in the program currently in use
 *
 * @param week - Program week
 * @param state - The current state of the store
 * @param changes - The changes to be made to the current program
 *
 */
const handleCopyWeekToCurrentProgram = ({ week, state, changes }: Props) => {
  handleDuplicateWeek({
    state,
    changes,
    weekId: week.id,
    copyToProgramId: week.programId,
  });
};

export default handleCopyWeekToCurrentProgram;
