import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { Program_WithID } from 'src/@types/firebase';
import { RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { assignProgramsToUser, deleteProgram, unassignProgramUser } from './programs';
import { saveProgram, saveProgramDetails } from './program/program';

const initialState: {
  programs: Program_WithID[];
  clientProgramsFetchStatus: FETCH_STATUS_TYPES_ENUM;
  clientProgramsError: string | null;
} = {
  programs: [],
  clientProgramsFetchStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  clientProgramsError: null,
};

export const fetchClientPrograms = createAsyncThunk<Program_WithID[], string>(
  'clientProfile/fetchClientPrograms',
  async (userId) => {
    const q = query(collection(DB, 'programs'), where('userIds', 'array-contains', userId));

    const programs: Program_WithID[] = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      programs.push({ ...doc.data(), id: doc.id } as Program_WithID);
    });
    return programs;
  }
);

export const resetClientProfileAction = createAction('clientProfile/resetClientProfile');

export const slice = createSlice({
  name: 'clientProfile',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Reset case
      .addCase(resetClientProfileAction, () => initialState)

      // Fetch client programs
      .addCase(fetchClientPrograms.pending, (state) => {
        state.clientProgramsFetchStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchClientPrograms.fulfilled, (state, action) => ({
        ...state,
        programs: action.payload,
        clientProgramsFetchStatus: FETCH_STATUS_TYPES_ENUM.SUCCEEDED,
      }))
      .addCase(fetchClientPrograms.rejected, (state, action) => {
        state.clientProgramsFetchStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.clientProgramsError = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // External
      .addCase(deleteProgram.fulfilled, (state, action) => {
        const itemId = action.payload;
        state.programs = state.programs.filter((item) => item.id !== itemId);
      })
      .addCase(unassignProgramUser.fulfilled, (state, action) => {
        const item = action.payload;
        // Remove program in state if it exists
        state.programs = state.programs.filter((p) => p.id !== item.id);
      })
      .addCase(assignProgramsToUser.fulfilled, (state, action) => {
        const items = action.payload;
        // Add programs to state
        state.programs = state.programs.concat(items);
      })
      .addCase(saveProgram.fulfilled, (state, action) => {
        const item = action.payload;
        // Replace program in state if it exists
        const index = state.programs.findIndex((p) => p.id === item.id);
        if (index !== -1) {
          state.programs[index] = item;
        }
      })
      .addCase(saveProgramDetails.fulfilled, (state, action) => {
        const item = action.payload;
        // Replace program in state if it exists
        const index = state.programs.findIndex((p) => p.id === item.id);
        if (index !== -1) {
          state.programs[index] = item;
        }
      });
  },
});

export default slice.reducer;

export const getClientProfile = (state: RootState) => state.clientProfile;
