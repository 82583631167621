// types
import { WorkoutExerciseGroup } from 'src/@types/program';
import { Changes } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
// functions
import { handleDuplicateWorkoutExerciseGroup } from '../duplicate';

type Props = {
  exerciseGroup: WorkoutExerciseGroup;
  newWorkoutId: string;
  state: RootState;
  changes: Changes;
};

/**
 * Duplicates a Exercise Group to a new destination in the program currently in use
 *
 * @param exerciseGroup - Exercise Group
 * @param newWorkoutId - The id of the workout to add the exercise group to.
 * @param state - The current state of the store
 * @param changes - The changes to be made to the current program
 *
 */
const handleCopyExerciseGroupToCurrentProgram = ({
  exerciseGroup,
  state,
  newWorkoutId,
  changes,
}: Props) => {
  handleDuplicateWorkoutExerciseGroup({
    state,
    changes,
    workoutExerciseGroupId: exerciseGroup.id,
    copyToWorkoutId: newWorkoutId,
  });
};

export default handleCopyExerciseGroupToCurrentProgram;
