import { HABIT_FREQUENCY_ENUM, HABIT_UNIT_ENUM } from 'src/@types/enums';
import { Habit, User_WithID } from 'src/@types/firebase';

type Props = {
  user: User_WithID;
};

const generateDefaultHabitsForUser = ({ user }: Props) => {
  const creator = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    profilePictureUrl: user.profilePictureUrl,
  };

  const defaultHabits: Habit[] = [
    {
      active: true,
      dateCreated: new Date(),
      lastUpdated: new Date(),
      creator: creator,
      name: 'Eat Vegetables',
      description: '',
      index: 8,
      color: '#55AB67',
      icon: {
        webIcon: 'mdi:silverware',
        mobileIcon: 'silverware',
        name: 'Food',
      },
      target: {
        value: [3, 6],
        unit: HABIT_UNIT_ENUM.SERVINGS,
        frequency: HABIT_FREQUENCY_ENUM.PER_DAY,
      },
      users: {},
      userIds: [],
    },
    {
      active: true,
      dateCreated: new Date(),
      lastUpdated: new Date(),
      creator: creator,
      name: 'Eat Protein',
      description: '',
      index: 7,
      color: '#EF8731',
      icon: {
        webIcon: 'mdi:silverware',
        mobileIcon: 'silverware',
        name: 'Food',
      },
      target: {
        value: [3, 5],
        unit: HABIT_UNIT_ENUM.SERVINGS,
        frequency: HABIT_FREQUENCY_ENUM.PER_DAY,
      },
      users: {},
      userIds: [],
    },
    {
      active: true,
      dateCreated: new Date(),
      lastUpdated: new Date(),
      creator: creator,
      name: 'Eat Carbs',
      description: '',
      index: 6,
      color: '#E76C67',
      icon: {
        webIcon: 'mdi:silverware',
        mobileIcon: 'silverware',
        name: 'Food',
      },
      target: {
        value: [3, 6],
        unit: HABIT_UNIT_ENUM.SERVINGS,
        frequency: HABIT_FREQUENCY_ENUM.PER_DAY,
      },
      users: {},
      userIds: [],
    },
    {
      active: true,
      dateCreated: new Date(),
      lastUpdated: new Date(),
      creator: creator,
      name: 'Eat Healthy Fats',
      description: '',
      index: 5,
      color: '#F05C88',
      icon: {
        webIcon: 'mdi:silverware',
        mobileIcon: 'silverware',
        name: 'Food',
      },
      target: {
        value: [3, 6],
        unit: HABIT_UNIT_ENUM.SERVINGS,
        frequency: HABIT_FREQUENCY_ENUM.PER_DAY,
      },
      users: {},
      userIds: [],
    },
    {
      active: true,
      dateCreated: new Date(),
      lastUpdated: new Date(),
      creator: creator,
      name: 'Drink Water',
      description: '',
      index: 4,
      color: '#1B8FFF',
      icon: {
        webIcon: 'mdi:opacity',
        mobileIcon: 'opacity',
        name: 'Water',
      },
      target: {
        value: [8],
        unit: HABIT_UNIT_ENUM.SERVINGS,
        frequency: HABIT_FREQUENCY_ENUM.PER_DAY,
      },
      users: {},
      userIds: [],
    },
    {
      active: true,
      dateCreated: new Date(),
      lastUpdated: new Date(),
      creator: creator,
      name: 'Meal Prep',
      description: '',
      index: 3,
      color: '#FFC107',
      icon: {
        webIcon: 'mdi:silverware',
        mobileIcon: 'silverware',
        name: 'Food',
      },
      target: {
        value: [1],
        unit: HABIT_UNIT_ENUM.TIMES,
        frequency: HABIT_FREQUENCY_ENUM.PER_WEEK,
      },
      users: {},
      userIds: [],
    },
    {
      active: true,
      dateCreated: new Date(),
      lastUpdated: new Date(),
      creator: creator,
      name: 'Sleep (Hours)',
      description: '',
      index: 2,
      color: '#72CEDF',
      icon: {
        webIcon: 'mdi:weather-night',
        mobileIcon: 'weather-night',
        name: 'Sleep',
      },
      target: {
        value: [7, 9],
        unit: HABIT_UNIT_ENUM.TIMES,
        frequency: HABIT_FREQUENCY_ENUM.PER_DAY,
      },
      users: {},
      userIds: [],
    },
    {
      active: true,
      dateCreated: new Date(),
      lastUpdated: new Date(),
      creator: creator,
      name: 'Meditate',
      description: '',
      index: 1,
      color: '#CA8CD7',
      icon: { webIcon: 'mdi:meditation', mobileIcon: 'meditation', name: 'Meditation' },
      target: {
        value: [1],
        unit: HABIT_UNIT_ENUM.TIMES,
        frequency: HABIT_FREQUENCY_ENUM.PER_DAY,
      },
      users: {},
      userIds: [],
    },
    {
      active: true,
      dateCreated: new Date(),
      lastUpdated: new Date(),
      creator: creator,
      name: 'Time In Nature',
      description: '',
      index: 0,
      color: '#C6E367',
      icon: { webIcon: 'mdi:forest', mobileIcon: 'forest', name: 'Nature' },
      target: {
        value: [1],
        unit: HABIT_UNIT_ENUM.TIMES,
        frequency: HABIT_FREQUENCY_ENUM.PER_DAY,
      },
      users: {},
      userIds: [],
    },
  ];

  return defaultHabits;
};

export default generateDefaultHabitsForUser;
