import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { IconButton, MenuItem, useTheme } from '@mui/material';
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import { useSnackbar } from 'notistack';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { deleteProgram } from 'src/redux/slices/programs';
import { useDispatch } from 'src/redux/store';
import usePrompt from 'src/hooks/usePrompt';
import { openProgramImageModal } from 'src/redux/slices/program/programImageModal';
//

// ----------------------------------------------------------------------

type Props = {
  programId: string;
};

export default function ProgramMenu({ programId }: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { openPrompt } = usePrompt();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(null);
  };

  const handleImageEdit = () => {
    dispatch(openProgramImageModal({ visible: true }));
    setOpen(null);
  };

  const handleDelete = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    openPrompt({
      title: 'Delete?',
      message:
        "Are you sure you want to delete this program? Any clients or users will no longer be able to access this program once it's removed. This action cannot be undone.",
      confirmLoadingMessage: 'Deleting...',
      confirmText: 'Delete',
      cancelText: 'Cancel',
      confirm: async () => {
        await dispatch(deleteProgram({ programId }));
        enqueueSnackbar('Program has been deleted.', { variant: 'success' });
        setOpen(null);
        navigate(PATH_DASHBOARD.programs.root);
      },
      cancel: async () => {
        setOpen(null);
      },
    });
  };

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ ml: 1 }}>
        <Iconify
          icon={'eva:more-vertical-fill'}
          width={25}
          height={25}
          color={isLight ? 'common.black' : 'common.white'}
          // sx={{ opacity: 0.5, ':hover': { opacity: 1 } }}
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="top-right"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        <MenuItem onClick={handleImageEdit}>
          <Iconify icon={'mdi:file-image-outline'} />
          Edit Image
        </MenuItem>

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}
