import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import { USER_TYPE_ENUM } from 'src/@types/enums';
import useUserAccess from 'src/hooks/useUserAccess';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: USER_TYPE_ENUM[];
  children: ReactNode | string;
  showErrorMessage?: boolean;
};

export default function RoleBasedGuard({
  accessibleRoles,
  children,
  showErrorMessage = true,
}: RoleBasedGuardProp) {
  const { userTypes } = useUserAccess();

  const hasPermission = accessibleRoles.some((role) => userTypes?.includes(role));

  if (!hasPermission && showErrorMessage) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
}
