import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  startExerciseMetricsListener,
  getExerciseMetricsFetchStatus,
  getExerciseMetricsFetchError,
  selectAllExerciseMetrics,
} from 'src/redux/slices/exerciseMetrics';
import { Alert } from '@mui/material';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';

// ----------------------------------------------------------------------

export default function useExerciseMetrics() {
  const dispatch = useDispatch();
  const exerciseMetrics = useSelector(selectAllExerciseMetrics);
  const fetchStatus = useSelector(getExerciseMetricsFetchStatus);
  const fetchError = useSelector(getExerciseMetricsFetchError);

  useEffect(() => {
    if (!exerciseMetrics.length && fetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(startExerciseMetricsListener());
    }
  }, [exerciseMetrics.length, fetchStatus, dispatch]);

  const ErrorAlert = () => (!!fetchError ? <Alert severity="error">{fetchError}</Alert> : null);

  return {
    exerciseMetrics,
    fetchStatus,
    fetchError,
    ErrorAlert,
  };
}
