import { createSlice, createAction, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ChangeTackerData, Program as Program_Redux } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { doc, getDoc } from 'firebase/firestore';
import { ANALYTICS, DB } from 'src/contexts/FirebaseContext';
import { Program } from 'src/@types/program';
import { fetchProgramWeeks } from './programWeeks';
import { PROGRAM, PROGRAM_WEEKS } from './constants/keys';
import { handleDuplicateProgram } from './functions/duplicate';
import {
  prepareSaveData,
  prepareFirebaseQuery,
  handleSaveProgramDetails,
  handleSaveProgramImage,
} from './functions/save';
import { ProgramFormValuesProps } from 'src/sections/@dashboard/program/ProgramNewEditForm';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';
import { removeChanges } from './programChangeTracker';
import { CustomFile } from 'src/components/upload';
import { assignProgramUsers } from '../programs';
import { logEvent } from 'firebase/analytics';
import cleanProgramData from 'src/utils/cleanProgramData';

export const MOCK_ID = 'mock';

const initialState: Program_Redux = {
  id: '',
  title: '',
  imageUrl: '',
  description: '',
  published: true,
  dateCreated: new Date(),
  lastUpdated: new Date(),
  creators: [],
  creatorIds: [],
  users: [],
  userIds: [],

  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  saveStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  saveError: null,
  saveAlertActive: false,
  saveDetailsStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  saveDetailsError: null,
  saveImageStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  saveImageError: null,
  duplicateStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  duplicateError: null,
  currentTab: MOCK_ID,
  dragging: false,
  dragItemId: null,
};

// Save program
export const saveProgram = createAsyncThunk<
  Program,
  {
    program: Program;
    numberOfWeeks?: number;
    numberOfWorkouts?: number;
    isEdit?: boolean;
    programChanges?: ChangeTackerData[];
  }
>(
  'program/saveProgram',
  async (
    { program, numberOfWeeks, numberOfWorkouts, isEdit = undefined, programChanges },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const programSaveData = prepareSaveData({
      program,
      numberOfWeeks,
      numberOfWorkouts,
      isEdit,
      programChanges,
      state,
    });

    // Clean up program save data
    // const cleanProgramSaveData = cleanProgramSaveDataForFirebase({programSaveData});

    const firestoreQuery = prepareFirebaseQuery({ programSaveData });
    if (firestoreQuery) {
      await firestoreQuery.commit();

      // Create analytics event for program save
      logEvent(ANALYTICS, 'program_save', { program_id: program.id, user_id: state.user.id });
    }

    // Remove changes
    if (programChanges) {
      dispatch(removeChanges(programChanges));
    }

    return program;
  }
);

// Save program details
export const saveProgramDetails = createAsyncThunk<
  Program,
  {
    data: ProgramFormValuesProps;
    isEdit?: boolean;
  }
>('program/saveProgramDetails', async ({ data, isEdit = undefined }, { getState }) => {
  const state = getState() as RootState;

  const program = handleSaveProgramDetails({
    data,
    isEdit,
    state,
  });

  return program;
});

// Save program image
export const saveProgramImage = createAsyncThunk<
  string,
  {
    programId: string;
    imageUrl?: string;
    file?: CustomFile;
  }
>('program/saveProgramImage', async ({ programId, imageUrl, file }) => {
  const newImageUrl = await handleSaveProgramImage({
    programId,
    imageUrl,
    file,
  });

  if (!newImageUrl) {
    throw new Error('Error saving program image, no image url returned');
  }

  return newImageUrl;
});

export const fetchProgram = createAsyncThunk<Program, string>(
  'program/fetchProgram',
  async (programId) => {
    const docRef = doc(DB, 'programs', programId);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const { id } = docSnap;
      const data = docSnap.data();
      convertFirebaseDataDates(data);

      const item = { id, ...data } as Program;

      const cleanProgram = cleanProgramData(item);

      return cleanProgram;
    } else {
      // doc.data() will be undefined in this case
      throw new Error('No such program!');
    }
  }
);

export const duplicateProgram = createAsyncThunk<Program, string>(
  'program/duplicateProgram',
  async (programId) => {
    const item = await handleDuplicateProgram({ programId });
    return item;
  }
);

export const updateProgramAction = createAction<{ id: string; updates: Partial<Program> }>(
  'program/updateProgram'
);
export const programResetAction = createAction('program/reset');

export const slice = createSlice({
  name: PROGRAM,
  initialState,
  reducers: {
    updateCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
    startDragging: (state, action: PayloadAction<string>) => {
      state.dragging = true;
      state.dragItemId = action.payload;
    },
    endDragging: (state) => {
      state.dragging = false;
      state.dragItemId = null;
    },
    setProgram: (state, action: PayloadAction<Program>) => {
      state = { ...state, ...action.payload };
    },
    openSaveAlert: (state) => {
      state.saveAlertActive = true;
    },
    closeSaveAlert: (state) => {
      state.saveAlertActive = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateProgramAction, (state, action) => ({
        ...state,
        ...action.payload.updates,
      }))

      // Fetch Program
      .addCase(fetchProgram.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
        state.error = null;
      })
      .addCase(fetchProgram.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
        status: FETCH_STATUS_TYPES_ENUM.SUCCEEDED,
      }))
      .addCase(fetchProgram.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // Save Program
      .addCase(saveProgram.pending, (state) => {
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
        state.saveError = null;
      })
      .addCase(saveProgram.fulfilled, (state) => {
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      })
      .addCase(saveProgram.rejected, (state, action) => {
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.saveError = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
        state.saveAlertActive = true;
      })

      // Save Program Details
      .addCase(saveProgramDetails.pending, (state) => {
        state.saveDetailsStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
        state.saveDetailsError = null;
      })
      .addCase(saveProgramDetails.fulfilled, (state, action) => {
        const program = action.payload;
        return {
          ...state,
          ...program,
          saveDetailsStatus: FETCH_STATUS_TYPES_ENUM.SUCCEEDED,
        };
      })
      .addCase(saveProgramDetails.rejected, (state, action) => {
        state.saveDetailsStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.saveDetailsError = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // Save Program Image
      .addCase(saveProgramImage.pending, (state) => {
        state.saveImageStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
        state.saveImageError = null;
      })
      .addCase(saveProgramImage.fulfilled, (state, action) => {
        const imageUrl = action.payload;
        return {
          ...state,
          imageUrl,
          saveImageStatus: FETCH_STATUS_TYPES_ENUM.SUCCEEDED,
        };
      })
      .addCase(saveProgramImage.rejected, (state, action) => {
        state.saveImageStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.saveImageError = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // Duplicate Program
      .addCase(duplicateProgram.pending, (state) => {
        state.duplicateStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
        state.duplicateError = null;
      })
      .addCase(duplicateProgram.fulfilled, (state, action) => {
        state.duplicateStatus = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
        state.duplicateError = null;
      })
      .addCase(duplicateProgram.rejected, (state, action) => {
        state.duplicateStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.duplicateError = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // Reset case
      .addCase(programResetAction, () => initialState)
      // External actions
      .addCase(fetchProgramWeeks.fulfilled, (state, action) => {
        const programWeeks = action.payload[PROGRAM_WEEKS];
        if (programWeeks.length) {
          const firstWeek = programWeeks.find((week) => week.index === 0);
          if (firstWeek) {
            state.currentTab = firstWeek.id;
          }
        }
      })

      .addCase(assignProgramUsers.fulfilled, (state, action) => {
        const item = action.payload;
        // Update the program with the new users
        state.users = item.users;
        state.userIds = item.userIds;
      });
  },
});

export const {
  updateCurrentTab,
  startDragging,
  endDragging,
  setProgram,
  openSaveAlert,
  closeSaveAlert,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// SELECTORS
// ----------------------------------------------------------------------

export const getProgram = (state: RootState) => state.program;
export const getProgramId = (state: RootState) => state.program.id;
export const getProgramPublished = (state: RootState) => state.program.published;
export const getProgramFetchStatus = (state: RootState) => state.program.status;
export const getProgramSaveStatus = (state: RootState) => state.program.saveStatus;
export const getProgramSaveError = (state: RootState) => state.program.saveError;
export const getProgramSaveAlertActive = (state: RootState) => state.program.saveAlertActive;
export const getProgramCurrentTab = (state: RootState) => state.program.currentTab;
export const getProgramDraggingStatus = (state: RootState) => state.program.dragging;
export const getProgramDragItemId = (state: RootState) => state.program.dragItemId;
export const getProgramDuplicateStatus = (state: RootState) => state.program.duplicateStatus;
