import {
  createSlice,
  createEntityAdapter,
  createSelector,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';
import { ChangeData, Changes } from 'src/@types/program_redux';
import { applyChanges } from 'src/redux/functions/applyChanges';
import { AppDispatch, RootState } from 'src/redux/store';
import {
  duplicateWorkoutExerciseGroupAction,
  removeExerciseGroupAction,
} from './workoutExerciseGroups';
import { programResetAction } from './program';
import { duplicateWeekAction, fetchProgramWeeks, removeWeekAction } from './programWeeks';
import {
  addWorkoutExerciseMetricAction,
  removeWorkoutExerciseMetricAction,
} from './workoutExerciseMetrics';
import {
  addExercisesAction,
  duplicateWorkoutExerciseAction,
  removeExerciseAction,
  swapWorkoutExerciseAction,
  updateSetsAction,
} from './workoutExercises';
import { duplicateWorkoutAction, removeWorkoutAction } from './workouts';
import { WORKOUT_EXERCISE_METRIC_VALUES } from './constants/keys';
import { WorkoutExerciseMetricValue } from 'src/@types/program';
import { groupingAction, reorderExercisesAction } from './workoutDragItems';
import { copyToCurrentProgram } from './copyToModal';

const workoutExerciseMetricValueAdapter = createEntityAdapter<WorkoutExerciseMetricValue>({
  // Sort by index
  sortComparer: (a: WorkoutExerciseMetricValue, b: WorkoutExerciseMetricValue) => a.set - b.set,
});

const initialState = workoutExerciseMetricValueAdapter.getInitialState();

export const updateWorkoutExerciseMetricValueAction = createAction<Changes>(
  'workoutExerciseMetricValues/updateWorkoutExerciseMetricValue'
);

export const slice = createSlice({
  name: WORKOUT_EXERCISE_METRIC_VALUES,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    const handleChangesReducer = (state: any, action: PayloadAction<Changes>) => {
      const items = action.payload.workoutExerciseMetricValues;
      if (items) {
        applyChanges(items, workoutExerciseMetricValueAdapter, state);
      }
    };

    builder
      // Internal
      .addCase(updateWorkoutExerciseMetricValueAction, handleChangesReducer)

      // External
      .addCase(addWorkoutExerciseMetricAction, handleChangesReducer)
      .addCase(removeWorkoutExerciseMetricAction, handleChangesReducer)
      .addCase(updateSetsAction, handleChangesReducer)
      .addCase(addExercisesAction, handleChangesReducer)
      .addCase(removeExerciseAction, handleChangesReducer)
      .addCase(removeExerciseGroupAction, handleChangesReducer)
      .addCase(removeWorkoutAction, handleChangesReducer)
      .addCase(removeWeekAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseGroupAction, handleChangesReducer)
      .addCase(duplicateWorkoutAction, handleChangesReducer)
      .addCase(duplicateWeekAction, handleChangesReducer)
      .addCase(reorderExercisesAction, handleChangesReducer)
      .addCase(groupingAction, handleChangesReducer)
      .addCase(copyToCurrentProgram, handleChangesReducer)
      .addCase(swapWorkoutExerciseAction, handleChangesReducer)

      .addCase(programResetAction, () => initialState)
      .addCase(fetchProgramWeeks.fulfilled, (state, action) => {
        const items = action.payload[WORKOUT_EXERCISE_METRIC_VALUES];

        if (items.length) {
          workoutExerciseMetricValueAdapter.addMany(state, items);
        }
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

export const updateWorkoutExerciseMetricValue =
  ({ id, updates }: { id: string; updates: Partial<WorkoutExerciseMetricValue> }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const workoutExerciseMetricValue = state.workoutExerciseMetricValues.entities[id];

    if (!workoutExerciseMetricValue) {
      console.error(`WorkoutExerciseMetricValue with id ${id} not found`);
      return;
    }

    // Used to update workout in database
    const workouts: ChangeData = {
      updated: [{ id: workoutExerciseMetricValue.workoutId, changes: {} }],
    };

    const update = { id: id, changes: updates };
    const changes: Changes = {
      workouts,
      workoutExerciseMetricValues: {
        updated: [update],
      },
    };
    dispatch(updateWorkoutExerciseMetricValueAction(changes));
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllWorkoutExerciseMetricValues,
  selectById: selectWorkoutExerciseMetricValueById,
  // Pass in a selector that returns the posts slice of state
} = workoutExerciseMetricValueAdapter.getSelectors(
  (state: RootState) => state[WORKOUT_EXERCISE_METRIC_VALUES]
);

const selectWorkoutExerciseMetricId = (state: RootState, workoutExerciseMetricId: string) =>
  workoutExerciseMetricId;

export const selectAllWorkoutExerciseMetricValuesByWorkoutExerciseMetricId = createSelector(
  [selectAllWorkoutExerciseMetricValues, selectWorkoutExerciseMetricId],
  (workoutExerciseMetricValues, workoutExerciseMetricId) =>
    workoutExerciseMetricValues.filter(
      (emv) => emv.workoutExerciseMetricId === workoutExerciseMetricId
    )
);
