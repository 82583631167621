import { Checkbox, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { memo } from 'react';
import { USER_TYPE_ENUM } from 'src/@types/enums';
import { Exercise_WithID } from 'src/@types/firebase';
import Iconify from 'src/components/Iconify';
import Image from 'src/components/Image';
import { getUserId, getUserType } from 'src/redux/slices/user';
import { useSelector } from 'src/redux/store';
import ExerciseMenu from './ExerciseMenu';
// import { isExerciseSelected } from 'src/redux/slices/program/exercisesToAdd';
// import { RootState, useSelector } from 'src/redux/store';

type Props = {
  exercise: Exercise_WithID;
  onClick: () => void;
  onCheckboxClick?: () => void;
  onDelete?: () => void;
  onInformationClick?: () => void;
  selected?: boolean;
  sx?: any;
};

function ExerciseCard({
  exercise,
  onClick,
  onCheckboxClick,
  onDelete,
  onInformationClick,
  selected,
  sx,
}: Props) {
  const { name, thumbnailUrl } = exercise;
  const userId = useSelector(getUserId);
  const userType = useSelector(getUserType);

  const theme = useTheme();
  // const selected = useSelector((state: RootState) => isExerciseSelected(state, id));

  const editable =
    exercise && (exercise.creatorIds.includes(userId) || userType?.includes(USER_TYPE_ENUM.ADMIN));

  const handleExerciseClick = () => {
    onClick();
  };

  const handleCheckboxClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (onCheckboxClick) {
      onCheckboxClick();
    }
  };

  const handleInformationClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (onInformationClick) {
      onInformationClick();
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      onClick={handleExerciseClick}
      sx={{
        py: 2,
        px: 4,
        backgroundColor: selected
          ? theme.palette.mode === 'dark'
            ? 'exerciseModal.selectedDark'
            : 'exerciseModal.selected'
          : 'transparent',
        borderBottom: 0.1,
        borderColor: selected ? 'background.default' : 'divider',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: selected ? 'exerciseModal.hover' : 'background.neutral',
        },
        ...sx,
      }}
    >
      {onCheckboxClick && (
        <Checkbox
          sx={{
            '&.Mui-checked': {
              color: 'exerciseModal.checkbox',
            },
          }}
          checked={selected}
          onClick={handleCheckboxClick}
        />
      )}
      <Image src={thumbnailUrl} alt={name} sx={{ width: 75, height: 75, borderRadius: 1 }} />

      {onInformationClick ? (
        <Stack direction="row" sx={{ flex: 1 }}>
          <Typography variant="body2">{name}</Typography>
          <IconButton tabIndex={-1} onClick={handleInformationClick} sx={{ m: 0, ml: 1, p: 0 }}>
            <Iconify
              icon={'mdi:information'}
              color="text.primary"
              width={20}
              height={20}
              sx={{ opacity: 0.25, ':hover': { opacity: 1 } }}
            />
          </IconButton>
        </Stack>
      ) : (
        <Typography variant="body2" sx={{ flex: 1 }}>
          {name}
        </Typography>
      )}

      {editable && <ExerciseMenu handleDeleteExercise={onDelete} />}
    </Stack>
  );
}

export default memo(ExerciseCard);
