// @mui
import { memo, useCallback } from 'react';
import { updateProgramAction } from 'src/redux/slices/program/program';
import { useDispatch } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM, PROGRAM_PAGE_ENUM } from 'src/@types/enums';
import { Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'src/components/Image';
import { TextField, ProgramMenu } from '.';

// ----------------------------------------------------------------------

const StyledTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
  minHeight: 'auto',
  p: 0,
  pb: 0.3,
}));

// ----------------------------------------------------------------------

type Props = {
  id: string;
  title: string;
  imageUrl: string;
  currentPage: PROGRAM_PAGE_ENUM;
  fetchStatus: FETCH_STATUS_TYPES_ENUM;
  handlePageChange: (
    event: React.SyntheticEvent<Element, Event>,
    newPage: PROGRAM_PAGE_ENUM
  ) => void;
};

function ProgramHeaderComponent({
  id,
  title,
  imageUrl,
  currentPage,
  fetchStatus,
  handlePageChange,
}: Props) {
  const dispatch = useDispatch();
  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateProgramAction({ id, updates: { title: e.target.value } }));
    },
    [dispatch, id]
  );

  const loading = fetchStatus === FETCH_STATUS_TYPES_ENUM.LOADING;

  return (
    <>
      {!loading ? (
        <Image
          src={imageUrl}
          alt={title}
          sx={{ width: 50, height: 50, my: 0.5, mr: 2, borderRadius: 1 }}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{ width: 50, height: 50, my: 0.5, mr: 2, borderRadius: 1 }}
        />
      )}
      <Stack>
        <Stack direction={'row'} alignItems={'center'}>
          {!loading ? (
            <TextField
              fontColor="text.secondary"
              size="small"
              font="subtitle2"
              value={title}
              onChange={handleNameChange}
            />
          ) : (
            <Skeleton variant="text" sx={{ width: 200 }} />
          )}
          <ProgramMenu programId={id} />
        </Stack>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentPage}
          onChange={handlePageChange}
          sx={{ minHeight: 'auto', p: 0, pt: 1 }}
        >
          <StyledTab disableRipple value={PROGRAM_PAGE_ENUM.PROGRAM} label="Program" />
          <StyledTab disableRipple value={PROGRAM_PAGE_ENUM.PROGRAM_DETAILS} label="Details" />
        </Tabs>
      </Stack>
    </>
  );
}

// ----------------------------------------------------------------------

export default memo(ProgramHeaderComponent);
