// @mui
import { styled } from '@mui/material/styles';
import { Stack, Tab, Tabs } from '@mui/material';
import { memo } from 'react';
import Iconify from 'src/components/Iconify';
import { getUserType } from 'src/redux/slices/user';
import { useSelector } from 'src/redux/store';
import { EXERCISES_PAGE_ENUM, USER_TYPE_ENUM } from 'src/@types/enums';
//

// ----------------------------------------------------------------------

const StyledTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
  minHeight: 'auto',
  p: 0,
  pb: 0.3,
}));

// ----------------------------------------------------------------------

type Props = {
  currentPage: EXERCISES_PAGE_ENUM;
  handlePageChange: (
    event: React.SyntheticEvent<Element, Event>,
    newPage: EXERCISES_PAGE_ENUM
  ) => void;
};

function ExercisesHeaderComponent({ currentPage, handlePageChange }: Props) {
  const userType = useSelector(getUserType);

  return (
    <>
      <Iconify
        color="primary.main"
        icon={'icon-park-outline:dumbbell'}
        sx={{ width: 35, height: 35, my: 0.5, mr: 4 }}
      />
      <Stack>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentPage}
          onChange={handlePageChange}
          sx={{ minHeight: 'auto', p: 0, pt: 1 }}
        >
          <StyledTab disableRipple value={EXERCISES_PAGE_ENUM.ALL} label="All" />
          <StyledTab disableRipple value={EXERCISES_PAGE_ENUM.MY_EXERCISES} label="My Exercises" />
          {userType?.includes(USER_TYPE_ENUM.ADMIN) && (
            <StyledTab
              disableRipple
              value={EXERCISES_PAGE_ENUM.GLOBAL_EXERCISES}
              label="Global Exercises"
            />
          )}
        </Tabs>
      </Stack>
    </>
  );
}

export default memo(ExercisesHeaderComponent);
