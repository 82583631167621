import {
  DragItem,
  WorkoutDragItem,
  WorkoutExercise,
  WorkoutExerciseGroup,
  WorkoutExerciseMetric,
} from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import uuidv4 from 'src/utils/uuidv4';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';
import { handleDuplicateWorkoutExerciseMetric } from '.';
import { createWorkoutExerciseOptions } from 'src/utils/createDragItems';

// --------------------------------------------------
// Handle Copy To
// --------------------------------------------------

type HandleCopyToProps = {
  state: RootState;
  copyToWorkoutId: string;
  changes: Changes;
  duplicatedItem: WorkoutExercise;
  duplicatedDragItem: WorkoutDragItem;
  workoutExerciseGroupUpdates: UpdateType[];
  exerciseUpdates: UpdateType[];
  dragItemUpdates: UpdateType[];
};

const handleCopyTo = ({
  state,
  copyToWorkoutId,
  changes,
  duplicatedItem,
  duplicatedDragItem,
  workoutExerciseGroupUpdates,
  exerciseUpdates,
  dragItemUpdates,
}: HandleCopyToProps) => {
  // Make sure the changes are initialized
  if (!changes.workoutExerciseGroups) return;
  if (!changes.workoutDragItems) return;

  // Create a new group
  const newWorkoutExerciseGroup: WorkoutExerciseGroup = {
    id: uuidv4(),
    index: 0,
    name: '',
    workoutId: copyToWorkoutId,
  };
  // Add the new group to the changes
  changes.workoutExerciseGroups?.added?.length
    ? changes.workoutExerciseGroups.added.push(newWorkoutExerciseGroup)
    : (changes.workoutExerciseGroups.added = [newWorkoutExerciseGroup]);

  // Set new group to duplicated item
  duplicatedItem.workoutExerciseGroupId = newWorkoutExerciseGroup.id;
  duplicatedDragItem.groupId = newWorkoutExerciseGroup.id;
  duplicatedDragItem.groupIndex = newWorkoutExerciseGroup.index;

  // Set index and drag index to 0
  duplicatedItem.index = 0;
  duplicatedDragItem.index = 0;
  duplicatedDragItem.dragIndex = 0;

  // Make the duplicated item a solo exercise
  duplicatedDragItem.inGroup = false;
  duplicatedDragItem.lastInGroup = true;

  // Create a new exercise options for the copied exercise
  const newOptions = createWorkoutExerciseOptions({
    id: duplicatedDragItem.id,
    index: 0,
    dragIndex: 1,
    groupId: duplicatedDragItem.groupId,
    groupIndex: duplicatedDragItem.groupIndex,
    workoutId: copyToWorkoutId,
    inGroup: false,
  });
  // Add the new options to the changes
  changes.workoutDragItems?.added?.length
    ? changes.workoutDragItems.added.push(newOptions)
    : (changes.workoutDragItems.added = [newOptions]);

  // Update any affected items
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (affectedItem): affectedItem is WorkoutDragItem =>
      !!affectedItem &&
      affectedItem.workoutId === duplicatedDragItem.workoutId &&
      affectedItem.dragIndex >= duplicatedDragItem.dragIndex
  );
  console.log('affectedItems', affectedItems);

  affectedItems.forEach((affectedItem) => {
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseChanges: Partial<WorkoutExercise> = {};
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    // Update the drag index of the affected item
    dragItemChanges.dragIndex = affectedItem.dragIndex + 2; // +2 because we are adding a new item and options

    // Drag item updates
    // Update group indexes
    if (affectedItem.groupIndex !== undefined) {
      dragItemChanges.groupIndex = affectedItem.groupIndex + 1;
    }
    // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
    if (
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    ) {
      dragItemChanges.index = affectedItem.index + 1;
    }

    // Group updates
    // If the item is a group header or a solo exercise
    // Update the exercise group index
    if (affectedItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
      exerciseGroupChanges.index = affectedItem.index + 1;
    } else if (
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      !affectedItem.inGroup &&
      affectedItem.groupIndex !== undefined
    ) {
      exerciseGroupChanges.index = affectedItem.groupIndex + 1;
    }

    // Push the changes
    exerciseUpdates.push({ id: affectedItem.id, changes: exerciseChanges });
    dragItemUpdates.push({ id: affectedItem.id, changes: dragItemChanges });
    if (exerciseGroupChanges.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id:
          affectedItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
            ? affectedItem.groupId
            : affectedItem.id,
        changes: exerciseGroupChanges,
      });
    }
  });
};

// --------------------------------------------------

// --------------------------------------------------
// Handle Only This Exercise Being Duplicated
// --------------------------------------------------

type HandleOnlyThisExerciseBeingDuplicatedProps = {
  state: RootState;
  item: WorkoutExercise;
  dragItem: DragItem;
  duplicatedItem: WorkoutExercise;
  duplicatedDragItem: WorkoutDragItem;
  exerciseUpdates: UpdateType[];
  dragItemUpdates: UpdateType[];
};

const handleOnlyThisExerciseBeingDuplicated = ({
  state,
  item,
  dragItem,
  duplicatedItem,
  duplicatedDragItem,
  exerciseUpdates,
  dragItemUpdates,
}: HandleOnlyThisExerciseBeingDuplicatedProps) => {
  // We need to update the index and drag index of the duplicated exercise
  duplicatedItem.index = item.index + 1;
  duplicatedDragItem.index = dragItem.index + 1;
  duplicatedDragItem.dragIndex = dragItem.dragIndex + 1;

  // Find affected items
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (affectedItem): affectedItem is WorkoutDragItem =>
      !!affectedItem &&
      affectedItem.workoutId === item.workoutId &&
      affectedItem.dragIndex > dragItem.dragIndex
  );

  affectedItems.forEach((affectedItem) => {
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseChanges: Partial<WorkoutExercise> = {};
    // Update the drag index of the affected item
    dragItemChanges.dragIndex = affectedItem.dragIndex + 1;

    // If part of the same group update the index
    if (
      affectedItem.groupId === dragItem.groupId &&
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
    ) {
      exerciseChanges.index = affectedItem.index + 1;
      dragItemChanges.index = affectedItem.index + 1;
    }

    // Push the changes
    exerciseUpdates.push({ id: affectedItem.id, changes: exerciseChanges });
    dragItemUpdates.push({ id: affectedItem.id, changes: dragItemChanges });
  });
};

// --------------------------------------------------

type Props = {
  state: RootState;
  changes: Changes;
  workoutExerciseId: string;
  // If new group
  newWorkoutExerciseGroupId?: string;
  newWorkoutExerciseGroupIndex?: number; // Index of the new group
  dragIndexIncrement?: number; // If only a new group is created, the drag index of the duplicated item needs to be incremented
  newDragIndex?: number; // If only a new group is copied to
  // If new workout
  newWorkoutId?: string;
  // If copy to
  copyToWorkoutId?: string;
};

const handleDuplicateWorkoutExercise = ({
  state,
  changes,
  workoutExerciseId,
  newWorkoutExerciseGroupId,
  newWorkoutExerciseGroupIndex,
  dragIndexIncrement,
  newDragIndex,
  newWorkoutId,
  copyToWorkoutId,
}: Props) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('handleDuplicateWorkoutExercise', dragIndexIncrement);
  }

  const item = state.workoutExercises.entities[workoutExerciseId];
  const dragItem = state.workoutDragItems.entities[workoutExerciseId];

  if (!item) {
    console.error(`No workout exercise with id ${workoutExerciseId} found`);
    return;
  }
  if (!dragItem) {
    console.error(`No workout drag item with id ${workoutExerciseId} found`);
    return;
  }

  // If changes for this item don't exist, create them
  if (changes.workoutExerciseGroups === undefined) {
    changes.workoutExerciseGroups = {};
  }
  if (changes.workoutExercises === undefined) {
    changes.workoutExercises = {};
  }
  if (changes.workoutDragItems === undefined) {
    changes.workoutDragItems = {};
  }
  if (changes.workouts === undefined) {
    const updatedWorkouts = [
      {
        id: newWorkoutId ? newWorkoutId : item.workoutId,
        changes: {},
      },
    ];

    if (copyToWorkoutId) {
      updatedWorkouts.push({
        id: copyToWorkoutId,
        changes: {},
      });
    }

    changes.workouts = {
      updated: updatedWorkouts,
    };
  }

  let workoutId = newWorkoutId ? newWorkoutId : item.workoutId;
  if (copyToWorkoutId) workoutId = copyToWorkoutId;

  const duplicatedItem: WorkoutExercise = {
    ...item,
    id: uuidv4(),
    workoutExerciseGroupId: newWorkoutExerciseGroupId
      ? newWorkoutExerciseGroupId
      : item.workoutExerciseGroupId,
    workoutId,
  };

  const duplicatedDragItem: WorkoutDragItem = {
    ...dragItem,
    key: duplicatedItem.id,
    id: duplicatedItem.id,
    groupId: newWorkoutExerciseGroupId ? newWorkoutExerciseGroupId : dragItem.groupId,
    groupIndex:
      newWorkoutExerciseGroupIndex !== undefined
        ? newWorkoutExerciseGroupIndex
        : dragItem.groupIndex,
    dragIndex:
      dragIndexIncrement !== undefined
        ? dragItem.dragIndex + dragIndexIncrement
        : dragItem.dragIndex,
    workoutId,
  };

  // When a group called a copy to function
  if (newDragIndex !== undefined) {
    duplicatedDragItem.dragIndex = newDragIndex;
  }

  // Check if there any affected exercises or drag items
  const workoutExerciseGroupUpdates: UpdateType[] = [];
  const exerciseUpdates: UpdateType[] = [];
  const dragItemUpdates: UpdateType[] = [];

  // Only this exercise was copied to another workout
  if (copyToWorkoutId !== undefined) {
    handleCopyTo({
      state,
      copyToWorkoutId,
      changes,
      duplicatedItem,
      duplicatedDragItem,
      workoutExerciseGroupUpdates,
      exerciseUpdates,
      dragItemUpdates,
    });
  }
  // Only this exercise was duplicated
  else if (newWorkoutExerciseGroupId === undefined) {
    handleOnlyThisExerciseBeingDuplicated({
      state,
      item,
      dragItem,
      duplicatedItem,
      duplicatedDragItem,
      exerciseUpdates,
      dragItemUpdates,
    });
  }

  // Push the changes
  // Exercise
  changes.workoutExercises?.added?.length
    ? changes.workoutExercises.added.push(duplicatedItem)
    : (changes.workoutExercises.added = [duplicatedItem]);
  // Drag item
  changes.workoutDragItems?.added?.length
    ? changes.workoutDragItems.added.push(duplicatedDragItem)
    : (changes.workoutDragItems.added = [duplicatedDragItem]);

  // If affected items exist, add them to the changes
  // Exercise groups
  if (workoutExerciseGroupUpdates.length) {
    changes.workoutExerciseGroups?.updated?.length
      ? changes.workoutExerciseGroups.updated.push(...workoutExerciseGroupUpdates)
      : (changes.workoutExerciseGroups.updated = workoutExerciseGroupUpdates);
  }
  if (exerciseUpdates.length) {
    changes.workoutExercises?.updated?.length
      ? changes.workoutExercises.updated.push(...exerciseUpdates)
      : (changes.workoutExercises.updated = exerciseUpdates);
  }
  if (dragItemUpdates.length) {
    changes.workoutDragItems?.updated?.length
      ? changes.workoutDragItems.updated.push(...dragItemUpdates)
      : (changes.workoutDragItems.updated = dragItemUpdates);
  }

  // Duplicate all items associated with this item
  const associatedItems = Object.values(state.workoutExerciseMetrics.entities).filter(
    (associatedItem): associatedItem is WorkoutExerciseMetric =>
      !!associatedItem && associatedItem.workoutExerciseId === workoutExerciseId
  );

  associatedItems.forEach((associatedItem) =>
    handleDuplicateWorkoutExerciseMetric({
      state,
      changes,
      workoutExerciseMetricId: associatedItem.id,
      newWorkoutExerciseId: duplicatedItem.id,
      newWorkoutId,
    })
  );
};

export default handleDuplicateWorkoutExercise;
