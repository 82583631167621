import { memo, useState } from 'react';
// @mui
import { IconButton, MenuItem } from '@mui/material';
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import { getNumberOfWeeks } from 'src/redux/slices/program/programWeeks';
import { useSelector } from 'src/redux/store';
//

// ----------------------------------------------------------------------

type Props = {
  color: string;
  handleDuplicateWeek: () => void;
  handleWeekCopyTo: () => void;
  handleRemoveWeek: () => void;
};

function WeekMenu({ color, handleDuplicateWeek, handleWeekCopyTo, handleRemoveWeek }: Props) {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const numberOfWeeks = useSelector(getNumberOfWeeks);
  const onlyOneWeekLeft = numberOfWeeks === 1;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(null);
  };

  const handleDuplicate = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleDuplicateWeek();
    setOpen(null);
  };

  const handleCopyTo = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleWeekCopyTo();
    setOpen(null);
  };

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleRemoveWeek();
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ m: 0, p: 0.5 }}>
        <Iconify
          icon={'eva:more-vertical-fill'}
          width={20}
          height={20}
          color={color}
          sx={{ opacity: 0.5, ':hover': { opacity: 1 } }}
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="top-right"
        sx={{
          mt: -1,
          width: 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        <MenuItem onClick={handleDuplicate}>
          <Iconify icon={'ic:outline-content-copy'} />
          Duplicate
        </MenuItem>
        <MenuItem onClick={handleCopyTo}>
          <Iconify icon={'mdi:file-replace-outline'} />
          Copy To
        </MenuItem>
        {!onlyOneWeekLeft && (
          <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} />
            Delete
          </MenuItem>
        )}
      </MenuPopover>
    </>
  );
}

export default memo(WeekMenu);
