import { Workout_WithID } from 'src/@types/firebase';
import uuidv4 from 'src/utils/uuidv4';

type Props = {
  index: number;
  programId: string;
  programWeekId: string;
};

const handleCreateFirebaseWorkout = ({ index, programId, programWeekId }: Props) => {
  const newWorkout: Workout_WithID = {
    id: uuidv4(),
    programId: programId,
    programWeekId: programWeekId,
    index: index,
    name: '',
    description: '',
    notes: '',
    dateCreated: new Date(),
    lastUpdated: new Date(),
    workoutExerciseGroups: [],
    workoutExercises: [],
    exerciseIds: [],
    workoutExerciseMetrics: [],
    exerciseMetricIds: [],
    workoutExerciseMetricValues: [],
  };
  return newWorkout;
};

export default handleCreateFirebaseWorkout;
