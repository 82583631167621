import { WorkoutDragItem, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  movedItem: WorkoutDragItem;
  destinationItem: WorkoutDragItem;
  movedDown: boolean;
  movedUp: boolean;
};

// If solo exercise was moved within it's current workout
const handleMoveExerciseWithinWorkout = ({
  state,
  movedItem,
  destinationItem,
  movedDown,
  movedUp,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [
    { id: movedItem.workoutId, changes: {} },
    { id: destinationItem.workoutId, changes: {} },
  ];
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutDragItems = {};

  // If moved up
  // Add 1 to the moved item index to account for the exercise options
  const from = movedUp ? movedItem.dragIndex + 1 : movedItem.dragIndex;
  let to = destinationItem.dragIndex;

  let destinationGroupIndex =
    destinationItem?.groupIndex !== undefined ? destinationItem.groupIndex : destinationItem.index;

  if (destinationItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS) {
    if (movedUp) {
      to -= 1;
    } else {
      to += 1;
      destinationGroupIndex += 1;
    }
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('destinationItem.type', destinationItem.type);
    console.log('movedUp', movedUp);
    console.log('newDragIndex', to);
    console.log('to', to);
    console.log('destinationGroupIndex', destinationGroupIndex);
  }

  const exerciseOptions = Object.values(state.workoutDragItems.entities).find(
    (item) =>
      !!item &&
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS &&
      item.workoutId === movedItem.workoutId &&
      item.groupId === movedItem.groupId
  );

  let movedItemCount = 0;
  // Update movedItem index
  const movedDragItemChanges: Partial<WorkoutDragItem> = {
    groupIndex: destinationGroupIndex,
    dragIndex: to,
  };
  // Increment number of moved items
  movedItemCount += 1;
  const movedExerciseOptionsChanges: Partial<WorkoutDragItem> = {
    index: destinationGroupIndex,
    groupIndex: destinationGroupIndex,
    dragIndex: to + movedItemCount,
  };
  // Increment number of moved items
  movedItemCount += 1;

  // If moved up
  if (movedUp) {
    // Increment to index to account for the exercise options
    to += 1;
  }

  // Update exercise group index
  const movedExerciseGroupChanges: Partial<WorkoutExerciseGroup> = {
    index: destinationGroupIndex,
  };

  // Push the changes
  changes.workoutDragItems.updated = [{ id: movedItem.id, changes: movedDragItemChanges }];
  if (exerciseOptions) {
    changes.workoutDragItems.updated.push({
      id: exerciseOptions.id,
      changes: movedExerciseOptionsChanges,
    });
  }
  changes.workoutExerciseGroups.updated = [
    { id: movedItem.groupId, changes: movedExerciseGroupChanges },
  ];

  // Update the index for any item between the moved item and the destination
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      // If the item is undefined or the workoutId is not equal to the moved items then skip
      if (!item || item.workoutId !== movedItem.workoutId) {
        return false;
      }

      // If moved down and the index is lesser than from and greater than or equal to to
      if (movedDown && item.dragIndex < from && item.dragIndex >= to) {
        return true;
      }
      // If moved up and the index is less than the from index and greater than the to index
      else if (movedUp && item.dragIndex > from && item.dragIndex <= to) {
        return true;
      }

      return false;
    }
  );

  const workoutDragItemUpdates: UpdateType[] = [];
  const workoutExerciseGroupUpdates: UpdateType[] = [];
  // Update the affected items
  affectedItems.forEach((item) => {
    // const groupIndex = (item.groupIndex || item.index) + (movedDown ? 1 : -1);
    const dragIndex = item.dragIndex + (movedDown ? movedItemCount : -movedItemCount);

    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    const dragItemChanges: Partial<WorkoutDragItem> = {
      dragIndex,
    };

    const groupIndexIncrement = movedDown ? 1 : -1;
    // Update group indexes
    if (item.groupIndex !== undefined) {
      dragItemChanges.groupIndex = item.groupIndex + groupIndexIncrement;
    }
    // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    ) {
      dragItemChanges.index = item.index + groupIndexIncrement;
    }

    // If the item is a group header or a solo exercise
    // Update the exercise group index
    if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
      exerciseGroupChanges.index = item.index + groupIndexIncrement;
    } else if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      !item.inGroup &&
      item.groupIndex !== undefined
    ) {
      exerciseGroupChanges.index = item.groupIndex + groupIndexIncrement;
    }

    workoutDragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });

    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...workoutDragItemUpdates);
  changes.workoutExerciseGroups.updated.push(...workoutExerciseGroupUpdates);

  return changes;
};

export default handleMoveExerciseWithinWorkout;
