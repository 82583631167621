import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit';
import { query, collection, getDocs } from 'firebase/firestore';
import { MovementTag_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import { RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';

const movementTagAdapter = createEntityAdapter<MovementTag_WithID>({
  // Sort by index
  sortComparer: (a, b) => a.index - b.index,
});

const initialState = movementTagAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null });

export const fetchMovementTags = createAsyncThunk<MovementTag_WithID[]>(
  'movementTags/fetchMuscleTags',
  async () => {
    const items: MovementTag_WithID[] = [];
    const q = query(collection(DB, 'movementTags'));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const item = { ...doc.data(), id: doc.id } as MovementTag_WithID;
      items.push(item);
    });
    return items;
  }
);

export const slice = createSlice({
  name: 'movementTags',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      // Reset case
      .addCase(fetchMovementTags.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchMovementTags.fulfilled, (state, action) => {
        // Upsert all the added exercise metrics
        const items = action.payload;
        if (items.length !== 0) {
          movementTagAdapter.upsertMany(state, items);
          // Change status
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        } else {
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        }
      })
      .addCase(fetchMovementTags.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllMovementTags } = movementTagAdapter.getSelectors(
  (state: RootState) => state.movementTags
);

export const getMovementTagsFetchStatus = (state: RootState) => state.movementTags.status;
