import { Checkbox, Skeleton, Stack } from '@mui/material';
import React, { memo } from 'react';
import { randomNumberRange } from 'src/_mock/funcs';

function SkeletonExerciseCard({ checkbox = false, sx }: { checkbox?: boolean; sx?: any }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{
        px: 4,
        py: 2,
        backgroundColor: 'transparent',
        borderBottom: 0.1,
        borderColor: 'divider',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: 'background.neutral',
        },
        ...sx,
      }}
    >
      {checkbox && <Checkbox />}
      <Skeleton
        variant="rectangular"
        sx={{ minWidth: 75, width: 75, height: 75, borderRadius: 1 }}
      />
      <Skeleton variant="text" sx={{ width: randomNumberRange(40, 180) }} />
    </Stack>
  );
}

export default memo(SkeletonExerciseCard);
