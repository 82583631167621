import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { CustomFile } from 'src/components/upload';
import { DB, STORAGE } from 'src/contexts/FirebaseContext';

type Props = {
  userId: string;
  profilePictureUrl?: string;
  file?: CustomFile;
};

const handleSaveUserImage = async ({ userId, profilePictureUrl, file }: Props) => {
  // Upload image to firebase storage
  if (file !== undefined) {
    // If the image is a file
    const storageRef = ref(STORAGE, `users/${userId}`);

    await uploadBytes(storageRef, file).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then(async (downloadURL) => {
        const ref = doc(DB, 'users', userId);
        // Update to firebase
        await updateDoc(ref, {
          lastUpdated: new Date(),
          profilePictureUrl: downloadURL,
        });
        profilePictureUrl = downloadURL;
      });
    });
  }

  return profilePictureUrl;
};

export default handleSaveUserImage;
