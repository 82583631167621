import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { collection, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { ExerciseMetric_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import { AppDispatch, RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';

export const DEFAULT_REPS_METRIC_ID = '135ff46f-c70e-4845-ba3e-71cbbcd4734c';

const exerciseMetricAdapter = createEntityAdapter<ExerciseMetric_WithID>({
  // Sort by index
  sortComparer: (a, b) => a.index - b.index,
});

const initialState = exerciseMetricAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  listener: null,
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null; listener: null | Unsubscribe });

export const slice = createSlice({
  name: 'exerciseMetrics',
  initialState,
  reducers: {
    setExerciseMetrics: (
      state,
      action: PayloadAction<{
        upsertItems: ExerciseMetric_WithID[];
        removeItems: string[];
      }>
    ) => {
      exerciseMetricAdapter.upsertMany(state, action.payload.upsertItems);
      exerciseMetricAdapter.removeMany(state, action.payload.removeItems);
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
    },
    // --------------------------------------------------
    // Listener commands start
    // --------------------------------------------------
    fetchConnectionsRequestsLoading(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
    },
    fetchConnectionsRequestsSuccess(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      state.listener = action.payload;
    },
    fetchConnectionsRequestsFailure(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.error = action.payload;
      console.error(action.payload);
    },
    startListener: (state, action) => {
      state.listener = action.payload;
    },
    stopListener: (state) => {
      if (state.listener) {
        state.listener();
      }
    },
    // --------------------------------------------------
    reset: (state) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    },
  },
  // extraReducers(builder) {
  //   builder;
  // },
});

export const {
  setExerciseMetrics,
  fetchConnectionsRequestsLoading,
  fetchConnectionsRequestsSuccess,
  fetchConnectionsRequestsFailure,
  startListener,
  stopListener,
  reset,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------
export const startExerciseMetricsListener =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { listener } = state.exerciseMetrics;

    if (listener) {
      console.error('Exercise metrics listener already exists');
      return;
    }

    dispatch(fetchConnectionsRequestsLoading());
    try {
      const unsubscribe = await onSnapshot(
        collection(DB, 'exerciseMetrics'),
        (querySnapshot) => {
          const upsertItems: ExerciseMetric_WithID[] = [];
          const removeItems: string[] = [];

          querySnapshot.docChanges().forEach((change) => {
            const { id } = change.doc;
            const data = change.doc.data() as any;
            convertFirebaseDataDates(data);

            const item = { ...data, id } as ExerciseMetric_WithID;

            if (change.type === 'added' || change.type === 'modified') {
              upsertItems.push(item);
            }
            if (change.type === 'removed') {
              removeItems.push(id);
            }
          });
          dispatch(setExerciseMetrics({ upsertItems, removeItems }));
        },
        (error) => {
          dispatch(fetchConnectionsRequestsFailure(error.message));
        }
      );
      dispatch(startListener(unsubscribe));
    } catch (error) {
      dispatch(fetchConnectionsRequestsFailure(error));
    }
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllExerciseMetrics, selectById: selectExerciseMetricById } =
  exerciseMetricAdapter.getSelectors((state: RootState) => state.exerciseMetrics);

export const getExerciseMetricsFetchStatus = (state: RootState) => state.exerciseMetrics.status;
export const getExerciseMetricsFetchError = (state: RootState) => state.exerciseMetrics.error;
