import { addDoc, collection, doc, getDoc, writeBatch } from 'firebase/firestore';
import { Program, ProgramWeek_WithID, Program_WithID, Workout_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';
import uuidv4 from 'src/utils/uuidv4';
import { handleLoadProgramWeeks } from '../load';
import cleanProgramData from 'src/utils/cleanProgramData';

type Props = {
  programId: string;
};

// --------------------------------------------------
// Duplicate a program
// --------------------------------------------------
// Duplicate a program
// Duplicate all program weeks
// Duplicate all workouts
// --------------------------------------------------
const handleDuplicateProgram = async ({ programId }: Props) => {
  // --------------------------------------------------
  // Load all program content locally
  // --------------------------------------------------
  // Fetch Program from Firebase
  const programDocRef = doc(DB, 'programs', programId);
  const programSnap = await getDoc(programDocRef);

  if (!programSnap.exists()) {
    throw new Error(`Program with id ${programId} does not exist`);
  }

  const data = programSnap.data();
  const { id } = programSnap;

  convertFirebaseDataDates(data);

  const program = { ...data, id } as Program_WithID;

  // Fetch Program Weeks from Firebase
  // Fetch Workouts from Firebase
  const { programWeeks, workouts } = await handleLoadProgramWeeks({ programId });

  if (!programWeeks || !workouts) {
    throw new Error('Program weeks or workouts are undefined');
  }

  // --------------------------------------------------
  // Duplicate all program content locally
  // --------------------------------------------------

  // Duplicate Program
  const cleanProgram = cleanProgramData(program);
  const newProgramData: Program & { id?: string } = {
    ...cleanProgram,
    id: undefined,
    users: [],
    userIds: [],
    dateCreated: new Date(),
    lastUpdated: new Date(),
  };
  delete newProgramData.id;

  const newProgramRef = await addDoc(collection(DB, 'programs'), newProgramData);
  const newProgramId = newProgramRef.id;
  const newProgram: Program_WithID = { ...newProgramData, id: newProgramId };
  if (!newProgramId) {
    throw new Error('Failed to duplicate program');
  }

  // Duplicate Program Weeks
  const newWorkouts: Workout_WithID[] = [];
  const newProgramWeeks = programWeeks.map((programWeek) => {
    const programWeekWorkouts = workouts.filter(
      (workout) => workout.programWeekId === programWeek.id
    );

    const newProgramWeek: ProgramWeek_WithID = {
      ...programWeek,
      id: uuidv4(),
      programId: newProgramId,
    };

    // Duplicate Workouts
    programWeekWorkouts.forEach((workout) => {
      const newWorkout: Workout_WithID = {
        ...workout,
        id: uuidv4(),
        programWeekId: newProgramWeek.id,
        programId: newProgramId,
      };
      newWorkouts.push(newWorkout);
    });

    return newProgramWeek;
  });

  // --------------------------------------------------
  // Save the duplicated program content to Firebase
  // --------------------------------------------------

  const batch = writeBatch(DB);

  // Save Program Weeks
  newProgramWeeks.forEach((programWeek) => {
    const { id: programWeekId, ...programWeekData } = programWeek;
    batch.set(doc(DB, 'programs', newProgramId, 'programWeeks', programWeekId), programWeekData);
  });

  // Save Workouts
  newWorkouts.forEach((workout) => {
    const { id: workoutId, ...workoutData } = workout;
    batch.set(
      doc(
        DB,
        'programs',
        newProgramId,
        'programWeeks',
        workout.programWeekId,
        'workouts',
        workoutId
      ),
      workoutData
    );
  });

  await batch.commit();

  return newProgram;
};

export default handleDuplicateProgram;
