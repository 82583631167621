import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { getUserId } from 'src/redux/slices/user';
import { Alert } from '@mui/material';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import {
  getNotifcationsFetchError,
  getNotifcationsFetchStatus,
  selectAllNotifications,
  startNotificationsListener,
} from 'src/redux/slices/notifications';
import { doc, updateDoc, writeBatch } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';

// ----------------------------------------------------------------------

export default function useNotifications() {
  const dispatch = useDispatch();
  const coachId = useSelector(getUserId);
  const notifications = useSelector(selectAllNotifications);
  const fetchStatus = useSelector(getNotifcationsFetchStatus);
  const fetchError = useSelector(getNotifcationsFetchError);

  useEffect(() => {
    if (coachId && fetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(startNotificationsListener(coachId));
    }
  }, [fetchStatus, coachId, dispatch]);

  const handleMarkAllAsRead = async () => {
    // Set all notifications with seen: false to seen: true on firebase
    const notificationsToMarkAsRead = notifications.filter((notification) => !notification.seen);
    // If no notifications to mark as read, return
    if (!notificationsToMarkAsRead.length) return;

    const batch = writeBatch(DB);

    notificationsToMarkAsRead.forEach((notification) => {
      const notificationRef = doc(DB, 'notifications', notification.id);
      batch.update(notificationRef, { seen: true });
    });

    await batch.commit();
  };

  const handleMarkItemAsRead = async (notificationId: string) => {
    const notificationRef = doc(DB, 'notifications', notificationId);
    await updateDoc(notificationRef, { seen: true });
  };

  const ErrorAlert = () => (!!fetchError ? <Alert severity="error">{fetchError}</Alert> : null);

  return {
    notifications,
    fetchStatus,
    fetchError,
    handleMarkItemAsRead,
    handleMarkAllAsRead,
    ErrorAlert,
  };
}
