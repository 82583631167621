import _ from 'lodash';
import { UNIT_OF_MEASUREMENT_ENUM } from 'src/@types/enums';
import { ExerciseMetric_WithID, UserExerciseMetricValueLog_WithID } from 'src/@types/firebase';
import { msToTime } from './msToTime';
import { mmToDistance } from './prettyMillimeters';

// --------------------------------------------------------------------------------------------------------------------
// Helper functions
// --------------------------------------------------------------------------------------------------------------------

const renderUnit = (
  type: UNIT_OF_MEASUREMENT_ENUM,
  abbreviation: string,
  items: UserExerciseMetricValueLog_WithID[]
) => {
  let unit = abbreviation ? abbreviation : '';

  if (type === UNIT_OF_MEASUREMENT_ENUM.DISTANCE) {
    // Value in millimeters
    const maxValue = items.reduce<number | undefined>((acc, item) => {
      if (!acc && item?.numericValue) {
        return item.numericValue;
      }
      if (item?.numericValue && acc && item.numericValue > acc) {
        return item.numericValue;
      }
      return acc;
    }, undefined);

    // Default distance unit is millimeters
    unit = 'mm';

    if (maxValue) {
      const distance = mmToDistance(maxValue);
      if (distance.kilometers > 0) {
        unit = 'km';
      } else if (distance.meters > 0) {
        unit = 'm';
      } else if (distance.centimeters > 0) {
        unit = 'cm';
      }
    }
  } else if (type === UNIT_OF_MEASUREMENT_ENUM.TIME) {
    // Value in milliseconds
    const maxValue = items.reduce<number | undefined>((acc, item) => {
      if (!acc && item?.numericValue) {
        return item.numericValue;
      }
      if (item?.numericValue && acc && item.numericValue > acc) {
        return item.numericValue;
      }
      return acc;
    }, undefined);

    // Default distance unit is milliseconds
    unit = 'ms';

    if (maxValue) {
      const distance = msToTime(maxValue);
      if (distance.hours > 0) {
        unit = 'hr';
      } else if (distance.minutes > 0) {
        unit = 'min';
      } else if (distance.seconds > 0) {
        unit = 'sec';
      }
    }
  }

  return unit;
};

const renderData = (
  type: UNIT_OF_MEASUREMENT_ENUM,
  unit: string,
  item: UserExerciseMetricValueLog_WithID
) => {
  // Convert date of type Date to milliseconds
  const dateCreatedTimestamp = item.dateCreated.getTime();

  const x =
    dateCreatedTimestamp +
    // Add 1 minutes for each group
    (item.workoutLogExerciseGroupIndex + 1) * 60000 +
    // Add 1 second for each exercise in a group
    (item.workoutLogExerciseIndex + 1) * 1000 +
    // Add 1 millisecond for set in an exercise
    item.set +
    1;
  if (!item.numericValue) {
    throw new Error('Render Graph Value: numericValue is required');
  }
  const { numericValue } = item;

  let y = numericValue;

  if (type === UNIT_OF_MEASUREMENT_ENUM.DISTANCE) {
    if (unit === 'cm') {
      // Convert from millimeters to centimeters
      y = numericValue / 10;
    } else if (unit === 'm') {
      // Convert from millimeters to meters
      y = numericValue / 1000;
    } else if (unit === 'km') {
      // Convert from millimeters to kilometers
      y = numericValue / 1000000;
    }
  } else if (type === UNIT_OF_MEASUREMENT_ENUM.TIME) {
    if (unit === 'sec') {
      // Convert from milliseconds to seconds
      y = numericValue / 1000;
    } else if (unit === 'min') {
      // Convert from milliseconds to minutes
      y = numericValue / 60000;
    } else if (unit === 'hr') {
      // Convert from milliseconds to hours
      y = numericValue / 3600000;
    }
  }

  // Round y to 2 decimal places
  y = Math.round(y * 100) / 100;

  return { x: x, y: y };
};

// --------------------------------------------------------------------------------------------------------------------

// --------------------------------------------------------------------------------------------------------------------
// Pretty up the data for the graph
// --------------------------------------------------------------------------------------------------------------------
export const renderGraphValues = (
  items: UserExerciseMetricValueLog_WithID[],
  exerciseMetric: ExerciseMetric_WithID
) => {
  const { type, abbreviation } = exerciseMetric.unitOfMeasurement;
  const unit = renderUnit(type, abbreviation, items);

  const metricName = unit ? `${exerciseMetric.name} (${unit})` : exerciseMetric.name;
  // Unique with the date and numericValue
  const uniqueItems = _.uniqWith(
    items,
    (item1, item2) =>
      item1.dateCreated === item2.dateCreated && item1.numericValue === item2.numericValue
  );
  const metricData = uniqueItems.map((item) => renderData(type, unit, item));

  const sortedMetricData = _.sortBy(metricData, 'x');

  return {
    name: metricName,
    data: sortedMetricData,
  };
};
// --------------------------------------------------------------------------------------------------------------------
