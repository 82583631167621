import * as React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  closeSaveAlert,
  getProgramSaveAlertActive,
  getProgramSaveError,
} from 'src/redux/slices/program/program';

export default function SaveProgramAlert() {
  const dispatch = useDispatch();
  const saveAlertActive = useSelector(getProgramSaveAlertActive);
  const error = useSelector(getProgramSaveError);

  const handleClose = () => {
    dispatch(closeSaveAlert());
  };

  return (
    <Dialog
      open={saveAlertActive}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Program Save Error</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{error}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
