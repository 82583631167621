/**
 * Gets data from Typesense and converts the date strings to Date objects.
 *
 * @param data - A Typesense document.
 *
 */
export default function convertTypesenseDataDates(data: any) {
  if (data?.dateCreated) {
    data.dateCreated = new Date(data.dateCreated);
  }
  if (data?.lastUpdated) {
    data.lastUpdated = new Date(data.lastUpdated);
  }

  // Coach Clients
  if (data?.dateAdded) {
    data.dateAdded = new Date(data.dateAdded);
  }
  if (data?.client?.dateCreated) {
    data.client.dateCreated = new Date(data.client.dateCreated);
  }
}
