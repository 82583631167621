import { useContext } from 'react';
import { getUserAuth } from 'src/redux/slices/user';
import { useSelector } from 'src/redux/store';
import { AuthContext } from '../contexts/FirebaseContext';

// ----------------------------------------------------------------------

const useAuth = () => {
  const context = useContext(AuthContext);
  const { authenticated: isAuthenticated, initialized: userIsInitialized } =
    useSelector(getUserAuth);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  const { userId, isInitialized: authIsInitialized } = context;

  let isInitialized = false;
  // If auth is initialized and user is not authenticated
  if (authIsInitialized && !userId) {
    isInitialized = true;
  }
  // If auth is initialized, the userId is present (meaning the user is logged in auth), and user is initialized (loaded from firestore)
  else if (authIsInitialized && userId && userIsInitialized) {
    isInitialized = true;
  }

  return { ...context, isAuthenticated, isInitialized };
};

export default useAuth;
