import { useState } from 'react';
// @mui
import { Button, Checkbox, Divider, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuPopover from 'src/components/MenuPopover';
import { useDispatch, useSelector } from 'src/redux/store';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { getExerciseMetricIconName } from 'src/utils/getExerciseMetricIconName';
import {
  addWorkoutExerciseMetric,
  removeWorkoutExerciseMetric,
} from 'src/redux/slices/program/workoutExerciseMetrics';
import {
  getWorkoutExerciseCoachNotesVisible,
  getWorkoutExerciseHeaderVisible,
  getWorkoutExerciseSetsVisible,
  toggleCoachNotes,
  toggleHeader,
  toggleSets,
} from 'src/redux/slices/program/workoutExercises';
import { ExerciseMetric_WithID } from 'src/@types/firebase';
import { WorkoutExerciseMetric } from 'src/@types/program';
import useExerciseMetrics from 'src/hooks/useExerciseMetrics';
//

// ----------------------------------------------------------------------

const BootstrapButton = styled(Button)(({ theme }) => ({
  fontSize: 12,
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.text.disabled,
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
}));

// ----------------------------------------------------------------------

const BootstrapStack = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: 1,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

// ----------------------------------------------------------------------

type Props = {
  workoutExerciseMetrics: WorkoutExerciseMetric[];
  workoutExerciseId: string;
  exerciseId: string;
  workoutId: string;
  inGroup?: boolean;
};

export default function DetailsMenu({
  workoutExerciseMetrics,
  workoutExerciseId,
  exerciseId,
  workoutId,
  inGroup,
}: Props) {
  const dispatch = useDispatch();

  const { exerciseMetrics } = useExerciseMetrics();
  const setsVisible = useSelector((state) =>
    getWorkoutExerciseSetsVisible(state, workoutExerciseId)
  );
  const headerVisible = useSelector((state) =>
    getWorkoutExerciseHeaderVisible(state, workoutExerciseId)
  );
  const coachNotesVisible = useSelector((state) =>
    getWorkoutExerciseCoachNotesVisible(state, workoutExerciseId)
  );

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(null);
  };

  const handleExerciseMetricAdd = (
    event: React.MouseEvent<HTMLElement>,
    exerciseMetric: ExerciseMetric_WithID
  ) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(
      addWorkoutExerciseMetric({ exerciseMetric, workoutExerciseId, exerciseId, workoutId })
    );
  };

  const handleExerciseMetricRemove = (
    event: React.MouseEvent<HTMLElement>,
    workoutExerciseMetricId: string
  ) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(removeWorkoutExerciseMetric({ workoutExerciseMetricId, workoutExerciseId }));
  };

  const toggleSetsVisible = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(toggleSets(workoutExerciseId));
  };

  const toggleHeaderVisible = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(toggleHeader(workoutExerciseId));
  };

  const toggleCoachNotesVisible = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(toggleCoachNotes(workoutExerciseId));
  };

  return (
    <>
      <BootstrapButton
        onClick={handleOpen}
        sx={{ ml: 1, mb: inGroup ? 0 : 1 }}
        startIcon={<SvgIconStyle src={`/icons/ic_add_text.svg`} sx={{ width: 16, height: 16 }} />}
      >
        Add/ Edit Details
      </BootstrapButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        // transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        // arrow="top-right"
        sx={{
          width: 'auto',
          mt: -1,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        <Stack width={400}>
          <Grid container columnSpacing={1}>
            <Grid item xs={12}>
              <Typography variant="caption">TEXT</Typography>
              <Divider sx={{ mb: 1 }} />
            </Grid>
            {!inGroup && (
              <Grid item xs={4} zeroMinWidth>
                <BootstrapStack alignItems="center" direction="row" onClick={toggleHeaderVisible}>
                  <SvgIconStyle
                    src={`/icons/ic_header.svg`}
                    sx={{ width: 16, height: 16, mr: 0.5, color: 'text.secondary' }}
                  />
                  <Typography variant="caption" sx={{ flex: 1 }}>
                    Header
                  </Typography>
                  <Checkbox checked={headerVisible} onClick={toggleHeaderVisible} />
                </BootstrapStack>
              </Grid>
            )}
            <Grid item xs={4} zeroMinWidth>
              <BootstrapStack alignItems="center" direction="row" onClick={toggleCoachNotesVisible}>
                <SvgIconStyle
                  src={`/icons/ic_notes.svg`}
                  sx={{ width: 16, height: 16, mr: 0.5, color: 'text.secondary' }}
                />
                <Typography variant="caption" sx={{ flex: 1 }}>
                  Notes
                </Typography>
                <Checkbox checked={coachNotesVisible} onClick={toggleCoachNotesVisible} />
              </BootstrapStack>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={12}>
              <Typography variant="caption">METRICS</Typography>
              <Divider sx={{ mb: 1 }} />
            </Grid>
            <Grid item xs={4}>
              <BootstrapStack alignItems="center" direction="row" onClick={toggleSetsVisible}>
                <SvgIconStyle
                  src={`/icons/ic_set.svg`}
                  sx={{ width: 16, height: 16, mr: 0.5, color: 'text.secondary' }}
                />

                <Typography variant="caption" sx={{ flex: 1 }}>
                  Sets
                </Typography>
                <Checkbox checked={setsVisible} onClick={toggleSetsVisible} />
              </BootstrapStack>
            </Grid>
            {exerciseMetrics.map((exerciseMetric) => {
              const exerciseMetricIconName = getExerciseMetricIconName(exerciseMetric.name);

              const workoutExerciseMetricId = workoutExerciseMetrics.find(
                (workoutExerciseMetric) =>
                  workoutExerciseMetric.exerciseMetricId === exerciseMetric.id
              )?.id;
              const active = !!workoutExerciseMetricId;

              return (
                <Grid item xs={4} key={exerciseMetric.id}>
                  <BootstrapStack
                    alignItems="center"
                    direction="row"
                    onClick={(e) =>
                      active
                        ? handleExerciseMetricRemove(e, workoutExerciseMetricId)
                        : handleExerciseMetricAdd(e, exerciseMetric)
                    }
                  >
                    <SvgIconStyle
                      src={`/icons/${exerciseMetricIconName}.svg`}
                      sx={{ width: 16, height: 16, mr: 0.5, color: 'text.secondary' }}
                    />

                    <Typography variant="caption" sx={{ flex: 1 }}>
                      {exerciseMetric.name}
                    </Typography>

                    <Checkbox
                      checked={active}
                      onClick={(e) =>
                        active
                          ? handleExerciseMetricRemove(e, workoutExerciseMetricId)
                          : handleExerciseMetricAdd(e, exerciseMetric)
                      }
                    />
                  </BootstrapStack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </MenuPopover>
    </>
  );
}
