import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { collection, onSnapshot, query, Unsubscribe, where } from 'firebase/firestore';
import { User_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { AppDispatch, RootState } from '../store';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';

const coachesAdapter = createEntityAdapter<User_WithID>({
  // Sort by date created
  sortComparer: (a: User_WithID, b: User_WithID) =>
    b.dateCreated.getTime() - a.dateCreated.getTime(),
});

const initialState = coachesAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  listener: null,
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null; listener: null | Unsubscribe });

export const slice = createSlice({
  name: 'coaches',
  initialState,
  reducers: {
    setCoaches: (
      state,
      action: PayloadAction<{ upsertItems: User_WithID[]; removeItems: string[] }>
    ) => {
      coachesAdapter.upsertMany(state, action.payload.upsertItems);
      coachesAdapter.removeMany(state, action.payload.removeItems);
    },
    // --------------------------------------------------
    // Listener commands start
    // --------------------------------------------------
    fetchConnectionsRequestsLoading(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
    },
    fetchConnectionsRequestsSuccess(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      state.listener = action.payload;
    },
    fetchConnectionsRequestsFailure(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.error = action.payload;
      console.error(action.payload);
    },
    stopListener: (state) => {
      if (state.listener) {
        state.listener();
      }
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    // --------------------------------------------------
    reset: (state) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    },
  },
});

export const {
  setCoaches,
  fetchConnectionsRequestsLoading,
  fetchConnectionsRequestsSuccess,
  fetchConnectionsRequestsFailure,
  setError,
  reset,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------
export const startCoachesListener =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { listener } = state.coaches;

    if (listener) {
      console.error('Coaches listener already exists');
      return;
    }

    dispatch(fetchConnectionsRequestsLoading());
    try {
      const q = query(collection(DB, 'users'), where('type', 'array-contains', 'COACH'));
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const upsertItems: User_WithID[] = [];
          const removeItems: string[] = [];

          querySnapshot.docChanges().forEach((change) => {
            const { id } = change.doc;
            const data = change.doc.data();
            convertFirebaseDataDates(data);

            const item = { ...data, id } as User_WithID;

            if (change.type === 'added' || change.type === 'modified') {
              upsertItems.push(item);
            }
            if (change.type === 'removed') {
              removeItems.push(id);
            }
          });

          dispatch(setCoaches({ upsertItems, removeItems }));
        },
        (error) => {
          dispatch(setError(error.message));
        }
      );
      dispatch(fetchConnectionsRequestsSuccess(unsubscribe));
    } catch (error) {
      dispatch(fetchConnectionsRequestsFailure(error.message));
    }
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllCoaches,
  selectById: selectCoachById,
  // Pass in a selector that returns the posts slice of state
} = coachesAdapter.getSelectors((state: RootState) => state.coaches);

export const getCoachesFetchStatus = (state: RootState) => state.coaches.status;
export const getCoachesFetchError = (state: RootState) => state.coaches.error;
