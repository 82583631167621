import { Stack, Card, Button, Typography } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { shallowEqual, useSelector } from 'react-redux';
import { COPY_ITEM_ENUM } from 'src/@types/enums';
import { WorkoutExerciseModal } from 'src/@types/exerciseModal';
import { Workout as WorkoutType } from 'src/@types/program';
import Iconify from 'src/components/Iconify';
import { WORKOUT_WIDTH } from 'src/constants';
import {
  isItemCollapsed,
  isWorkoutCollapsed,
  toggleCollapseItem,
  toggleCollapseWorkout,
} from 'src/redux/slices/program/collapsedItems';
import { openCopyToModal } from 'src/redux/slices/program/copyToModal';
import { openExerciseModal } from 'src/redux/slices/program/exerciseModal';
import { selectAllWorkoutDragItemsByWorkoutId } from 'src/redux/slices/program/workoutDragItems';
import { updateWorkout, removeWorkout, duplicateWorkout } from 'src/redux/slices/program/workouts';
import { RootState, useDispatch } from 'src/redux/store';
import { WorkoutDragItem, CollapseButton, WorkoutMenu, TextField } from '.';

type Props = {
  workout: WorkoutType;
};

function Workout({ workout }: Props) {
  const dispatch = useDispatch();
  const { id, index, name, description, descriptionVisible } = workout;
  const noteId = `note-${id}`;
  // const {  name, cover, /*position*/ follower, totalPost, avatarUrl, following } = user;
  const workoutDragItems = useSelector(
    (state: RootState) => selectAllWorkoutDragItemsByWorkoutId(state, id),
    shallowEqual
  );
  const collapsed = useSelector((state: RootState) => isWorkoutCollapsed(state, id));
  const noteCollapsed = useSelector((state: RootState) => isItemCollapsed(state, noteId));
  const [hover, setHover] = useState(false);
  const [noteHover, setNoteHover] = useState(false);
  const workoutName = name ? name : 'Workout ' + (index + 1);

  const toggleCollapsed = useCallback(() => dispatch(toggleCollapseWorkout(id)), [dispatch, id]);
  const toggleNoteCollapsed = useCallback(
    () => dispatch(toggleCollapseItem(noteId)),
    [dispatch, noteId]
  );

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateWorkout({ id, updates: { name: e.target.value } }));
    },
    [dispatch, id]
  );

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateWorkout({ id, updates: { description: e.target.value } }));
    },
    [dispatch, id]
  );

  const handleAddExercise = useCallback(() => {
    // Find the bottom most workoutDragItem
    const lastWorkoutDragItem = workoutDragItems[workoutDragItems.length - 1];
    const dragIndex = workoutDragItems.length ? lastWorkoutDragItem.dragIndex : undefined;

    const exerciseModalDetails: WorkoutExerciseModal = {
      visible: true,
      workoutId: id,
      dragIndex: dragIndex,
      workoutName: name,
      groupAboveIndex:
        lastWorkoutDragItem?.groupIndex !== undefined ? lastWorkoutDragItem.groupIndex : undefined,
    };

    dispatch(openExerciseModal(exerciseModalDetails));
  }, [dispatch, id, name, workoutDragItems]);

  const handleDuplicateWorkout = useCallback(() => {
    dispatch(duplicateWorkout({ workoutId: id }));
  }, [dispatch, id]);

  const handleWorkoutCopyTo = useCallback(
    () =>
      dispatch(
        openCopyToModal({
          visible: true,
          copyItemId: id,
          copyItemType: COPY_ITEM_ENUM.WORKOUT,
          copyItemName: workoutName,
        })
      ),
    [dispatch, id, workoutName]
  );

  const handleRemoveWorkout = useCallback(() => {
    dispatch(removeWorkout({ workoutId: id }));
  }, [dispatch, id]);

  const handleToggleWorkoutDescriptionVisibility = useCallback(() => {
    dispatch(updateWorkout({ id, updates: { descriptionVisible: !descriptionVisible } }));
  }, [dispatch, id, descriptionVisible]);

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Stack
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{ minWidth: WORKOUT_WIDTH, maxWidth: WORKOUT_WIDTH }}
        >
          <Card
            {...provided.dragHandleProps}
            sx={{ pl: 2, pr: 1, py: 1, mb: 3 }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Stack direction="row" alignItems="center" sx={{ pl: 2 }}>
              {
                process.env.NODE_ENV === 'development' && (
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {index}
                  </Typography>
                ) /* shows index in dev mode */
              }
              <TextField
                font="body2"
                fullWidth
                onChange={handleNameChange}
                value={!!name ? name : ''}
                placeholder={!!name ? '' : 'Workout ' + (index + 1)}
              />
              <CollapseButton
                active={hover}
                collapsed={collapsed}
                toggleCollapsed={toggleCollapsed}
              />
              <WorkoutMenu
                active={hover}
                handleToggleWorkoutDescriptionVisibility={handleToggleWorkoutDescriptionVisibility}
                handleDuplicateWorkout={handleDuplicateWorkout}
                handleWorkoutCopyTo={handleWorkoutCopyTo}
                handleRemoveWorkout={handleRemoveWorkout}
              />
            </Stack>
          </Card>
          {descriptionVisible ? (
            <Card
              sx={{ p: 2, pr: 1, mb: 2, borderLeft: 3, borderColor: 'primary.light' }}
              onMouseEnter={() => setNoteHover(true)}
              onMouseLeave={() => setNoteHover(false)}
            >
              <Stack direction="row" alignItems="flex-start">
                <TextField
                  size="small"
                  collapsed={noteCollapsed}
                  fullWidth
                  multiline
                  placeholder="Add a description..."
                  value={description}
                  onChange={handleDescriptionChange}
                />
                <CollapseButton
                  active={noteHover}
                  collapsed={noteCollapsed}
                  toggleCollapsed={toggleNoteCollapsed}
                />
              </Stack>
            </Card>
          ) : null}

          <Droppable type="EXERCISE" droppableId={id} isCombineEnabled>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {workoutDragItems.map((workoutDragItem, index) => (
                  <WorkoutDragItem
                    key={workoutDragItem.id}
                    workoutDragItem={workoutDragItem}
                    lastItem={index === workoutDragItems.length - 1}
                  />
                ))}
                <Stack sx={{ py: workoutDragItems.length ? 0 : 1 }}>{provided.placeholder}</Stack>
              </div>
            )}
          </Droppable>

          <Button
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            sx={{ mt: 2, height: 45 }}
            variant="outlined"
            onClick={handleAddExercise}
          >
            Add Exercise
          </Button>
        </Stack>
      )}
    </Draggable>
  );
}

export default memo(Workout);
