import { WorkoutDragItem, WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  exerciseOptionsDragItem: WorkoutDragItem;
  dragItemBelowExerciseOptions?: WorkoutDragItem;
  groupDragItems: WorkoutDragItem[];
};

// Combine one solo group above into an existing group below
const handleCombineOneAboveTwoOrMoreBelow = ({
  state,
  exerciseOptionsDragItem,
  dragItemBelowExerciseOptions,
  groupDragItems,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [{ id: exerciseOptionsDragItem.workoutId, changes: {} }];
  if (dragItemBelowExerciseOptions) {
    workoutUpdates.push({ id: dragItemBelowExerciseOptions.workoutId, changes: {} });
  }
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutExercises = {};
  changes.workoutDragItems = {};

  // Merge to the group with the most exercises (bottom group in this case)
  const groupTwoHeader = dragItemBelowExerciseOptions;
  const groupOneExercise = groupDragItems.find(
    (item) =>
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE && item.groupId !== groupTwoHeader?.groupId
  );

  if (!groupOneExercise || !groupTwoHeader) {
    console.error(
      'handleCombineOneAboveTwoOrMoreBelow: groupOneExercise or groupTwoHeader is undefined'
    );
    return changes;
  }

  // Find all exercises in the group below
  const groupTwoExercises = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem =>
      !!item &&
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.groupId === groupTwoHeader.groupId
  );

  // Remove group one group
  const removedGroupId = groupOneExercise.groupId;
  // Remove exercise options for the removed group
  const removedExerciseOptionsId = `${removedGroupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;

  const newDragIndex = groupOneExercise.dragIndex;
  const newGroupIndex = groupOneExercise.groupIndex;

  let itemCount = 0;
  // Update group two header
  const groupHeaderUpdateId = groupTwoHeader.groupId;
  const groupHeaderUpdates: Partial<WorkoutDragItem> = {
    inGroup: true,
    dragIndex: newDragIndex,
    index: newGroupIndex,
    groupIndex: newGroupIndex,
  };
  // Increment item count
  itemCount++;

  // Update group two index
  const groupTwoUpdates: Partial<WorkoutExerciseGroup> = {
    index: newGroupIndex,
  };

  // Update the exercises to be in group
  const groupOneExerciseDragItemUpdates: Partial<WorkoutDragItem> = {
    inGroup: true,
    lastInGroup: false,
    dragIndex: newDragIndex + itemCount,
    groupId: groupTwoHeader.groupId,
  };
  // Update groupOneExercise to be in groupTwo
  const groupOneExerciseUpdates: Partial<WorkoutExercise> = {
    workoutExerciseGroupId: groupTwoHeader.groupId,
  };
  // Increment item count
  itemCount++;

  // Update groupTwo drag items
  const groupTwoExerciseDragItemChanges: UpdateType[] = groupTwoExercises.map((item, i) => {
    const changes: Partial<WorkoutDragItem> = {
      groupIndex: newGroupIndex,
      index: item.index + 1, // +1 because on the solo exercise above
      dragIndex: newDragIndex + itemCount,
    };
    // Increment item count
    itemCount++;

    return {
      id: item.id,
      changes,
    };
  });
  // Update groupTwo exercises
  const groupTwoExerciseChanges: UpdateType[] = groupTwoExercises.map((item, i) => {
    const changes: Partial<WorkoutExercise> = {
      index: item.index + 1, // +1 because on the solo exercise above
    };
    return {
      id: item.id,
      changes,
    };
  });

  // Update group two footer
  const groupFooterUpdateId = `${groupTwoHeader.groupId}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`;
  const groupFooterUpdates: Partial<WorkoutDragItem> = {
    inGroup: true,
    dragIndex: newDragIndex + itemCount,
    index: newGroupIndex,
    groupIndex: newGroupIndex,
  };
  // Increment item count
  itemCount++;

  // Update the drag index of group two exercise options
  const updateExerciseOptionsId = `${groupTwoHeader.groupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;
  const exerciseOptionsUpdates: Partial<WorkoutDragItem> = {
    inGroup: true,
    dragIndex: newDragIndex + itemCount,
    index: newGroupIndex,
    groupIndex: newGroupIndex,
  };

  // Apply the changes
  changes.workoutExerciseGroups.removed = [removedGroupId];
  changes.workoutExerciseGroups.updated = [{ id: groupHeaderUpdateId, changes: groupTwoUpdates }];
  changes.workoutDragItems.removed = [removedExerciseOptionsId];
  changes.workoutDragItems.updated = [
    { id: groupOneExercise.id, changes: groupOneExerciseDragItemUpdates },
    ...groupTwoExerciseDragItemChanges,
    { id: groupHeaderUpdateId, changes: groupHeaderUpdates },
    { id: groupFooterUpdateId, changes: groupFooterUpdates },
    { id: updateExerciseOptionsId, changes: exerciseOptionsUpdates },
  ];
  changes.workoutExercises.updated = [
    { id: groupOneExercise.id, changes: groupOneExerciseUpdates },
    ...groupTwoExerciseChanges,
  ];

  // Find all the drag items below the removed group
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      if (!item) {
        return false;
      }

      if (item.workoutId !== groupOneExercise.workoutId) {
        return false;
      }

      // exclude items from group two
      if (item.groupId === groupTwoHeader.groupId) {
        return false;
      }

      if (item.dragIndex > groupTwoHeader.dragIndex) {
        return true;
      }

      return false;
    }
  );

  // Update the drag indexes for all items below the removed group
  // Update all the group indexes for all items below removed group
  const dragItemUpdates: UpdateType[] = [];
  const workoutExerciseGroupUpdates: UpdateType[] = [];

  affectedItems.forEach((item) => {
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};

    dragItemChanges.dragIndex = item.dragIndex - 1; // -1 because we removed 1 (exercise options)

    // Update group indexes
    // if (item.groupIndex !== undefined) {
    //   dragItemChanges.groupIndex = item.groupIndex - 1;
    // }
    // // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
    // if (
    //   item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
    //   item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
    //   item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    // ) {
    //   dragItemChanges.index = item.index - 1;
    // }

    // dragItemUpdates.push({ id: item.id, changes: dragItemChanges });

    // Update group indexes

    // Drag item changes
    if (item.groupIndex !== undefined) {
      dragItemChanges.groupIndex = item.groupIndex - 1;
    }
    // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    ) {
      dragItemChanges.index = item.index - 1;
    }

    // ExerciseGroup changes
    // If the item is a group header or a solo exercise
    // Update the exercise group index
    if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
      exerciseGroupChanges.index = item.index - 1;
    } else if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      !item.inGroup &&
      item.groupIndex !== undefined
    ) {
      exerciseGroupChanges.index = item.groupIndex - 1;
    }

    // Push updates
    dragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });
    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...dragItemUpdates);
  if (workoutExerciseGroupUpdates.length) {
    changes.workoutExerciseGroups.updated.length
      ? changes.workoutExerciseGroups.updated.push(...workoutExerciseGroupUpdates)
      : (changes.workoutExerciseGroups.updated = workoutExerciseGroupUpdates);
  }

  return changes;
};

export default handleCombineOneAboveTwoOrMoreBelow;
