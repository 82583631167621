import { useContext, useEffect } from 'react';
import { PromptContext } from 'src/contexts/PromptContext';

// ----------------------------------------------------------------------

export default function usePrompt() {
  const context = useContext(PromptContext);
  const { resetPrompt } = context;

  // Clean up when screen unmounts
  useEffect(() => {
    const unsubscribe = () => {
      resetPrompt();
    };
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return context;
}
