import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import { ChangeData, Changes } from 'src/@types/program_redux';
import { applyChanges } from 'src/redux/functions/applyChanges';
import { AppDispatch, RootState } from 'src/redux/store';
import uuidv4 from 'src/utils/uuidv4';
import {
  duplicateWorkoutExerciseGroupAction,
  removeExerciseGroupAction,
} from './workoutExerciseGroups';
import { programResetAction } from './program';
import { duplicateWeekAction, fetchProgramWeeks, removeWeekAction } from './programWeeks';
import {
  addExercisesAction,
  duplicateWorkoutExerciseAction,
  removeExerciseAction,
  swapWorkoutExerciseAction,
} from './workoutExercises';
import { duplicateWorkoutAction, removeWorkoutAction } from './workouts';
import { WORKOUT_EXERCISE_METRICS } from './constants/keys';
import { ExerciseMetric_WithID } from 'src/@types/firebase';
import { WorkoutExerciseMetric, WorkoutExerciseMetricValue } from 'src/@types/program';
import { groupingAction, reorderExercisesAction } from './workoutDragItems';
import { copyToCurrentProgram } from './copyToModal';

const workoutExerciseMetricAdapter = createEntityAdapter<WorkoutExerciseMetric>({
  // Sort by index
  sortComparer: (a: WorkoutExerciseMetric, b: WorkoutExerciseMetric) => a.index - b.index,
});

const initialState = workoutExerciseMetricAdapter.getInitialState();

export const addWorkoutExerciseMetricAction = createAction<Changes>(
  'workoutExerciseMetrics/addWorkoutExerciseMetric'
);
export const removeWorkoutExerciseMetricAction = createAction<Changes>(
  'workoutExerciseMetrics/removeWorkoutExerciseMetric'
);
export const reorderWorkoutExerciseMetricsAction = createAction<Changes>(
  'workoutExerciseMetrics/reorderWorkoutExerciseMetrics'
);

export const slice = createSlice({
  name: WORKOUT_EXERCISE_METRICS,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    const handleChangesReducer = (state: any, action: PayloadAction<Changes>) => {
      const items = action.payload.workoutExerciseMetrics;
      if (items) {
        applyChanges(items, workoutExerciseMetricAdapter, state);
      }
    };

    builder
      // Local
      .addCase(addWorkoutExerciseMetricAction, handleChangesReducer)
      .addCase(removeWorkoutExerciseMetricAction, handleChangesReducer)
      .addCase(reorderWorkoutExerciseMetricsAction, handleChangesReducer)
      // External
      .addCase(addExercisesAction, handleChangesReducer)
      .addCase(removeExerciseAction, handleChangesReducer)
      .addCase(removeExerciseGroupAction, handleChangesReducer)
      .addCase(removeWorkoutAction, handleChangesReducer)
      .addCase(removeWeekAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseGroupAction, handleChangesReducer)
      .addCase(duplicateWorkoutAction, handleChangesReducer)
      .addCase(duplicateWeekAction, handleChangesReducer)
      .addCase(reorderExercisesAction, handleChangesReducer)
      .addCase(groupingAction, handleChangesReducer)
      .addCase(copyToCurrentProgram, handleChangesReducer)
      .addCase(swapWorkoutExerciseAction, handleChangesReducer)

      .addCase(programResetAction, () => initialState)
      .addCase(fetchProgramWeeks.fulfilled, (state, action) => {
        const items = action.payload[WORKOUT_EXERCISE_METRICS];

        if (items.length) {
          workoutExerciseMetricAdapter.addMany(state, action.payload.workoutExerciseMetrics);
        }
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

export const addWorkoutExerciseMetric =
  ({
    exerciseMetric,
    workoutExerciseId,
    exerciseId,
    workoutId,
  }: {
    exerciseMetric: ExerciseMetric_WithID;
    workoutExerciseId: string;
    exerciseId: string;
    workoutId: string;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const workouts: ChangeData = {
      updated: [{ id: workoutId, changes: {} }],
    };

    const id = uuidv4();
    const exerciseMetricId = exerciseMetric.id;
    const index = Object.values(getState().workoutExerciseMetrics.entities).filter(
      (item): item is WorkoutExerciseMetric =>
        !!item && item.workoutExerciseId === workoutExerciseId
    ).length;
    const sets = getState().workoutExercises.entities[workoutExerciseId]?.sets;

    const workoutExerciseMetric: WorkoutExerciseMetric = {
      id,
      index,
      workoutExerciseId,
      exerciseMetricId,
      name: exerciseMetric.name,
      color: exerciseMetric.color,
      unitOfMeasurement: exerciseMetric.unitOfMeasurement,
      workoutId,
    };

    // Newly added workoutExerciseMetrics
    const added = [workoutExerciseMetric];
    // Newly added workoutExerciseMetricValues
    const addedValues = [];

    if (sets) {
      // Create a new value for each set
      for (let i = 0; i < sets; i++) {
        const newWorkoutExerciseMetricValue: WorkoutExerciseMetricValue = {
          id: uuidv4(),
          index: i,
          workoutExerciseId: workoutExerciseId,
          exerciseId: exerciseId,
          workoutExerciseMetricId: id,
          exerciseMetricId,
          value: '',
          set: i + 1,
          workoutId,
        };

        addedValues.push(newWorkoutExerciseMetricValue);
      }

      // Dispatch the new Metrics and Values
      dispatch(
        addWorkoutExerciseMetricAction({
          workouts,
          workoutExerciseMetrics: { added },
          workoutExerciseMetricValues: { added: addedValues },
        })
      );
    }
  };

export const removeWorkoutExerciseMetric =
  ({
    workoutExerciseId,
    workoutExerciseMetricId,
  }: {
    workoutExerciseId: string;
    workoutExerciseMetricId: string;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const workoutId = getState().workoutExercises.entities[workoutExerciseId]?.workoutId;

    if (!workoutId) {
      console.error(`Could not find workoutId for WorkoutExercise with id ${workoutExerciseId}`);
      return;
    }

    const workouts: ChangeData = {
      updated: [{ id: workoutId, changes: {} }],
    };

    const workoutExerciseMetrics = selectAllWorkoutExerciseMetricsByWorkoutExerciseId(
      getState(),
      workoutExerciseId
    );
    // Removed workoutExerciseMetrics
    const removed = [workoutExerciseMetricId];
    // Removed workoutExerciseMetricValues
    const removedValues = Object.values(getState().workoutExerciseMetricValues.entities)
      .filter(
        (value): value is WorkoutExerciseMetricValue =>
          !!value && value.workoutExerciseMetricId === workoutExerciseMetricId
      )
      .map((value) => value.id);

    // Find the index of the workoutExerciseMetric to remove
    const removedItemIndex = workoutExerciseMetrics.findIndex(
      (workoutExerciseMetric) => workoutExerciseMetric.id === workoutExerciseMetricId
    );
    // Find the indexes of any workoutExerciseMetrics that were below the removed one
    const updated = workoutExerciseMetrics
      .filter((workoutExerciseMetric) => workoutExerciseMetric.index > removedItemIndex)
      .map((workoutExerciseMetric) => ({
        id: workoutExerciseMetric.id,
        changes: {
          index: workoutExerciseMetric.index - 1,
        },
      }));

    dispatch(
      addWorkoutExerciseMetricAction({
        workouts,
        workoutExerciseMetrics: { updated, removed },
        workoutExerciseMetricValues: { removed: removedValues },
      })
    );
  };

export const reorderWorkoutExerciseMetrics =
  ({
    id,
    workoutExerciseId,
    from,
    to,
  }: {
    id: string;
    workoutExerciseId: string;
    from: number;
    to: number;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    // If from is greater than to then we moved the item left
    const movedDown = from > to;
    const movedUp = !movedDown;

    const state = getState();
    const movedItem = Object.values(state.workoutExerciseMetrics.entities).find(
      (item): item is WorkoutExerciseMetric => !!item && item.id === id
    );

    if (!movedItem) {
      console.error(`Workout exercise metric with id ${id} not found`);
      return;
    }

    const workouts: ChangeData = {
      updated: [{ id: movedItem.workoutId, changes: {} }],
    };
    const workoutExerciseMetrics: ChangeData = {};

    // Update the index of the moved item
    workoutExerciseMetrics.updated = [
      {
        id: movedItem.id,
        changes: {
          index: to,
        },
      },
    ];

    // Update the index for any item between the moved item and the destination
    const affectedItems = Object.values(state.workoutExerciseMetrics.entities).filter(
      (item): item is WorkoutExerciseMetric => {
        // If the item is undefined or the index is equal to the moved week then skip
        if (
          !item ||
          item.workoutExerciseId !== workoutExerciseId ||
          item.index === movedItem.index
        ) {
          return false;
        }

        // If moved down and the index is lesser than from and greater than or equal to to
        if (movedDown && item.index < from && item.index >= to) {
          return true;
        }
        // If moved up and the index is less than the from index and greater than the to index
        else if (movedUp && item.index > from && item.index <= to) {
          return true;
        }

        return false;
      }
    );

    const updates = affectedItems.map((item) => ({
      id: item.id,
      changes: {
        index: item.index + (movedDown ? 1 : -1),
      },
    }));

    workoutExerciseMetrics.updated.push(...updates);

    dispatch(reorderWorkoutExerciseMetricsAction({ workouts, workoutExerciseMetrics }));
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllWorkoutExerciseMetrics,
  selectById: selectWorkoutExerciseMetricById,
  // Pass in a selector that returns the posts slice of state
} = workoutExerciseMetricAdapter.getSelectors(
  (state: RootState) => state[WORKOUT_EXERCISE_METRICS]
);

const selectWorkoutExerciseId = (state: RootState, workoutExerciseId: string) => workoutExerciseId;

export const selectAllWorkoutExerciseMetricsByWorkoutExerciseId = createSelector(
  [selectAllWorkoutExerciseMetrics, selectWorkoutExerciseId],
  (workoutExerciseMetrics, workoutExerciseId) =>
    workoutExerciseMetrics.filter((em) => em.workoutExerciseId === workoutExerciseId)
);
