import { WorkoutDragItem, WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  exerciseOptionsDragItem: WorkoutDragItem;
  dragItemBelowExerciseOptions?: WorkoutDragItem;
  groupDragItems: WorkoutDragItem[];
};

// Combine one existing group below into an existing group above
const handleCombineTwoGroups = ({
  state,
  exerciseOptionsDragItem,
  dragItemBelowExerciseOptions,
  groupDragItems,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [{ id: exerciseOptionsDragItem.workoutId, changes: {} }];
  if (dragItemBelowExerciseOptions) {
    workoutUpdates.push({ id: dragItemBelowExerciseOptions.workoutId, changes: {} });
  }
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutExercises = {};
  changes.workoutDragItems = {};

  // Merge to the group with the most exercises (bottom group in this case)
  const groupOneHeader = groupDragItems.find(
    (item) => item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER
  );
  const groupTwoHeader = dragItemBelowExerciseOptions;

  if (!groupOneHeader || !groupTwoHeader) {
    console.error('handleCombineTwoGroups: groupOneHeader or groupTwoHeader is undefined');
    return changes;
  }

  // Find all exercises in the group below
  const groupOneExercises = Object.values(state.workoutDragItems.entities)
    .filter(
      (item): item is WorkoutDragItem =>
        !!item &&
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
        item.groupId === groupOneHeader.groupId
    )
    .sort((a, b) => a.dragIndex - b.dragIndex);
  const lastGroupOneExercise = groupOneExercises[groupOneExercises.length - 1];

  // Find all exercises in the group below
  const groupTwoExercises = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem =>
      !!item &&
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.groupId === groupTwoHeader.groupId
  );

  // Remove group two
  const removedGroupId = groupTwoHeader.groupId;
  // Remove exercise options for the removed group
  const removedExerciseOptionsId = `${removedGroupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;
  // Remove group two group header and footer
  const removedGroupHeaderId = removedGroupId;
  const removedGroupFooterId = `${removedGroupId}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`;

  const newDragIndex = groupOneHeader.dragIndex;
  let itemCount = 0;

  // Account for the group header
  // Increment item count
  itemCount++;

  // Update the last exercise in group one
  const updateLastExerciseDragItemId = lastGroupOneExercise.id;
  const groupOneLastExerciseDragItemUpdates: Partial<WorkoutDragItem> = {
    lastInGroup: false,
  };

  // Increment item count
  itemCount += groupOneExercises.length;

  // Update groupTwo drag items
  const groupTwoExerciseDragItemChanges: UpdateType[] = groupTwoExercises.map((item, i) => {
    const changes: Partial<WorkoutDragItem> = {
      groupId: groupOneHeader.groupId,
      groupIndex: groupOneHeader.index,
      index: item.index + groupOneExercises.length,
      dragIndex: newDragIndex + itemCount,
    };
    // Increment item count
    itemCount++;

    return {
      id: item.id,
      changes,
    };
  });
  // Update groupTwo exercises
  const groupTwoExerciseChanges: UpdateType[] = groupTwoExercises.map((item, i) => {
    const changes: Partial<WorkoutExercise> = {
      workoutExerciseGroupId: groupOneHeader.groupId,
      index: item.index + groupOneExercises.length, // + groupOneExercises.length because we are adding to the end of the group
    };
    return {
      id: item.id,
      changes,
    };
  });

  // Update group one footer
  const groupFooterUpdateId = `${groupOneHeader.groupId}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`;
  const groupFooterUpdates: Partial<WorkoutDragItem> = {
    inGroup: true,
    dragIndex: newDragIndex + itemCount,
  };
  // Increment item count
  itemCount++;

  // Update the drag index of group two exercise options
  const updateExerciseOptionsId = `${groupOneHeader.groupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;
  const exerciseOptionsUpdates: Partial<WorkoutDragItem> = {
    inGroup: true,
    dragIndex: newDragIndex + itemCount,
  };

  // Apply the changes
  changes.workoutExerciseGroups.removed = [removedGroupId];
  changes.workoutDragItems.removed = [
    removedGroupHeaderId,
    removedGroupFooterId,
    removedExerciseOptionsId,
  ];
  changes.workoutDragItems.updated = [
    { id: updateLastExerciseDragItemId, changes: groupOneLastExerciseDragItemUpdates },
    ...groupTwoExerciseDragItemChanges,
    { id: groupFooterUpdateId, changes: groupFooterUpdates },
    { id: updateExerciseOptionsId, changes: exerciseOptionsUpdates },
  ];
  changes.workoutExercises.updated = [...groupTwoExerciseChanges];

  // Find all the drag items below the removed group
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      if (!item) {
        return false;
      }

      if (item.workoutId !== groupOneHeader.workoutId) {
        return false;
      }

      // exclude items from group two
      if (item.groupId === groupTwoHeader.groupId) {
        return false;
      }

      if (item.dragIndex > groupTwoHeader.dragIndex) {
        return true;
      }

      return false;
    }
  );

  // Update the drag indexes for all items below the removed group
  // Update all the group indexes for all items below removed group
  const dragItemUpdates: UpdateType[] = [];
  const workoutExerciseGroupUpdates: UpdateType[] = [];

  affectedItems.forEach((item) => {
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};

    dragItemChanges.dragIndex = item.dragIndex - 3; // -1 because we removed 3 items (group header, group footer, exercise options)

    // Update group indexes

    // Drag item changes
    if (item.groupIndex !== undefined) {
      dragItemChanges.groupIndex = item.groupIndex - 1;
    }
    // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    ) {
      dragItemChanges.index = item.index - 1;
    }

    // ExerciseGroup changes
    // If the item is a group header or a solo exercise
    // Update the exercise group index
    if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
      exerciseGroupChanges.index = item.index - 1;
    } else if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      !item.inGroup &&
      item.groupIndex !== undefined
    ) {
      exerciseGroupChanges.index = item.groupIndex - 1;
    }

    // Push updates
    dragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });
    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...dragItemUpdates);
  if (workoutExerciseGroupUpdates.length) {
    changes?.workoutExerciseGroups?.updated?.length
      ? changes.workoutExerciseGroups.updated.push(...workoutExerciseGroupUpdates)
      : (changes.workoutExerciseGroups.updated = workoutExerciseGroupUpdates);
  }

  return changes;
};

export default handleCombineTwoGroups;
