import { memo, useState } from 'react';
// @mui
import { IconButton, MenuItem } from '@mui/material';
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
//

// ----------------------------------------------------------------------

type Props = {
  active: boolean;
  handleToggleWorkoutDescriptionVisibility: VoidFunction;
  handleDuplicateWorkout: VoidFunction;
  handleWorkoutCopyTo: VoidFunction;
  handleRemoveWorkout: VoidFunction;
};

function WorkoutMenu({
  active,
  handleToggleWorkoutDescriptionVisibility,
  handleDuplicateWorkout,
  handleWorkoutCopyTo,
  handleRemoveWorkout,
}: Props) {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(null);
  };

  const toggleWorkoutDescription = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleToggleWorkoutDescriptionVisibility();
    setOpen(null);
  };

  const handleDuplicate = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleDuplicateWorkout();
    setOpen(null);
  };

  const handleCopyTo = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleWorkoutCopyTo();
    setOpen(null);
  };

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    handleRemoveWorkout();
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ m: 0, p: 0.5 }}>
        <Iconify
          icon="eva:more-vertical-fill"
          color={active ? 'text.primary' : 'action.disabledBackground'}
          width={20}
          height={20}
          sx={{ opacity: 0.5, ':hover': { opacity: 1 } }}
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="top-right"
        sx={{
          mt: -1,
          width: 180,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        <MenuItem onClick={toggleWorkoutDescription}>
          <Iconify icon={'material-symbols:notes'} />
          Add Description
        </MenuItem>
        <MenuItem onClick={handleDuplicate}>
          <Iconify icon={'ic:outline-content-copy'} />
          Duplicate
        </MenuItem>
        <MenuItem onClick={handleCopyTo}>
          <Iconify icon={'mdi:file-replace-outline'} />
          Copy To
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}

export default memo(WorkoutMenu);
