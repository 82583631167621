import {
  ProgramWeek,
  Workout,
  WorkoutExerciseGroup,
  WorkoutExercise,
  WorkoutExerciseMetric,
  WorkoutExerciseMetricValue,
} from 'src/@types/program';
import { PROGRAM_DATA_KEYS_ENUM } from 'src/@types/enums';
import {
  ProgramWeek_WithID as ProgramWeek_FB,
  Workout_WithID as Workout_FB,
  WorkoutExerciseGroup as WorkoutExerciseGroup_FB,
  WorkoutExercise as WorkoutExercise_FB,
  WorkoutExerciseMetric as WorkoutExerciseMetric_FB,
  WorkoutExerciseMetricValue as WorkoutExerciseMetricValue_FB,
} from 'src/@types/firebase';
import { AddType, ProgramChangesKeyType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import {
  WORKOUT_EXERCISES,
  WORKOUT_EXERCISE_GROUPS,
  WORKOUT_EXERCISE_METRICS,
  WORKOUT_EXERCISE_METRIC_VALUES,
} from '../../constants/keys';

// Removes non-necessary properties for objects being uploaded to the server
const omitUnnecessaryProperties = ({
  key,
  item,
  state,
}: {
  key: ProgramChangesKeyType;
  item?: AddType;
  state: RootState;
}): (Workout_FB & { programWeekId: string }) | ProgramWeek_FB | undefined => {
  if (!item) {
    return undefined;
  }

  //   if (key === PROGRAM_DATA_KEYS_ENUM.PROGRAM) {
  //     const prevItem = item as unknown as ProgramRedux;
  //     const { id, title, description, dateCreated, lastUpdated, imageUrl } = prevItem;
  //     const newItem: Program = { id, title, description, dateCreated, lastUpdated, imageUrl };
  //     return newItem;
  //   }
  //   else

  if (key === PROGRAM_DATA_KEYS_ENUM.PROGRAM_WEEKS) {
    const prevItem = item as ProgramWeek;
    const { id, programId, index, name } = prevItem;
    const newWeek: ProgramWeek_FB = { id, programId, index, name };
    return newWeek;
  } else if (key === PROGRAM_DATA_KEYS_ENUM.WORKOUTS) {
    const prevItem = item as Workout;
    const {
      id,
      programId,
      programWeekId,
      index,
      name,
      description,
      notes,
      dateCreated,
      lastUpdated,
    } = prevItem;
    const workoutExerciseGroups: WorkoutExerciseGroup_FB[] = Object.values(
      state[WORKOUT_EXERCISE_GROUPS].entities
    )
      .filter(
        (workoutExerciseGroup): workoutExerciseGroup is WorkoutExerciseGroup =>
          !!workoutExerciseGroup && workoutExerciseGroup.workoutId === id
      )
      .map((workoutExerciseGroup) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { workoutId, ...rest } = workoutExerciseGroup;
        return rest;
      });

    const workoutExercises: WorkoutExercise_FB[] = Object.values(state[WORKOUT_EXERCISES].entities)
      .filter(
        (workoutExercise): workoutExercise is WorkoutExercise =>
          !!workoutExercise && workoutExercise.workoutId === id
      )
      .map((workoutExercise) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { coachNotesVisible, workoutId, headerVisible, ...rest } = workoutExercise;
        return rest;
      });
    const exerciseIds = workoutExercises.map((workoutExercise) => workoutExercise.exerciseId);

    const workoutExerciseMetrics: WorkoutExerciseMetric_FB[] = Object.values(
      state[WORKOUT_EXERCISE_METRICS].entities
    )
      .filter(
        (workoutExerciseMetric): workoutExerciseMetric is WorkoutExerciseMetric =>
          !!workoutExerciseMetric && workoutExerciseMetric.workoutId === id
      )
      .map((workoutExerciseMetric) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { workoutId, ...rest } = workoutExerciseMetric;
        // Prevents undefined colors from being uploaded to the server
        const data = { ...rest, color: workoutExerciseMetric.color || '' };
        return data;
      });
    const exerciseMetricIds = workoutExerciseMetrics.map(
      (workoutExerciseMetric) => workoutExerciseMetric.exerciseMetricId
    );

    const workoutExerciseMetricValues: WorkoutExerciseMetricValue_FB[] = Object.values(
      state[WORKOUT_EXERCISE_METRIC_VALUES].entities
    )
      .filter(
        (workoutExerciseMetricValue): workoutExerciseMetricValue is WorkoutExerciseMetricValue =>
          !!workoutExerciseMetricValue && workoutExerciseMetricValue.workoutId === id
      )
      .map((workoutExerciseMetricValue) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { workoutId, ...rest } = workoutExerciseMetricValue;
        return rest;
      });

    const newWorkout: Workout_FB & { programWeekId: string } = {
      id,
      programId,
      programWeekId,
      index,
      name,
      description,
      notes,
      dateCreated,
      lastUpdated,
      workoutExerciseGroups,
      workoutExercises,
      exerciseIds,
      workoutExerciseMetrics,
      exerciseMetricIds,
      workoutExerciseMetricValues,
    };
    return newWorkout;
  }

  //   else if (key === PROGRAM_DATA_KEYS_ENUM.WORKOUT_EXERCISE_GROUPS) {
  //     const prevItem = item as WorkoutExerciseGroup;
  //     const { id, index, name, workoutId } = prevItem;
  //     const newItem: WorkoutExerciseGroupServer = { id, index, name, workoutId };
  //     return newItem;
  //   } else if (key === PROGRAM_DATA_KEYS_ENUM.WORKOUT_EXERCISES) {
  //     const prevItem = item as WorkoutExercise;
  //     const { id, exerciseId, exerciseGroupId, sets, index, setsVisible, coachNotes, header } =
  //       prevItem;
  //     const newItem: WorkoutExerciseServer = {
  //       id,
  //       exerciseId,
  //       exerciseGroupId,
  //       sets,
  //       index,
  //       setsVisible,
  //       coachNotes,
  //       header,
  //     };
  //     return newItem;
  //   } else if (key === PROGRAM_DATA_KEYS_ENUM.WORKOUT_EXERCISE_METRICS) {
  //     const prevItem = item as WorkoutExerciseMetric;
  //     const { id, workoutExerciseId, exerciseMetricId, index } = prevItem;
  //     const newItem: WorkoutExerciseMetricServer = { id, workoutExerciseId, exerciseMetricId, index };
  //     return newItem;
  //   } else if (key === PROGRAM_DATA_KEYS_ENUM.WORKOUT_EXERCISE_METRIC_VALUES) {
  //     const prevItem = item as WorkoutExerciseMetricValue;
  //     const { id, workoutExerciseId, workoutExerciseMetricId, value, set } = prevItem;
  //     const newItem: WorkoutExerciseMetricValueServer = {
  //       id,
  //       workoutExerciseId,
  //       workoutExerciseMetricId,
  //       value,
  //       set,
  //     };
  //     return newItem;
  //   }
};

export default omitUnnecessaryProperties;
