import { Stack, useTheme } from '@mui/material';
import { memo, useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { COPY_ITEM_ENUM } from 'src/@types/enums';
import { WeekTab as WeekTabType } from 'src/@types/program';
import { openCopyToModal } from 'src/redux/slices/program/copyToModal';
import { getProgramCurrentTab, updateCurrentTab } from 'src/redux/slices/program/program';
import { duplicateWeek, removeWeek, updateWeek } from 'src/redux/slices/program/programWeeks';
import { useDispatch, useSelector } from 'src/redux/store';
import { TextField, WeekMenu } from '.';

type Props = WeekTabType;

function WeekTab({ id, index, label }: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const currentTab = useSelector(getProgramCurrentTab);

  const colorDefault = isLight ? 'text.primary' : 'common.white';
  const colorActive = isLight ? 'common.white' : 'common.black';
  const borderColorDefault = isLight ? 'grey.500' : 'grey.300';
  const borderColorActive = isLight ? 'text.primary' : 'common.white';
  const backgroundColorDefault = isLight ? 'rgba(223, 223, 223, 0.2)' : 'rgba(42, 42, 42, 0.2)';
  const backgroundColorActive = isLight ? 'text.primary' : 'common.white';

  const color = id === currentTab ? colorActive : colorDefault;
  const borderColor = id === currentTab ? borderColorActive : borderColorDefault;
  const backgroundColor = id === currentTab ? backgroundColorActive : backgroundColorDefault;

  const weekName = label ? label : 'Week ' + (index + 1);

  const setCurrentTab = useCallback(() => dispatch(updateCurrentTab(id)), [dispatch, id]);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateWeek({ id, updates: { name: e.target.value } }));
    },
    [dispatch, id]
  );

  const handleDuplicateWeek = useCallback(
    () => dispatch(duplicateWeek({ weekId: id })),
    [dispatch, id]
  );

  const handleWeekCopyTo = useCallback(
    () =>
      dispatch(
        openCopyToModal({
          visible: true,
          copyItemId: id,
          copyItemType: COPY_ITEM_ENUM.WEEK,
          copyItemName: weekName,
        })
      ),
    [dispatch, id, weekName]
  );

  const handleRemoveWeek = useCallback(() => dispatch(removeWeek({ weekId: id })), [dispatch, id]);

  return (
    <Draggable key={id} draggableId={'week-' + id} index={index}>
      {(provided, snapshot) => (
        <Stack
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          alignItems="center"
          direction="row"
          sx={{
            pl: 4,
            maxWidth: 200,
            minWidth: 200,
            minHeight: 40,
            border: 1,
            borderColor: borderColor,
            backgroundColor: backgroundColor,
            borderRadius: 1,
            cursor: 'pointer',
          }}
          onClick={setCurrentTab}
        >
          {process.env.NODE_ENV === 'development' && index /* shows index in dev mode */}
          <TextField
            fontColor={color}
            size="small"
            font="subtitle2"
            value={!!label ? label : ''}
            placeholder={!!label ? '' : 'Week ' + (index + 1)}
            onChange={handleOnChange}
            collapsed={true}
            sx={{}}
          />
          <WeekMenu
            color={color}
            handleDuplicateWeek={handleDuplicateWeek}
            handleWeekCopyTo={handleWeekCopyTo}
            handleRemoveWeek={handleRemoveWeek}
          />
        </Stack>
      )}
    </Draggable>
  );
}

export default memo(WeekTab);
