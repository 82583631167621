import { Alert, Box, Button, CircularProgress, Modal, Stack, Typography } from '@mui/material';
import { ReactNode, createContext, useState, useCallback } from 'react';

// ----------------------------------------------------------------------

type PromptState = {
  title: string;
  message: string;
  confirmLoadingMessage?: string;
  confirmText?: string;
  cancelText: string;
  confirm?: () => Promise<void>;
  cancel: () => Promise<void>;
};

export type PromptContextProps = {
  open: boolean;
  prompt: PromptState;
  openPrompt: (prompt: PromptState) => void;
  closePrompt: () => void;
  resetPrompt: () => void;
};

const initialState: PromptContextProps = {
  open: false,
  prompt: {
    title: 'Delete?',
    message: 'Are you sure you want to delete this?',
    confirmLoadingMessage: 'Deleting...',
    confirmText: 'Delete',
    cancelText: 'Cancel',
    confirm: () => Promise.resolve(),
    cancel: () => Promise.resolve(),
  },
  openPrompt: (prompt: PromptState) => {},
  closePrompt: () => {},
  resetPrompt: () => {},
};

const PromptContext = createContext(initialState);

type PromptProviderProps = {
  children: ReactNode;
};

function PromptProvider({ children }: PromptProviderProps) {
  const [promptOpen, setPromptOpen] = useState(initialState.open);
  const [prompt, setPrompt] = useState(initialState.prompt);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const { title, message, confirmLoadingMessage, confirmText, cancelText, confirm, cancel } =
    prompt;

  const handlePromptClose = useCallback(() => {
    setPromptOpen(false);
    setPrompt(initialState.prompt);
  }, []);

  const handlePromptOpen = useCallback((item: PromptState) => {
    setPromptOpen(true);
    setPrompt(item);
  }, []);

  const handleConfirm = useCallback(async () => {
    if (!confirm) {
      return;
    }

    try {
      setLoading(true);
      await confirm();
      setLoading(false);
      handlePromptClose();
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, [confirm, handlePromptClose]);

  const handleCancel = useCallback(async () => {
    try {
      setLoading(true);
      await cancel();
      setLoading(false);
      handlePromptClose();
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, [cancel, handlePromptClose]);

  const resetPrompt = useCallback(() => {
    setPromptOpen(initialState.open);
    setPrompt(initialState.prompt);
  }, []);

  return (
    <PromptContext.Provider
      value={{
        open: promptOpen,
        prompt: prompt,
        openPrompt: handlePromptOpen,
        closePrompt: handlePromptClose,
        resetPrompt,
      }}
    >
      {children}
      <Modal
        open={promptOpen}
        onClose={handlePromptClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {error && <Alert severity="error">{error?.message}</Alert>}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          {loading ? (
            <Stack sx={{ py: 0.5, px: 1 }} spacing={2} direction="row">
              <CircularProgress size={18} />
              <Typography sx={{ fontSize: 14 }}>{confirmLoadingMessage}</Typography>
            </Stack>
          ) : (
            <>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {message}
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button onClick={handleCancel}>{cancelText}</Button>
                {confirmText && (
                  <Button onClick={handleConfirm} color="error">
                    {confirmText}
                  </Button>
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </PromptContext.Provider>
  );
}

export { PromptProvider, PromptContext };
