import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { APP_NAMES_ENUM, USER_TYPE_ENUM } from 'src/@types/enums';
import { User_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';

const makeUserACoach = async (user: User_WithID) => {
  const userRef = doc(DB, 'users', user.id);

  const SITE =
    process.env.REACT_APP_PUBLIC_SITE === APP_NAMES_ENUM.FITPROS
      ? APP_NAMES_ENUM.FITPROS
      : APP_NAMES_ENUM.ONI;

  await updateDoc(userRef, {
    type: arrayUnion(USER_TYPE_ENUM.COACH),
    appName: SITE,
  });
};

export default makeUserACoach;
