import {
  createSlice,
  createEntityAdapter,
  createSelector,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';
import { WorkoutDragItem as WorkoutDragItemType } from 'src/@types/program';
import { Changes } from 'src/@types/program_redux';
import { applyChanges } from 'src/redux/functions/applyChanges';
import { AppDispatch, RootState } from 'src/redux/store';
import { createWorkoutDragData } from 'src/utils/createWorkoutDragData';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';
import {
  duplicateWorkoutExerciseGroupAction,
  removeExerciseGroupAction,
} from './workoutExerciseGroups';
import {
  handleMoveExerciseToExistingGroup,
  handleMoveExerciseToNewGroup,
  handleMoveExerciseWithinGroup,
  handleMoveExerciseWithinWorkout,
  handleMoveExerciseToExistingGroupNewWorkout,
  handleMoveExerciseToNewGroupNewWorkout,
  handleMoveExerciseToNewWorkout,
  handleMoveGroupedExerciseToSolo,
  handleMoveGroupedExerciseToExistingGroup,
  handleMoveGroupedExerciseToNewGroup,
  handleMoveGroupedExerciseToSoloNewWorkout,
  handleMoveGroupedExerciseToExistingGroupNewWorkout,
  handleMoveGroupedExerciseToNewGroupNewWorkout,
} from './functions/exerciseReorder';
import {
  handleCombineOneAboveTwoOrMoreBelow,
  handleCombineTwoGroups,
  handleCombineTwoOrMoreAboveOneBelow,
  handleCombineTwoSoloGroups,
  handleSplitOneAboveOneBelow,
  handleSplitOneAboveTwoOrMoreBelow,
  handleSplitTwoOrMoreAboveOneBelow,
  handleSplitTwoOrMoreAboveTwoOrMoreBelow,
} from './functions/grouping';
import {
  handleMoveGroupToExistingGroup,
  handleMoveGroupToExistingGroupNewWorkout,
  handleMoveGroupToNewGroup,
  handleMoveGroupToNewGroupNewWorkout,
  handleMoveGroupToNewWorkout,
  handleMoveGroupWithinWorkout,
} from './functions/groupReorder';
import { programResetAction } from './program';
import { duplicateWeekAction, fetchProgramWeeks, removeWeekAction } from './programWeeks';
import {
  addExercisesAction,
  duplicateWorkoutExerciseAction,
  removeExerciseAction,
} from './workoutExercises';
import { duplicateWorkoutAction, removeWorkoutAction } from './workouts';
import { WORKOUT_DRAG_ITEMS, WORKOUT_EXERCISES, WORKOUT_EXERCISE_GROUPS } from './constants/keys';
import { applyNewWorkoutChanges } from './functions/util';
import { copyToCurrentProgram } from './copyToModal';
import { healthCheck } from 'src/redux/functions/healthCheck';

const workoutDragDataAdapter = createEntityAdapter<WorkoutDragItemType>({
  // Sort by index
  sortComparer: (a: WorkoutDragItemType, b: WorkoutDragItemType) => a.dragIndex - b.dragIndex,
});

const initialState = workoutDragDataAdapter.getInitialState();

export const reorderExercisesAction = createAction<Changes>('workoutDragItems/reorderExercises');
export const groupingAction = createAction<Changes>('workoutDragItems/grouping');

export const slice = createSlice({
  name: WORKOUT_DRAG_ITEMS,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    const handleChangesReducer = (state: any, action: PayloadAction<Changes>) => {
      const items = action.payload.workoutDragItems;
      if (items) {
        applyChanges(items, workoutDragDataAdapter, state);
      }

      if (process.env.NODE_ENV === 'development') {
        healthCheck(workoutDragDataAdapter, state, WORKOUT_DRAG_ITEMS);
      }
    };

    builder
      // Internal
      .addCase(reorderExercisesAction, handleChangesReducer)
      .addCase(groupingAction, handleChangesReducer)
      // External
      .addCase(addExercisesAction, handleChangesReducer)
      .addCase(removeExerciseAction, handleChangesReducer)
      .addCase(removeExerciseGroupAction, handleChangesReducer)
      .addCase(removeWorkoutAction, handleChangesReducer)
      .addCase(removeWeekAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseGroupAction, handleChangesReducer)
      .addCase(duplicateWorkoutAction, handleChangesReducer)
      .addCase(duplicateWeekAction, handleChangesReducer)
      .addCase(copyToCurrentProgram, handleChangesReducer)

      .addCase(programResetAction, () => initialState)
      .addCase(fetchProgramWeeks.fulfilled, (state, action) => {
        const exerciseGroups = action.payload[WORKOUT_EXERCISE_GROUPS];
        const exercises = action.payload[WORKOUT_EXERCISES];

        if (exerciseGroups.length) {
          const dragData = createWorkoutDragData(exerciseGroups, exercises);
          // Add any fetched exercise logs to the array
          // Use the `upsertMany` reducer as a mutating update utility
          workoutDragDataAdapter.upsertMany(state, dragData);
          // workoutDragDataAdapter.setAll(state, action.payload.workoutExercises);
        }
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

// When an exercise or exercise group is dragged an reordered
export const reorderExercises =
  ({
    id,
    workoutId,
    from,
    to,
    combineId,
    droppedWithinSameWorkout,
  }: {
    id: string;
    workoutId: string;
    from: number;
    to?: number;
    combineId?: string;
    droppedWithinSameWorkout: boolean;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const movedItem = Object.values(state.workoutDragItems.entities).find(
      (item): item is WorkoutDragItemType => !!item && item.id === id
    );
    let destinationItem = Object.values(state.workoutDragItems.entities).find(
      (item): item is WorkoutDragItemType => {
        if (!item) {
          return false;
        }

        if (item.workoutId !== workoutId) {
          return false;
        }

        if (combineId !== undefined) {
          return item.id === combineId;
        }

        if (to !== undefined) {
          return item.dragIndex === to;
        }

        return false;
      }
    );

    if (!movedItem) {
      console.error(`Workout drag item with id ${id} not found`);
      return;
    }

    if (!destinationItem) {
      // Dropped into an empty workout
      if (!droppedWithinSameWorkout && to === 0) {
        // Create a dummy destination item
        destinationItem = {
          key: movedItem.key,
          id: movedItem.id,
          index: 0,
          dragIndex: 0,
          type: WORKOUT_DRAG_DATA_ENUM.EXERCISE,
          groupId: '',
          workoutId,
          inGroup: false,
          groupIndex: 0,
          lastInGroup: true,
        };
      } else {
        console.error(`Destination workout drag item not found`);
        return;
      }
    }

    console.log('Destination item:', destinationItem);

    if (combineId && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS) {
      // Combined into an exercise options
      console.error(`Cannot combine into an exercise options item`);
      return;
    }

    // Check if the item was moved to the exact same spot
    if (
      movedItem.dragIndex === destinationItem.dragIndex &&
      movedItem.groupId === destinationItem.groupId
    ) {
      if (process.env.NODE_ENV === 'development') {
        console.log('Same destination');
      }
      return;
    }

    // If from is greater than to then we moved the item down
    const movedDown = movedItem.dragIndex > destinationItem.dragIndex;
    const movedUp = movedItem.dragIndex < destinationItem.dragIndex;

    // Find out what type of drag item was moved
    const itemType = movedItem.type;

    // Moved in the same group
    const movedWithinSameGroup =
      movedItem.groupId === destinationItem.groupId &&
      ((!!destinationItem?.inGroup && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE) ||
        (movedUp && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) ||
        (!!combineId && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) ||
        (movedDown && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER) ||
        (!!combineId && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER));
    // Moved to existing group
    const movedToExistingGroup =
      !movedWithinSameGroup &&
      ((!!destinationItem?.inGroup && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE) ||
        (droppedWithinSameWorkout &&
          ((movedUp && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) ||
            (!!combineId && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) ||
            (movedDown && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER) ||
            (!!combineId && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER))) ||
        (!droppedWithinSameWorkout &&
          ((!!combineId && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) ||
            destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER)));

    // Moved to new group
    const movedToNewGroup = !movedWithinSameGroup && !movedToExistingGroup && !!combineId;
    // Moved to solo exercise
    const movedToSolo = !movedToNewGroup;

    let changes: Changes = {};
    const moveData = {
      state,
      movedItem,
      destinationItem,
      movedDown,
      movedUp,
    };

    if (itemType === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
      const groupSameWorkout = droppedWithinSameWorkout;
      const groupNewWorkout = !droppedWithinSameWorkout;
      const groupExistingGroupNewWorkout = movedToExistingGroup && !droppedWithinSameWorkout;
      const groupNewGroupNewWorkout = movedToNewGroup && !droppedWithinSameWorkout;

      // Prevent moving a group header to the same group
      if (movedWithinSameGroup) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Group moved within itself');
        }
        return;
      }

      // -- New Workout
      // If group was moved to a new group (combined) in a new workout
      if (groupNewGroupNewWorkout) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Group Moved To New Group, New Workout');
        }
        changes = handleMoveGroupToNewGroupNewWorkout(moveData);
      }
      // If group was moved to an existing group in a new workout
      else if (groupExistingGroupNewWorkout) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Group Moved To Existing Group, New Workout');
        }
        changes = handleMoveGroupToExistingGroupNewWorkout(moveData);
      }
      // If group was moved to a new workout
      else if (groupNewWorkout) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Group Moved, New Workout');
        }
        changes = handleMoveGroupToNewWorkout(moveData);
      }

      // -- Same Workout
      // If group was moved to a new group (combined) in the same workout
      else if (movedToNewGroup) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Group Moved To New Group');
        }
        changes = handleMoveGroupToNewGroup(moveData);
      }
      // If group was moved to an existing group in the same workout
      else if (movedToExistingGroup) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Group Moved To Existing Group');
        }
        changes = handleMoveGroupToExistingGroup(moveData);
      }
      // If group was moved within it's current workout
      else if (groupSameWorkout) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Group Moved, Same Workout');
        }
        changes = handleMoveGroupWithinWorkout(moveData);
      }
    }

    if (itemType === WORKOUT_DRAG_DATA_ENUM.EXERCISE) {
      const exerciseExistingGroupSameWorkout = movedToExistingGroup && droppedWithinSameWorkout;
      const exerciseNewGroupSameWorkout = movedToNewGroup && droppedWithinSameWorkout;
      const exerciseSameWorkout = droppedWithinSameWorkout;
      const exerciseExistingGroupNewWorkout = movedToExistingGroup && !droppedWithinSameWorkout;
      const exerciseNewGroupNewWorkout = movedToNewGroup && !droppedWithinSameWorkout;
      const exerciseNewWorkout = !droppedWithinSameWorkout;

      // GROUPED EXERCISE
      if (movedItem.inGroup) {
        const groupedExerciseToSoloNewWorkout = !droppedWithinSameWorkout && movedToSolo;
        const groupedExerciseToSoloSameWorkout = droppedWithinSameWorkout && movedToSolo;
        const sameWorkoutSameGroup = droppedWithinSameWorkout && movedWithinSameGroup;

        // -- New Workout
        // If grouped exercise was moved to a new group (combined) in a new workout
        if (exerciseNewGroupNewWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Grouped Exercise To New Group, New Workout');
          }
          changes = handleMoveGroupedExerciseToNewGroupNewWorkout(moveData);
        }
        // If grouped exercise was moved to an existing group in a new workout
        else if (exerciseExistingGroupNewWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Grouped Exercise to Existing Group, New Workout');
          }
          changes = handleMoveGroupedExerciseToExistingGroupNewWorkout(moveData);
        }
        // If grouped exercise was moved to become a solo exercise in a new workout
        else if (groupedExerciseToSoloNewWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Grouped Exercise To Solo, New Workout');
          }
          changes = handleMoveGroupedExerciseToSoloNewWorkout(moveData);
        }

        // -- Same Workout
        // If grouped exercise was moved to a new group (combined) in the same workout
        else if (exerciseNewGroupSameWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Grouped Exercise To New Group, Same Workout');
          }
          changes = handleMoveGroupedExerciseToNewGroup(moveData);
        }
        // If grouped exercise was moved to an existing group in the same workout
        else if (exerciseExistingGroupSameWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Grouped Exercise to Existing Group, Same Workout');
          }
          changes = handleMoveGroupedExerciseToExistingGroup(moveData);
        }
        // If exercise was moved within it's current group
        else if (sameWorkoutSameGroup) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Grouped Exercise Within Same Group, Same Workout');
          }
          changes = handleMoveExerciseWithinGroup(moveData);
        }
        // If grouped exercise was moved to become a solo exercise in the same workout
        else if (groupedExerciseToSoloSameWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Grouped Exercise To Solo, Same Workout');
          }
          changes = handleMoveGroupedExerciseToSolo(moveData);
        }
      }

      // SOLO EXERCISE
      else {
        // -- New Workout
        // If solo exercise was moved to a new group (combined) in a new workout
        if (exerciseNewGroupNewWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('New Group, New Workout');
          }
          changes = handleMoveExerciseToNewGroupNewWorkout(moveData);
        }
        // If solo exercise was moved to an existing group in a new workout
        else if (exerciseExistingGroupNewWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Existing Group, New Workout');
          }
          changes = handleMoveExerciseToExistingGroupNewWorkout(moveData);
        }
        // If solo exercise was moved to a new workout
        else if (exerciseNewWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('New Workout');
          }
          changes = handleMoveExerciseToNewWorkout(moveData);
        }

        // -- Same Workout
        // If solo exercise was moved to a new group (combined)
        else if (exerciseNewGroupSameWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('New Group, Same Workout');
          }
          changes = handleMoveExerciseToNewGroup(moveData);
        }
        // If solo exercise was moved to an existing group
        else if (exerciseExistingGroupSameWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Existing Group, Same Workout');
          }
          changes = handleMoveExerciseToExistingGroup(moveData);
        }
        // If solo exercise was moved within it's current workout
        else if (exerciseSameWorkout) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Same Workout');
          }
          changes = handleMoveExerciseWithinWorkout(moveData);
        }
      }
    }

    if (!droppedWithinSameWorkout) {
      if (process.env.NODE_ENV === 'development') {
        console.log('Dropped in new workout, applying new workout changes');
      }
      // Update workoutId for any items belonging to the moved item group
      // This is used to make sure exercise metrics and values are updated correctly
      applyNewWorkoutChanges({
        state,
        changes,
        // workoutExerciseGroupId: movedItem.groupId,
        newWorkoutId: destinationItem.workoutId,
      });
    }

    console.log('Changes', { ...changes });

    dispatch(reorderExercisesAction(changes));
  };

// When an exercise or exercise group is grouped together or ungrouped
export const handleGrouping =
  ({ dragItemId, combineGroups }: { dragItemId: string; combineGroups: boolean }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    // This will either be of type EXERCISE_OPTIONS or EXERCISE
    // If workout level type === EXERCISE_OPTIONS
    // If group level type === EXERCISE
    const exerciseOptionsDragItem = Object.values(state.workoutDragItems.entities).find(
      (item) => !!item && item.id === dragItemId
    );

    if (!exerciseOptionsDragItem) {
      if (process.env.NODE_ENV === 'development') {
        console.error(`Workout drag item with id ${dragItemId} not found`);
      }
      return;
    }

    const groupDragItems = Object.values(state.workoutDragItems.entities).filter(
      (item): item is WorkoutDragItemType =>
        !!item && item.groupId === exerciseOptionsDragItem.groupId
    );

    if (!groupDragItems.length) {
      if (process.env.NODE_ENV === 'development') {
        console.error(`No drag items found belonging to group ${exerciseOptionsDragItem.groupId}`);
      }
      return;
    }

    const groupExercises = groupDragItems.filter(
      (item) => item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
    );
    const dragItemBelowExerciseOptions = Object.values(state.workoutDragItems.entities).find(
      (item) =>
        !!item &&
        item.workoutId === exerciseOptionsDragItem.workoutId &&
        item.dragIndex === exerciseOptionsDragItem.dragIndex + 1
    );

    let changes: Changes = {};
    const groupingData = {
      state,
      exerciseOptionsDragItem,
      dragItemBelowExerciseOptions,
      groupDragItems,
    };

    // -- Grouping
    if (combineGroups) {
      const twoSoloGroups =
        groupExercises.length === 1 &&
        dragItemBelowExerciseOptions?.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE;
      const oneAboveTwoOrMoreBelow =
        groupExercises.length === 1 &&
        dragItemBelowExerciseOptions?.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER;
      const twoOrMoreAboveOneBelow =
        groupExercises.length > 1 &&
        dragItemBelowExerciseOptions?.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE;
      const twoOrMoreAboveTwoOrMoreBelow =
        groupExercises.length > 1 &&
        dragItemBelowExerciseOptions?.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER;

      // Combine two solo groups together
      if (twoSoloGroups) {
        // Create new group header and footer
        if (process.env.NODE_ENV === 'development') {
          console.log('Combine Two Solo Groups');
        }
        changes = handleCombineTwoSoloGroups(groupingData);
      }
      // Combine one solo group above into an existing group below
      else if (oneAboveTwoOrMoreBelow) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Combine One Above Two Or More Below');
        }
        changes = handleCombineOneAboveTwoOrMoreBelow(groupingData);
      }
      // Combine a solo group below into an existing group above
      else if (twoOrMoreAboveOneBelow) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Combine Two Or More Above One Below');
        }
        changes = handleCombineTwoOrMoreAboveOneBelow(groupingData);
      }
      // Combine two groups together
      else if (twoOrMoreAboveTwoOrMoreBelow) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Combine Two Groups');
        }
        changes = handleCombineTwoGroups(groupingData);
      }
    }

    // -- Ungrouping
    else {
      // Ungroup exercises in an existing group
      const exerciseOptionsInGroup =
        exerciseOptionsDragItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE;
      if (exerciseOptionsInGroup) {
        const exercisesAboveExerciseOptions = groupExercises.filter(
          (exercise) => exercise.dragIndex <= exerciseOptionsDragItem.dragIndex
        );
        const exercisesBelowExerciseOptions = groupExercises.filter(
          (exercise) => exercise.dragIndex > exerciseOptionsDragItem.dragIndex
        );
        const oneAboveOneBelow =
          exercisesAboveExerciseOptions.length === 1 && exercisesBelowExerciseOptions.length === 1;
        const oneAboveTwoOrMoreBelow =
          exercisesAboveExerciseOptions.length === 1 && exercisesBelowExerciseOptions.length >= 2;
        const twoOrMoreAboveOneBelow =
          exercisesAboveExerciseOptions.length >= 2 && exercisesBelowExerciseOptions.length === 1;
        const twoOrMoreAboveTwoOrMoreBelow =
          exercisesAboveExerciseOptions.length >= 2 && exercisesBelowExerciseOptions.length >= 2;

        // If 1 item above and 1 item below exercise options
        if (oneAboveOneBelow) {
          if (process.env.NODE_ENV === 'development') {
            console.log('One Above and One Below Ungrouped');
          }
          // Create two solo exercises
          changes = handleSplitOneAboveOneBelow(groupingData);
        }

        // If 1 item above and 2 or more items below exercise options
        else if (oneAboveTwoOrMoreBelow) {
          if (process.env.NODE_ENV === 'development') {
            console.log('One Above and Two or More Below Ungrouped');
          }
          // Create one solo exercise and one group
          changes = handleSplitOneAboveTwoOrMoreBelow(groupingData);
        }
        // If 2 or more items above and 1 item below exercise options
        else if (twoOrMoreAboveOneBelow) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Two or More Above and One Below Ungrouped');
          }
          // Create one solo exercise and one group
          changes = handleSplitTwoOrMoreAboveOneBelow(groupingData);
        }

        // If 2 or more items above and 2 or more items below exercise options
        else if (twoOrMoreAboveTwoOrMoreBelow) {
          if (process.env.NODE_ENV === 'development') {
            console.log('Two or More Above and Two or More Below Ungrouped');
          }
          // Create two groups
          changes = handleSplitTwoOrMoreAboveTwoOrMoreBelow(groupingData);
        }
      }
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Changes', { ...changes });
    }
    dispatch(groupingAction(changes));
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllWorkoutDragItems, selectById: selectWorkoutDragItemById } =
  workoutDragDataAdapter.getSelectors((state: RootState) => state[WORKOUT_DRAG_ITEMS]);

const selectWorkoutId = (_: RootState, workoutId: string) => workoutId;
const selectGroupButtonTitleData = (
  _: RootState,
  groupButtonTitleData: { workoutId: string; groupIndex: number | undefined }
) => groupButtonTitleData;

export const selectAllWorkoutDragItemsByWorkoutId = createSelector(
  [selectAllWorkoutDragItems, selectWorkoutId],
  (workoutDragItems, workoutId) => workoutDragItems.filter((d) => d.workoutId === workoutId)
);

export const getGroupButtonTitle = createSelector(
  [selectAllWorkoutDragItems, selectGroupButtonTitleData],
  (workoutDragItems, groupButtonTitleData) => {
    const { workoutId, groupIndex } = groupButtonTitleData;

    if (groupIndex === undefined) {
      return 'Make Superset';
    }

    const group = workoutDragItems.find(
      (d) => d.groupIndex === groupIndex && d.workoutId === workoutId
    );
    const groupBelow = workoutDragItems.find(
      (d) => d.groupIndex === groupIndex + 1 && d.workoutId === workoutId
    );

    const groupLength = group
      ? workoutDragItems.filter(
          (d) => d.groupId === group.id && d.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
        ).length
      : 0;
    const groupBelowLength = groupBelow
      ? workoutDragItems.filter(
          (d) => d.groupId === groupBelow.id && d.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
        ).length
      : 0;

    // If group above or below has more the 1 item, then title is "Make Circuit"
    if (groupLength > 1 || groupBelowLength > 1) {
      return 'Make Circuit';
    }

    return 'Make Superset';
  }
);
