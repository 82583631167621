// @mui
import { Stack, Button, Typography, Link } from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function NavbarReport() {
  const link = PATH_DASHBOARD['report-an-issue']?.root || '/';
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      <div>
        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
          Found an issue?
          <br /> Please report it here.
        </Typography>
      </div>

      <Link target="_blank" href={link}>
        <Button variant="contained">Report Issue</Button>
      </Link>
    </Stack>
  );
}
