import { Stack } from '@mui/material';
import { memo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { WorkoutExerciseMetric as WorkoutExerciseMetricType } from 'src/@types/program';
import { WorkoutExerciseMetric } from '.';

type Props = {
  workoutExerciseMetrics: WorkoutExerciseMetricType[];
  workoutExerciseId: string;
  collapsed: boolean;
};

function WorkoutExerciseMetrics({ workoutExerciseMetrics, workoutExerciseId, collapsed }: Props) {
  return (
    <Droppable type="METRIC" droppableId={workoutExerciseId}>
      {(provided, snapshot) => (
        <Stack
          spacing={0}
          ref={provided.innerRef}
          {...provided.droppableProps}
          direction={collapsed ? 'row' : 'column'}
        >
          {workoutExerciseMetrics.map((workoutExerciseMetric) => (
            <WorkoutExerciseMetric
              key={workoutExerciseMetric.id}
              workoutExerciseId={workoutExerciseId}
              workoutExerciseMetric={workoutExerciseMetric}
              collapsed={collapsed}
            />
          ))}
          {provided.placeholder}
        </Stack>
      )}
    </Droppable>
  );
}

export default memo(WorkoutExerciseMetrics);
