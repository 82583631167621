import { ProgramWeek, Workout } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import uuidv4 from 'src/utils/uuidv4';
import { handleDuplicateWorkout } from '.';

type Props = {
  state: RootState;
  changes: Changes;
  weekId: string;
  // If new program
  newProgramId?: string;
  // If copy to
  copyToProgramId?: string;
};

const handleDuplicateWeek = ({ state, changes, weekId, newProgramId, copyToProgramId }: Props) => {
  const item = state.programWeeks.entities[weekId];

  if (!item) {
    console.error(`No week with id ${weekId} found`);
    return;
  }

  // If changes for this item don't exist, create them
  if (changes.programWeeks === undefined) {
    changes.programWeeks = {};
  }

  let programId = newProgramId ? newProgramId : item.programId;
  if (copyToProgramId !== undefined) programId = copyToProgramId;

  const duplicatedName = item.name ? `${item.name} (copy)` : `Week ${item.index + 1} (copy)`;
  const duplicatedItem: ProgramWeek = {
    ...item,
    id: uuidv4(),
    programId,
    name: newProgramId === undefined ? duplicatedName : item.name,
  };

  // Check if there any affected items
  const weekUpdates: UpdateType[] = [];

  // Only this workout was duplicated
  if (copyToProgramId !== undefined) {
    // We need to update the index and drag index of the duplicated exercise
    duplicatedItem.index = 0;

    // Find affected items
    const affectedItems = Object.values(state.programWeeks.entities).filter(
      (affectedItem): affectedItem is ProgramWeek =>
        !!affectedItem &&
        affectedItem.programId === copyToProgramId &&
        affectedItem.index >= duplicatedItem.index
    );

    affectedItems.forEach((affectedItem) => {
      const weekChanges: Partial<ProgramWeek> = {};
      // Update the index of the affected item
      weekChanges.index = affectedItem.index + 1;

      // Push the changes
      weekUpdates.push({ id: affectedItem.id, changes: weekChanges });
    });
  }
  // Only this workout was duplicated
  else if (newProgramId === undefined) {
    // We need to update the index and drag index of the duplicated exercise
    duplicatedItem.index = item.index + 1;

    // Find affected items
    const affectedItems = Object.values(state.programWeeks.entities).filter(
      (affectedItem): affectedItem is ProgramWeek =>
        !!affectedItem &&
        affectedItem.programId === item.programId &&
        affectedItem.index > item.index
    );

    affectedItems.forEach((affectedItem) => {
      const weekChanges: Partial<ProgramWeek> = {};
      // Update the index of the affected item
      weekChanges.index = affectedItem.index + 1;

      // Push the changes
      weekUpdates.push({ id: affectedItem.id, changes: weekChanges });
    });
  }

  // Push the changes
  // Workout
  changes.programWeeks?.added?.length
    ? changes.programWeeks.added.push(duplicatedItem)
    : (changes.programWeeks.added = [duplicatedItem]);

  // If affected items exist, add them to the changes
  if (weekUpdates.length) {
    changes.programWeeks?.updated?.length
      ? changes.programWeeks.updated.push(...weekUpdates)
      : (changes.programWeeks.updated = weekUpdates);
  }

  // Duplicate all items associated with this item
  const associatedItems = Object.values(state.workouts.entities).filter(
    (associatedItem): associatedItem is Workout =>
      !!associatedItem && associatedItem.programWeekId === weekId
  );

  associatedItems.forEach((associatedItem) =>
    handleDuplicateWorkout({
      state,
      changes,
      workoutId: associatedItem.id,
      newWeekId: duplicatedItem.id,
      newProgramId: newProgramId,
    })
  );
};

export default handleDuplicateWeek;
