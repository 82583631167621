// @mui
import { InputAdornment, TextField } from '@mui/material';
// hooks
// redux
import {
  endSearch,
  getProgramsFetchStatus,
  getProgramsFilters,
  startSearch,
} from 'src/redux/slices/programs';
// components
import Iconify from 'src/components/Iconify';
import { useDispatch, useSelector } from 'src/redux/store';
import { useCallback, useEffect, useState } from 'react';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import useDebouncedCallback from 'src/utils/useDebouncedCallback';

// ----------------------------------------------------------------------

export default function ProgramsSearch() {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const filters = useSelector(getProgramsFilters);
  const status = useSelector(getProgramsFetchStatus);
  const { searchPhrase } = filters;

  const debounced = useDebouncedCallback((value) => {
    dispatch(endSearch(value));
  }, 500);

  // Update the search phrase if changed elsewhere
  useEffect(() => {
    if (searchPhrase !== value && searchPhrase !== undefined) {
      setValue(searchPhrase);
    }
    // Ignoring because we only want to check when searchPhrase changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);

      if (status !== FETCH_STATUS_TYPES_ENUM.SEARCHING) {
        dispatch(startSearch());
      }
      debounced(event.target.value);
    },
    [status, dispatch, debounced]
  );

  return (
    <TextField
      placeholder="Search programs"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify
              icon={'eva:search-fill'}
              sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
            />
          </InputAdornment>
        ),
      }}
      onChange={handleOnChange}
      value={value}
      variant="outlined"
    />
  );
}
