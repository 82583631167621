import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { initializeApp, getApps, getApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  connectAuthEmulator,
} from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator, doc, setDoc, updateDoc } from 'firebase/firestore';
// @types
import { ActionMap, AuthState, FirebaseContextType, NewUser } from 'src/@types/auth';
//redux
import { useDispatch } from 'src/redux/store';
//
import { FIREBASE_API } from '../config';
import { logoutAction, startUserListener } from 'src/redux/slices/user';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { CoachClient_WithID } from 'src/@types/firebase';
import { APP_NAMES_ENUM, CLIENT_STATUS_ENUM, USER_TYPE_ENUM } from 'src/@types/enums';
import addSelfAsClient from 'src/utils/addSelfAsClient';

// ----------------------------------------------------------------------

// const ADMIN_EMAILS = ['demo@minimals.cc'];

let firebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(FIREBASE_API);
} else {
  firebaseApp = getApp();
}

const DB = getFirestore(firebaseApp);
const STORAGE = getStorage();
const FUNCTIONS = getFunctions(firebaseApp);
const AUTH = getAuth(firebaseApp);
const ANALYTICS = getAnalytics(firebaseApp);

// If user id is set
if (AUTH.currentUser?.uid) {
  setUserId(ANALYTICS, AUTH.currentUser.uid);
}

if (process.env.NODE_ENV === 'development') {
  console.log('[Firebase] Connecting to Firestore emulator...');
  // firebase emulators:start --import=./firebase_tmp --export-on-exit --only firestore
  connectAuthEmulator(AUTH, 'http://localhost:9099');
  connectStorageEmulator(STORAGE, 'localhost', 9199);
  connectFirestoreEmulator(DB, 'localhost', 8080);
  connectFunctionsEmulator(FUNCTIONS, 'localhost', 5003);
}

const initialState: AuthState = {
  isInitialized: false,
};

enum Types {
  Initial = 'INITIALISE',
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    userId?: string;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { userId } = action.payload;
    return {
      ...state,
      isInitialized: true,
      userId,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchRedux = useDispatch();

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userId = user.uid;
          // Load user data from db
          await dispatchRedux(startUserListener(userId));
          dispatch({
            type: Types.Initial,
            payload: { userId },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {},
          });
        }
      }),
    [dispatch, dispatchRedux]
  );

  const login = async (email: string, password: string) => {
    // Analytics event
    logEvent(ANALYTICS, 'login', {
      method: 'email',
    });
    return signInWithEmailAndPassword(AUTH, email, password);
  };
  const register = (newUser: NewUser, coachClientId?: string) =>
    createUserWithEmailAndPassword(AUTH, newUser.email, newUser.password).then(async (res) => {
      // Update user profile
      if (res.user) {
        await updateProfile(res.user, {
          displayName: `${newUser.firstName} ${newUser.lastName}`,
        });
      }

      // Analytics event
      logEvent(ANALYTICS, 'sign_up', {
        method: 'email',
      });
      // Create user in db
      const id = res.user.uid;
      if (id) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { password, ...user } = newUser;

        // Used for first time setup with coaches
        user.needsStripeSetup = true;

        //   const SITE = process.env.REACT_APP_PUBLIC_SITE;
        const SITE = process.env.REACT_APP_PUBLIC_SITE as APP_NAMES_ENUM;

        if (SITE !== undefined) {
          user.appName = SITE;
        }

        await setDoc(doc(DB, 'users', id), user);
      }

      // Update the coach client details for registering clients
      if (coachClientId) {
        const coachClientRef = doc(DB, 'coachClients', coachClientId);
        const updates: Partial<CoachClient_WithID> = {
          status: CLIENT_STATUS_ENUM.ACTIVE,
          client: {
            id: id,
            dateCreated: newUser.dateCreated,
            email: newUser.email,
            phone: newUser?.phone ? newUser.phone : '',
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            profilePictureUrl: newUser.profilePictureUrl,
          },
        };
        await updateDoc(coachClientRef, updates);
      }

      // If the user is a coach, add the coach as a client to themselves
      if (newUser.type?.includes(USER_TYPE_ENUM.COACH)) {
        await addSelfAsClient({
          ...newUser,
          id,
        });
      }
    });

  const logout = () => {
    dispatchRedux(logoutAction());
    return signOut(AUTH);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { DB, STORAGE, FUNCTIONS, ANALYTICS, AuthContext, AuthProvider };
