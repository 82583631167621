import { WorkoutDragItem, WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import {
  createWorkoutExerciseGroup,
  createWorkoutExerciseOptions,
} from 'src/utils/createDragItems';
import uuidv4 from 'src/utils/uuidv4';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  exerciseOptionsDragItem: WorkoutDragItem;
  dragItemBelowExerciseOptions?: WorkoutDragItem;
  groupDragItems: WorkoutDragItem[];
};

// If 1 item above and 2 or more items below exercise options
const handleSplitOneAboveTwoOrMoreBelow = ({
  state,
  exerciseOptionsDragItem,
  dragItemBelowExerciseOptions,
  groupDragItems,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [{ id: exerciseOptionsDragItem.workoutId, changes: {} }];
  if (dragItemBelowExerciseOptions) {
    workoutUpdates.push({ id: dragItemBelowExerciseOptions.workoutId, changes: {} });
  }
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutExercises = {};
  changes.workoutDragItems = {};

  // Merge to the group with the lowest index
  const groupOneHeader = groupDragItems.find(
    (item) => item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER
  );
  const groupOneFirstExercise = groupDragItems.find(
    (item) => item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE && item.index === 0
  );
  const groupTwoExercise = dragItemBelowExerciseOptions;

  if (!groupOneHeader || !groupOneFirstExercise || !groupTwoExercise) {
    console.error(
      'handleSplitOneAboveTwoOrMoreBelow: groupOneHeader, groupOneFirstExercise, or groupTwoExercise is undefined'
    );
    return changes;
  }

  // All items greater than or equal to groupTwoExercise
  const groupTwoExercises = groupDragItems
    .filter(
      (item) =>
        item.index >= groupTwoExercise.index && item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
    )
    .sort((a, b) => a.index - b.index);

  const newDragIndex = groupOneHeader.dragIndex;
  let itemCount = 0;

  // Remove group one header and footer
  const removedGroupHeaderId = groupOneHeader.groupId;
  const removedGroupFooterId = `${groupOneHeader.groupId}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`;

  // Update group one exercise
  const groupOneExerciseDragItemUpdates: Partial<WorkoutDragItem> = {
    dragIndex: newDragIndex,
    inGroup: false,
    lastInGroup: true,
  };
  // Increment item count
  itemCount++;

  // Update group one exercise options
  const groupOneExerciseOptionsIds = `${groupOneHeader.groupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;
  const groupOneExerciseOptionsUpdates: Partial<WorkoutDragItem> = {
    dragIndex: newDragIndex + itemCount,
    inGroup: false,
    lastInGroup: true,
  };
  // Increment item count
  itemCount++;

  // Create new group for group two
  const groupTwo: WorkoutExerciseGroup = {
    id: uuidv4(),
    index: groupOneHeader.index + 1,
    name: '',
    workoutId: groupOneHeader.workoutId,
  };

  // Create group header for group two
  const groupTwoHeader: WorkoutDragItem = createWorkoutExerciseGroup({
    id: groupTwo.id,
    index: groupTwo.index,
    dragIndex: newDragIndex + itemCount,
    workoutId: groupTwo.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER,
    inGroup: true,
  });
  // Increment item count
  itemCount++;

  // Update groupTwo drag items
  const groupTwoExerciseDragItemChanges: UpdateType[] = groupTwoExercises.map((item, i) => {
    const changes: Partial<WorkoutDragItem> = {
      groupId: groupTwo.id,
      groupIndex: groupTwo.index,
      index: i,
      inGroup: true,
      lastInGroup: i === groupTwoExercises.length - 1,
      dragIndex: newDragIndex + itemCount,
    };
    // Increment item count
    itemCount++;

    return {
      id: item.id,
      changes,
    };
  });
  // Update groupTwo exercises
  const groupTwoExerciseChanges: UpdateType[] = groupTwoExercises.map((item, i) => {
    const changes: Partial<WorkoutExercise> = {
      workoutExerciseGroupId: groupTwo.id,
      index: i,
    };
    return {
      id: item.id,
      changes,
    };
  });

  // Create group footer for group two
  const groupTwoFooter: WorkoutDragItem = createWorkoutExerciseGroup({
    id: groupTwo.id,
    index: groupTwo.index,
    dragIndex: newDragIndex + itemCount,
    workoutId: groupTwo.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER,
    inGroup: true,
  });
  // Increment item count
  itemCount++;

  // Add new group two exercise options
  const newGroupTwoExerciseOptions = createWorkoutExerciseOptions({
    id: groupTwo.id,
    index: groupTwo.index,
    dragIndex: newDragIndex + itemCount,
    groupId: groupTwo.id,
    workoutId: groupTwo.workoutId,
    inGroup: false,
    groupIndex: groupTwo.index,
    lastInGroup: true,
  });

  // Apply the changes
  changes.workoutExerciseGroups.added = [groupTwo];
  changes.workoutDragItems.removed = [removedGroupHeaderId, removedGroupFooterId];
  changes.workoutDragItems.updated = [
    { id: groupOneFirstExercise.id, changes: groupOneExerciseDragItemUpdates },
    { id: groupOneExerciseOptionsIds, changes: groupOneExerciseOptionsUpdates },
    ...groupTwoExerciseDragItemChanges,
  ];
  changes.workoutDragItems.added = [groupTwoHeader, groupTwoFooter, newGroupTwoExerciseOptions];
  changes.workoutExercises.updated = [...groupTwoExerciseChanges];

  // Find all the drag items below the removed group
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      if (!item) {
        return false;
      }

      if (item.workoutId !== groupOneHeader.workoutId) {
        return false;
      }

      // exclude items from group two
      if (item.groupId === groupTwoExercise.groupId) {
        return false;
      }

      if (item.dragIndex > groupTwoExercise.dragIndex + 1) {
        return true;
      }

      return false;
    }
  );

  // Update the drag indexes for all items below the added group
  // Update all the group indexes for all items below added group
  const dragItemUpdates: UpdateType[] = [];
  const workoutExerciseGroupUpdates: UpdateType[] = [];

  affectedItems.forEach((item) => {
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};

    // We removed 2 items (group 1 header and footer)
    // We added 2 items (group 2 header and footer)
    // We added 1 item (group 2 exercise options)
    dragItemChanges.dragIndex = item.dragIndex + 1;

    // Update group indexes

    // Drag item changes
    if (item.groupIndex !== undefined) {
      dragItemChanges.groupIndex = item.groupIndex + 1;
    }
    // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    ) {
      dragItemChanges.index = item.index + 1;
    }

    // ExerciseGroup changes
    // If the item is a group header or a solo exercise
    // Update the exercise group index
    if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
      exerciseGroupChanges.index = item.index + 1;
    } else if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      !item.inGroup &&
      item.groupIndex !== undefined
    ) {
      exerciseGroupChanges.index = item.groupIndex + 1;
    }

    // Push updates
    dragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });
    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...dragItemUpdates);
  if (workoutExerciseGroupUpdates.length) {
    changes?.workoutExerciseGroups?.updated?.length
      ? changes.workoutExerciseGroups.updated.push(...workoutExerciseGroupUpdates)
      : (changes.workoutExerciseGroups.updated = workoutExerciseGroupUpdates);
  }

  return changes;
};

export default handleSplitOneAboveTwoOrMoreBelow;
