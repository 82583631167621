import { WorkoutExerciseMetricValue } from 'src/@types/program';
import { Changes } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import uuidv4 from 'src/utils/uuidv4';

type Props = {
  state: RootState;
  changes: Changes;
  workoutExerciseMetricValueId: string;
  newWorkoutExerciseId: string;
  newWorkoutExerciseMetricId: string;
  newWorkoutId?: string; // If only a new workout is created
};

const handleDuplicateWorkoutExerciseMetricValue = ({
  state,
  changes,
  workoutExerciseMetricValueId,
  newWorkoutExerciseId,
  newWorkoutExerciseMetricId,
  newWorkoutId,
}: Props) => {
  const item = state.workoutExerciseMetricValues.entities[workoutExerciseMetricValueId];

  if (!item) {
    console.error(`No workout exercise metric value with id ${workoutExerciseMetricValueId} found`);
    return;
  }

  // If changes for this item don't exist, create them
  if (changes.workoutExerciseMetricValues === undefined) {
    changes.workoutExerciseMetricValues = {};
  }
  if (changes.workouts === undefined) {
    changes.workouts = {
      updated: [
        {
          id: newWorkoutId ? newWorkoutId : item.workoutId,
          changes: {},
        },
      ],
    };
  }

  const duplicatedItem: WorkoutExerciseMetricValue = {
    ...item,
    id: uuidv4(),
    workoutExerciseId: newWorkoutExerciseId,
    workoutExerciseMetricId: newWorkoutExerciseMetricId,
    workoutId: newWorkoutId ? newWorkoutId : item.workoutId,
  };

  // Push the changes
  changes.workoutExerciseMetricValues?.added?.length
    ? changes.workoutExerciseMetricValues.added.push(duplicatedItem)
    : (changes.workoutExerciseMetricValues.added = [duplicatedItem]);
};

export default handleDuplicateWorkoutExerciseMetricValue;
