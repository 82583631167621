import { Workout, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import uuidv4 from 'src/utils/uuidv4';
import { handleDuplicateWorkoutExerciseGroup } from '.';
import { WORKOUT_EXERCISE_GROUPS } from '../../constants/keys';

type Props = {
  state: RootState;
  changes: Changes;
  workoutId: string;
  // If new week
  newWeekId?: string;
  newProgramId?: string;
  // If copy to
  copyToWeekId?: string;
};

const handleDuplicateWorkout = ({
  state,
  changes,
  workoutId,
  newWeekId,
  newProgramId,
  copyToWeekId,
}: Props) => {
  const item = state.workouts.entities[workoutId];

  if (!item) {
    console.error(`No workout with id ${workoutId} found`);
    return;
  }

  // If changes for this item don't exist, create them
  if (changes.workouts === undefined) {
    changes.workouts = {};
  }

  let programWeekId = newWeekId ? newWeekId : item.programWeekId;
  if (copyToWeekId !== undefined) programWeekId = copyToWeekId;

  const duplicatedName = item.name ? `${item.name} (copy)` : `Workout ${item.index + 1} (copy)`;
  const duplicatedItem: Workout = {
    ...item,
    id: uuidv4(),
    programWeekId,
    programId: newProgramId ? newProgramId : item.programId,
    name: newWeekId === undefined ? duplicatedName : item.name,
  };

  // Check if there any affected items
  const workoutUpdates: UpdateType[] = [];

  // Only this workout was duplicated
  if (copyToWeekId !== undefined) {
    // We need to update the index and drag index of the duplicated exercise
    duplicatedItem.index = 0;

    // Find affected items
    const affectedItems = Object.values(state.workouts.entities).filter(
      (affectedItem): affectedItem is Workout =>
        !!affectedItem &&
        affectedItem.programWeekId === copyToWeekId &&
        affectedItem.index >= duplicatedItem.index
    );

    affectedItems.forEach((affectedItem) => {
      const workoutChanges: Partial<Workout> = {};
      // Update the index of the affected item
      workoutChanges.index = affectedItem.index + 1;

      // Push the changes
      workoutUpdates.push({ id: affectedItem.id, changes: workoutChanges });
    });
  }
  // Only this workout was duplicated
  else if (newWeekId === undefined) {
    // We need to update the index and drag index of the duplicated exercise
    duplicatedItem.index = item.index + 1;

    // Find affected items
    const affectedItems = Object.values(state.workouts.entities).filter(
      (affectedItem): affectedItem is Workout =>
        !!affectedItem &&
        affectedItem.programWeekId === item.programWeekId &&
        affectedItem.index > item.index
    );

    affectedItems.forEach((affectedItem) => {
      const workoutChanges: Partial<Workout> = {};
      // Update the index of the affected item
      workoutChanges.index = affectedItem.index + 1;

      // Push the changes
      workoutUpdates.push({ id: affectedItem.id, changes: workoutChanges });
    });
  }

  // Push the changes
  // Workout
  changes.workouts?.added?.length
    ? changes.workouts.added.push(duplicatedItem)
    : (changes.workouts.added = [duplicatedItem]);

  // If affected items exist, add them to the changes
  if (workoutUpdates.length) {
    changes.workouts?.updated?.length
      ? changes.workouts.updated.push(...workoutUpdates)
      : (changes.workouts.updated = workoutUpdates);
  }

  // Duplicate all items associated with this item
  const associatedItems = Object.values(state[WORKOUT_EXERCISE_GROUPS].entities).filter(
    (associatedItem): associatedItem is WorkoutExerciseGroup =>
      !!associatedItem && associatedItem.workoutId === workoutId
  );

  associatedItems.forEach((associatedItem) =>
    handleDuplicateWorkoutExerciseGroup({
      state,
      changes,
      workoutExerciseGroupId: associatedItem.id,
      newWorkoutId: duplicatedItem.id,
    })
  );
};

export default handleDuplicateWorkout;
