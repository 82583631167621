import { ProgramWeek } from 'src/@types/program';
import uuidv4 from 'src/utils/uuidv4';

type Props = {
  programId: string;
  index: number;
  name?: string;
};

const handleCreateWeek = ({ programId, index, name = '' }: Props) => {
  const newWeek: ProgramWeek = {
    id: uuidv4(),
    programId: programId,
    index: index,
    name: name,
  };
  return newWeek;
};

export default handleCreateWeek;
