import { useState } from 'react';
// @mui
import { Button, MenuItem, Typography } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import {
  getExercisesFilters,
  getExercisesSortBy,
  sortByExercises,
} from 'src/redux/slices/exercises';
import { SORT_BY_OPTIONS } from './ExerciseFilterSidebar';

// ----------------------------------------------------------------------

function renderLabel(label: string | null) {
  if (label === 'nameDesc') {
    return 'Name: Z-A';
  } else if (label === 'nameAsc') {
    return 'Name: A-Z';
  }
  return 'Relevance';
}

// ----------------------------------------------------------------------

export default function ExerciseSort() {
  const dispatch = useDispatch();

  const sortBy = useSelector(getExercisesSortBy);
  const filters = useSelector(getExercisesFilters);
  const filtersActive =
    filters.movementTags.length > 0 ||
    filters.muscleTags.length > 0 ||
    filters?.searchPhrase !== '';

  const [open, setOpen] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (currentTarget: HTMLButtonElement) => {
    if (!filtersActive) {
      setOpen(currentTarget);
    }
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSortBy = (value: string) => {
    handleClose();
    dispatch(sortByExercises(value));
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={(event) => handleOpen(event.currentTarget)}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        Sort By:&nbsp;
        <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {renderLabel(filtersActive ? null : sortBy)}
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': { typography: 'body2', borderRadius: 0.75 },
        }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === sortBy}
            onClick={() => handleSortBy(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
