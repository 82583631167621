import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  QueryConstraint,
  startAt,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import { FETCH_STATUS_TYPES_ENUM, USER_HISTORY_ENUM } from 'src/@types/enums';
import { UserHistory_WithID } from 'src/@types/firebase';
import { HistorySectionData, HistorySectionListData } from 'src/@types/userHistory';
import { DB } from 'src/contexts/FirebaseContext';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';
import { RootState } from '../store';
import { resetClientProfileAction } from './clientProfile';

const FETCH_LIMIT = 20;

const userHistoryAdapter = createEntityAdapter<UserHistory_WithID>({
  // Sort by name
  // sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = userHistoryAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  lastVisibleDoc: null,
  sortBy: 'dateDesc',
  filters: {
    dateRange: [],
    userHistoryType: [],
  },
} as {
  status: FETCH_STATUS_TYPES_ENUM;
  error: string | null;
  lastVisibleDoc: any | null;
  sortBy: string | null;
  filters: {
    dateRange: string[];
    userHistoryType: USER_HISTORY_ENUM[];
  };
});

// ----------------------------------------------------------------------

export const fetchUserHistory = createAsyncThunk<
  { items: UserHistory_WithID[]; lastVisibleDoc: any },
  { clientId?: string }
>('userHistory/fetchUserHistory', async ({ clientId }, { getState }) => {
  const state = getState() as RootState;
  const userId = clientId ? clientId : state.user.id;
  const { filters, sortBy } = state.userHistory;
  let { lastVisibleDoc } = state.userHistory;

  const items: UserHistory_WithID[] = [];

  if (!filters.dateRange.length && !filters.userHistoryType.length) {
    let firebaseOrderBy = orderBy('dateCreated', 'asc');
    if (sortBy === 'dateDesc') {
      firebaseOrderBy = orderBy('dateCreated', 'desc');
    }

    const queryConstraints: QueryConstraint[] = [
      firebaseOrderBy,
      limit(FETCH_LIMIT),
      where('userId', '==', userId),
    ];

    if (lastVisibleDoc) {
      queryConstraints.push(startAt(lastVisibleDoc));
    }

    const q = query(collection(DB, 'userHistory'), ...queryConstraints);

    const querySnapshot = await getDocs(q);
    lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      const { id } = doc;
      const data = doc.data() as any;
      convertFirebaseDataDates(data);

      const item = { ...data, id } as UserHistory_WithID;
      items.push(item);
    });
  } else {
    // Typesense search
    // const client = useTypesense();
    // const currentPage = startAt / FETCH_LIMIT + 1;
    // const searchParameters: SearchParams = {
    //   q: filters.searchPhrase ? filters.searchPhrase : '*',
    //   query_by: 'name, description, movementTagNames, muscleTagNames',
    //   query_by_weights: '4, 2, 1, 1',
    //   page: currentPage,
    //   per_page: FETCH_LIMIT,
    //   // sort_by: `${sortByOptions.field}:${sortByOptions.direction}`,
    // };
    // let filterBy = null;
    // // Creator filters
    // // if (creatorIds.length) {
    // //   const creatorFilter = `creatorIds:= [${creatorIds.join()}]`;
    // //   filterBy = filterBy ? filterBy + ' && ' + creatorFilter : creatorFilter;
    // // }
    // // Movement tags
    // if (filters.movementTags.length) {
    //   const tagNames = filters.movementTags.map((tag) => tag.id);
    //   // splice off first tag
    //   const firstTag = tagNames.shift();
    //   let tagFilter = `movementTagIds:=${firstTag}`;
    //   tagNames.forEach((tag) => {
    //     tagFilter += ` &&  movementTagIds:=${tag}`;
    //   });
    //   filterBy = filterBy ? filterBy + ' && ' + tagFilter : tagFilter;
    // }
    // // Muscle tags
    // if (filters.muscleTags.length) {
    //   const tagNames = filters.muscleTags.map((tag) => tag.id);
    //   // splice off first tag
    //   const firstTag = tagNames.shift();
    //   let tagFilter = `muscleTagIds:=${firstTag}`;
    //   tagNames.forEach((tag) => {
    //     tagFilter += ` &&  muscleTagIds:=${tag}`;
    //   });
    //   filterBy = filterBy ? filterBy + ' && ' + tagFilter : tagFilter;
    // }
    // if (filterBy) {
    //   searchParameters.filter_by = filterBy;
    // }
    // const response = (await client
    //   .collections('exercises')
    //   .documents()
    //   .search(searchParameters)) as unknown as SearchResponse<Exercise_WithID>;
    // if (response?.hits && response.hits.length !== 0) {
    //   exercises.push(
    //     ...response.hits.map((hit: SearchResponseHit<Exercise_WithID>) => hit.document)
    //   );
    // }
  }
  return { items, lastVisibleDoc };
});

// ----------------------------------------------------------------------

export const slice = createSlice({
  name: 'userHistory',
  initialState,
  reducers: {
    reset: () => initialState,
    //  SORT & FILTER PRODUCTS
    sortBy(state, action) {
      state.sortBy = action.payload;
      userHistoryAdapter.removeAll(state);
      state.lastVisibleDoc = null;
      state.error = null;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
    filter(state, action) {
      state.filters.dateRange = action.payload.dateRange;
      state.filters.userHistoryType = action.payload.userHistoryType;
      userHistoryAdapter.removeAll(state);
      state.lastVisibleDoc = null;
      state.error = null;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
  },
  extraReducers(builder) {
    builder
      // Reset case
      // Get Many Exercises
      .addCase(fetchUserHistory.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchUserHistory.fulfilled, (state, action) => {
        // Upsert all the added exercise metrics
        const { lastVisibleDoc, items } = action.payload;

        if (items && items.length !== 0) {
          userHistoryAdapter.upsertMany(state, items);
          state.lastVisibleDoc = lastVisibleDoc;
          // Change status to succeeded
          if (items.length < FETCH_LIMIT) {
            state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
          } else {
            state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
          }
        } else {
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        }
      })
      .addCase(fetchUserHistory.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // External
      .addCase(resetClientProfileAction, () => initialState);
  },
});

export const { sortBy, filter, reset } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllUserHistory, selectById: selectUserHistoryById } =
  userHistoryAdapter.getSelectors((state: RootState) => state.userHistory);

export const getUserHistoryFetchStatus = (state: RootState) => state.userHistory.status;
export const getUserHistorySortBy = (state: RootState) => state.userHistory.sortBy;
export const getUserHistoryFilters = (state: RootState) => state.userHistory.filters;

// ----------------------------------------------------------------------
// Custom Selectors
// ----------------------------------------------------------------------

export const selectAllUserHistorySections = createSelector(
  [selectAllUserHistory],
  (userHistoryItems) => {
    // Convert history items into history sections
    const historySections: HistorySectionData[] = [];

    userHistoryItems.forEach((item) => {
      const historySectionId = moment(item.dateCreated).startOf('day').format();

      // If the section doesn't exist, create it
      if (!historySections.some((h) => h.id === historySectionId)) {
        /* historySections does not already contain this id */
        historySections.push({
          id: historySectionId,
          date: moment(item.dateCreated).startOf('day').toDate(),
          title: moment(item.dateCreated).format('MMM Do YY'),
          data: [item],
        });
      } else {
        /* historySections already contains this id */
        const historySection = historySections.find((h) => h.id === historySectionId);
        if (historySection) {
          historySection.data.push(item);
        }
      }
    });

    // Sort the history sections by date
    historySections.sort((a, b) => b.date.getTime() - a.date.getTime());

    const userHistoryListData: HistorySectionListData = historySections.map((section) =>
      section.data.sort((a, b) => b.dateCreated.getTime() - a.dateCreated.getTime())
    );

    return {
      userHistoryListData,
      historySections,
    };
  }
);
