import { useState } from 'react';
// @mui
import { Card, Stack, Paper, Button, Typography, Alert, Dialog } from '@mui/material';
// @types
import { UserStripePaymentMethod } from 'src/@types/firebase';
// components
import Image from 'src/components/Image';
import Iconify from 'src/components/Iconify';
import { AddUpdateCardForm } from 'src/components/stripe';
import CardMenu from './CardMenu';

// section
// import { PaymentNewCardDialog } from 'src/payment';

// ----------------------------------------------------------------------

type Props = {
  paymentMethod?: UserStripePaymentMethod;
};

export default function AccountBillingPaymentMethod({ paymentMethod }: Props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const id = paymentMethod?.id ? paymentMethod.id : 'new';
  const cardNumber = `**** **** **** ${paymentMethod?.last4 ? paymentMethod?.last4 : '****'}`;
  const brand = paymentMethod?.brand ? paymentMethod?.brand : 'card';

  return (
    <>
      <Card sx={{ p: 3 }}>
        <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
          <Typography
            variant="overline"
            sx={{
              flexGrow: 1,
              color: 'text.secondary',
            }}
          >
            Payment Method
          </Typography>

          <Button size="small" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpen}>
            {paymentMethod?.id ? 'Change' : 'Add'} Card
          </Button>
        </Stack>

        <Stack spacing={2} direction="column">
          {paymentMethod?.id ? (
            <>
              <CardAlert paymentMethod={paymentMethod} />
              <Paper
                key={id}
                variant="outlined"
                sx={{
                  p: 3,
                  width: 1,
                  position: 'relative',
                  maxWidth: 320,
                }}
              >
                <Image
                  alt="icon"
                  src={
                    brand === 'master_card'
                      ? '/assets/icons/payments/ic_mastercard.svg'
                      : '/assets/icons/payments/ic_visa.svg'
                  }
                  sx={{ mb: 1, maxWidth: 36 }}
                />

                <Typography variant="subtitle2">{cardNumber}</Typography>

                <CardMenu />
              </Paper>
            </>
          ) : (
            <Typography variant="subtitle2">No payment method</Typography>
          )}
        </Stack>
      </Card>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Stack sx={{ p: 4 }}>
          <AddUpdateCardForm onSuccess={handleClose} />
        </Stack>
      </Dialog>
    </>
  );
}

// ----------------------------------------------------------------------

type CardAlertProps = {
  paymentMethod?: UserStripePaymentMethod;
};

const CardAlert = ({ paymentMethod }: CardAlertProps) => {
  const { expMonth, expYear } = paymentMethod || {};

  const isExpired = () => {
    if (!expMonth || !expYear) {
      return false;
    }

    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    if (currentYear > expYear) {
      return true;
    }

    if (currentYear === expYear && currentMonth > expMonth) {
      return true;
    }

    return false;
  };

  const isAboutToExpire = () => {
    if (!expMonth || !expYear) {
      return false;
    }

    const today = new Date();
    const currentMonth = today.getMonth() + 2;
    const currentYear = today.getFullYear();

    if (currentYear >= expYear && currentMonth >= expMonth) {
      return true;
    }

    return false;
  };

  if (isExpired()) {
    return (
      <Alert severity="error" sx={{ mb: 1 }}>
        Your card is expired. Please update your payment method.
      </Alert>
    );
  }

  if (isAboutToExpire()) {
    return (
      <Alert severity="error" sx={{ mb: 1 }}>
        Your card is about to expire. Please update your payment method.
      </Alert>
    );
  }

  return null;
};
