// reudx
import { useSelector } from 'src/redux/store';
import { getUser } from 'src/redux/slices/user';
// types
import { ACTIVE_SUBSCRIPTION_STATUSES } from 'src/@types/enums';
import { MAX_FREE_CLIENTS } from './useClients';
import moment from 'moment';

// ----------------------------------------------------------------------

const useCurrentUser = () => {
  const user = useSelector(getUser);
  const { activeClients, bonusSeats, initialized } = user;

  const appName = user?.appName;

  const numberOfBonusSeats = bonusSeats ? bonusSeats : 0;

  const numberOfActiveClients =
    activeClients === undefined ? 0 : activeClients - numberOfBonusSeats;

  const clientLimitReached = numberOfActiveClients >= MAX_FREE_CLIENTS;
  const isSubscribed =
    user?.stripe &&
    user.stripe.clientSubscription?.status &&
    ACTIVE_SUBSCRIPTION_STATUSES.includes(user.stripe.clientSubscription?.status);

  const hasUpgradedClientSubscription = user?.stripe?.clientSubscription?.upgraded;
  const paymentMethod = user?.stripe?.paymentMethod;
  const clientSubscription = user?.stripe?.clientSubscription;
  const endOfBillingPeriod = user?.stripe?.clientSubscription?.currentPeriodEnd
    ? moment(user?.stripe?.clientSubscription?.currentPeriodEnd * 1000).format('MMMM Do YYYY')
    : null;

  return {
    user,
    initialized,
    numberOfActiveClients,
    clientLimitReached,
    appName,
    isSubscribed,
    paymentMethod,
    endOfBillingPeriod,
    hasUpgradedClientSubscription,
    clientSubscription,
  };
};

export default useCurrentUser;
