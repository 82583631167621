import { Button, Box, Typography, Modal } from '@mui/material';
import { useCallback, useRef, useState, createContext, ReactNode } from 'react';

type UnsavedPromptProps = {
  children: ReactNode;
};

const UnsavedPromptContext = createContext<() => Promise<void>>(() => Promise.resolve());

const UnsavedPromptProvider = ({ children }: UnsavedPromptProps) => {
  const cbReject = useRef<() => void>();
  const cbResolve = useRef<() => void>();
  const [open, setOpen] = useState(false);

  const showPrompt = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        cbReject.current = reject;
        cbResolve.current = resolve;
        setOpen(true);
      }),
    []
  );

  const close = useCallback(() => {
    if (cbReject.current) {
      cbReject.current();
    }
    setOpen(false);
  }, []);

  const confirm = useCallback(() => {
    if (cbResolve.current) {
      cbResolve.current();
    }
    setOpen(false);
  }, []);

  return (
    <UnsavedPromptContext.Provider value={showPrompt}>
      {children}
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Unsaved Changes
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to leave? You will lose any unsaved changes
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button onClick={close}>Stay</Button>
            <Button onClick={confirm} color="error">
              Leave
            </Button>
          </Box>
        </Box>
      </Modal>
    </UnsavedPromptContext.Provider>
  );
};

export { UnsavedPromptProvider, UnsavedPromptContext };
