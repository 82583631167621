import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  Workout as WorkoutType,
  WorkoutDragItem,
  WorkoutExercise,
  WorkoutExerciseGroup,
  WorkoutExerciseMetric,
  WorkoutExerciseMetricValue,
} from 'src/@types/program';
import { ChangeData, Changes, WorkoutChangeData } from 'src/@types/program_redux';
import { applyChanges } from 'src/redux/functions/applyChanges';
import { AppDispatch, RootState } from 'src/redux/store';
import { WORKOUTS, WORKOUT_EXERCISE_GROUPS } from './constants/keys';
import { copyToCurrentProgram } from './copyToModal';
import { handleCreateWorkout } from './functions/create';
import { handleDuplicateWorkout } from './functions/duplicate';
import { programResetAction } from './program';
import { duplicateWeekAction, fetchProgramWeeks, removeWeekAction } from './programWeeks';

const workoutAdapter = createEntityAdapter<WorkoutType>({
  // Sort by index
  sortComparer: (a: WorkoutType, b: WorkoutType) => a.index - b.index,
});

const initialState = workoutAdapter.getInitialState();

export const addWorkoutAction = createAction<Changes>('workouts/addWorkout');
export const updateWorkoutAction = createAction<Changes>('workouts/updateWorkout');
export const removeWorkoutAction = createAction<Changes & { programWeekId: string }>(
  'workouts/removeWorkout'
);
export const reorderWorkoutsAction = createAction<Changes>('workouts/reorderWorkouts');
export const duplicateWorkoutAction = createAction<Changes>('workouts/duplicateWorkout');

export const slice = createSlice({
  name: WORKOUTS,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    const handleChangesReducer = (state: any, action: PayloadAction<Changes>) => {
      const items = action.payload.workouts;
      if (items) {
        applyChanges(items, workoutAdapter, state);
      }
    };

    builder
      // Internal
      .addCase(addWorkoutAction, handleChangesReducer)
      .addCase(updateWorkoutAction, handleChangesReducer)
      .addCase(removeWorkoutAction, handleChangesReducer)
      .addCase(reorderWorkoutsAction, handleChangesReducer)
      .addCase(duplicateWorkoutAction, handleChangesReducer)
      // External
      .addCase(removeWeekAction, handleChangesReducer)
      .addCase(duplicateWeekAction, handleChangesReducer)
      .addCase(copyToCurrentProgram, handleChangesReducer)

      .addCase(programResetAction, () => initialState)
      .addCase(fetchProgramWeeks.fulfilled, (state, action) => {
        const items = action.payload[WORKOUTS];
        if (items.length) {
          workoutAdapter.addMany(state, items);
        }
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

export const updateWorkout =
  ({ id, updates }: { id: string; updates: Partial<WorkoutType> }) =>
  (dispatch: AppDispatch) => {
    const update = { id: id, changes: updates };
    const changes: Changes = {
      workouts: {
        updated: [update],
      },
    };
    dispatch(updateWorkoutAction(changes));
  };

export const addWorkout =
  ({ programId, weekId, index }: { programId: string; weekId: string; index: number }) =>
  (dispatch: AppDispatch) => {
    const workouts: ChangeData = {};

    const newWorkout: WorkoutType = handleCreateWorkout({
      programId: programId,
      programWeekId: weekId,
      index,
    });

    workouts.added = [newWorkout];

    dispatch(addWorkoutAction({ workouts }));
  };

export const removeWorkout =
  ({ workoutId }: { workoutId: string }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const workout = selectWorkoutById(state, workoutId);

    if (!workout) {
      console.error(`Workout with id ${workoutId} not found`);
      return;
    }

    const { index } = workout;
    const weekId = workout.programWeekId;

    const workouts: WorkoutChangeData = {
      programWeekId: weekId,
    };
    const workoutExerciseGroups: ChangeData = {};
    const workoutExercises: ChangeData = {};
    const workoutDragItems: ChangeData = {};
    const workoutExerciseMetrics: ChangeData = {};
    const workoutExerciseMetricValues: ChangeData = {};

    // Remove workout
    workouts.removed = [workoutId];

    // Remove the exercise group
    const workoutExerciseGroupIds = Object.values(state[WORKOUT_EXERCISE_GROUPS].entities)
      .filter((item): item is WorkoutExerciseGroup => !!item && item?.workoutId === workoutId)
      .map((item) => item.id);
    workoutExerciseGroups.removed = workoutExerciseGroupIds;

    // Remove the workout exercises within the group
    const workoutExerciseIds = Object.values(state.workoutExercises.entities)
      .filter(
        (item): item is WorkoutExercise =>
          !!item && workoutExerciseGroupIds.includes(item.workoutExerciseGroupId)
      )
      .map((item) => item.id);
    workoutExercises.removed = workoutExerciseIds;

    // Remove all the workout exercises metrics for each exercise within the group
    const workoutExerciseMetricIds = Object.values(state.workoutExerciseMetrics.entities)
      .filter(
        (item): item is WorkoutExerciseMetric =>
          !!item && workoutExerciseIds.includes(item.workoutExerciseId)
      )
      .map((item) => item.id);
    workoutExerciseMetrics.removed = workoutExerciseMetricIds;

    // Remove all the workout exercise metric values for each exercise within the group
    const workoutExerciseMetricValueIds = Object.values(state.workoutExerciseMetricValues.entities)
      .filter(
        (item): item is WorkoutExerciseMetricValue =>
          !!item && workoutExerciseIds.includes(item.workoutExerciseId)
      )
      .map((item) => item.id);
    workoutExerciseMetricValues.removed = workoutExerciseMetricValueIds;

    // Remove all workout drag items
    const workoutDragItemIds = Object.values(state.workoutDragItems.entities)
      .filter((item): item is WorkoutDragItem => !!item && item?.workoutId === workoutId)
      .map((item) => item.id);
    workoutDragItems.removed = workoutDragItemIds;

    // Find all the workouts after the removed workout
    const affectedWorkouts = Object.values(state.workouts.entities).filter(
      (item): item is WorkoutType => !!item && item.index > index && item.programWeekId === weekId
    );
    // Update the indexes of workout after this workout
    const workoutUpdates = affectedWorkouts.map((item) => ({
      id: item.id,
      changes: {
        index: item.index - 1,
      },
    }));

    workouts.updated = workoutUpdates;

    // Dispatch removed and updated data
    dispatch(
      removeWorkoutAction({
        workouts,
        workoutExerciseGroups,
        workoutExercises,
        workoutDragItems,
        workoutExerciseMetrics,
        workoutExerciseMetricValues,
        programWeekId: weekId,
      })
    );
  };

export const reorderWorkouts =
  ({
    id,
    programWeekId,
    from,
    to,
  }: {
    id: string;
    programWeekId: string;
    from: number;
    to: number;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const workouts: ChangeData = {};

    // If from is greater than to then we moved the item left
    const movedDown = from > to;
    const movedUp = !movedDown;

    const state = getState();
    const movedItem = Object.values(state.workouts.entities).find(
      (item): item is WorkoutType => !!item && item.id === id
    );

    if (!movedItem) {
      console.error(`Workout with id ${id} not found`);
      return;
    }

    // Update the index of the moved item
    workouts.updated = [
      {
        id: movedItem.id,
        changes: {
          index: to,
        },
      },
    ];

    // Update the index for any item between the moved item and the destination
    const affectedItems = Object.values(state.workouts.entities).filter(
      (item): item is WorkoutType => {
        // If the item is undefined or the index is equal to the moved week then skip
        if (!item || item.programWeekId !== programWeekId || item.index === movedItem.index) {
          return false;
        }

        // If moved down and the index is lesser than from and greater than or equal to to
        if (movedDown && item.index < from && item.index >= to) {
          return true;
        }
        // If moved up and the index is less than the from index and greater than the to index
        else if (movedUp && item.index > from && item.index <= to) {
          return true;
        }

        return false;
      }
    );

    const updates = affectedItems.map((item) => ({
      id: item.id,
      changes: {
        index: item.index + (movedDown ? 1 : -1),
      },
    }));

    workouts.updated.push(...updates);

    dispatch(reorderWorkoutsAction({ workouts }));
  };

export const duplicateWorkout =
  ({ workoutId }: { workoutId: string }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const changes: Changes = {};

    handleDuplicateWorkout({
      state,
      changes,
      workoutId: workoutId,
    });

    dispatch(duplicateWorkoutAction(changes));
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllWorkouts,
  selectById: selectWorkoutById,
  // Pass in a selector that returns the posts slice of state
} = workoutAdapter.getSelectors((state: RootState) => state[WORKOUTS]);

const selectProgramWeekId = (state: RootState, programWeekId: string) => programWeekId;

export const selectAllWorkoutsByProgramWeekId = createSelector(
  [selectAllWorkouts, selectProgramWeekId],
  (workouts, programWeekId) => workouts.filter((w) => w.programWeekId === programWeekId)
);

export const getWorkoutName = (state: RootState, workoutId: string) =>
  selectWorkoutById(state, workoutId)?.name;
