import { FETCH_STATUS_TYPES_ENUM, PROGRAM_DATA_KEYS_ENUM } from './enums';
import {
  Program as ProgramType,
  ProgramWeek,
  Workout,
  WorkoutDragItem,
  WorkoutExercise,
  WorkoutExerciseGroup,
  WorkoutExerciseMetric,
  WorkoutExerciseMetricValue,
} from './program';

export type ProgramChangesKeyType =
  | PROGRAM_DATA_KEYS_ENUM.PROGRAM
  | PROGRAM_DATA_KEYS_ENUM.PROGRAM_WEEKS
  | PROGRAM_DATA_KEYS_ENUM.WORKOUTS
  | PROGRAM_DATA_KEYS_ENUM.WORKOUT_EXERCISE_GROUPS
  | PROGRAM_DATA_KEYS_ENUM.WORKOUT_EXERCISES
  | PROGRAM_DATA_KEYS_ENUM.WORKOUT_EXERCISE_METRICS
  | PROGRAM_DATA_KEYS_ENUM.WORKOUT_EXERCISE_METRIC_VALUES;

export type ChangesKeyType = ProgramChangesKeyType | PROGRAM_DATA_KEYS_ENUM.WORKOUT_DRAG_ITEMS;

export type Program = ProgramType & {
  status: FETCH_STATUS_TYPES_ENUM;
  error: string | null;
  saveStatus: FETCH_STATUS_TYPES_ENUM;
  saveError: string | null;
  saveAlertActive: boolean;
  saveDetailsStatus: FETCH_STATUS_TYPES_ENUM;
  saveDetailsError: string | null;
  saveImageStatus: FETCH_STATUS_TYPES_ENUM;
  saveImageError: string | null;
  duplicateStatus: FETCH_STATUS_TYPES_ENUM;
  duplicateError: string | null;
  currentTab: string;
  dragging: boolean;
  dragItemId: string | null;
};

export type ProgramWeekFetchData = {
  programWeeks: ProgramWeek[];
  workouts: Workout[];
  workoutExerciseGroups: WorkoutExerciseGroup[];
  workoutExercises: WorkoutExercise[];
  workoutExerciseMetrics: WorkoutExerciseMetric[];
  workoutExerciseMetricValues: WorkoutExerciseMetricValue[];
};

export type AddType =
  | ProgramWeek
  | Workout
  | WorkoutExercise
  | WorkoutExerciseGroup
  | WorkoutExerciseMetric
  | WorkoutExerciseMetricValue
  | WorkoutDragItem;

export type UpdateType = {
  id: string;
  changes: Partial<AddType>;
};

export type ChangeData = {
  added?: Array<AddType>;
  updated?: Array<UpdateType>;
  removed?: Array<string>;

  // Workouts
  programWeekId?: string;
};

export type WorkoutChangeData = ChangeData & {
  // Workouts
  programWeekId: string;
};

export type Changes = {
  [key in ChangesKeyType]?: ChangeData;
};

export enum CHANGE_TRACKER_TYPE {
  ADDED = 'added',
  UPDATED = 'updated',
  REMOVED = 'removed',
}

export type ChangeTackerData = {
  changeKeyType: ProgramChangesKeyType;
  id: string;
  type: CHANGE_TRACKER_TYPE;

  // Workouts
  programWeekId?: string;

  // Workout Items
  workoutId?: string;
};
