import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExerciseModal } from 'src/@types/exerciseModal';
import { RootState } from 'src/redux/store';
import { programResetAction } from './program';

const initialState: ExerciseModal = {
  visible: false,
  workoutId: '',
  dragIndex: 0,

  workoutName: undefined,
  groupAboveIndex: undefined,

  groupId: undefined,
  groupIndex: undefined,
  groupName: undefined,
  exerciseAboveId: undefined,
  exerciseAboveIndex: undefined,
  isExerciseAboveLastInGroup: undefined,
};

export const slice = createSlice({
  name: 'exerciseModal',
  initialState,
  reducers: {
    openExerciseModal: (state, action: PayloadAction<ExerciseModal>) => action.payload,
    closeExerciseModal: () => initialState,
  },
  extraReducers(builder) {
    builder
      // Reset case
      .addCase(programResetAction, () => initialState);
  },
});

export const { openExerciseModal, closeExerciseModal } = slice.actions;

export default slice.reducer;

// ################################################################
// SELECTORS
// ################################################################

export const getExerciseModal = (state: RootState) => state.exerciseModal;
export const getExerciseModalVisible = (state: RootState) => state.exerciseModal.visible;
