import {
  createSlice,
  createEntityAdapter,
  createSelector,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';
import {
  WorkoutDragItem,
  WorkoutExercise,
  WorkoutExerciseGroup as WorkoutExerciseGroupType,
  WorkoutExerciseMetric,
  WorkoutExerciseMetricValue,
} from 'src/@types/program';
import { ChangeData, Changes } from 'src/@types/program_redux';
import { applyChanges } from 'src/redux/functions/applyChanges';
import { AppDispatch, RootState } from 'src/redux/store';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';
import { WORKOUT_EXERCISE_GROUPS } from './constants/keys';
import { handleDuplicateWorkoutExerciseGroup } from './functions/duplicate';
import { programResetAction } from './program';
import { duplicateWeekAction, fetchProgramWeeks, removeWeekAction } from './programWeeks';
import { groupingAction, reorderExercisesAction } from './workoutDragItems';
import { addExercisesAction, removeExerciseAction } from './workoutExercises';
import { duplicateWorkoutAction, removeWorkoutAction } from './workouts';
import { copyToCurrentProgram } from './copyToModal';

const exerciseGroupAdapter = createEntityAdapter<WorkoutExerciseGroupType>({
  // Sort by index
  sortComparer: (a: WorkoutExerciseGroupType, b: WorkoutExerciseGroupType) => a.index - b.index,
});

const initialState = exerciseGroupAdapter.getInitialState();

export const updateExerciseGroupAction = createAction<Changes>(
  'workoutExerciseGroups/updateExerciseGroup'
);
export const removeExerciseGroupAction = createAction<Changes>(
  'workoutExerciseGroups/removeExerciseGroup'
);
export const duplicateWorkoutExerciseGroupAction = createAction<Changes>(
  'workoutExerciseGroups/duplicateWorkoutExerciseGroup'
);

export const slice = createSlice({
  name: WORKOUT_EXERCISE_GROUPS,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    const handleChangesReducer = (state: any, action: PayloadAction<Changes>) => {
      const items = action.payload.workoutExerciseGroups;
      if (items) {
        applyChanges(items, exerciseGroupAdapter, state);
      }
    };

    builder
      // Internal
      .addCase(updateExerciseGroupAction, handleChangesReducer)
      .addCase(removeExerciseGroupAction, handleChangesReducer)
      .addCase(duplicateWorkoutExerciseGroupAction, handleChangesReducer)
      // External
      .addCase(addExercisesAction, handleChangesReducer)
      .addCase(removeExerciseAction, handleChangesReducer)
      .addCase(removeWorkoutAction, handleChangesReducer)
      .addCase(removeWeekAction, handleChangesReducer)
      .addCase(reorderExercisesAction, handleChangesReducer)
      .addCase(groupingAction, handleChangesReducer)
      .addCase(duplicateWorkoutAction, handleChangesReducer)
      .addCase(duplicateWeekAction, handleChangesReducer)
      .addCase(copyToCurrentProgram, handleChangesReducer)

      .addCase(programResetAction, () => initialState)
      .addCase(fetchProgramWeeks.fulfilled, (state, action) => {
        const items = action.payload[WORKOUT_EXERCISE_GROUPS];
        if (items.length) {
          exerciseGroupAdapter.addMany(state, items);
        }
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

export const updateWorkoutExerciseGroup =
  ({ id, updates }: { id: string; updates: Partial<WorkoutExerciseGroupType> }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const workoutExerciseGroup = state.workoutExerciseGroups.entities[id];

    if (!workoutExerciseGroup) {
      console.error(`WorkoutExerciseGroup with id ${id} not found`);
      return;
    }

    // Used to update workout in database
    const workouts: ChangeData = {
      updated: [{ id: workoutExerciseGroup.workoutId, changes: {} }],
    };

    const update = { id: id, changes: updates };
    const workoutExerciseGroups: ChangeData = {
      updated: [update],
    };

    const changes: Changes = {
      workouts,
      workoutExerciseGroups,
    };
    dispatch(updateExerciseGroupAction(changes));
  };

export const removeExerciseGroup =
  ({ exerciseGroupId }: { exerciseGroupId: string }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    let removedDragItems = 0;
    const groupHeaderKey = exerciseGroupId;
    const groupFooterKey = `${exerciseGroupId}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`;

    // Get the footer because we want to update everything below it
    const exerciseGroupFooterDragItem = Object.values(state.workoutDragItems.entities).find(
      (item) => item?.id === groupFooterKey
    );

    if (!exerciseGroupFooterDragItem) {
      console.error(`Exercise group with id ${exerciseGroupId} not found`);
      return;
    }

    const { workoutId, index, dragIndex } = exerciseGroupFooterDragItem;

    const workouts: ChangeData = {
      updated: [{ id: workoutId, changes: {} }],
    };
    const workoutExerciseGroups: ChangeData = {};
    const workoutExercises: ChangeData = {};
    const workoutDragItems: ChangeData = {};
    const workoutExerciseMetrics: ChangeData = {};
    const workoutExerciseMetricValues: ChangeData = {};

    // Remove the exercise group
    workoutExerciseGroups.removed = [exerciseGroupId];

    // Remove the workout exercises within the group
    const workoutExerciseIds = Object.values(state.workoutExercises.entities)
      .filter(
        (item): item is WorkoutExercise =>
          !!item && item?.workoutExerciseGroupId === exerciseGroupId
      )
      .map((item) => item.id);
    workoutExercises.removed = workoutExerciseIds;

    // Remove all the workout exercises metrics for each exercise within the group
    const workoutExerciseMetricIds = Object.values(state.workoutExerciseMetrics.entities)
      .filter(
        (item): item is WorkoutExerciseMetric =>
          !!item && workoutExerciseIds.includes(item.workoutExerciseId)
      )
      .map((item) => item.id);
    workoutExerciseMetrics.removed = workoutExerciseMetricIds;

    // Remove all the workout exercise metric values for each exercise within the group
    const workoutExerciseMetricValueIds = Object.values(state.workoutExerciseMetricValues.entities)
      .filter(
        (item): item is WorkoutExerciseMetricValue =>
          !!item && workoutExerciseIds.includes(item.workoutExerciseId)
      )
      .map((item) => item.id);
    workoutExerciseMetricValues.removed = workoutExerciseMetricValueIds;

    // Remove the group drag items
    workoutDragItems.removed = [groupHeaderKey, groupFooterKey];
    // Increment drag item count
    removedDragItems += 2;

    // Remove the exercise drag items
    workoutDragItems.removed.push(...workoutExerciseIds);
    // Increment drag item count
    removedDragItems += workoutExerciseIds.length;

    // Remove the exercise option drag item
    const exerciseOptionKey = `${exerciseGroupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;
    workoutDragItems.removed.push(exerciseOptionKey);
    // Increment drag item count
    removedDragItems += 1;

    // Update any drag items below the removed group
    const affectedWorkoutDragItems = Object.values(state.workoutDragItems.entities).filter(
      (item): item is WorkoutDragItem =>
        !!item && item.dragIndex > dragIndex && item.workoutId === workoutId
    );

    // Update all affected drag indexes
    const updatedWorkoutDragItems = affectedWorkoutDragItems.map((item) => {
      const updatedDragIndex = item.dragIndex - removedDragItems;

      const changes: Partial<WorkoutDragItem> = {
        dragIndex: updatedDragIndex,
      };

      // Update group indexes
      if (
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
      ) {
        // Update any groups below the removed group
        if (item.index > index) {
          changes.index = item.index - 1;
        }
      }
      // Update group indexes for exercises and exercise options
      if (item.groupIndex !== undefined) {
        // Update any exercises below the removed group
        if (item.groupIndex > index) {
          changes.groupIndex = item.groupIndex - 1;
        }
      }

      return { id: item.id, changes };
    });

    workoutDragItems.updated = updatedWorkoutDragItems;

    // Dispatch removed and updated data
    dispatch(
      removeExerciseGroupAction({
        workouts,
        workoutExerciseGroups,
        workoutExercises,
        workoutDragItems,
        workoutExerciseMetrics,
        workoutExerciseMetricValues,
      })
    );
  };

export const duplicateExerciseGroup =
  ({ exerciseGroupId }: { exerciseGroupId: string }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const changes: Changes = {};

    handleDuplicateWorkoutExerciseGroup({
      state,
      changes,
      workoutExerciseGroupId: exerciseGroupId,
    });

    dispatch(duplicateWorkoutExerciseGroupAction(changes));
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllExerciseGroups,
  selectById: selectExerciseGroupById,
  // Pass in a selector that returns the posts slice of state
} = exerciseGroupAdapter.getSelectors((state: RootState) => state[WORKOUT_EXERCISE_GROUPS]);

const selectWorkoutId = (state: RootState, workoutId: string) => workoutId;

export const selectAllExerciseGroupsByWorkoutId = createSelector(
  [selectAllExerciseGroups, selectWorkoutId],
  (exerciseGroups, workoutId) => exerciseGroups.filter((eg) => eg.workoutId === workoutId)
);

export const getExerciseGroupName = (state: RootState, exerciseGroupId: string) =>
  selectExerciseGroupById(state, exerciseGroupId)?.name;
