import { EntityAdapter, EntityState } from '@reduxjs/toolkit';
import {
  Changes,
  ChangeTackerData,
  CHANGE_TRACKER_TYPE,
  ProgramChangesKeyType,
} from 'src/@types/program_redux';
import { PROGRAM, PROGRAM_WEEKS, WORKOUTS } from '../slices/program/constants/keys';

export const trackChanges = (
  changes: Changes,
  adapter: EntityAdapter<ChangeTackerData>,
  state: EntityState<ChangeTackerData>
) => {
  // Convert any ExerciseGroup, Exercise, ExerciseMetric, or ExerciseMetricValue changes to Workout update changes
  // console.log('changes', changes);
  // const workoutUpdates: UpdateType[] = Object.keys(changes)
  //   .filter(
  //     (key): key is ProgramChangesKeyType =>
  //       !!key &&
  //       (key === WORKOUT_EXERCISE_GROUPS ||
  //         key === WORKOUT_EXERCISES ||
  //         key === WORKOUT_EXERCISE_METRICS ||
  //         key === WORKOUT_EXERCISE_METRIC_VALUES)
  //   )
  //   .filter((key) => changes[key]?.workoutId)
  //   .map((key) => {
  //     const workoutId = changes[key]?.workoutId as string;
  //     const workoutUpdate: UpdateType = { id: workoutId, changes: {} };
  //     return workoutUpdate;
  //   });

  // // Add workout updates to changes
  // changes = {
  //   ...changes,
  //   workouts: {
  //     ...changes.workouts,
  //     updated: changes.workouts?.updated
  //       ? {
  //           ...changes.workouts.updated,
  //           ...workoutUpdates,
  //         }
  //       : workoutUpdates,
  //   },
  // };

  const changesKeys = Object.keys(changes).filter(
    (key): key is ProgramChangesKeyType =>
      !!key && (key === PROGRAM || key === PROGRAM_WEEKS || key === WORKOUTS)
  );

  changesKeys.forEach((key) => {
    const data = changes[key];
    const programWeekId = data ? data?.programWeekId : undefined;

    if (data?.added && data?.added?.length > 0) {
      // Convert to ChangeTackerData
      const added: ChangeTackerData[] = data.added.map((item) => ({
        changeKeyType: key,
        id: item.id,
        type: CHANGE_TRACKER_TYPE.ADDED,

        programWeekId,
      }));
      adapter.upsertMany(state, added);
    }

    if (data?.updated && data?.updated?.length > 0) {
      // If a new item is added locally, it doesn't need updating on the database
      // If the item already exists in change tracker no need to update
      const filteredUpdateData = data.updated.filter((item) => {
        const existingItem = state.entities[item.id];

        if (existingItem && existingItem.type !== CHANGE_TRACKER_TYPE.REMOVED) {
          return false;
        }

        return true;
      });

      // Convert to ChangeTackerData
      const updated: ChangeTackerData[] = filteredUpdateData.map((item) => ({
        changeKeyType: key,
        id: item.id,
        type: CHANGE_TRACKER_TYPE.UPDATED,

        programWeekId,
      }));
      if (updated.length > 0) {
        adapter.upsertMany(state, updated);
      }
    }

    if (data?.removed && data?.removed?.length > 0) {
      // If a new item was added locally and then removed, it doesn't need removing from the database

      // Filter out the items that were removed locally
      const localRemoveData = data.removed.filter((item) => {
        const existingItem = state.entities[item];

        if (existingItem && existingItem.type === CHANGE_TRACKER_TYPE.ADDED) {
          return true;
        }

        return false;
      });

      // Filter out the items that were removed remotely
      const databaseRemoveData = data.removed.filter((item) => {
        const existingItem = state.entities[item];

        if (existingItem && existingItem.type === CHANGE_TRACKER_TYPE.ADDED) {
          return false;
        }

        return true;
      });

      // Convert to ChangeTackerData
      const removedFromDatabase: ChangeTackerData[] = databaseRemoveData.map((item) => ({
        changeKeyType: key,
        id: item,
        type: CHANGE_TRACKER_TYPE.REMOVED,

        programWeekId,
      }));
      if (removedFromDatabase.length > 0) {
        adapter.upsertMany(state, removedFromDatabase);
      }

      // Remove local items
      if (localRemoveData.length > 0) {
        adapter.removeMany(state, localRemoveData);
      }
    }
  });
};
