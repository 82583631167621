// form
// import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { Button, Dialog, IconButton, Slide, Stack } from '@mui/material';
// @types
// components
import Iconify from '../../../components/Iconify';
import { ExerciseNewEditForm } from '.';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { Exercise_WithID } from 'src/@types/firebase';

// ----------------------------------------------------------------------

// const onSelected = (selected: string[], item: string) =>
//   selected.includes(item) ? selected.filter((value) => value !== item) : [...selected, item];

type Props = {
  isOpen: boolean;
  onSave: (exercise: Exercise_WithID) => void;
  onOpen: VoidFunction;
  onClose: VoidFunction;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewExerciseModal({ isOpen, onSave, onOpen, onClose }: Props) {
  const handleSave = (exercise: Exercise_WithID) => {
    onSave(exercise);
    onClose();
  };

  return (
    <>
      <Button variant="contained" onClick={onOpen} startIcon={<Iconify icon={'eva:plus-fill'} />}>
        New Exercise
      </Button>

      <Dialog
        keepMounted={false}
        fullScreen
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
        sx={{ zIndex: 1400 }}
      >
        <IconButton onClick={onClose} sx={{ zIndex: 9, position: 'absolute', top: 10, right: 10 }}>
          <Iconify
            icon={'fe:close'}
            color="text.primary"
            width={30}
            height={30}
            sx={{ opacity: 0.5, ':hover': { opacity: 1 } }}
          />
        </IconButton>
        <Stack sx={{ p: 4, pt: 6 }}>
          <ExerciseNewEditForm onSave={handleSave} />
        </Stack>
      </Dialog>
    </>
  );
}
