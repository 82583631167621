// contexts
import { DB } from 'src/contexts/FirebaseContext';
// types
import { doc, WriteBatch } from 'firebase/firestore';
import { ProgramWeek_WithID, Workout_WithID } from 'src/@types/firebase';
import { RootState } from 'src/redux/store';
import { Workout } from 'src/@types/program';
import { PROGRAM_DATA_KEYS_ENUM } from 'src/@types/enums';
// util
import uuidv4 from 'src/utils/uuidv4';
import { omitUnnecessaryProperties } from '../util';
// functions
import { handleLoadProgramWeeks } from '../load';

type Props = {
  destinationProgramId: string;
  week: ProgramWeek_WithID;
  batch: WriteBatch;
  state: RootState;
};

/**
 * Duplicates a Week to a program
 *
 * @param destinationProgramId - The id of the program to add the week to.
 * @param week - Program week
 * @param batch - The Firestore batch to write to
 * @param state - The current state of the store
 *
 */
const handleCopyWeekToOtherProgram = async ({
  destinationProgramId,
  week,
  batch,
  state,
}: Props) => {
  const duplicatedName = week.name ? `${week.name} (copy)` : `Week ${week.index + 1} (copy)`;
  const duplicate = {
    ...week,
    id: uuidv4(),
    programId: destinationProgramId,
    name: duplicatedName,
    index: 0,
  };

  const { id, ...duplicateData } = duplicate;
  const ref = doc(DB, 'programs', destinationProgramId, 'programWeeks', id);
  batch.set(ref, duplicateData);

  // Update the index of any item after the newly added item
  const { programWeeks: affectedItems } = await handleLoadProgramWeeks({
    programId: destinationProgramId,
    weeksOnly: true,
  });

  const updatedItems = affectedItems.map((item) => ({
    ...item,
    index: item.index + 1,
  }));

  updatedItems.forEach((item) => {
    const ref = doc(DB, 'programs', destinationProgramId, 'programWeeks', item.id);
    batch.update(ref, { index: item.index });
  });

  // Duplicate the workouts in the week
  const affectedWorkouts = Object.values(state.workouts.entities)
    .filter((item): item is Workout => !!item && item.programWeekId === week.id)
    .map((item) => {
      const firebaseWorkout = omitUnnecessaryProperties({
        key: PROGRAM_DATA_KEYS_ENUM.WORKOUTS,
        item,
        state,
      }) as Workout_WithID;
      return firebaseWorkout;
    });

  const duplicatedWorkouts = affectedWorkouts.map((item) => ({
    ...item,
    id: uuidv4(),
    programWeekId: duplicate.id,
    programId: duplicate.programId,
  }));

  duplicatedWorkouts.forEach((item) => {
    const { id: workoutId, ...workoutData } = item;
    const ref = doc(
      DB,
      'programs',
      destinationProgramId,
      'programWeeks',
      duplicate.id,
      'workouts',
      workoutId
    );
    batch.set(ref, workoutData);
  });

  return 'Copied week to other program';
};

export default handleCopyWeekToOtherProgram;
