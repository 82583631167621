// [PENDING, PENDING_ACCEPTED, DECLINED, ACTIVE, INACTIVE]
export enum CLIENT_STATUS_ENUM {
  PENDING = 'PENDING', // Client has not yet accepted the coach's invitation
  REGISTERING = 'REGISTERING', // Client has accepted the coach's invitation but has not yet completed the registration process
  DECLINED = 'DECLINED', // Client has declined the coach's invitation
  ACTIVE = 'ACTIVE', // Client has completed the registration process and is an active client
  EXPIRING = 'EXPIRING', // Client's status is becoming inactive at the end of this billing cycle
  INACTIVE = 'INACTIVE', // Client has completed the registration process and is NOT an active client
  CURRENT_COACH = 'CURRENT_COACH', // Client is the current coach
}

// Types of users that count towards a users active clients
export const CLIENT_ACTIVE_STATUS_TYPES = [
  CLIENT_STATUS_ENUM.ACTIVE,
  CLIENT_STATUS_ENUM.EXPIRING,
  CLIENT_STATUS_ENUM.PENDING,
  CLIENT_STATUS_ENUM.REGISTERING,
];
// Types of users that DON'T count towards a users active clients
export const CLIENT_INACTIVE_STATUS_TYPES = [
  CLIENT_STATUS_ENUM.DECLINED,
  CLIENT_STATUS_ENUM.INACTIVE,
];

// Types of clients to show in the active clients list
export const CLIENT_ACTIVE_LIST_STATUS_TYPES = [
  CLIENT_STATUS_ENUM.CURRENT_COACH,
  CLIENT_STATUS_ENUM.ACTIVE,
  CLIENT_STATUS_ENUM.PENDING,
  CLIENT_STATUS_ENUM.REGISTERING,
];
// Types of clients to show in the inactive clients list
export const CLIENT_INACTIVE_LIST_STATUS_TYPES = [
  CLIENT_STATUS_ENUM.DECLINED,
  CLIENT_STATUS_ENUM.EXPIRING,
  CLIENT_STATUS_ENUM.INACTIVE,
];

// ----------------------------------------------------------------------

export enum EXERCISE_METRIC_ENUM {
  PERCENTAGE = 'Percentage',
  REPS = 'Reps',
  TIME = 'Time',
  WEIGHT = 'Weight',
  RESISTANCE = 'Resistance',
  REST = 'Rest',
  CALORIES = 'Calories',
  DISTANCE = 'Distance',
  TEMPO = 'Tempo',
  HEIGHT = 'Height',
  RIR = 'RIR',
  RPE = 'RPE',
  ASSISTANCE = 'Assistance',

  SETS = 'Sets',
}

// ----------------------------------------------------------------------

export enum EXERCISES_PAGE_ENUM {
  ALL = 'all',
  MY_EXERCISES = 'my-exercises',
  GLOBAL_EXERCISES = 'global',
}

// ----------------------------------------------------------------------

export enum FETCH_STATUS_TYPES_ENUM {
  // 'idle' | 'loading' | 'succeeded' | 'failed';
  IDLE = 'idle',
  LOADING = 'loading',
  SEARCHING = 'searching',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

// ----------------------------------------------------------------------

export enum GLOBAL_PROGRAM_TYPE {
  PRO = 'PRO',
  FREEMIUM = 'FREEMIUM',
}

// ----------------------------------------------------------------------

export enum PROGRAM_PAGE_ENUM {
  PROGRAM = 'program',
  PROGRAM_DETAILS = 'programDetails',
}

// ----------------------------------------------------------------------

export enum UNIT_OF_MEASUREMENT_ENUM {
  NUMERIC = 'NUMERIC',
  CHECKLIST = 'CHECKLIST',
  DISTANCE = 'DISTANCE',
  TIME = 'TIME',
  PERCENT = 'PERCENT',
  RADIO = 'RADIO',
  OTHER = 'OTHER',
}

// ----------------------------------------------------------------------

// [WORKOUT_LOG, MEASUREMENT_LOG, NOTE, PERSONAL_BEST]
export enum USER_HISTORY_ENUM {
  WORKOUT_LOG = 'WORKOUT_LOG',
  EXERCISE_LOG = 'EXERCISE_LOG',
  MEASUREMENT_LOG = 'MEASUREMENT_LOG',
  HABIT_LOG = 'HABIT_LOG',
  NOTE = 'NOTE',
  PERSONAL_BEST = 'PERSONAL_BEST',
}

// ----------------------------------------------------------------------

export enum USER_TYPE_ENUM {
  ADMIN = 'ADMIN',
  COACH = 'COACH',
  CLIENT = 'CLIENT',
  COACH_LYDIA_ADMIN = 'COACH_LYDIA_ADMIN',
}

// ----------------------------------------------------------------------

export enum WORKOUT_DRAG_DATA_ENUM {
  EXERCISE = 'EXERCISE',
  EXERCISE_OPTIONS = 'EXERCISE_OPTIONS',
  GROUP_FOOTER = 'GROUP_FOOTER',
  GROUP_HEADER = 'GROUP_HEADER',
}

// ----------------------------------------------------------------------

export enum PROGRAM_DATA_KEYS_ENUM {
  PROGRAM = 'program',
  PROGRAM_WEEKS = 'programWeeks',
  WORKOUTS = 'workouts',
  WORKOUT_EXERCISE_GROUPS = 'workoutExerciseGroups',
  WORKOUT_EXERCISES = 'workoutExercises',
  WORKOUT_EXERCISE_METRICS = 'workoutExerciseMetrics',
  WORKOUT_EXERCISE_METRIC_VALUES = 'workoutExerciseMetricValues',
  WORKOUT_DRAG_ITEMS = 'workoutDragItems',
}

// ----------------------------------------------------------------------

export enum COPY_TO_ENUM {
  PROGRAM = PROGRAM_DATA_KEYS_ENUM.PROGRAM,
  WEEK = PROGRAM_DATA_KEYS_ENUM.PROGRAM_WEEKS,
  WORKOUT = PROGRAM_DATA_KEYS_ENUM.WORKOUTS,
}

export enum COPY_ITEM_ENUM {
  WEEK = PROGRAM_DATA_KEYS_ENUM.PROGRAM_WEEKS,
  WORKOUT = PROGRAM_DATA_KEYS_ENUM.WORKOUTS,
  GROUP = PROGRAM_DATA_KEYS_ENUM.WORKOUT_EXERCISE_GROUPS,
  EXERCISE = PROGRAM_DATA_KEYS_ENUM.WORKOUT_EXERCISES,
}

// ----------------------------------------------------------------------

export enum STRIPE_SUBSCRIPTION_STATUS_ENUM {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

// All but canceled
export const ACTIVE_SUBSCRIPTION_STATUSES = [
  STRIPE_SUBSCRIPTION_STATUS_ENUM.INCOMPLETE,
  STRIPE_SUBSCRIPTION_STATUS_ENUM.INCOMPLETE_EXPIRED,
  STRIPE_SUBSCRIPTION_STATUS_ENUM.TRIALING,
  STRIPE_SUBSCRIPTION_STATUS_ENUM.ACTIVE,
  STRIPE_SUBSCRIPTION_STATUS_ENUM.PAST_DUE,
  STRIPE_SUBSCRIPTION_STATUS_ENUM.UNPAID,
];

// ----------------------------------------------------------------------

export enum STRIPE_INVOICE_STATUS_ENUM {
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
  VOID = 'void',
  UNCOLLECTIBLE = 'uncollectible',
}

// ----------------------------------------------------------------------

export enum NOTIFICATION_TYPE_ENUM {
  WORKOUT_LOG = 'WORKOUT_LOG',
  MEASUREMENT_LOG = 'MEASUREMENT_LOG',
  COACH_CLIENT_ACCEPT = 'COACH_CLIENT_ACCEPT',
}

// ----------------------------------------------------------------------

export enum GRAPH_DATE_RANGE_ENUM {
  // in number of days
  LAST_WEEK = 7,
  LAST_MONTH = 30,
  LAST_3_MONTHS = 90,
  LAST_6_MONTHS = 180,
  LAST_12_MONTHS = 365,
}

// ----------------------------------------------------------------------

export enum GRAPH_TIMESPAN_ENUM {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  ALL = 'all',
}

// ----------------------------------------------------------------------

export enum HABIT_UNIT_ENUM {
  TIMES = 'Times',
  SERVINGS = 'Servings',
}

// ----------------------------------------------------------------------

export enum HABIT_FREQUENCY_ENUM {
  PER_DAY = 'Per Day',
  PER_WEEK = 'Per Week',
}

// ----------------------------------------------------------------------

export enum HABIT_TIME_SPAN_ENUM {
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year',
}

// ----------------------------------------------------------------------

export enum CLIENT_PROFILE_TABS {
  PROFILE = 'profile',
  EXERCISE = 'exercise',
  BODYWEIGHT = 'bodyweight',
  PROGRAMS = 'programs',
  HABITS = 'habits',
}

// ----------------------------------------------------------------------

export enum APP_NAMES_ENUM {
  ONI = 'ONI',
  FITPROS = 'FITPROS',
}
