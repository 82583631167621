// types
import { Workout } from 'src/@types/program';
import { Changes } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
// functions
import { handleDuplicateWorkout } from '../duplicate';

type Props = {
  workout: Workout;
  newWeekId: string;
  state: RootState;
  changes: Changes;
};

/**
 * Duplicates a Workout to a new destination in the program currently in use
 *
 * @param workout - Workout
 * @param newWeekId - The id of the week to add the workout to. If undefined its the same week
 * @param state - The current state of the store
 * @param changes - The changes to be made to the current program
 *
 */
const handleCopyWorkoutToCurrentProgram = ({ workout, state, newWeekId, changes }: Props) => {
  handleDuplicateWorkout({
    state,
    changes,
    workoutId: workout.id,
    copyToWeekId: newWeekId,
  });
};

export default handleCopyWorkoutToCurrentProgram;
