import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WorkoutLog_WithID } from 'src/@types/firebase';
import { RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { doc, getDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';

const initialState: WorkoutLog_WithID & {
  status: FETCH_STATUS_TYPES_ENUM;
  error: string | null;
} = {
  id: '',
  userId: '',
  name: '',
  description: '',
  coachNotes: '',
  notes: '',
  dateCreated: new Date(),
  lastUpdated: new Date(),
  imageUrl: '',
  programId: '',
  programWeekId: '',
  workoutId: '',
  workoutLogExerciseGroups: [],
  workoutLogExercises: [],
  exerciseIds: [],
  workoutLogExerciseMetrics: [],
  exerciseMetricIds: [],
  workoutLogExerciseMetricValues: [],
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
};

// Fetch a single exercise
export const fetchWorkoutLog = createAsyncThunk<WorkoutLog_WithID, { workoutLogId: string }>(
  'workoutLog/fetchWorkoutLog',
  async ({ workoutLogId }) => {
    const docRef = doc(DB, 'workoutLogs', workoutLogId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() } as WorkoutLog_WithID;
    } else {
      // doc.data() will be undefined in this case
      throw new Error('No such workout log! ' + workoutLogId);
    }
  }
);

export const slice = createSlice({
  name: 'workoutLog',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers(builder) {
    builder
      // Reset case
      // Get Many Exercises
      .addCase(fetchWorkoutLog.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchWorkoutLog.fulfilled, (state, action) => {
        const item = action.payload;
        return { ...state, ...item, status: FETCH_STATUS_TYPES_ENUM.SUCCEEDED };
      })
      .addCase(fetchWorkoutLog.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// SELECTORS
// ----------------------------------------------------------------------

export const getWorkoutLog = (state: RootState) => state.workoutLog;
export const getWorkoutLogFetchStatus = (state: RootState) => state.workoutLog.status;
export const getWorkoutLogError = (state: RootState) => state.workoutLog.error;

export const selectAllWorkoutLogExercisesByWorkoutLogExerciseGroupId = (
  state: RootState,
  workoutLogExerciseGroupId: string
) => {
  const { workoutLogExercises } = state.workoutLog;
  return workoutLogExercises
    .filter(
      (workoutLogExercise) =>
        workoutLogExercise.workoutLogExerciseGroupId === workoutLogExerciseGroupId
    )
    .sort(
      // Sort by index
      (a, b) => a.index - b.index
    );
};

export const selectAllWorkoutLogExerciseMetricsByWorkoutLogExerciseId = (
  state: RootState,
  workoutExerciseId: string
) => {
  const { workoutLogExerciseMetrics } = state.workoutLog;
  return workoutLogExerciseMetrics
    .filter(
      (workoutLogExerciseMetric) =>
        workoutLogExerciseMetric.workoutLogExerciseId === workoutExerciseId
    )
    .sort(
      // Sort by index
      (a, b) => a.index - b.index
    );
};

export const selectAllWorkoutLogExerciseMetricValuesByWorkoutLogExerciseMetricId = (
  state: RootState,
  workoutExerciseMetricId: string
) => {
  const { workoutLogExerciseMetricValues } = state.workoutLog;
  return workoutLogExerciseMetricValues
    .filter(
      (workoutLogExerciseMetricValue) =>
        workoutLogExerciseMetricValue.workoutLogExerciseMetricId === workoutExerciseMetricId
    )
    .sort(
      // Sort by index
      (a, b) => a.index - b.index
    );
};

// ----------------------------------------------------------------------
