import { ReactNode } from 'react';
import { Navigate } from 'react-router';
import { FETCH_STATUS_TYPES_ENUM, USER_TYPE_ENUM } from 'src/@types/enums';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useProgram from 'src/hooks/useProgram';
import { PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------
type Props = {
  children: ReactNode;
};

export default function ProgramEditGuard({ children }: Props) {
  // If the user is in the creator ids array, they can edit the program
  // Admins can edit all programs

  const { user } = useCurrentUser();
  const { currentProgram, fetchStatus } = useProgram();

  // If the program hasn't successfully loaded return the program as is
  if (fetchStatus !== FETCH_STATUS_TYPES_ENUM.SUCCEEDED) {
    return <>{children}</>;
  }

  // Check if the user is an admin
  const isAdmin = user?.type?.includes(USER_TYPE_ENUM.ADMIN);

  // Check if the user is a creator
  const isCreator = currentProgram?.creatorIds?.includes(user?.id);

  if (!isCreator && !isAdmin) return <Navigate to={PATH_PAGE.page403} />;
  else {
    return <>{children}</>;
  }
}
