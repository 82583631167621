import { EntityAdapter } from '@reduxjs/toolkit';
import { ChangeData } from 'src/@types/program_redux';

export const applyChanges = (data: ChangeData, adapter: EntityAdapter<any>, state: any) => {
  if (data?.added && data?.added?.length > 0) {
    adapter.upsertMany(state, data.added);
  }
  if (data?.updated && data?.updated?.length > 0) {
    adapter.updateMany(state, data.updated);
  }
  if (data?.removed && data?.removed?.length > 0) {
    adapter.removeMany(state, data.removed);
  }
};
