import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { WorkoutExercise as WorkoutExerciseType } from 'src/@types/program';
import { AppDispatch, RootState } from 'src/redux/store';
import { isCollapsed } from 'src/utils/isCollapsed';
import { programResetAction } from './program';

const collapsedItemAdapter = createEntityAdapter<string>({
  selectId: (collapsedItem: string) => collapsedItem,
  // Sort by index
  // sortComparer: (a: WorkoutExerciseGroupType, b: WorkoutExerciseGroupType) => a.index - b.index,
});

const initialState = collapsedItemAdapter.getInitialState();

export const slice = createSlice({
  name: 'collapsedItems',
  initialState,
  reducers: {
    addCollapsedItem: (state, action) => {
      collapsedItemAdapter.addOne(state, action.payload);
    },
    addCollapsedItems: (state, action) => {
      collapsedItemAdapter.addMany(state, action.payload);
    },
    removeCollapsedItem: (state, action) => {
      collapsedItemAdapter.removeOne(state, action.payload);
    },
    removeCollapsedItems: (state, action) => {
      collapsedItemAdapter.removeMany(state, action.payload);
    },
    expandAll: () => initialState,
    reset: () => initialState,
  },
  extraReducers: {
    [programResetAction.type]: () => initialState,
  },
});

export const {
  addCollapsedItem,
  addCollapsedItems,
  removeCollapsedItem,
  removeCollapsedItems,
  expandAll,
  reset,
} = slice.actions;

// ################################################################
// THUNKS
// ################################################################

export const toggleCollapseAll = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const ids = selectProgramItemIds(getState());
  const collapsed = isProgramCollapsed(getState());

  if (collapsed) {
    dispatch(removeCollapsedItems(ids));
  } else {
    dispatch(addCollapsedItems(ids));
  }
};

export const toggleCollapseWorkout =
  (workoutId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const ids = selectWorkoutItemIds(getState(), workoutId);
    const collapsed = isWorkoutCollapsed(getState(), workoutId);

    if (collapsed) {
      dispatch(removeCollapsedItems(ids));
    } else {
      dispatch(addCollapsedItems(ids));
    }
  };

export const toggleCollapseExerciseGroup =
  (groupId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const ids = selectExerciseGroupItemIds(getState(), groupId);
    const collapsed = isExerciseGroupCollapsed(getState(), groupId);

    if (collapsed) {
      dispatch(removeCollapsedItems(ids));
    } else {
      dispatch(addCollapsedItems(ids));
    }
  };

export const toggleCollapseItem =
  (id: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const collapsed = isItemCollapsed(getState(), id);

    if (collapsed) {
      dispatch(removeCollapsedItem(id));
    } else {
      dispatch(addCollapsedItem(id));
    }
  };

export default slice.reducer;

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllCollapsedItems,
  selectIds: selectCollapsedItemIds,
  selectById: selectCollapsedItemById,
} = collapsedItemAdapter.getSelectors((state: RootState) => state.collapsedItems);

const selectProgramItemIds = createSelector(
  [
    (state: RootState) => state.workouts.ids.map((id) => `note-${id}`) as string[],
    (state: RootState) => state.workoutExercises.ids as string[],
  ],
  (workoutIds, workoutExerciseIds) => [...workoutExerciseIds, ...workoutIds]
);

const selectWorkoutItemIds = createSelector(
  [
    (state: RootState) =>
      Object.values(state.workoutExercises.entities).filter((e): e is WorkoutExerciseType => !!e),
    (_: RootState, workoutId: string) => workoutId,
  ],
  (workoutExercises, workoutId) => {
    const workoutExerciseIds = workoutExercises
      .filter((e) => e.workoutId === workoutId)
      .map((e) => e.id);
    const workoutNoteId = `note-${workoutId}`;
    const allIdsToCheck = [...workoutExerciseIds, workoutNoteId];
    return allIdsToCheck;
  }
);

const selectExerciseGroupItemIds = createSelector(
  [
    (state: RootState) =>
      Object.values(state.workoutExercises.entities).filter((e): e is WorkoutExerciseType => !!e),
    (_: RootState, exerciseGroupId: string) => exerciseGroupId,
  ],
  (workoutExercises, exerciseGroupId) => {
    const workoutExerciseIds = workoutExercises
      .filter((e) => e.workoutExerciseGroupId === exerciseGroupId)
      .map((e) => e.id);
    return workoutExerciseIds;
  }
);

export const isProgramCollapsed = createSelector(
  [selectCollapsedItemIds, selectProgramItemIds],
  (collapsedItemIds, idsToCheck) => isCollapsed(collapsedItemIds as string[], idsToCheck)
);

export const isWorkoutCollapsed = createSelector(
  [selectCollapsedItemIds, selectWorkoutItemIds],
  (collapsedItemIds, idsToCheck) => isCollapsed(collapsedItemIds as string[], idsToCheck)
);

export const isExerciseGroupCollapsed = createSelector(
  [selectCollapsedItemIds, selectExerciseGroupItemIds],
  (collapsedItemIds, idsToCheck) => isCollapsed(collapsedItemIds as string[], idsToCheck)
);

// For checking individual exercises or workout notes
export const isItemCollapsed = createSelector(
  [selectCollapsedItemIds, (_: RootState, idToCheck: string) => idToCheck],
  (collapsedItemIds, idToCheck) => isCollapsed(collapsedItemIds as string[], [idToCheck])
);
