// form
// import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { Box, Stack, Button, Drawer, Divider, IconButton, Typography } from '@mui/material';
// @types
import { NAVBAR } from '../../../config';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import { useEffect } from 'react';
import { RHFAutocomplete } from '../../../components/hook-form';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  fetchMuscleTags,
  getMuscleTagsFetchStatus,
  selectAllMuscleTags,
} from 'src/redux/slices/muscleTags';
import {
  fetchMovementTags,
  getMovementTagsFetchStatus,
  selectAllMovementTags,
} from 'src/redux/slices/movementTags';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'nameAsc', label: 'Name: A-Z' },
  { value: 'nameDesc', label: 'Name: Z-A' },
];

type Props = {
  isOpen: boolean;
  onOpen: VoidFunction;
  onResetAll: VoidFunction;
  onClose: VoidFunction;
};

export default function ExerciseFilterSidebar({ isOpen, onResetAll, onOpen, onClose }: Props) {
  //   const { control } = useFormContext();
  const dispatch = useDispatch();

  const muscleTags = useSelector(selectAllMuscleTags);
  const movementTags = useSelector(selectAllMovementTags);
  const muscleTagsFetchStatus = useSelector(getMuscleTagsFetchStatus);
  const movementTagsFetchStatus = useSelector(getMovementTagsFetchStatus);

  useEffect(() => {
    if (!muscleTags.length && muscleTagsFetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(fetchMuscleTags());
    }
    if (!movementTags.length && movementTagsFetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(fetchMovementTags());
    }
  }, [
    muscleTags.length,
    muscleTagsFetchStatus,
    movementTags.length,
    movementTagsFetchStatus,
    dispatch,
  ]);

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Iconify icon={'ic:round-filter-list'} />}
        onClick={onOpen}
      >
        Filters
      </Button>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: { width: NAVBAR.BASE_WIDTH },
        }}
        sx={{ zIndex: 1400 }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onClose}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar sx={{ height: '100%' }}>
          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1}>
              <Typography variant="subtitle1">Muscles Used</Typography>
              <RHFAutocomplete
                name="muscleTags"
                label="Muscles"
                options={muscleTags}
                sx={{ width: 1 }}
              />
            </Stack>

            <Stack spacing={1}>
              <Typography variant="subtitle1">Movement Type</Typography>
              <RHFAutocomplete
                name="movementTags"
                label="Movement"
                options={movementTags}
                sx={{ width: 1 }}
              />
            </Stack>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={onResetAll}
            startIcon={<Iconify icon={'ic:round-clear-all'} />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
