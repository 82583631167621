import { Card, Collapse, Stack } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { shallowEqual } from 'react-redux';
// hooks
import useExerciseDetailsModal from 'src/hooks/useExerciseDetailsModal';
import { WorkoutDragItem as WorkoutDragItemType } from 'src/@types/program';
import { isItemCollapsed, toggleCollapseItem } from 'src/redux/slices/program/collapsedItems';
import { duplicateExerciseGroup } from 'src/redux/slices/program/workoutExerciseGroups';
import { getProgramDraggingStatus, getProgramDragItemId } from 'src/redux/slices/program/program';
import { selectAllWorkoutExerciseMetricsByWorkoutExerciseId } from 'src/redux/slices/program/workoutExerciseMetrics';
import {
  duplicateWorkoutExercise,
  removeExercise,
  selectWorkoutExerciseById,
  updateWorkoutExercise,
} from 'src/redux/slices/program/workoutExercises';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { SetsInput, TextField, WorkoutExerciseMetrics, WorkoutExerciseHeader } from '.';
import DetailsMenu from './DetailsMenu';
import WorkoutExerciseOptions from './WorkoutExerciseOptions';
import { COPY_ITEM_ENUM } from 'src/@types/enums';
import { openCopyToModal } from 'src/redux/slices/program/copyToModal';
import { openExerciseSwapModal } from 'src/redux/slices/program/exerciseSwapModal';

type Props = {
  workoutDragItem: WorkoutDragItemType;
};

function WorkoutExercise({ workoutDragItem }: Props) {
  const dispatch = useDispatch();
  const { openExerciseDetailsModal } = useExerciseDetailsModal();
  const { id, groupId, dragIndex, inGroup } = workoutDragItem;

  // const { name, cover, /*position*/ follower, totalPost, avatarUrl, following } = user;

  const draggingActive = useSelector(getProgramDraggingStatus);
  const dragItemId = useSelector(getProgramDragItemId);
  const workoutExercise = useSelector((state: RootState) => selectWorkoutExerciseById(state, id));
  // const inGroup = useSelector((state: RootState) => isWorkoutExerciseInAGroup(state, groupId));
  const collapsed = useSelector((state: RootState) => isItemCollapsed(state, id));

  const workoutExerciseMetrics = useSelector(
    (state: RootState) => selectAllWorkoutExerciseMetricsByWorkoutExerciseId(state, id),
    shallowEqual
  );

  const isGroupBeingDragged = !!dragItemId && dragItemId === groupId;

  const [hover, setHover] = useState(false);

  const commonStyles = {
    p: 0,
    borderLeft: 3,
    borderColor: inGroup ? 'primary.dark' : 'primary.main',
  };
  const soloStyles = {
    ...commonStyles,
    mt: dragIndex === 0 ? 0 : 2,
    mb: 2,
  };
  const groupStyles = {
    ...commonStyles,
    my: 0,
    borderRadius: 0,
    opacity: isGroupBeingDragged ? 0.4 : 1,
  };

  const handleMouseEnter = useCallback(
    () => (draggingActive ? {} : setHover(true)),
    [draggingActive]
  );
  const handleMouseLeave = useCallback(() => setHover(false), []);

  const toggleCollapsed = useCallback(() => {
    dispatch(toggleCollapseItem(id));
  }, [dispatch, id]);

  const handleCoachNotesChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateWorkoutExercise({ id, updates: { coachNotes: e.target.value } }));
    },
    [dispatch, id]
  );

  const handleHeaderTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateWorkoutExercise({ id, updates: { header: e.target.value } }));
    },
    [dispatch, id]
  );

  const handleDuplicateExercise = useCallback(() => {
    if (inGroup) {
      dispatch(duplicateWorkoutExercise({ workoutExerciseId: id }));
    } else {
      dispatch(duplicateExerciseGroup({ exerciseGroupId: groupId }));
    }
  }, [dispatch, inGroup, groupId, id]);

  const handleExerciseSwap = useCallback(() => {
    if (workoutExercise) {
      dispatch(
        openExerciseSwapModal({
          visible: true,
          workoutExerciseId: id,
          workoutExerciseName: workoutExercise.name,
        })
      );
    }
  }, [dispatch, id, workoutExercise]);

  const handleExerciseCopyTo = useCallback(
    () =>
      dispatch(
        openCopyToModal({
          visible: true,
          copyItemId: inGroup ? id : groupId,
          copyItemType: inGroup ? COPY_ITEM_ENUM.EXERCISE : COPY_ITEM_ENUM.GROUP,
          copyItemName: workoutExercise?.name ? workoutExercise.name : '',
        })
      ),
    [dispatch, id, groupId, inGroup, workoutExercise?.name]
  );

  const handleRemoveExercise = useCallback(() => {
    dispatch(removeExercise({ workoutExerciseId: id }));
  }, [dispatch, id]);

  const handleOpenExerciseDetailsModal = useCallback(() => {
    if (workoutExercise?.exerciseId) {
      openExerciseDetailsModal(workoutExercise.exerciseId);
    }
  }, [workoutExercise?.exerciseId, openExerciseDetailsModal]);

  if (!workoutExercise) {
    return null;
  }

  return (
    <Draggable key={id} draggableId={id} index={dragIndex}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Card
            sx={inGroup ? groupStyles : soloStyles}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div {...provided.dragHandleProps}>
              <WorkoutExerciseHeader
                thumbnailUrl={workoutExercise?.thumbnailUrl}
                name={workoutExercise.name}
                hover={hover}
                collapsed={collapsed}
                headerText={workoutExercise.header}
                headerVisible={!inGroup && workoutExercise.headerVisible}
                toggleCollapsed={toggleCollapsed}
                handleHeaderTextChange={handleHeaderTextChange}
                handleDuplicateExercise={handleDuplicateExercise}
                handleExerciseSwap={handleExerciseSwap}
                handleExerciseCopyTo={handleExerciseCopyTo}
                handleRemoveExercise={handleRemoveExercise}
                handleOpenExerciseDetailsModal={handleOpenExerciseDetailsModal}
              />
            </div>

            <Stack
              sx={{ py: collapsed ? 0.5 : 2, flex: 1 }}
              direction={collapsed ? 'row' : 'column'}
            >
              {workoutExercise.setsVisible && (
                <SetsInput id={id} sets={workoutExercise.sets} collapsed={collapsed} />
              )}

              <WorkoutExerciseMetrics
                workoutExerciseMetrics={workoutExerciseMetrics}
                workoutExerciseId={id}
                collapsed={collapsed}
              />
            </Stack>

            {workoutExercise?.coachNotesVisible && !collapsed && (
              <Stack sx={{ pb: 2, px: 2 }}>
                <TextField
                  size="small"
                  collapsed={collapsed}
                  fullWidth
                  multiline
                  placeholder="Add coaching notes..."
                  value={workoutExercise.coachNotes}
                  onChange={handleCoachNotesChange}
                />
              </Stack>
            )}

            {!collapsed && (
              <Collapse in={hover} unmountOnExit>
                <DetailsMenu
                  workoutExerciseMetrics={workoutExerciseMetrics}
                  workoutExerciseId={workoutExercise.id}
                  exerciseId={workoutExercise.exerciseId}
                  workoutId={workoutExercise.workoutId}
                  inGroup={inGroup}
                />
                <WorkoutExerciseOptions workoutDragItem={workoutDragItem} />
              </Collapse>
            )}
          </Card>
        </div>
      )}
    </Draggable>
  );
}

export default memo(WorkoutExercise);
