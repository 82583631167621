// contexts
import { DB } from 'src/contexts/FirebaseContext';
// types
import { doc, WriteBatch } from 'firebase/firestore';
import { WorkoutExercise as WorkoutExerciseFB, WorkoutExerciseGroup } from 'src/@types/firebase';
import { WorkoutExercise } from 'src/@types/program';
import { RootState } from 'src/redux/store';
// util
import uuidv4 from 'src/utils/uuidv4';

type Props = {
  destinationWorkoutId: string;
  exercise: WorkoutExercise;
  batch: WriteBatch;
  state: RootState;
};

/**
 * Duplicates an Exercise to a workout
 *
 * @param destinationWorkoutId - The id of the workout to add the exercise to.
 * @param exercise - Exercise
 * @param batch - The Firestore batch to write to
 * @param state - The current state of the store
 *
 */
const handleCopyExerciseToOtherProgram = ({
  destinationWorkoutId,
  exercise,
  batch,
  state,
}: Props) => {
  // Fetch the workout this exercise group belongs to
  const workout = state.copyToModal.workouts.find((workout) => workout.id === destinationWorkoutId);
  if (!workout) throw new Error(`Workout not found: ${destinationWorkoutId}`);

  // Create a new group
  const newGroup: WorkoutExerciseGroup = {
    id: uuidv4(),
    index: 0,
    name: '',
  };

  // Update the indexes of any exercise groups after the duplicated one
  const workoutExerciseGroups = workout.workoutExerciseGroups.map((exerciseGroup) => {
    if (exerciseGroup.index >= newGroup.index) {
      return {
        ...exerciseGroup,
        index: exerciseGroup.index + 1,
      };
    }
    return exerciseGroup;
  });

  // Add the new exercise group to the workout
  workoutExerciseGroups.push(newGroup);

  // sort the exercise groups by index
  workoutExerciseGroups.sort((a, b) => a.index - b.index);

  // Duplicate the exercise
  const duplicate: WorkoutExerciseFB = {
    id: uuidv4(),
    index: 0,
    workoutExerciseGroupId: newGroup.id,
    sets: exercise.sets,
    setsVisible: exercise.setsVisible,
    coachNotes: exercise.coachNotes,
    header: exercise.header,
    exerciseId: exercise.exerciseId,
    name: exercise.name,
    youtubeId: exercise?.youtubeId ? exercise.youtubeId : '',
    thumbnailUrl: exercise?.thumbnailUrl ? exercise.thumbnailUrl : '',
  };

  // Add the duplicated exercise to the workout
  const workoutExercises = [...workout.workoutExercises, duplicate];

  // Update the workout
  const ref = doc(
    DB,
    'programs',
    workout.programId,
    'programWeeks',
    workout.programWeekId,
    'workouts',
    workout.id
  );
  batch.update(ref, { workoutExerciseGroups, workoutExercises });
};

export default handleCopyExerciseToOtherProgram;
