import {
  ProgramSaveData,
  ProgramWeek_Save,
  ProgramWeek_WithID,
  Workout_Save,
  Workout_WithID,
} from 'src/@types/firebase';
import { Program } from 'src/@types/program';
import { ChangeTackerData, CHANGE_TRACKER_TYPE } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { PROGRAM, PROGRAM_WEEKS, WORKOUTS } from '../../constants/keys';
import { handleCreateFirebaseWorkout, handleCreateWeek } from '../create';
import { omitUnnecessaryProperties } from '../util';
import cleanProgramData from 'src/utils/cleanProgramData';

type Props = {
  program: Program;
  numberOfWeeks?: number;
  numberOfWorkouts?: number;
  isEdit?: boolean;
  programChanges?: ChangeTackerData[];
  state: RootState;
};

const newProgram = ({
  program,
  numberOfWeeks,
  numberOfWorkouts,
}: {
  program: Program;
  numberOfWeeks: number;
  numberOfWorkouts: number;
}): ProgramSaveData => {
  const programSaveData: ProgramSaveData = {};

  // Clean program data, remove unnecessary properties
  const cleanProgram = cleanProgramData(program);

  programSaveData.program = { save: cleanProgram };

  // Create new weeks based on numberOfWeeks
  const weeks: { [key: string]: ProgramWeek_Save } = {};
  const workouts: { [key: string]: Workout_Save } = {};

  for (let i = 0; i < numberOfWeeks; i++) {
    const week: ProgramWeek_WithID = handleCreateWeek({
      programId: program.id,
      index: i,
    });
    const { id, ...rest } = week;
    weeks[id] = { ...rest, programId: program.id };

    // Create new workouts based on numberOfWorkouts

    for (let j = 0; j < numberOfWorkouts; j++) {
      const workout: Workout_WithID = handleCreateFirebaseWorkout({
        programId: program.id,
        programWeekId: week.id,
        index: j,
      });
      const { id, ...rest } = workout;
      workouts[id] = { ...rest, programWeekId: week.id, programId: program.id };
    }
  }

  programSaveData.programWeeks = { save: weeks };
  programSaveData.workouts = { save: workouts };

  return programSaveData;
};

// ----------------------------------------------------------------------------

const existingProgram = ({
  program,
  programChanges,
  state,
}: {
  program: Program;
  programChanges: ChangeTackerData[];
  state: RootState;
}) => {
  // Clean program data, remove unnecessary properties
  const cleanProgram = cleanProgramData(program);

  const programSaveData: ProgramSaveData = {};
  programChanges.forEach((change) => {
    let { changeKeyType } = change;
    let entityId = change.id;

    // If change type is Workout Exercise Group, Workout Exercise, Workout Exercise Metric, or Workout Exercise Metric Value
    // These items are nested within a Workout. Therefore any changes to these items should be saved to the workout
    // if (
    //   changeKeyType === WORKOUT_EXERCISE_GROUPS ||
    //   changeKeyType === WORKOUT_EXERCISES ||
    //   changeKeyType === WORKOUT_EXERCISE_METRICS ||
    //   changeKeyType === WORKOUT_EXERCISE_METRIC_VALUES
    // ) {
    //   const stateItem = state[changeKeyType].entities[entityId];
    //   if (stateItem) {
    //     const workoutId = stateItem?.workoutId;
    //     entityId = workoutId;
    //     changeKeyType = WORKOUTS;
    //   } else {
    //     if (process.env.NODE_ENV === 'development') {
    //       console.error(`State item of type ${changeKeyType} with id ${entityId} is not defined`);
    //     }
    //     return;
    //   }
    // }

    // If program
    if (changeKeyType === PROGRAM) {
      if (change.type === CHANGE_TRACKER_TYPE.UPDATED) {
        programSaveData.program = { save: cleanProgram };
      }
      return;
    }

    // If program weeks
    else if (changeKeyType === PROGRAM_WEEKS) {
      if (!programSaveData.programWeeks) {
        programSaveData.programWeeks = {};
      }

      const stateItem = state[changeKeyType].entities[entityId];

      // If the change is an addition
      if (
        change.type === CHANGE_TRACKER_TYPE.ADDED ||
        change.type === CHANGE_TRACKER_TYPE.UPDATED
      ) {
        if (!stateItem) {
          if (process.env.NODE_ENV === 'development') {
            console.error(`State item of type ${changeKeyType} with id ${entityId} is not defined`);
          }
          return;
        }

        const cleanStateItem = omitUnnecessaryProperties({
          key: changeKeyType,
          item: stateItem,
          state: state,
        }) as ProgramWeek_WithID;

        const { id, ...rest } = cleanStateItem;
        if (!programSaveData.programWeeks.save) {
          programSaveData.programWeeks.save = {};
        }
        programSaveData.programWeeks.save[id] = { ...rest, programId: program.id };
      }
      // If the change is a deletion
      else if (change.type === CHANGE_TRACKER_TYPE.REMOVED) {
        const removeItem = { id: entityId, programId: program.id };
        if (!programSaveData.programWeeks.remove) {
          programSaveData.programWeeks.remove = [removeItem];
        } else {
          programSaveData.programWeeks.remove.push(removeItem);
        }
      }
    }
    // If workouts
    else if (changeKeyType === WORKOUTS) {
      if (!programSaveData.workouts) {
        programSaveData.workouts = {};
      }

      const stateItem = state[changeKeyType].entities[entityId];

      // If the change is an addition
      if (
        change.type === CHANGE_TRACKER_TYPE.ADDED ||
        change.type === CHANGE_TRACKER_TYPE.UPDATED
      ) {
        if (!stateItem) {
          if (process.env.NODE_ENV === 'development') {
            console.error(`State item of type ${changeKeyType} with id ${entityId} is not defined`);
          }
          return;
        }

        const cleanStateItem = omitUnnecessaryProperties({
          key: changeKeyType,
          item: stateItem,
          state: state,
        }) as Workout_WithID;

        const { id, programWeekId, ...rest } = cleanStateItem;
        if (!programSaveData.workouts.save) {
          programSaveData.workouts.save = {};
        }
        programSaveData.workouts.save[id] = {
          ...rest,
          programWeekId: programWeekId,
          programId: program.id,
        };
      }
      // If the change is a deletion
      else if (change.type === CHANGE_TRACKER_TYPE.REMOVED && change?.programWeekId) {
        const removeItem = {
          id: entityId,
          programWeekId: change.programWeekId,
          programId: program.id,
        };
        if (!programSaveData.workouts.remove) {
          programSaveData.workouts.remove = [removeItem];
        } else {
          programSaveData.workouts.remove.push(removeItem);
        }
      }
    }
  });

  return programSaveData;
};

// ----------------------------------------------------------------------------

const prepareSaveData = ({
  program,
  numberOfWeeks,
  numberOfWorkouts,
  isEdit,
  programChanges,
  state,
}: Props): ProgramSaveData => {
  // New Program, Create New
  if (!isEdit && program) {
    const numberOfNewWeeks = numberOfWeeks || 1;
    const numberOfNewWorkouts = numberOfWorkouts || 1;
    return newProgram({
      program,
      numberOfWeeks: numberOfNewWeeks,
      numberOfWorkouts: numberOfNewWorkouts,
    });
  }
  // Existing Program, Update
  else if (programChanges?.length) {
    return existingProgram({
      program,
      programChanges,
      state,
    });
  } else {
    return {} as ProgramSaveData;
  }
};

export default prepareSaveData;
