import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { collection, onSnapshot } from 'firebase/firestore';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { Measurement_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';
import { AppDispatch, RootState } from '../store';
import { logoutAction } from './user';
// import { DB } from 'src/contexts/FirebaseContext';

export const DEFAULT_MEASUREMENT_ID = 'DfqsrFQBGi04aHWAPA7I';

const measurementAdapter = createEntityAdapter<Measurement_WithID>({
  // Sort by index
  sortComparer: (a, b) => a.index - b.index,
});

const initialState = measurementAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  listener: null,
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null; listener: null | Function });

export const slice = createSlice({
  name: 'measurements',
  initialState,
  reducers: {
    setMeasurements: (
      state,
      action: PayloadAction<{
        upsertItems: Measurement_WithID[];
        removeItems: string[];
      }>
    ) => {
      measurementAdapter.upsertMany(state, action.payload.upsertItems);
      measurementAdapter.removeMany(state, action.payload.removeItems);
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
    },
    // --------------------------------------------------
    // Listener commands start
    // --------------------------------------------------
    fetchConnectionsRequestsLoading(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
    },
    fetchConnectionsRequestsSuccess(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
    },
    fetchConnectionsRequestsFailure(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.error = action.payload;
      console.error(action.payload);
    },
    startListener: (state, action) => {
      state.listener = action.payload;
    },
    stopListener: (state) => {
      if (state.listener) {
        state.listener();
      }
    },
    // --------------------------------------------------
    reset: (state) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    },
  },
  extraReducers(builder) {
    const handleReset = (state: any) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    };

    builder
      // Reset
      .addCase(logoutAction, handleReset);
  },
});

export const {
  setMeasurements,
  fetchConnectionsRequestsLoading,
  fetchConnectionsRequestsSuccess,
  fetchConnectionsRequestsFailure,
  startListener,
  stopListener,
  reset,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

export const startMeasurementsListener =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { listener } = state.measurements;

    if (listener) {
      console.error('Measurements listener already exists');
      return;
    }

    dispatch(fetchConnectionsRequestsLoading());
    try {
      const unsubscribe = await onSnapshot(
        collection(DB, 'measurements'),
        (querySnapshot) => {
          const upsertItems: Measurement_WithID[] = [];
          const removeItems: string[] = [];

          querySnapshot.docChanges().forEach((change) => {
            const { id } = change.doc;
            const data = change.doc.data() as any;
            convertFirebaseDataDates(data);

            const item = { ...data, id } as Measurement_WithID;

            if (change.type === 'added' || change.type === 'modified') {
              upsertItems.push(item);
            }
            if (change.type === 'removed') {
              removeItems.push(id);
            }
          });
          dispatch(setMeasurements({ upsertItems, removeItems }));
        },
        (error) => {
          dispatch(fetchConnectionsRequestsFailure(error.message));
        }
      );
      dispatch(startListener(unsubscribe));
    } catch (error) {
      dispatch(fetchConnectionsRequestsFailure(error));
    }
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllMeasurements, selectById: selectMeasurementById } =
  measurementAdapter.getSelectors((state: RootState) => state.measurements);

export const getMeasurementFetchStatus = (state: RootState) => state.measurements.status;
export const getMeasurementFetchError = (state: RootState) => state.measurements.error;
