import {
  DragItem,
  WorkoutDragItem,
  WorkoutExercise,
  WorkoutExerciseGroup,
} from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import uuidv4 from 'src/utils/uuidv4';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';
import { handleDuplicateWorkoutExercise } from '.';
import { WORKOUT_EXERCISE_GROUPS } from '../../constants/keys';

// --------------------------------------------------
// Handle Copy To
// --------------------------------------------------

type HandleCopyToProps = {
  copyToWorkoutId: string;
  state: RootState;
  duplicatedItem: WorkoutExerciseGroup;
  duplicatedGroupHeader?: DragItem;
  duplicatedGroupFooter?: DragItem;
  duplicatedExerciseOptions: DragItem;
  dragIndexIncrement: number;
  dragItemUpdates: UpdateType[];
  workoutExerciseGroupUpdates: UpdateType[];
  numberOfExercises: number;
};

const handleCopyTo = ({
  copyToWorkoutId,
  state,
  duplicatedItem,
  duplicatedGroupHeader,
  duplicatedGroupFooter,
  duplicatedExerciseOptions,
  dragIndexIncrement,
  dragItemUpdates,
  workoutExerciseGroupUpdates,
  numberOfExercises,
}: HandleCopyToProps) => {
  // We need to update the index and drag index of the duplicated group
  // Update duplicated group index
  duplicatedItem.index = 0;

  let increment = 0;

  if (duplicatedGroupHeader && duplicatedGroupFooter) {
    // Update group header drag item
    duplicatedGroupHeader.index = 0;
    duplicatedGroupHeader.groupIndex = 0;
    duplicatedGroupHeader.dragIndex = 0;
    increment++;

    // Update group footer drag item
    duplicatedGroupFooter.index = 0;
    duplicatedGroupFooter.groupIndex = 0;
    duplicatedGroupFooter.dragIndex = numberOfExercises + 1;
    increment++;
  }

  // Update exercise options drag item
  duplicatedExerciseOptions.index = 0;
  duplicatedExerciseOptions.groupIndex = 0;
  duplicatedExerciseOptions.dragIndex = numberOfExercises + increment;

  // Find affected items
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (affectedItem): affectedItem is WorkoutDragItem =>
      !!affectedItem && affectedItem.workoutId === copyToWorkoutId && affectedItem.dragIndex >= 0
  );

  affectedItems.forEach((affectedItem) => {
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    // Update the drag index of the affected item
    dragItemChanges.dragIndex = affectedItem.dragIndex + dragIndexIncrement;

    // Drag item updates
    // Update group indexes
    if (affectedItem.groupIndex !== undefined) {
      dragItemChanges.groupIndex = affectedItem.groupIndex + 1;
    }
    // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
    if (
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    ) {
      dragItemChanges.index = affectedItem.index + 1;
    }

    // Group updates
    // If the item is a group header or a solo exercise
    // Update the exercise group index
    if (affectedItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
      exerciseGroupChanges.index = affectedItem.index + 1;
    } else if (
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      !affectedItem.inGroup &&
      affectedItem.groupIndex !== undefined
    ) {
      exerciseGroupChanges.index = affectedItem.groupIndex + 1;
    }

    // Push the changes
    dragItemUpdates.push({ id: affectedItem.id, changes: dragItemChanges });
    if (exerciseGroupChanges.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id:
          affectedItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
            ? affectedItem.groupId
            : affectedItem.id,
        changes: exerciseGroupChanges,
      });
    }
  });
};

// --------------------------------------------------

// --------------------------------------------------
// Handle Only This Exercise Being Duplicated
// --------------------------------------------------

type HandleOnlyThisExerciseGroupBeingDuplicatedProps = {
  state: RootState;
  item: WorkoutExerciseGroup;
  itemExerciseOptions: WorkoutDragItem;
  duplicatedItem: WorkoutExerciseGroup;
  duplicatedGroupHeader?: DragItem;
  duplicatedGroupFooter?: DragItem;
  duplicatedExerciseOptions: DragItem;
  dragIndexIncrement: number;
  dragItemUpdates: UpdateType[];
  workoutExerciseGroupUpdates: UpdateType[];
};

const handleOnlyThisExerciseGroupBeingDuplicated = ({
  state,
  item,
  itemExerciseOptions,
  duplicatedItem,
  duplicatedGroupHeader,
  duplicatedGroupFooter,
  duplicatedExerciseOptions,
  dragIndexIncrement,
  dragItemUpdates,
  workoutExerciseGroupUpdates,
}: HandleOnlyThisExerciseGroupBeingDuplicatedProps) => {
  // We need to update the index and drag index of the duplicated group
  // Update duplicated group index
  duplicatedItem.index = item.index + 1;

  // Update group header drag item
  if (duplicatedGroupHeader) {
    duplicatedGroupHeader.index = duplicatedItem.index;
    duplicatedGroupHeader.groupIndex = duplicatedItem.index;
    duplicatedGroupHeader.dragIndex += dragIndexIncrement;
  }

  // Update group footer drag item
  if (duplicatedGroupFooter) {
    duplicatedGroupFooter.index = duplicatedItem.index;
    duplicatedGroupFooter.groupIndex = duplicatedItem.index;
    duplicatedGroupFooter.dragIndex += dragIndexIncrement;
  }

  // Update exercise options drag item
  duplicatedExerciseOptions.index = duplicatedItem.index;
  duplicatedExerciseOptions.groupIndex = duplicatedItem.index;
  duplicatedExerciseOptions.dragIndex += dragIndexIncrement;

  // Find affected items
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (affectedItem): affectedItem is WorkoutDragItem =>
      !!affectedItem &&
      affectedItem.workoutId === item.workoutId &&
      affectedItem.dragIndex > itemExerciseOptions.dragIndex
  );

  affectedItems.forEach((affectedItem) => {
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    // Update the drag index of the affected item
    dragItemChanges.dragIndex = affectedItem.dragIndex + dragIndexIncrement;

    // Drag item updates
    // Update group indexes
    if (affectedItem.groupIndex !== undefined) {
      dragItemChanges.groupIndex = affectedItem.groupIndex + 1;
    }
    // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
    if (
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    ) {
      dragItemChanges.index = affectedItem.index + 1;
    }

    // Group updates
    // If the item is a group header or a solo exercise
    // Update the exercise group index
    if (affectedItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
      exerciseGroupChanges.index = affectedItem.index + 1;
    } else if (
      affectedItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      !affectedItem.inGroup &&
      affectedItem.groupIndex !== undefined
    ) {
      exerciseGroupChanges.index = affectedItem.groupIndex + 1;
    }

    // Push the changes
    dragItemUpdates.push({ id: affectedItem.id, changes: dragItemChanges });
    if (exerciseGroupChanges.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id:
          affectedItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
            ? affectedItem.groupId
            : affectedItem.id,
        changes: exerciseGroupChanges,
      });
    }
  });
};
// --------------------------------------------------

type Props = {
  state: RootState;
  changes: Changes;
  workoutExerciseGroupId: string;
  newWorkoutId?: string;
  // If copy to
  copyToWorkoutId?: string;
};

const handleDuplicateWorkoutExerciseGroup = ({
  state,
  changes,
  workoutExerciseGroupId,
  newWorkoutId,
  copyToWorkoutId,
}: Props) => {
  const item = state[WORKOUT_EXERCISE_GROUPS].entities[workoutExerciseGroupId];
  // Find the group drag items
  const groupDragItems = Object.values(state.workoutDragItems.entities).filter(
    (dragItem): dragItem is WorkoutDragItem =>
      !!dragItem && dragItem.groupId === workoutExerciseGroupId
  );
  const itemExerciseOptions = groupDragItems.find(
    (dragItem) => dragItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
  );
  const itemExerciseDragItems = groupDragItems
    .filter((dragItem) => dragItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE)
    .sort((a, b) => a.index - b.index);

  if (!item) {
    console.error(`No workout exercise group with id ${workoutExerciseGroupId} found`);
    return;
  }
  if (!itemExerciseOptions) {
    console.error(`No exercise options drag item found in group with id ${workoutExerciseGroupId}`);
    return;
  }
  if (!itemExerciseDragItems.length) {
    console.error(
      `No exercise workout drag items found in group with id ${workoutExerciseGroupId}`
    );
    return;
  }

  // Is this a solo group
  const firstExerciseDragItem = itemExerciseDragItems[0];
  const isSoloGroup = firstExerciseDragItem.inGroup === (false || undefined);

  // If changes for this item don't exist, create them
  if (changes.workoutExerciseGroups === undefined) {
    changes.workoutExerciseGroups = {};
  }
  if (changes.workoutDragItems === undefined) {
    changes.workoutDragItems = {};
  }
  if (changes.workouts === undefined) {
    const updatedWorkouts = [
      {
        id: newWorkoutId ? newWorkoutId : item.workoutId,
        changes: {},
      },
    ];

    if (copyToWorkoutId) {
      updatedWorkouts.push({
        id: copyToWorkoutId,
        changes: {},
      });
    }

    changes.workouts = {
      updated: updatedWorkouts,
    };
  }

  let workoutId = newWorkoutId ? newWorkoutId : item.workoutId;
  if (copyToWorkoutId !== undefined) workoutId = copyToWorkoutId;

  const duplicatedItem: WorkoutExerciseGroup = {
    ...item,
    id: uuidv4(),
    workoutId,
  };

  // Duplicate drag items associated with this group
  const duplicatedDragItems: WorkoutDragItem[] = [];
  // Group header
  let duplicatedGroupHeader: WorkoutDragItem | undefined = undefined;
  const itemGroupHeader: WorkoutDragItem | undefined = isSoloGroup
    ? undefined
    : groupDragItems.find((dragItem) => dragItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER);
  if (itemGroupHeader) {
    duplicatedGroupHeader = {
      ...itemGroupHeader,
      key: duplicatedItem.id,
      id: duplicatedItem.id,
      groupId: duplicatedItem.id,
      workoutId,
    };
    duplicatedDragItems.push(duplicatedGroupHeader);
  }

  // Group footer
  let duplicatedGroupFooter: WorkoutDragItem | undefined = undefined;
  const itemGroupFooter: WorkoutDragItem | undefined = isSoloGroup
    ? undefined
    : groupDragItems.find((dragItem) => dragItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER);
  if (itemGroupFooter) {
    duplicatedGroupFooter = {
      ...itemGroupFooter,
      key: `${duplicatedItem.id}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`,
      id: `${duplicatedItem.id}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`,
      groupId: duplicatedItem.id,
      workoutId,
    };
    duplicatedDragItems.push(duplicatedGroupFooter);
  }

  // Exercise options
  const duplicatedExerciseOptions: WorkoutDragItem = {
    ...itemExerciseOptions,
    key: `${duplicatedItem.id}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`,
    id: `${duplicatedItem.id}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`,
    groupId: duplicatedItem.id,
    workoutId,
  };
  duplicatedDragItems.push(duplicatedExerciseOptions);

  // Check if there any affected drag items
  const dragItemUpdates: UpdateType[] = [];
  const workoutExerciseGroupUpdates: UpdateType[] = [];

  const onlyThisGroupDuplicated =
    copyToWorkoutId === undefined && newWorkoutId === undefined ? true : false;
  // Amount to increase drag index by
  const dragIndexIncrement = groupDragItems.length;

  // Only this exercise group was copied to another workout
  if (copyToWorkoutId !== undefined) {
    handleCopyTo({
      copyToWorkoutId,
      state,
      duplicatedItem,
      duplicatedGroupHeader,
      duplicatedGroupFooter,
      duplicatedExerciseOptions,
      dragIndexIncrement,
      dragItemUpdates,
      workoutExerciseGroupUpdates,
      numberOfExercises: itemExerciseDragItems.length,
    });
  }
  // Only this group was duplicated
  else if (onlyThisGroupDuplicated) {
    handleOnlyThisExerciseGroupBeingDuplicated({
      state,
      item,
      itemExerciseOptions,
      duplicatedItem,
      duplicatedGroupHeader,
      duplicatedGroupFooter,
      duplicatedExerciseOptions,
      dragIndexIncrement,
      dragItemUpdates,
      workoutExerciseGroupUpdates,
    });
  }

  // Push the changes
  // Groups
  changes.workoutExerciseGroups?.added?.length
    ? changes.workoutExerciseGroups.added.push(duplicatedItem)
    : (changes.workoutExerciseGroups.added = [duplicatedItem]);
  // Drag item
  changes.workoutDragItems?.added?.length
    ? changes.workoutDragItems.added.push(...duplicatedDragItems)
    : (changes.workoutDragItems.added = [...duplicatedDragItems]);

  // If affected items exist, add them to the changes
  // Drag items
  if (dragItemUpdates.length) {
    changes.workoutDragItems?.updated?.length
      ? changes.workoutDragItems.updated.push(...dragItemUpdates)
      : (changes.workoutDragItems.updated = dragItemUpdates);
  }
  // Exercise groups
  if (workoutExerciseGroupUpdates.length) {
    changes.workoutExerciseGroups?.updated?.length
      ? changes.workoutExerciseGroups.updated.push(...workoutExerciseGroupUpdates)
      : (changes.workoutExerciseGroups.updated = workoutExerciseGroupUpdates);
  }

  // Duplicate all items associated with this item
  const associatedItems = Object.values(state.workoutExercises.entities).filter(
    (associatedItem): associatedItem is WorkoutExercise =>
      !!associatedItem && associatedItem.workoutExerciseGroupId === workoutExerciseGroupId
  );

  associatedItems.forEach((associatedItem) => {
    let newDragIndex = undefined;

    if (copyToWorkoutId !== undefined) {
      newDragIndex = associatedItem.index;
      if (duplicatedGroupHeader) newDragIndex += 1; // +1 because of the group header
    }

    handleDuplicateWorkoutExercise({
      state,
      changes,
      workoutExerciseId: associatedItem.id,
      newWorkoutExerciseGroupId: duplicatedItem.id,
      newWorkoutExerciseGroupIndex: duplicatedItem.index,
      newDragIndex: newDragIndex,
      dragIndexIncrement: onlyThisGroupDuplicated ? dragIndexIncrement : undefined,
      newWorkoutId: copyToWorkoutId ? copyToWorkoutId : newWorkoutId,
    });
  });
};

export default handleDuplicateWorkoutExerciseGroup;
