import { Typography } from '@mui/material';
import { memo } from 'react';
import { WorkoutDragItem as WorkoutDragItemType } from 'src/@types/program';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';
import { WorkoutExercise, WorkoutExerciseGroup, WorkoutExerciseOptions } from '.';

type Props = {
  workoutDragItem: WorkoutDragItemType;
  lastItem: boolean;
};

function WorkoutDragData({ workoutDragItem, lastItem }: Props) {
  let dragItem = <WorkoutExercise workoutDragItem={workoutDragItem} />;

  if (workoutDragItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
    dragItem = <WorkoutExerciseGroup workoutDragItem={workoutDragItem} />;
  } else if (workoutDragItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER) {
    dragItem = <WorkoutExerciseGroup workoutDragItem={workoutDragItem} />;
  } else if (workoutDragItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS) {
    dragItem = lastItem ? (
      <></>
    ) : (
      <WorkoutExerciseOptions workoutDragItem={workoutDragItem} inExercise={false} />
    );
  }

  return (
    <>
      {process.env.NODE_ENV === 'development' && (
        <>
          {/* Debugging drag and drop */}
          {workoutDragItem?.errors && (
            <Typography color="error.main" variant="caption" sx={{ mr: 1 }}>
              ERROR: <b>{workoutDragItem?.errors.join(', ')}</b>
              <br />
            </Typography>
          )}
          <Typography color="primary.dark" variant="caption" sx={{ mr: 1 }}>
            drag index: <b>{workoutDragItem.dragIndex}</b>
          </Typography>
          <Typography color="secondary.main" variant="caption" sx={{ mr: 1 }}>
            index: <b>{workoutDragItem.index}</b>
          </Typography>
          <Typography color="info.main" variant="caption" sx={{ mr: 1 }}>
            group index:{' '}
            <b>
              {workoutDragItem?.groupIndex !== undefined
                ? workoutDragItem.groupIndex
                : workoutDragItem.index}
            </b>
          </Typography>
          <Typography color="info.dark" variant="caption" sx={{ mr: 1 }}>
            group id: <b>{workoutDragItem.groupId}</b>
          </Typography>
          <Typography color="info.dark" variant="caption">
            id: <b>{workoutDragItem.id}</b>
          </Typography>
        </>
      )}

      {dragItem}
    </>
  );
}

export default memo(WorkoutDragData);
