// redux
import { RootState } from 'src/redux/store';
// types
import { CopyToDestination } from 'src/@types/copyToModal';
import { COPY_ITEM_ENUM, COPY_TO_ENUM } from 'src/@types/enums';
import { Changes } from 'src/@types/program_redux';
// functions
import {
  handleCopyWeekToCurrentProgram,
  handleCopyWeekToOtherProgram,
  handleCopyWorkoutToCurrentProgram,
  handleCopyWorkoutToOtherProgram,
  handleCopyExerciseGroupToCurrentProgram,
  handleCopyExerciseGroupToOtherProgram,
  handleCopyExerciseToCurrentProgram,
  handleCopyExerciseToOtherProgram,
} from '.';
import { writeBatch } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';

type Props = {
  changes: Changes;
  selectedDestinations: CopyToDestination[];
  itemType: COPY_ITEM_ENUM;
  itemId: string;
  state: RootState;
};

/**
 * Duplicates a Week, Workout, Exercise Group, or Exercise
 * to the program currently in use
 *
 * @param changes - The changes to be made to the current program
 * @param selectedDestinations - An array of selected destinations to copy our item to
 * @param itemType - The type of item to copy
 * @param itemId - The id of the item to copy
 * @param state - The current state of the store
 *
 */
const handleCopyTo = async ({ changes, selectedDestinations, itemType, itemId, state }: Props) => {
  const batch = writeBatch(DB);

  if (!selectedDestinations.length) return;

  // --------------------------------------------------
  // If the copied item was a week
  // --------------------------------------------------
  if (itemType === COPY_ITEM_ENUM.WEEK) {
    const week = state.programWeeks.entities[itemId];

    if (!week) throw new Error(`Week ${itemId} not found`);

    // Destination is a program
    // Local changes
    const localDestinations = selectedDestinations.filter(
      (destination) => destination.destinationIsCurrentProgram
    );
    const remoteDestinations = selectedDestinations.filter(
      (destination) => !destination.destinationIsCurrentProgram
    );

    // Copy to current program
    if (localDestinations.length) {
      localDestinations.forEach((destination) => {
        handleCopyWeekToCurrentProgram({ week, state, changes });
      });
    }

    // Copy to other programs
    if (remoteDestinations.length) {
      await Promise.all(
        remoteDestinations.map(async (destination) => {
          const destinationProgramId = destination.id;
          const response = await handleCopyWeekToOtherProgram({
            destinationProgramId,
            week,
            batch,
            state,
          });
          console.log(response);
        })
      );
    }
  }
  // --------------------------------------------------

  // --------------------------------------------------
  // If the copied item was a workout
  // --------------------------------------------------
  else if (itemType === COPY_ITEM_ENUM.WORKOUT) {
    const workout = state.workouts.entities[itemId];

    if (!workout) throw new Error(`Workout ${itemId} not found`);

    // Destination is a program
    selectedDestinations.forEach((destination) => {
      if (destination.type !== COPY_TO_ENUM.WEEK) return;
      const destinationWeek = destination.destinationIsCurrentProgram
        ? state.programWeeks.entities[destination.id]
        : state.copyToModal.weeks.find((week) => week.id === destination.id);
      if (!destinationWeek) throw new Error(`Destination Week ${destination.id} not found`);
      const destinationWeekId = destinationWeek.id;

      // If destination is the current program
      if (destination.destinationIsCurrentProgram) {
        handleCopyWorkoutToCurrentProgram({
          workout,
          state,
          newWeekId: destinationWeekId,
          changes,
        });
      } else {
        handleCopyWorkoutToOtherProgram({
          destinationProgramWeekId: destinationWeekId,
          workout,
          batch,
          state,
        });
      }
    });
  }
  // --------------------------------------------------

  // --------------------------------------------------
  // If the copied item was an exercise group
  // --------------------------------------------------
  else if (itemType === COPY_ITEM_ENUM.GROUP) {
    const exerciseGroup = state.workoutExerciseGroups.entities[itemId];

    if (!exerciseGroup) throw new Error(`Exercise Group ${itemId} not found`);

    // Destination is a program
    selectedDestinations.forEach((destination) => {
      if (destination.type !== COPY_TO_ENUM.WORKOUT) return;
      const destinationWorkout = destination.destinationIsCurrentProgram
        ? state.workouts.entities[destination.id]
        : state.copyToModal.workouts.find((workout) => workout.id === destination.id);
      if (!destinationWorkout) throw new Error(`Destination Workout ${destination.id} not found`);
      const destinationWorkoutId = destinationWorkout.id;

      // If destination is the current program
      if (destination.destinationIsCurrentProgram) {
        handleCopyExerciseGroupToCurrentProgram({
          exerciseGroup,
          state,
          newWorkoutId: destinationWorkoutId,
          changes,
        });
      } else {
        handleCopyExerciseGroupToOtherProgram({
          destinationWorkoutId,
          exerciseGroup,
          batch,
          state,
        });
      }
    });
  }
  // --------------------------------------------------

  // --------------------------------------------------
  // If the copied item was an exercise
  // --------------------------------------------------
  else if (itemType === COPY_ITEM_ENUM.EXERCISE) {
    const exercise = state.workoutExercises.entities[itemId];

    if (!exercise) throw new Error(`Exercise ${itemId} not found`);

    // Destination is a program
    selectedDestinations.forEach((destination) => {
      if (destination.type !== COPY_TO_ENUM.WORKOUT) return;
      const destinationWorkout = destination.destinationIsCurrentProgram
        ? state.workouts.entities[destination.id]
        : state.copyToModal.workouts.find((workout) => workout.id === destination.id);
      if (!destinationWorkout) throw new Error(`Destination Workout ${destination.id} not found`);
      const destinationWorkoutId = destinationWorkout.id;

      // If destination is the current program
      if (destination.destinationIsCurrentProgram) {
        handleCopyExerciseToCurrentProgram({
          exercise,
          state,
          newWorkoutId: destinationWorkoutId,
          changes,
        });
      } else {
        handleCopyExerciseToOtherProgram({
          destinationWorkoutId,
          exercise,
          batch,
          state,
        });
      }
    });
  }

  await batch.commit();
  // --------------------------------------------------
};

export default handleCopyTo;
