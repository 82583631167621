import { Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import {
  getActiveWorkoutExerciseId,
  setActiveWorkoutExercise,
} from 'src/redux/slices/program/workoutExercises';
import { useDispatch, useSelector } from 'src/redux/store';

type Props = TextFieldProps & {
  workoutExerciseId?: string;
  exerciseMetricName?: string;
};

function ExerciseMetricInput(props: Props) {
  const dispatch = useDispatch();

  const { workoutExerciseId: weId, exerciseMetricName, ...otherProps } = props;
  const value = otherProps.value as string;
  const workoutExerciseId = weId ? weId : '';

  const currentActiveWorkoutExerciseId = useSelector(getActiveWorkoutExerciseId);
  const exerciseIsActive = currentActiveWorkoutExerciseId === workoutExerciseId;

  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  const setCurrentActiveWorkoutExercise = useCallback(
    () => dispatch(setActiveWorkoutExercise(workoutExerciseId)),
    [dispatch, workoutExerciseId]
  );

  const handleMouseEnter = useCallback(() => setHover(true), []);
  const handleMouseLeave = useCallback(() => setHover(false), []);

  const handleFocus = useCallback(() => {
    setCurrentActiveWorkoutExercise();
    setFocus(true);
  }, [setCurrentActiveWorkoutExercise]);
  const handleBlur = useCallback(() => setFocus(false), []);

  const showInput = hover || focus || exerciseIsActive;

  return (
    <Stack
      justifyContent="flex-end"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showInput ? (
        <TextField
          {...otherProps}
          name={exerciseMetricName}
          variant="standard"
          size="small"
          inputProps={{ style: { fontSize: 12, width: 42, textAlign: 'center' } }}
          sx={{ mt: 1 }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          // InputLabelProps={{ style: { fontSize: 12 } }}
        />
      ) : (
        <Typography
          variant="caption"
          sx={{
            fontSize: 12,
            pb: 0.5,
            width: 42,
            textAlign: 'center',
            borderBottom: 1,
            borderColor: 'divider',
            height: 23,
            mt: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
  // return (
  //   <TextField
  //     {...props}
  //     variant="standard"
  //     size="small"
  //     inputProps={{ style: { fontSize: 12, width: 45, textAlign: 'center' } }}
  //     // InputLabelProps={{ style: { fontSize: 12 } }}
  //   />
  // );
}

export default memo(ExerciseMetricInput);
