import { useState } from 'react';
// @mui
import { IconButton, MenuItem } from '@mui/material';
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import { useSnackbar } from 'notistack';
import usePrompt from 'src/hooks/usePrompt';
import { httpsCallable } from 'firebase/functions';
import { UserStripePaymentMethod } from 'src/@types/firebase';
import { FUNCTIONS } from 'src/contexts/FirebaseContext';
import { APP_NAMES_ENUM } from 'src/@types/enums';
import useCurrentUser from 'src/hooks/useCurrentUser';
//

// ----------------------------------------------------------------------

export default function CardMenu() {
  const { enqueueSnackbar } = useSnackbar();
  const { appName } = useCurrentUser();
  const { openPrompt } = usePrompt();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(null);
  };

  // Handle the submission of card details
  const handleCardDelete = async () => {
    try {
      // Create Subscription on the Server
      const functionName =
        appName === APP_NAMES_ENUM.FITPROS
          ? 'events-stripeFitPros-removeStripePaymentDetails'
          : 'events-stripe-removeStripePaymentDetails';
      const removeStripePaymentDetails = httpsCallable<{ paymentMethod: UserStripePaymentMethod }>(
        FUNCTIONS,
        functionName
      );

      await removeStripePaymentDetails({
        paymentMethod: {
          id: '',
          brand: '',
          last4: '',
          expMonth: 0,
          expYear: 0,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    openPrompt({
      title: 'Delete?',
      message: 'Are you sure you want to delete this card? This action cannot be undone.',
      confirmLoadingMessage: 'Deleting...',
      confirmText: 'Delete',
      cancelText: 'Cancel',
      confirm: async () => {
        await handleCardDelete();
        enqueueSnackbar('Card has been deleted from your account.', { variant: 'success' });
        setOpen(null);
      },
      cancel: async () => {
        setOpen(null);
      },
    });
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          top: 8,
          right: 8,
          position: 'absolute',
        }}
      >
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="top-right"
        sx={{
          mt: -1,
          width: 180,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}
