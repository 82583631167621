export const msToTime = (totalTimeInMilliseconds: number) => {
  const milliseconds = Math.floor(totalTimeInMilliseconds % 1000),
    seconds = Math.floor((totalTimeInMilliseconds / 1000) % 60),
    minutes = Math.floor((totalTimeInMilliseconds / (1000 * 60)) % 60),
    hours = Math.floor((totalTimeInMilliseconds / (1000 * 60 * 60)) % 24);

  //   if (hours) {
  //     hours = hours < 10 ? '0' + hours : hours.toString();
  //   } else {
  //     hours = '';
  //   }
  //   if (minutes) {
  //     minutes = minutes < 10 ? '0' + minutes : minutes.toString();
  //   } else {
  //     minutes = '';
  //   }
  //   if (seconds) {
  //     seconds = seconds < 10 ? '0' + seconds : seconds.toString();
  //   } else {
  //     seconds = '';
  //   }
  //   if (milliseconds) {
  //     milliseconds =
  //       milliseconds < 10 ? '0' + milliseconds : milliseconds.toString();
  //   } else {
  //     milliseconds = '';
  //   }

  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    milliseconds: milliseconds,
  };
};
