import { WorkoutDragItem, WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { createWorkoutExerciseGroup } from 'src/utils/createDragItems';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  movedItem: WorkoutDragItem;
  destinationItem: WorkoutDragItem;
  movedDown: boolean;
  movedUp: boolean;
};

// If solo exercise was moved to a new group (combined)
const handleMoveExerciseToNewGroup = ({
  state,
  movedItem,
  destinationItem,
  movedDown,
  movedUp,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [
    { id: movedItem.workoutId, changes: {} },
    { id: destinationItem.workoutId, changes: {} },
  ];
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutDragItems = {};
  changes.workoutExercises = {};

  const from = movedItem.dragIndex;
  const to = destinationItem.dragIndex;

  const destinationGroupIndex =
    destinationItem?.groupIndex !== undefined ? destinationItem.groupIndex : destinationItem.index;

  const newGroupIndex = movedUp ? destinationGroupIndex - 1 : destinationGroupIndex; // -1 to account for the removed group
  const newDragIndex = movedUp ? destinationItem.dragIndex - 2 : destinationItem.dragIndex; // -2 to account for the moved exercise and the removed exercise options

  // Create a group header
  const newGroupHeader = createWorkoutExerciseGroup({
    id: destinationItem.groupId,
    index: newGroupIndex,
    dragIndex: newDragIndex,
    workoutId: destinationItem.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER,
    inGroup: true,
  });

  // Update destinationItem
  const destinationDragItemChanges: Partial<WorkoutDragItem> = {
    dragIndex: newDragIndex + 1,
    inGroup: true,
    lastInGroup: false,
    groupIndex: newGroupIndex,
  };

  // Update movedItem
  const movedDragItemChanges: Partial<WorkoutDragItem> = {
    index: 1,
    dragIndex: newDragIndex + 2,
    inGroup: true,
    groupId: destinationItem.groupId,
    lastInGroup: true,
    groupIndex: newGroupIndex,
  };
  // Update exercise associated with movedItem
  const movedExerciseChanges: Partial<WorkoutExercise> = {
    index: 1,
    workoutExerciseGroupId: destinationItem.groupId,
  };

  // Create a group footer
  const newGroupFooter = createWorkoutExerciseGroup({
    id: destinationItem.groupId,
    index: newGroupIndex,
    dragIndex: newDragIndex + 3,
    workoutId: destinationItem.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER,
    inGroup: true,
  });

  // Remove movedItem group
  changes.workoutExerciseGroups.removed = [movedItem.groupId];
  // Remove movedItem exercise options
  changes.workoutDragItems.removed = [
    movedItem.groupId + '-' + WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS,
  ];

  // Push the changes
  changes.workoutDragItems.added = [newGroupHeader, newGroupFooter];
  changes.workoutDragItems.updated = [
    {
      id: destinationItem.id,
      changes: destinationDragItemChanges,
    },
    { id: movedItem.id, changes: movedDragItemChanges },
  ];
  changes.workoutExercises.updated = [{ id: movedItem.id, changes: movedExerciseChanges }];

  // Find all the affected drag items
  // If between to and from
  // If moved item group index > item group index
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      // If the item is undefined or the workout id isn't the same then skip
      if (!item || item.workoutId !== movedItem.workoutId) {
        return false;
      }

      // Exclude the moved item and moved item exercise options
      if (
        item.id === movedItem.id ||
        item.id === movedItem.groupId + '-' + WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
      ) {
        return false;
      }

      // Exclude the destination item
      if (item.id === destinationItem.id) {
        return false;
      }

      // Find all items below the destination item
      if (movedDown && item.dragIndex >= to) {
        return true;
      }
      // Find all items below the moved item
      else if (movedUp && item.dragIndex > from) {
        return true;
      }

      return false;
    }
  );

  const workoutExerciseGroupUpdates: UpdateType[] = [];
  const workoutDragItemUpdates: UpdateType[] = [];
  // Update the affected items
  affectedItems.forEach((item) => {
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    const dragItemChanges: Partial<WorkoutDragItem> = {};

    // If dragIndex is greater than the removed exercise options (movedItem.dragIndex + 1)
    if (item.dragIndex > movedItem.dragIndex + 1) {
      dragItemChanges.dragIndex = item.dragIndex - 1;
    }

    // If exercise options belonging to destination group
    if (item.id === destinationItem.groupId + '-' + WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS) {
      dragItemChanges.inGroup = true;
    }

    // If item dragIndex is greater than to
    // Add 2 to the dragIndex to account for the new group header and footer
    if (item.dragIndex > to) {
      dragItemChanges.dragIndex =
        (dragItemChanges?.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) + 2;
    }

    // If moved down and the index is lesser than from and greater than or equal to to
    if (movedDown && item.dragIndex < from && item.dragIndex >= to) {
      dragItemChanges.dragIndex =
        (dragItemChanges?.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) + 1;
    }
    // If moved up and the index is less than the from index and greater than the to index
    else if (movedUp && item.dragIndex > from && item.dragIndex <= to) {
      dragItemChanges.dragIndex =
        (dragItemChanges?.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) - 1;
    }

    // Update any groups below the removed moved item group
    const itemGroupIndex = item.groupIndex !== undefined ? item.groupIndex : item.index;
    if (movedItem.groupIndex !== undefined && itemGroupIndex > movedItem.groupIndex) {
      // Update group indexes

      // Drag item changes
      if (item.groupIndex !== undefined) {
        dragItemChanges.groupIndex = item.groupIndex - 1;
      }
      // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
      if (
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
      ) {
        dragItemChanges.index = item.index - 1;
      }

      // ExerciseGroup changes
      // If the item is a group header or a solo exercise
      // Update the exercise group index
      if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
        exerciseGroupChanges.index = item.index - 1;
      } else if (
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
        !item.inGroup &&
        item.groupIndex !== undefined
      ) {
        exerciseGroupChanges.index = item.groupIndex - 1;
      }
    }

    // Push updates
    workoutDragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });
    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...workoutDragItemUpdates);

  if (workoutExerciseGroupUpdates.length) {
    changes.workoutExerciseGroups.updated = [...workoutExerciseGroupUpdates];
  }

  return changes;
};

export default handleMoveExerciseToNewGroup;
