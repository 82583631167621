import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
  Dictionary,
} from '@reduxjs/toolkit';
import { ExerciseMetric_WithID, UserExerciseMetricValueLog_WithID } from 'src/@types/firebase';
import { RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM, GRAPH_DATE_RANGE_ENUM } from 'src/@types/enums';
import { renderGraphValues } from 'src/utils/renderGraphValues';
import {
  ExerciseHistoryExerciseMetric,
  ExerciseHistoryExerciseMetricValue,
  ExerciseHistoryItem,
} from 'src/@types/exerciseHistory';
import { resetClientProfileAction } from '../clientProfile';
import { query, collection, getDocs, where, orderBy } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import _ from 'lodash';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';

type FetchExerciseDataResponse = {
  items: UserExerciseMetricValueLog_WithID[];
  exerciseName: string;
};

const exerciseDataAdapter = createEntityAdapter<UserExerciseMetricValueLog_WithID>({
  // Sort by index
  // sortComparer: (a, b) => a.index - b.index,
});

const initialState = exerciseDataAdapter.getInitialState({
  exerciseName: '',
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
} as { exerciseName: string; status: FETCH_STATUS_TYPES_ENUM; error: string | null });

export const fetchExerciseData = createAsyncThunk<
  FetchExerciseDataResponse,
  { userId: string; exerciseId: string; exerciseName: string; dateRange?: GRAPH_DATE_RANGE_ENUM }
>('exerciseData/fetchExerciseData', async ({ userId, exerciseId, exerciseName, dateRange }) => {
  const items: UserExerciseMetricValueLog_WithID[] = [];

  const queries = [
    where('userId', '==', userId),
    where('exerciseId', '==', exerciseId),
    orderBy('dateCreated', 'desc'),
  ];

  if (dateRange !== undefined) {
    const dateRangeDate = new Date(new Date().getTime() - dateRange * 24 * 60 * 60 * 1000);
    queries.push(where('dateCreated', '>=', dateRangeDate));
  }

  // Fetch UserExerciseMetricValueLogs from the last month (30 days) for the given exerciseId from firbase
  const q = query(collection(DB, 'userExerciseMetricValueLogs'), ...queries);

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const { id } = doc;
    const data = doc.data();
    convertFirebaseDataDates(data);
    const item = { ...data, id: id } as UserExerciseMetricValueLog_WithID;

    items.push(item);
  });

  return { items, exerciseName };
});

export const slice = createSlice({
  name: 'exerciseData',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      // Reset case
      // Internal
      .addCase(fetchExerciseData.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchExerciseData.fulfilled, (state, action) => {
        // Upsert all the added exercise metrics
        const { items } = action.payload;
        const { exerciseName } = action.payload;
        if (items.length !== 0) {
          exerciseDataAdapter.upsertMany(state, items);
          state.exerciseName = exerciseName;
          // Change status
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        } else {
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        }
      })
      .addCase(fetchExerciseData.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // External
      .addCase(resetClientProfileAction, () => initialState);
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllExerciseData } = exerciseDataAdapter.getSelectors(
  (state: RootState) => state.exerciseData
);

export const getExerciseDataFetchStatus = (state: RootState) => state.exerciseData.status;
export const getExerciseDataName = (state: RootState) => state.exerciseData.exerciseName;
export const getExerciseMetrics = (state: RootState): Dictionary<ExerciseMetric_WithID> =>
  state.exerciseMetrics.entities;

export const selectAllExerciseGraphData = createSelector(
  [selectAllExerciseData, getExerciseDataName, getExerciseMetrics],
  (data, exerciseName, exerciseMetrics) => {
    // Only get data that is numeric
    const numericData = data.filter((item) => !!item?.numericValue);

    // group by exercise metrics
    const groupedByExerciseMetric = _.groupBy(numericData, 'exerciseMetricId');

    const graphData = [
      {
        name: exerciseName,
        data: Object.values(groupedByExerciseMetric)
          .map((group) => {
            const exerciseMetric: ExerciseMetric_WithID | undefined =
              exerciseMetrics[group[0].exerciseMetricId];

            if (exerciseMetric?.name) {
              return renderGraphValues(group, exerciseMetric);
            } else {
              return null;
            }
          })
          .filter(
            (
              item
            ): item is {
              name: string;
              data: { x: number; y: number }[];
            } => !!item
          ),
      },
    ];

    return graphData;
  }
);

// ----------------------------------------------------------------------

const renderExerciseHistoryItem = ({
  exerciseValues,
  dateCreated,
  exerciseMetrics,
}: {
  exerciseValues: UserExerciseMetricValueLog_WithID[];
  dateCreated: Date;
  exerciseMetrics: Dictionary<ExerciseMetric_WithID>;
}) => {
  // Group by workoutLogExerciseId
  const exerciseHistoryItem: ExerciseHistoryItem = {
    id: exerciseValues[0].workoutLogExerciseId,
    dateCreated,
    exerciseMetrics: [],
    notes: exerciseValues[0]?.notes ? exerciseValues[0].notes : '',
  };

  const groupedByWorkoutLogExerciseMetricId = _.groupBy(
    exerciseValues,
    'workoutLogExerciseMetricId'
  );

  Object.values(groupedByWorkoutLogExerciseMetricId).forEach((exerciseMetricValues) => {
    const exerciseMetric = exerciseMetrics[exerciseMetricValues[0].exerciseMetricId];

    if (exerciseMetric) {
      const exerciseHistoryExerciseMetric: ExerciseHistoryExerciseMetric = {
        id: exerciseMetricValues[0].workoutLogExerciseMetricId,
        name: exerciseMetric.name,
        values: exerciseMetricValues.map(
          (item) =>
            ({
              id: item.id,
              value: item.value,
              set: item.set,
              type: exerciseMetric.unitOfMeasurement.type,
            } as ExerciseHistoryExerciseMetricValue)
        ),
      };
      exerciseHistoryItem.exerciseMetrics.push(exerciseHistoryExerciseMetric);
    }
  });

  return exerciseHistoryItem;
};

export const selectAllExerciseHistoryData = createSelector(
  [selectAllExerciseData, getExerciseMetrics],
  (data, exerciseMetrics) => {
    const items: ExerciseHistoryItem[] = [];

    const groupedByWorkoutLogExerciseId = _.groupBy(data, 'workoutLogExerciseId');

    Object.values(groupedByWorkoutLogExerciseId).forEach((exerciseValues) => {
      const itemDate = exerciseValues[0].dateCreated;
      const exerciseHistoryItem = renderExerciseHistoryItem({
        exerciseValues,
        dateCreated: itemDate,
        exerciseMetrics,
      });

      items.push(exerciseHistoryItem);
    });

    // const listData: ExerciseHistorySectionListData = sections.map(section =>
    //   section.data.sort(
    //     (a, b) => b.dateCreated.getTime() - a.dateCreated.getTime(),
    //   ),
    // );

    return items.sort((a, b) => b.dateCreated.getTime() - a.dateCreated.getTime());
    // return {
    //   listData,
    //   sections,
    // };
  }
);

// ----------------------------------------------------------------------
