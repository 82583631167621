// @mui
import { Stack, Typography, IconButton, Box } from '@mui/material';
// utils
import { fDate } from 'src/utils/formatTime';
import { fCurrency } from 'src/utils/formatNumber';
// @types
// components
import Iconify from 'src/components/Iconify';
// hooks
import useStripeInvoices from 'src/hooks/useStripeInvoices';
import SkeletonStripeInvoices from 'src/components/skeleton/SkeletonStripeInvoices';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';

// ----------------------------------------------------------------------

export default function AccountBillingInvoiceHistory() {
  const { invoices, fetchStatus } = useStripeInvoices();

  const renderInvoices = () => {
    if (fetchStatus === FETCH_STATUS_TYPES_ENUM.LOADING) {
      return <SkeletonStripeInvoices />;
    }

    if (invoices.length === 0) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            No invoices found.
          </Typography>
        </Box>
      );
    }

    return (
      <>
        {invoices.map((invoice, index) => (
          <Stack
            key={invoice.id ? invoice.id : 'draft-' + index}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: 1 }}
          >
            <Typography variant="body2" sx={{ minWidth: 120 }}>
              {fDate(invoice.period_start * 1000)}
            </Typography>

            <Typography variant="body2">{fCurrency(invoice.amount_due / 100)}</Typography>

            {invoice.invoice_pdf ? (
              <Box sx={{ maxWidth: 40 }} alignItems="center">
                <IconButton href={invoice.invoice_pdf} target="_blank">
                  <Iconify
                    icon={'ci:file-pdf'}
                    sx={{
                      hover: {
                        color: 'primary',
                      },
                    }}
                  />
                </IconButton>
              </Box>
            ) : (
              // Placeholder for upcoming invoice
              <Box sx={{ maxWidth: 40 }} alignItems="center">
                <Typography
                  variant="caption"
                  textAlign="center"
                  sx={{
                    backgroundColor: 'grey.500',
                    borderRadius: 1,
                    color: 'common.white',
                    p: 0.5,
                  }}
                >
                  Draft
                </Typography>
              </Box>
            )}
          </Stack>
        ))}
      </>
    );
  };

  return (
    <Stack spacing={3} alignItems="flex-end">
      <Typography variant="overline" sx={{ width: 1, color: 'text.secondary' }}>
        Invoice History
      </Typography>

      <Stack spacing={2} sx={{ width: 1 }}>
        {renderInvoices()}
      </Stack>

      {/* <Button size="small" color="inherit" endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}>
        All invoices
      </Button> */}
    </Stack>
  );
}
