import Typesense from 'typesense';

const useTypesense = () => {
  if (process.env.REACT_APP_TYPESENSE_API_KEY === undefined) {
    throw new Error('Typesense api key must be used');
  } else if (process.env.REACT_APP_TYPESENSE_HOST === undefined) {
    throw new Error('Typesense host must be used');
  } else if (process.env.REACT_APP_TYPESENSE_PORT === undefined) {
    throw new Error('Typesense port must be used');
  } else if (process.env.REACT_APP_TYPESENSE_PROTOCOL === undefined) {
    throw new Error('Typesense protocol must be used');
  } else {
    const host = process.env.REACT_APP_TYPESENSE_HOST;
    const port = process.env.REACT_APP_TYPESENSE_PORT as unknown as number;
    const protocol = process.env.REACT_APP_TYPESENSE_PROTOCOL as string;
    const apiKey = process.env.REACT_APP_TYPESENSE_API_KEY as string;

    const client = new Typesense.Client({
      nodes: [
        {
          host,
          port,
          protocol,
        },
      ],
      apiKey,
      connectionTimeoutSeconds: 4,
    });

    return client;
  }
};

export default useTypesense;
