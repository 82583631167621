import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createAction,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import { FetchExercisesParams } from 'src/@types/exercise';
import { ExerciseFilter as ExerciseFilterType } from 'src/@types/program';
import useTypesense from 'src/hooks/useTypesense';
import { AppDispatch, RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { programResetAction } from './program/program';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAt as firestoreStartAt,
  getDocs,
  QueryConstraint,
  doc,
  updateDoc,
  addDoc,
  getDoc,
  increment,
  arrayUnion,
  writeBatch,
  setDoc,
} from 'firebase/firestore';
import { ANALYTICS, DB } from 'src/contexts/FirebaseContext';
import { Exercise, ExerciseCreator, Exercise_WithID } from 'src/@types/firebase';
import { ExerciseCleanable as TypesenseExerciseCleanable } from 'src/@types/typesense';
import { ExerciseFormValuesProps } from 'src/sections/@dashboard/exercises/ExerciseNewEditForm';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';
import convertTypesenseDataDates from 'src/utils/convertTypesenseDataDates';
import { logEvent } from 'firebase/analytics';

const FETCH_LIMIT = 20;

const exerciseAdapter = createEntityAdapter<Exercise_WithID>({
  // Sort by name
  // sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = exerciseAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  page: 0,
  lastVisibleDoc: null,
  fetchExerciseStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  fetchExerciseError: null,
  saveStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  saveError: null,
  deleteStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  deleteError: null,
  sortBy: 'nameAsc',
  filters: {
    movementTags: [],
    muscleTags: [],
    searchPhrase: '',
  },
} as {
  status: FETCH_STATUS_TYPES_ENUM;
  error: string | null;
  page: number;
  lastVisibleDoc: any | null;
  fetchExerciseStatus: FETCH_STATUS_TYPES_ENUM;
  fetchExerciseError: string | null;
  saveStatus: FETCH_STATUS_TYPES_ENUM;
  saveError: string | null;
  deleteStatus: FETCH_STATUS_TYPES_ENUM;
  deleteError: string | null;
  sortBy: string | null;
  filters: ExerciseFilterType;
});
// ----------------------------------------------------------------------

// Fetch many exercises
export const fetchExercises = createAsyncThunk<
  {
    exercises: Exercise_WithID[];
    lastVisibleDoc: any;
    page: number;
  },
  FetchExercisesParams
>('exercises/fetchExercises', async ({ onlyOwnerExercises = undefined, admin }, { getState }) => {
  const state = getState() as RootState;
  let { lastVisibleDoc, page } = state.exercises;
  const userId = state.user.id;
  const { filters, sortBy } = state.exercises;

  const exercises = [];
  const creatorIds = admin ? [] : [userId, 'PUBLIC'];
  if (onlyOwnerExercises) {
    // Remove PUBLIC from creatorIds
    creatorIds.splice(1, 1);
  }

  // If there is a search
  if (filters.searchPhrase) {
    // ANALYTICS

    // Record analytics event for the search phrase
    logEvent(ANALYTICS, 'search_exercise', {
      search_term: filters.searchPhrase,
    });

    // Update exerciseSearchAnalytics
    const exerciseSearchAnalyticsRef = doc(DB, 'exerciseSearchAnalytics', filters.searchPhrase);
    // Merge
    const users = {
      [`${userId}`]: {
        id: userId,
        name: `${state.user.firstName} ${state.user.lastName}`,
        count: increment(1),
        lastSearch: new Date(),
      },
    };
    setDoc(
      exerciseSearchAnalyticsRef,
      {
        lastSearch: new Date(),
        popularity: increment(1),
        userIds: arrayUnion(userId),
        users,
      },
      { merge: true }
    );
  }

  if (!filters.searchPhrase && !filters.movementTags.length && !filters.muscleTags.length) {
    // SORT BY
    let firebaseOrderBy = orderBy('name', 'asc');
    if (sortBy === 'nameDesc') {
      firebaseOrderBy = orderBy('name', 'desc');
    }

    const queryConstraints: QueryConstraint[] = [firebaseOrderBy, limit(FETCH_LIMIT)];

    if (!admin) {
      queryConstraints.push(where('creatorIds', 'array-contains-any', creatorIds));
    } else {
      // Prevent admin from fetching deleted exercises
      queryConstraints.push(where('deleted', '==', false));
    }

    if (lastVisibleDoc) {
      queryConstraints.push(firestoreStartAt(lastVisibleDoc));
    }

    const q = query(collection(DB, 'exercises'), ...queryConstraints);

    const querySnapshot = await getDocs(q);
    // Get the last visible document
    lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const youtubeId = data?.youtubeId ? data.youtubeId : ''; // Some exercises don't have a youtubeId. Prevents this field from being undefined
      const { id } = doc;

      // Convert firebase data dates to JS dates
      convertFirebaseDataDates(data);

      const item = { ...data, youtubeId, id };
      exercises.push(item);
    });
  } else {
    // increment page
    page += 1;

    const searchParameters: SearchParams = {
      q: filters.searchPhrase ? filters.searchPhrase : '*',
      query_by: 'name',
      query_by_weights: '100',
      prioritize_exact_match: true,
      // prioritize_token_position: true,
      page: page,
      per_page: FETCH_LIMIT,
    };

    let filterBy = null;
    // Creator filters
    if (creatorIds.length) {
      const creatorFilter = `creatorIds:= [${creatorIds.join()}]`;
      filterBy = filterBy ? filterBy + ' && ' + creatorFilter : creatorFilter;
    }

    // Movement tags
    if (filters.movementTags.length) {
      const tagNames = filters.movementTags.map((tag) => tag.id);
      // splice off first tag
      const firstTag = tagNames.shift();
      let tagFilter = `movementTagIds:=${firstTag}`;
      tagNames.forEach((tag) => {
        tagFilter += ` && movementTagIds:=${tag}`;
      });

      filterBy = filterBy ? filterBy + ' && ' + tagFilter : tagFilter;
    }

    // Muscle tags
    if (filters.muscleTags.length) {
      const tagNames = filters.muscleTags.map((tag) => tag.id);
      // splice off first tag
      const firstTag = tagNames.shift();
      let tagFilter = `muscleTagIds:=${firstTag}`;
      tagNames.forEach((tag) => {
        tagFilter += ` && muscleTagIds:=${tag}`;
      });

      filterBy = filterBy ? filterBy + ' && ' + tagFilter : tagFilter;
    }

    if (filterBy) {
      searchParameters.filter_by = filterBy;
    }

    // Typesense search
    const client = useTypesense();

    const response = await client
      .collections<TypesenseExerciseCleanable>('exercises')
      .documents()
      .search(searchParameters);

    if (response?.hits && response.hits.length !== 0) {
      exercises.push(
        ...response.hits.map((hit) => {
          const item = hit.document;
          const youtubeId = item?.youtubeId ? item.youtubeId : ''; // Some exercises don't have a youtubeId. Prevents this field from being undefined
          // Remove Typesense only fields re: ExerciseTypesense
          delete item.dateCreatedUnix;
          delete item.lastUpdatedUnix;
          delete item.movementTagNames;
          delete item.muscleTagNames;
          convertTypesenseDataDates(item);

          return { ...item, youtubeId };
        })
      );
    }
  }
  return { exercises, lastVisibleDoc, page };
});

// Fetch a single exercise
export const fetchExercise = createAsyncThunk<Exercise_WithID, { exerciseId: string }>(
  'exercises/fetchExercise',
  async ({ exerciseId }) => {
    const docRef = doc(DB, 'exercises', exerciseId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const youtubeId = data?.youtubeId ? data.youtubeId : ''; // Some exercises don't have a youtubeId. Prevents this field from being undefined
      const { id } = docSnap;

      // Convert firebase data dates to JS dates
      convertFirebaseDataDates(data);

      return { ...data, youtubeId, id } as Exercise_WithID;
    } else {
      // doc.data() will be undefined in this case
      throw new Error('No such exercise! ' + exerciseId);
    }
  }
);

// Save exercise
export const saveExercise = createAsyncThunk<
  Exercise_WithID,
  { formData: ExerciseFormValuesProps; isEdit?: boolean }
>('exercises/saveExercise', async ({ formData, isEdit = undefined }, { getState }) => {
  const state = getState() as RootState;
  const exerciseId = formData.id;
  delete formData?.id;
  let exercise;

  // Update exercise
  if (isEdit && exerciseId) {
    const update: Partial<Exercise> = { ...formData, lastUpdated: new Date() };
    const updateRef = doc(DB, 'exercises', exerciseId);
    await updateDoc(updateRef, update);
    exercise = { ...state.exercises.entities[exerciseId], ...update } as Exercise_WithID;
  }
  // Add new exercise
  else {
    const creatorId = state.user.id;
    const createName = `${state.user.firstName} ${state.user.lastName}`;
    const creatorImageUrl = state.user?.profilePictureUrl ? state.user.profilePictureUrl : '';
    if (!creatorId) {
      throw new Error('User not logged in');
    }
    const creators: ExerciseCreator[] = [
      { id: creatorId, name: createName, imageUrl: creatorImageUrl },
    ];

    // Create analytics for new exercises
    logEvent(ANALYTICS, 'create_exercise', {
      exercise_name: formData.name,
    });

    const add: Exercise & { id?: string } = {
      ...formData,
      dateCreated: new Date(),
      lastUpdated: new Date(),
      creators,
      creatorIds: creators.map((creator) => creator.id),
      public: false,
      deleted: false,
    };
    const result = await addDoc(collection(DB, 'exercises'), add);
    exercise = { ...add, id: result.id } as Exercise_WithID;
  }

  return exercise;
});

// Delete exercise
export const deleteExercise = createAsyncThunk<Exercise_WithID, string>(
  'exercises/deleteExercise',
  async (exerciseId, { getState }) => {
    const state = getState() as RootState;

    const userId = state.user.id;

    const exercise = state.exercises.entities[exerciseId];
    if (!exercise) throw new Error(`No exercise with id ${exerciseId}`);

    const update: Partial<Exercise_WithID> = {
      deleted: true,
      // remove user from creators
      creatorIds: exercise.creatorIds.filter((id) => id !== userId),
      lastUpdated: new Date(),
    };

    const updateRef = doc(DB, 'exercises', exerciseId);
    await updateDoc(updateRef, update);

    return exercise;
  }
);

// ----------------------------------------------------------------------

// Increase exercise popularity
export const increasePopularityOfSelectedExercises = async ({
  exerciseIds,
}: {
  exerciseIds: string[];
}) => {
  const batch = writeBatch(DB);

  for (const exerciseId of exerciseIds) {
    const docRef = doc(DB, 'exercises', exerciseId);
    batch.update(docRef, { popularity: increment(1) });
  }

  await batch.commit();
};

// Remove all exercises
export const exercisesResetAction = createAction('exercises/exercisesReset');
// Reset to initial state
export const exercisesFullResetAction = createAction('exercises/exercisesFullReset');

export const slice = createSlice({
  name: 'exercises',
  initialState,
  reducers: {
    updateExercise: (state, action: PayloadAction<Update<Exercise_WithID>>) => {
      const update = action.payload;
      exerciseAdapter.updateOne(state, update);
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    //  SORT & FILTER PRODUCTS
    startSearch: (state) => {
      exerciseAdapter.removeAll(state);
      state.page = initialState.page;
      state.lastVisibleDoc = initialState.lastVisibleDoc;
      state.status = FETCH_STATUS_TYPES_ENUM.SEARCHING;
      state.error = null;
    },
    endSearch: (state, action) => {
      state.filters.searchPhrase = action.payload;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
    updateSearchPhrase: (state, action) => {
      state.filters.searchPhrase = action.payload;
    },
    resetSearchPhrase: (state) => {
      state.filters.searchPhrase = initialState.filters.searchPhrase;
      state.page = initialState.page;
      state.lastVisibleDoc = initialState.lastVisibleDoc;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
    resetStatus: (state) => {
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
    sortByExercises(state, action) {
      state.sortBy = action.payload;
      exerciseAdapter.removeAll(state);
      state.page = initialState.page;
      state.lastVisibleDoc = initialState.lastVisibleDoc;
      state.error = null;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
    filterExercises(state, action: PayloadAction<Partial<ExerciseFilterType>>) {
      if (action.payload?.movementTags) {
        state.filters.movementTags = action.payload.movementTags;
      }
      if (action.payload?.muscleTags) {
        state.filters.muscleTags = action.payload.muscleTags;
      }
      exerciseAdapter.removeAll(state);
      state.page = initialState.page;
      state.lastVisibleDoc = initialState.lastVisibleDoc;
      state.error = null;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
  },
  extraReducers(builder) {
    builder
      // Reset case
      .addCase(programResetAction, () => initialState)
      .addCase(exercisesFullResetAction, () => initialState)
      .addCase(exercisesResetAction, (state) => {
        exerciseAdapter.removeAll(state);
        state.page = initialState.page;
        state.lastVisibleDoc = initialState.lastVisibleDoc;
        state.error = null;
        state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
      })
      // Get Many Exercises
      .addCase(fetchExercises.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchExercises.fulfilled, (state, action) => {
        // Upsert all the added exercise metrics
        const items = action.payload.exercises;
        const { lastVisibleDoc, page } = action.payload;

        if (items && items.length !== 0) {
          exerciseAdapter.upsertMany(state, items);

          state.lastVisibleDoc = lastVisibleDoc;
          state.page = page;

          // Change status to succeeded
          if (items.length < FETCH_LIMIT) {
            state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
          } else {
            state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
          }
        } else {
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        }
      })
      .addCase(fetchExercises.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // Get Single Exercise
      .addCase(fetchExercise.pending, (state) => {
        state.fetchExerciseStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchExercise.fulfilled, (state, action) => {
        // Upsert all the added exercise metrics
        const item = action.payload;

        exerciseAdapter.upsertOne(state, item);
        // Change status to succeeded
        state.fetchExerciseStatus = FETCH_STATUS_TYPES_ENUM.COMPLETED;
      })
      .addCase(fetchExercise.rejected, (state, action) => {
        state.fetchExerciseStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.fetchExerciseError = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // Save Exercise
      .addCase(saveExercise.pending, (state) => {
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(saveExercise.fulfilled, (state, action) => {
        // Upsert the item
        const item = action.payload;

        exerciseAdapter.upsertOne(state, item);
        // Change status to succeeded
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.COMPLETED;
      })
      .addCase(saveExercise.rejected, (state, action) => {
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.saveError = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // Delete Exercise
      .addCase(deleteExercise.pending, (state) => {
        state.deleteStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(deleteExercise.fulfilled, (state, action) => {
        // Remove the item
        const item = action.payload;

        exerciseAdapter.removeOne(state, item.id);
        // Change status to succeeded
        state.deleteStatus = FETCH_STATUS_TYPES_ENUM.COMPLETED;
      })
      .addCase(deleteExercise.rejected, (state, action) => {
        state.deleteStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.deleteError = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      });
  },
});

export const {
  updateExercise,
  setError,
  startSearch,
  endSearch,
  updateSearchPhrase,
  resetSearchPhrase,
  resetStatus,
  sortByExercises,
  filterExercises,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

export const toggleExerciseGlobal =
  (exerciseId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const exercise = state.exercises.entities[exerciseId];

    if (exercise) {
      // New values
      const isPublic = !exercise.public;
      // Add PUBLIC to creatorIds if it is becoming public otherwise remove it
      const creatorIds = isPublic
        ? [...exercise.creatorIds, 'PUBLIC']
        : exercise.creatorIds.filter((id) => id !== 'PUBLIC');

      const updates = {
        public: isPublic,
        creatorIds,
      };

      const ref = doc(DB, 'exercises', exerciseId);
      try {
        await updateDoc(ref, updates);

        // Update the exercise in the store
        const update: Update<Exercise_WithID> = {
          id: exerciseId,
          changes: updates,
        };
        dispatch(updateExercise(update));
      } catch (error) {
        console.error(error);
        dispatch(setError(error.message));
      }
    }
  };
// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllExercises, selectById: selectExerciseById } =
  exerciseAdapter.getSelectors((state: RootState) => state.exercises);

export const getExercisesFetchStatus = (state: RootState) => state.exercises.status;
export const getExercisesSortBy = (state: RootState) => state.exercises.sortBy;
export const getExercisesFilters = (state: RootState) => state.exercises.filters;
export const getExercisesSearchPhrase = (state: RootState) => state.exercises.filters?.searchPhrase;
