import { Dialog, Grid, IconButton, Slide, Stack, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useState, memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormProvider } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import {
  fetchExercises,
  getExercisesFetchStatus,
  getExercisesFilters,
  selectAllExercises,
  filterExercises,
  exercisesFullResetAction,
} from 'src/redux/slices/exercises';
import {
  closeExerciseSwapModal,
  getExerciseSwapModal,
} from 'src/redux/slices/program/exerciseSwapModal';
import { useDispatch, useSelector } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import {
  ExerciseCard,
  ExerciseSearch,
  ExerciseFilterSidebar,
  ExerciseSort,
  ExerciseTagFiltered,
} from '.';
import { SkeletonExerciseCardList } from 'src/components/skeleton';
import _ from 'lodash';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import NewExerciseModal from './NewExerciseModal';
import useExerciseMetrics from 'src/hooks/useExerciseMetrics';
import { MovementTag_WithID, MuscleTag_WithID, Exercise_WithID } from 'src/@types/firebase';
import useExerciseDetailsModal from 'src/hooks/useExerciseDetailsModal';
import { swapWorkoutExercise } from 'src/redux/slices/program/workoutExercises';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ExerciseSwapModal() {
  const dispatch = useDispatch();
  const { height } = useWindowDimensions();
  useExerciseMetrics();
  const { openExerciseDetailsModal } = useExerciseDetailsModal();

  const exerciseSwapModal = useSelector(getExerciseSwapModal);
  const { visible, workoutExerciseId, workoutExerciseName } = exerciseSwapModal;
  const exercises = useSelector(selectAllExercises);
  const fetchStatus = useSelector(getExercisesFetchStatus);
  const filters = useSelector(getExercisesFilters);

  // const hasMore = fetchStatus !== FETCH_STATUS_TYPES_ENUM.COMPLETED;

  const [openFilter, setOpenFilter] = useState(false);
  const [openNewExerciseModal, setOpenNewExerciseModal] = useState(false);

  const hasMore = fetchStatus !== FETCH_STATUS_TYPES_ENUM.COMPLETED;

  const defaultValues = {
    muscleTags: [] as MuscleTag_WithID[],
    movementTags: [] as MovementTag_WithID[],
    searchPhrase: '',
  };

  const methods = useForm({
    defaultValues,
  });

  const { reset, watch, setValue } = methods;

  const values = watch();

  const isDefault =
    filters.muscleTags.length === 0 &&
    filters.movementTags.length === 0 &&
    filters.searchPhrase === '';

  useEffect(() => {
    if (visible && fetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      if (exercises.length) {
        dispatch(exercisesFullResetAction());
      }
      dispatch(fetchExercises({}));
    }
  }, [visible, fetchStatus, exercises.length, dispatch]);

  const fetchMore = () => {
    if (
      visible &&
      fetchStatus !== FETCH_STATUS_TYPES_ENUM.LOADING &&
      fetchStatus !== FETCH_STATUS_TYPES_ENUM.COMPLETED
    ) {
      dispatch(fetchExercises({}));
    }
  };
  // const fetchMore = () => {
  //   setLoadLimit(loadLimit + LIMIT);
  // };

  // Clean up redux state when component unmounts
  useEffect(() => {
    // Reset exercises when modal is opened
    reset();
    dispatch(exercisesFullResetAction());

    // Reset exercises when modal is closed
    const unsubscribe = () => {
      reset();
      dispatch(exercisesFullResetAction());
    };
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If there is a difference in the muscle tags or movement tags
    if (
      visible &&
      (!_.isEqual(filters.muscleTags, values.muscleTags) ||
        !_.isEqual(filters.movementTags, values.movementTags))
    ) {
      dispatch(filterExercises(values));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values, visible]);

  const swapExercise = useCallback(
    (exercise: Exercise_WithID) => {
      dispatch(swapWorkoutExercise({ workoutExerciseId, newExercise: exercise }));
      reset();
      dispatch(exercisesFullResetAction());
      dispatch(closeExerciseSwapModal());
    },
    [dispatch, reset, workoutExerciseId]
  );

  const handleNewExerciseSave = useCallback(
    (exercise: Exercise_WithID) => {
      swapExercise(exercise);
    },
    [swapExercise]
  );

  const handleOpenNewExerciseModal = () => {
    setOpenNewExerciseModal(true);
  };

  const handleCloseNewExerciseModal = () => {
    setOpenNewExerciseModal(false);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    reset();
    dispatch(exercisesFullResetAction());
    handleCloseFilter();
  };

  const handleRemoveMuscleTag = (id: string) => {
    const newValue = filters.muscleTags.filter((item) => item.id !== id);
    setValue('muscleTags', newValue);
  };

  const handleRemoveMovementTag = (id: string) => {
    const newValue = filters.movementTags.filter((item) => item.id !== id);
    setValue('movementTags', newValue);
  };

  const handleClose = useCallback(() => {
    // Clean up redux state when closing
    reset();
    dispatch(exercisesFullResetAction());
    dispatch(closeExerciseSwapModal());
  }, [reset, dispatch]);

  const handleInformationClick = (exerciseId: string) => {
    openExerciseDetailsModal(exerciseId);
  };

  const renderExercises = () => {
    if (
      fetchStatus === FETCH_STATUS_TYPES_ENUM.SEARCHING ||
      fetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE
    ) {
      // Array of size 12
      return <SkeletonExerciseCardList checkbox={false} length={12} />;
    }

    return exercises.map((exercise) => (
      <ExerciseCard
        key={exercise.id}
        exercise={exercise}
        onClick={() => swapExercise(exercise)}
        onInformationClick={() => handleInformationClick(exercise.id)}
      />
    ));
  };

  return (
    <Dialog
      keepMounted={false}
      fullScreen
      open={visible}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <IconButton
        onClick={handleClose}
        sx={{ zIndex: 9, position: 'absolute', top: 10, right: 10 }}
      >
        <Iconify
          icon={'fe:close'}
          color="text.primary"
          width={30}
          height={30}
          sx={{ opacity: 0.5, ':hover': { opacity: 1 } }}
        />
      </IconButton>

      <Grid container spacing={0} height="100%">
        <Grid item xs={12} sx={{ height: '100%', backgroundColor: 'background.default' }}>
          <Stack id="exercise-list" sx={{ height: '100%', overflow: 'scroll' }}>
            <Stack sx={{ p: 4, pr: 8 }}>
              <Stack direction="row">
                <Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
                  <Typography variant="h4">{'Swap an exercise with '}</Typography>
                  <Typography variant="h4" color="primary.main">
                    {workoutExerciseName}
                  </Typography>
                </Stack>

                <NewExerciseModal
                  isOpen={openNewExerciseModal}
                  onSave={handleNewExerciseSave}
                  onOpen={handleOpenNewExerciseModal}
                  onClose={handleCloseNewExerciseModal}
                />
              </Stack>
            </Stack>

            <Stack
              spacing={2}
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{ sm: 'center' }}
              justifyContent="space-between"
              sx={{ mb: 2, px: 4 }}
            >
              <ExerciseSearch />

              <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                <FormProvider methods={methods}>
                  <ExerciseFilterSidebar
                    onResetAll={handleResetFilter}
                    isOpen={openFilter}
                    onOpen={handleOpenFilter}
                    onClose={handleCloseFilter}
                  />
                </FormProvider>

                <ExerciseSort />
              </Stack>
            </Stack>

            <Stack sx={{ mb: 3, px: 4 }}>
              {!isDefault && (
                <>
                  {/* <Typography variant="body2" gutterBottom>
                    <strong>{filteredExercises.length}</strong>
                    &nbsp;Exercises found
                  </Typography> */}

                  <ExerciseTagFiltered
                    filters={filters}
                    isShowReset={!isDefault && !openFilter}
                    onRemoveMuscleTag={handleRemoveMuscleTag}
                    onRemoveMovementTag={handleRemoveMovementTag}
                    onResetAll={handleResetFilter}
                  />
                </>
              )}
            </Stack>

            <InfiniteScroll
              dataLength={exercises.length}
              next={fetchMore}
              hasMore={hasMore}
              scrollThreshold={height + 'px'}
              loader={
                <SkeletonExerciseCardList checkbox={false} length={exercises.length ? 5 : 20} />
              } // If first load show 20 otherwise show 5
              scrollableTarget="exercise-list"
            >
              <Stack spacing={0}>{renderExercises()}</Stack>
            </InfiniteScroll>
          </Stack>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default memo(ExerciseSwapModal);
