import { Stack } from '@mui/material';
import { memo } from 'react';
import Iconify from 'src/components/Iconify';
import Image from 'src/components/Image';

type Props = {
  imageUrl: string;
  selected: boolean;
  onSelect: () => void;
};

function ImageFormButton({ imageUrl, selected, onSelect }: Props) {
  return (
    <Stack onClick={onSelect} alignItems="center" position="relative" sx={{ cursor: 'pointer' }}>
      {/* Create an overlay */}
      {selected && (
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            minWidth: { xs: 100, sm: 150, md: 200 },
            height: { xs: 75, sm: 100, md: 150 },
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9,
            borderRadius: 2,
          }}
        >
          <Iconify
            icon="mdi:check-circle-outline"
            color="common.white"
            width={30}
            height={30}
            sx={{ position: 'absolute', top: 10, right: 10 }}
          />
        </Stack>
      )}
      <Image
        src={imageUrl}
        sx={{
          minWidth: { xs: 100, sm: 150, md: 200 },
          height: { xs: 75, sm: 100, md: 150 },
          borderRadius: 2,
        }}
      />
    </Stack>
  );
}

export default memo(ImageFormButton);
