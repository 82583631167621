import * as Yup from 'yup';
import { useCallback } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// assets
import { countries } from 'src/assets/data';
// components
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'notistack';
import { FormProvider, RHFSelect, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getUser, saveUser, saveUserImage } from 'src/redux/slices/user';
// utils
import Compressor from 'compressorjs';
import { UserAwards } from './details';

// ----------------------------------------------------------------------

type FormValuesProps = {
  firstName: string;
  lastName: string;
  image: CustomFile | null;
  phone?: string;
  country?: string;
  address?: string;
  state?: string;
  city?: string;
};

export default function AccountGeneral() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector(getUser);

  const UpdateUserSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
  });

  const defaultValues = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    image: ({ preview: user.profilePictureUrl } as CustomFile) || null,
    phone: user.phone || '',
    country: user?.country || '',
    address: user?.address || '',
    state: user?.state || '',
    city: user?.city || '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const { image, ...userData } = data;

      await dispatch(saveUser({ ...userData, id: user.id }));

      if (image?.name) {
        await dispatch(
          saveUserImage({
            userId: user.id,
            file: image,
          })
        );
      }

      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        new Compressor(file, {
          quality: 0.7,
          maxWidth: 300,
          maxHeight: 300,
          success: (result: any) => {
            // If size is greater than 300kb (300 * 1024) set error for file field
            if (result.size > 300 * 1024) {
              const fileSizeKb = Math.round(result.size / 1024);
              setError('image', {
                type: 'maxSize',
                message: `The size of your image once compressed (${fileSizeKb}kb / 300kb) was too big. Try reducing the size of your image before uploading again.`,
              });
            } else if (result) {
              const preview = URL.createObjectURL(result);
              setValue(
                'image',
                Object.assign(result, {
                  preview: preview,
                })
              );
            }
          },
        });
      }
    },
    [setValue, setError]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            <RHFUploadAvatar
              name="image"
              accept={{ 'image/jpeg': [] }}
              maxSize={5242880} // 5MB
              onDrop={handleDrop}
              // helperText={
              //   <Typography
              //     variant="caption"
              //     sx={{
              //       mt: 2,
              //       mx: 'auto',
              //       display: 'block',
              //       textAlign: 'center',
              //       color: 'text.secondary',
              //     }}
              //   >
              //     Allowed *.jpeg, *.jpg, *.png, *.gif
              //     <br /> max size of {fData(3145728)}
              //   </Typography>
              // }
            />
            <UserAwards />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="firstName" label="First Name" />
              <RHFTextField name="lastName" label="Last Name" />

              <RHFTextField name="phone" label="Phone Number" />

              <RHFTextField name="address" label="Address" />

              <RHFSelect name="country" label="Country" placeholder="Country">
                <option value="" />
                {countries.map((option) => (
                  <option key={option.code} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </RHFSelect>

              <RHFTextField name="state" label="State/Region" />

              <RHFTextField name="city" label="City" />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
