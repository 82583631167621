import { Stack, Card, Typography } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { shallowEqual } from 'react-redux';
import { WorkoutDragItem as WorkoutDragItemType } from 'src/@types/program';
import {
  isExerciseGroupCollapsed,
  toggleCollapseExerciseGroup,
} from 'src/redux/slices/program/collapsedItems';
import {
  duplicateExerciseGroup,
  removeExerciseGroup,
  selectExerciseGroupById,
  updateWorkoutExerciseGroup,
} from 'src/redux/slices/program/workoutExerciseGroups';
import { selectWorkoutExerciseGroupLength } from 'src/redux/slices/program/workoutExercises';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { COPY_ITEM_ENUM, WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';
import { TextField, CollapseButton, WorkoutExerciseGroupMenu } from '.';
import { openCopyToModal } from 'src/redux/slices/program/copyToModal';
import { getProgramDragItemId } from 'src/redux/slices/program/program';

// -------------------------------------------------------------

const NumberBadge = ({ number }: { number: number }) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{
      position: 'absolute',
      top: -15,
      right: 20,
      backgroundColor: 'primary.main',
      width: 20,
      height: 20,
      borderRadius: '50%',
      padding: 2,
    }}
  >
    <Typography variant="body2" color="white">
      {number}
    </Typography>
  </Stack>
);

// -------------------------------------------------------------

type Props = {
  workoutDragItem: WorkoutDragItemType;
};

function WorkoutExerciseGroup({ workoutDragItem }: Props) {
  const dispatch = useDispatch();
  const { id, type, dragIndex, groupId } = workoutDragItem;
  // const { name, cover, /*position*/ follower, totalPost, avatarUrl, following } = user;

  const dragItemId = useSelector(getProgramDragItemId);
  const workoutExerciseGroup = useSelector((state: RootState) =>
    selectExerciseGroupById(state, id)
  );
  const numberOfExercisesInGroup = useSelector(
    (state: RootState) => selectWorkoutExerciseGroupLength(state, groupId),
    shallowEqual
  );
  const collapsed = useSelector((state: RootState) => isExerciseGroupCollapsed(state, id));

  const [hover, setHover] = useState(false);

  // const inGroup = numberOfExercisesInGroup > 1;
  const isGroupBeingDragged = !!dragItemId && dragItemId === groupId;
  const isItemBeingDragged = isGroupBeingDragged && type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER;
  const isCurrenItemAFooterAndAssociatedHeaderBeingDragged =
    isGroupBeingDragged && type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER;
  const defaultName = numberOfExercisesInGroup > 2 ? 'Circuit' : 'Superset';
  const exerciseGroupName = workoutExerciseGroup?.name ? workoutExerciseGroup.name : defaultName;

  const commonStyles = {
    pl: 2,
    pr: 1,
    py: 1,
    borderLeft: 3,
    borderColor: 'primary.dark',

    // borderColor: inGroup ? 'primary.dark' : 'primary.main',
  };
  const headerStyles = {
    ...commonStyles,
    mt: dragIndex === 0 ? 0 : 2,
    borderBottomLeftRadius: isItemBeingDragged ? 15 : 0,
    borderBottomRightRadius: isItemBeingDragged ? 15 : 0,
    // overflow: isItemBeingDragged ? 'visible' : 'hidden',
    overflow: 'visible',
  };
  const footerStyles = {
    ...commonStyles,
    mb: 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    opacity: isCurrenItemAFooterAndAssociatedHeaderBeingDragged ? 0.4 : 1,
  };

  const toggleCollapsed = useCallback(
    () => dispatch(toggleCollapseExerciseGroup(id)),
    [dispatch, id]
  );

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateWorkoutExerciseGroup({ id, updates: { name: e.target.value } }));
    },
    [dispatch, id]
  );

  const handleDuplicateExerciseGroup = useCallback(() => {
    dispatch(duplicateExerciseGroup({ exerciseGroupId: id }));
  }, [dispatch, id]);

  const handleExerciseGroupCopyTo = useCallback(
    () =>
      dispatch(
        openCopyToModal({
          visible: true,
          copyItemId: id,
          copyItemType: COPY_ITEM_ENUM.GROUP,
          copyItemName: exerciseGroupName,
        })
      ),
    [dispatch, id, exerciseGroupName]
  );

  const handleRemoveExerciseGroup = useCallback(() => {
    dispatch(removeExerciseGroup({ exerciseGroupId: id }));
  }, [dispatch, id]);

  const renderGroup = () => (
    <Card
      sx={type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ? headerStyles : footerStyles}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER && (
        <>
          {isItemBeingDragged && <NumberBadge number={numberOfExercisesInGroup} />}
          <Stack direction="row" alignItems="center" sx={{ pl: 2 }}>
            <TextField
              font="body2"
              fullWidth
              value={workoutExerciseGroup?.name || defaultName}
              onChange={handleNameChange}
            />
            <CollapseButton
              active={hover}
              collapsed={collapsed}
              toggleCollapsed={toggleCollapsed}
            />
            <WorkoutExerciseGroupMenu
              active={hover}
              handleDuplicateExerciseGroup={handleDuplicateExerciseGroup}
              handleExerciseGroupCopyTo={handleExerciseGroupCopyTo}
              handleRemoveExerciseGroup={handleRemoveExerciseGroup}
            />
          </Stack>
        </>
      )}
    </Card>
  );

  return (
    <Draggable
      key={id}
      draggableId={id}
      index={dragIndex}
      isDragDisabled={type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ? false : true}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {renderGroup()}
        </div>
      )}
    </Draggable>
  );
}

export default memo(WorkoutExerciseGroup);
