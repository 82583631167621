import { Stack, TextField as MTextField, TextFieldProps, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';
import { memo, useCallback, useState } from 'react';

type Props = TextFieldProps & {
  font?: Variant;
  fontColor?: string;
  collapsed?: boolean;
};

const StyledTextField = styled(MTextField)(({ theme }) => ({
  '.MuiInput-underline:before': {
    borderBottom: `0px solid ${theme.palette.text.secondary}`,
  },
  '&& .MuiInput-underline:hover:before': {
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
  },
}));

function TextField(props: Props) {
  const { font = 'caption', fontColor, collapsed = false, multiline, sx, ...rest } = props;
  const value = props.value as string;
  const placeholder = props?.placeholder as string;

  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  const showInput = hover || focus;

  const handleMouseEnter = useCallback(() => setHover(true), []);
  const handleMouseLeave = useCallback(() => setHover(false), []);

  const handleBlur = useCallback(() => {
    setFocus(false);
    setExpanded(false);
  }, []);

  // Prevents week button from being clicked when clicking on the text field
  // const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  // }, []);

  const handleFocus = useCallback(() => {
    setFocus(true);
    if (multiline && collapsed) {
      setExpanded(true);
    }
  }, [multiline, collapsed]);

  return (
    <Stack
      sx={{ flex: 1, overflow: 'hidden' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showInput ? (
        <StyledTextField
          {...rest}
          variant="standard"
          // onClick={handleClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoFocus={expanded}
          multiline={collapsed ? expanded : multiline}
          inputProps={{
            style: {
              fontSize: theme.typography[font].fontSize,
              fontWeight: theme.typography[font].fontWeight,
              lineHeight: 1.5,
            },
          }}
          sx={{ ...sx, flex: 1, input: { color: fontColor } }}
        />
      ) : (
        <Typography
          variant={font}
          style={{ whiteSpace: collapsed ? 'nowrap' : 'pre-wrap' }}
          color={value ? fontColor : 'text.disabled'}
          sx={{
            ...sx,
            // fontSize: 12,
            flex: 1,
            pb: 0.5,
            borderBottom: 1,
            borderColor: 'divider',
            lineHeight: 1.5,
          }}
        >
          {value ? value : placeholder}
        </Typography>
      )}
    </Stack>
  );
  // return (
  //   <StyledTextField
  //     {...rest}
  //     variant="standard"
  //     onClick={handleClick}
  //     onFocus={handleFocus}
  //     onBlur={() => setExpanded(false)}
  //     autoFocus={expanded}
  //     multiline={collapsed ? expanded : multiline}
  //     inputProps={{
  //       style: {
  //         fontSize: theme.typography[font].fontSize,
  //         fontWeight: theme.typography[font].fontWeight,
  //       },
  //     }}
  //     sx={{ ...sx, input: { color: fontColor } }}
  //   />
  // );
}

export default memo(TextField);
