import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { isProgramCollapsed, toggleCollapseAll } from 'src/redux/slices/program/collapsedItems';
import { getProgram, saveProgram } from 'src/redux/slices/program/program';
import { selectAllProgramChangeTackerItems } from 'src/redux/slices/program/programChangeTracker';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';

// @mui
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import SvgIconStyle from 'src/components/SvgIconStyle';
import useProgramClientModal from 'src/hooks/useProgramClientModal';

//

// ----------------------------------------------------------------------

function ProgramHeaderOptions() {
  const dispatch = useDispatch();

  const program = useSelector(getProgram);
  const { saveStatus } = program;
  // const programId = program.id;
  // const published = program.published;
  const { openProgramClientModal } = useProgramClientModal();
  const collapsed = useSelector(isProgramCollapsed);
  const programChanges = useSelector(selectAllProgramChangeTackerItems);

  const toggleCollapsed = useCallback(() => dispatch(toggleCollapseAll()), [dispatch]);
  // const togglePublished = useCallback(
  //   () =>
  //     dispatch(
  //       updateProgramAction({
  //         id: programId,
  //         updates: { published: !published },
  //       })
  //     ),
  //   [dispatch, programId, published]
  // );

  const handleAssign = () => {
    openProgramClientModal(program.id);
  };

  const handleSave = useCallback(async () => {
    // Create a copy of the changes so they aren't lost before the save is complete
    const changes = [...programChanges];
    // Start saving
    dispatch(saveProgram({ program, isEdit: true, programChanges: changes }));
    // End saving
  }, [dispatch, program, programChanges]);

  const renderSaveButton = () => {
    let buttonText = 'Saved';
    let buttonColor = 'text.secondary';
    let button = (
      <SvgIconStyle
        src={`/icons/ic_circle_check_fill.svg`}
        sx={{ width: 23, height: 23, my: 0.9, color: 'text.secondary' }}
      />
    );

    if (saveStatus === FETCH_STATUS_TYPES_ENUM.LOADING) {
      buttonText = 'Saving...';
      buttonColor = 'primary.main';
      button = <CircularProgress size={25} sx={{ my: 0.8 }} />;
    } else if (programChanges.length > 0) {
      buttonText = 'Unsaved Changes';
      buttonColor = 'error.main';
      button = (
        <IconButton sx={{ m: 0, p: 0.5 }}>
          <Iconify icon={'ic:baseline-save'} color={'error.main'} width={30} height={30} />
        </IconButton>
      );
    }

    return (
      <Stack alignItems="center" onClick={handleSave} sx={{ cursor: 'pointer' }}>
        {button}
        <Typography variant="caption" color={buttonColor}>
          {buttonText}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Stack alignItems="center" onClick={toggleCollapsed} sx={{ cursor: 'pointer' }}>
        <IconButton sx={{ m: 0, p: 0.5 }}>
          <Iconify
            icon={collapsed ? 'eva:expand-fill' : 'eva:collapse-fill'}
            color={'text.secondary'}
            width={30}
            height={30}
            sx={{ opacity: 0.5, ':hover': { opacity: 1 } }}
          />
        </IconButton>
        <Typography variant="caption" color="text.secondary">
          {collapsed ? 'Expand All' : 'Collapse All'}
        </Typography>
      </Stack>

      <Stack alignItems="center" onClick={handleAssign} sx={{ cursor: 'pointer' }}>
        <IconButton sx={{ m: 0, p: 0.5 }}>
          <Iconify
            icon="ic:baseline-person-add"
            color={'text.secondary'}
            width={30}
            height={30}
            sx={{ opacity: 0.5, ':hover': { opacity: 1 } }}
          />
        </IconButton>
        <Typography variant="caption" color="text.secondary">
          Assign Clients
        </Typography>
      </Stack>

      {/* <Stack alignItems="center">
        <Switch
          aria-label="Publish to client switch"
          checked={published}
          onChange={togglePublished}
        />
        <Typography variant="caption" color="text.secondary">
          Publish
        </Typography>
      </Stack> */}
      {renderSaveButton()}
    </>
  );
}

export default memo(ProgramHeaderOptions);
