import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { Notification_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { AppDispatch, RootState } from '../store';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';

const notificationsAdapter = createEntityAdapter<Notification_WithID>({
  // Sort by dateCreated
  sortComparer: (a: Notification_WithID, b: Notification_WithID) =>
    b.dateCreated.getTime() - a.dateCreated.getTime(),
});

const initialState = notificationsAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  listener: null,
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null; listener: null | Unsubscribe });

export const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifcations: (
      state,
      action: PayloadAction<{ upsertItems: Notification_WithID[]; removeItems: string[] }>
    ) => {
      notificationsAdapter.upsertMany(state, action.payload.upsertItems);
      notificationsAdapter.removeMany(state, action.payload.removeItems);
    },
    // --------------------------------------------------
    // Listener commands start
    // --------------------------------------------------
    fetchConnectionsRequestsLoading(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
    },
    fetchConnectionsRequestsSuccess(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      state.listener = action.payload;
    },
    fetchConnectionsRequestsFailure(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.error = action.payload;
      console.error(action.payload);
    },
    stopListener: (state) => {
      if (state.listener) {
        state.listener();
      }
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    // --------------------------------------------------
    reset: (state) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    },
  },
  extraReducers(builder) {},
});

export const {
  setNotifcations,
  fetchConnectionsRequestsLoading,
  fetchConnectionsRequestsSuccess,
  fetchConnectionsRequestsFailure,
  setError,
  reset,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------
export const startNotificationsListener =
  (coachId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { listener } = state.notifications;

    if (listener) {
      console.error('Notifications listener already exists');
      return;
    }

    const userId = coachId;
    dispatch(fetchConnectionsRequestsLoading());
    try {
      // where user is the coach and seen is false. MAX 20. Sort by most recent dateCreated
      const q = query(
        collection(DB, 'notifications'),
        where('userId', '==', userId),
        // where dateCreated is less than 90 days ago
        where('dateCreated', '>=', new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000)),
        // where('seen', '==', false),
        orderBy('dateCreated', 'desc'),
        limit(20)
      );
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const upsertItems: Notification_WithID[] = [];
          const removeItems: string[] = [];

          querySnapshot.docChanges().forEach((change) => {
            const { id } = change.doc;
            const data = change.doc.data();
            convertFirebaseDataDates(data);

            const item = { ...data, id } as Notification_WithID;

            if (change.type === 'added' || change.type === 'modified') {
              upsertItems.push(item);
            }
            if (change.type === 'removed') {
              removeItems.push(id);
            }
          });

          dispatch(setNotifcations({ upsertItems, removeItems }));
        },
        (error) => {
          console.error(error);
          dispatch(setError(error.message));
        }
      );
      dispatch(fetchConnectionsRequestsSuccess(unsubscribe));
    } catch (error) {
      dispatch(fetchConnectionsRequestsFailure(error.message));
    }
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllNotifications,
  selectById: selectNotificationById,
  // Pass in a selector that returns the posts slice of state
} = notificationsAdapter.getSelectors((state: RootState) => state.notifications);

export const getNotifcationsFetchStatus = (state: RootState) => state.notifications.status;
export const getNotifcationsFetchError = (state: RootState) => state.notifications.error;
