import { WorkoutDragItem, WorkoutExercise } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  movedItem: WorkoutDragItem;
  destinationItem: WorkoutDragItem;
  movedDown: boolean;
  movedUp: boolean;
};

// Moving an item within a group of two or more exercises
const handleMoveExerciseWithinGroup = ({
  state,
  movedItem,
  destinationItem,
  movedDown,
  movedUp,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [
    { id: movedItem.workoutId, changes: {} },
    { id: destinationItem.workoutId, changes: {} },
  ];
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutDragItems = {};
  changes.workoutExercises = {};

  const from = movedItem.dragIndex;
  let to = destinationItem.dragIndex;

  // Change the to destination if combining with group header and moving down
  // Or if combining with group footer and moving up
  if (movedUp && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER) {
    to -= 1;
  }
  if (movedDown && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
    to += 1;
  }

  // Update movedItem index
  const movedDragItemChanges: Partial<WorkoutDragItem> = {
    index: destinationItem.index,
    dragIndex: to,
  };
  const movedExerciseChanges: Partial<WorkoutExercise> = {
    index: destinationItem.index,
  };

  // If movedItem was last in group
  // Update movedItem to not be last in group
  if (movedItem.lastInGroup) {
    movedDragItemChanges.lastInGroup = false;
  }

  // If destination item was last in group
  // Update movedItem to be last in group
  if (destinationItem.lastInGroup) {
    movedDragItemChanges.lastInGroup = true;
  }

  // Push the changes
  changes.workoutDragItems.updated = [{ id: movedItem.id, changes: movedDragItemChanges }];
  changes.workoutExercises.updated = [{ id: movedItem.id, changes: movedExerciseChanges }];

  // Update the index for any item between the moved item and the destination
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      // If the item is undefined or the index is equal to the moved week then skip
      if (!item || item.workoutId !== movedItem.workoutId || item.groupId !== movedItem.groupId) {
        return false;
      }

      // If moved down and the index is lesser than from and greater than or equal to to
      if (movedDown && item.dragIndex < from && item.dragIndex >= to) {
        return true;
      }
      // If moved up and the index is less than the from index and greater than the to index
      else if (movedUp && item.dragIndex > from && item.dragIndex <= to) {
        return true;
      }

      return false;
    }
  );

  const workoutDragItemUpdates: UpdateType[] = [];
  const workoutExerciseUpdates: UpdateType[] = [];
  // Update the affected items
  affectedItems.forEach((item) => {
    const index = item.index + (movedDown ? 1 : -1);
    const dragIndex = item.dragIndex + (movedDown ? 1 : -1);

    const dragItemChanges: Partial<WorkoutDragItem> = {
      index,
      dragIndex,
    };
    const exerciseChanges: Partial<WorkoutExercise> = {
      index,
    };

    // If moved item was last in group
    // Update the new last item in group to be last in group
    if (movedItem.lastInGroup && item.dragIndex === movedItem.dragIndex - 1) {
      dragItemChanges.lastInGroup = true;
    }

    // If destination item was last in group
    // Update the destination item to not be last in group
    if (destinationItem.lastInGroup && item.dragIndex === destinationItem.dragIndex) {
      dragItemChanges.lastInGroup = false;
    }

    workoutDragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });
    workoutExerciseUpdates.push({
      id: item.id,
      changes: exerciseChanges,
    });
  });

  changes.workoutDragItems.updated.push(...workoutDragItemUpdates);
  changes.workoutExercises.updated.push(...workoutExerciseUpdates);

  return changes;
};

export default handleMoveExerciseWithinGroup;
