// @mui
import { InputAdornment, TextField } from '@mui/material';
// hooks
// components
import Iconify from '../../../components/Iconify';
import {
  endSearch,
  getExercisesFetchStatus,
  getExercisesSearchPhrase,
  startSearch,
} from 'src/redux/slices/exercises';
import { useDispatch, useSelector } from 'src/redux/store';
import { useCallback, useEffect, useState } from 'react';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';

// ----------------------------------------------------------------------

export default function ExerciseSearch() {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const searchPhrase = useSelector(getExercisesSearchPhrase);
  const status = useSelector(getExercisesFetchStatus);

  // Delay sending the search until the user has stopped typing
  useEffect(() => {
    if (status === FETCH_STATUS_TYPES_ENUM.SEARCHING) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(endSearch(value));
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [value, status, dispatch]);

  // Update the search phrase if changed elsewhere
  useEffect(() => {
    if (searchPhrase !== value && searchPhrase !== undefined) {
      setValue(searchPhrase);
    }
    // Ignoring because we only want to check when searchPhrase changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);

      if (status !== FETCH_STATUS_TYPES_ENUM.SEARCHING) {
        dispatch(startSearch());
      }
    },
    [status, dispatch]
  );

  return (
    <TextField
      placeholder="Search exercises"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify
              icon={'eva:search-fill'}
              sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
            />
          </InputAdornment>
        ),
      }}
      onChange={handleOnChange}
      value={value}
      variant="outlined"
    />
  );
}
