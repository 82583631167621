import { Card, Chip, Link, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import YouTube from 'react-youtube';
import { Exercise_WithID } from 'src/@types/firebase';

type Props = {
  exercise: Exercise_WithID;
};

function ExerciseDetails({ exercise }: Props) {
  const videoOptions: any = {
    height: '390',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <Card>
      <YouTube opts={videoOptions} videoId={exercise.youtubeId} />

      <Stack sx={{ p: 4 }} spacing={2}>
        <Typography variant="caption">
          Youtube url: <Link>{`https://www.youtube.com/watch?v=${exercise.youtubeId}`}</Link>
        </Typography>
        <Typography variant="h4">{exercise.name}</Typography>

        <Stack spacing={0.5}>
          <Typography variant="caption" color="text.disabled">
            Description
          </Typography>
          <Typography variant="body2">
            {exercise?.description ? exercise.description : 'n/a'}
          </Typography>
        </Stack>

        <Stack spacing={0.5}>
          <Typography variant="caption" color="text.disabled">
            Movements
          </Typography>
          <Stack direction="row" spacing={1}>
            {exercise.movementTags.map((tag) => (
              <Chip key={tag.id} label={tag.name} />
            ))}
          </Stack>
        </Stack>

        <Stack spacing={0.5}>
          <Typography variant="caption" color="text.disabled">
            Muscles
          </Typography>
          <Stack direction="row" spacing={1}>
            {exercise.muscleTags.map((tag) => (
              <Chip key={tag.id} label={tag.name} />
            ))}
          </Stack>
        </Stack>

        <Stack spacing={0.5}>
          <Typography variant="caption" color="text.disabled">
            Default Metrics
          </Typography>
          <Stack direction="row" spacing={1}>
            {exercise.defaultExerciseMetrics.map((tag) => (
              <Chip key={tag.id} label={tag.name} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

export default memo(ExerciseDetails);
