import { IconButton, Stack } from '@mui/material';
import ExerciseMetricIcon from './ExerciseMetricIcon';
import ExerciseMetricInput from './ExerciseMetricInput';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'src/redux/store';
import { toggleSets, updateSets } from 'src/redux/slices/program/workoutExercises';

type Props = {
  id: string;
  sets: number;
  collapsed: boolean;
};

function SetsInput({ id, sets, collapsed }: Props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [setsValue, setSetsValue] = useState(sets.toString());

  const handleDecrement = useCallback(() => {
    dispatch(updateSets({ workoutExerciseId: id, sets: (Number(sets) - 1).toString() }));
  }, [dispatch, id, sets]);

  const handleIncrement = useCallback(() => {
    dispatch(updateSets({ workoutExerciseId: id, sets: (Number(sets) + 1).toString() }));
  }, [dispatch, id, sets]);

  const handleSetsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;
      setSetsValue(value);
      dispatch(updateSets({ workoutExerciseId: id, sets: value }));
    },
    [dispatch, id, setSetsValue]
  );

  const handleRemoveSets = useCallback(() => {
    dispatch(toggleSets(id));
  }, [dispatch, id]);

  useEffect(() => {
    // Convert local version of sets to int
    const setsValueNumber = parseInt(setsValue, 10);
    // If the sets value is a number and is not the same as the current sets value
    if (!isNaN(setsValueNumber) && setsValueNumber !== sets) {
      setSetsValue(sets.toString());
    }
  }, [sets, setsValue]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
      sx={{
        pr: collapsed ? 0 : 2,
        pl: 2,
        pb: 0.5,
        ':hover': {
          backgroundColor: 'background.neutral',
        },
      }}
    >
      <Stack direction="row" flex={1} spacing={0}>
        <ExerciseMetricIcon exerciseMetricName={'Sets'} collapsed={collapsed} />
        <Stack direction="row" spacing={0}>
          {!collapsed && (
            <IconButton onClick={handleDecrement} tabIndex={-1} sx={{ m: 0, p: 0 }}>
              <SvgIconStyle
                src={`/icons/ic_minus.svg`}
                sx={{ width: 16, height: 16, color: 'text.secondary' }}
              />
            </IconButton>
          )}
          <ExerciseMetricInput
            onChange={handleSetsChange}
            workoutExerciseId={id}
            value={setsValue}
          />
          {!collapsed && (
            <IconButton onClick={handleIncrement} tabIndex={-1} sx={{ m: 0, p: 0.5 }}>
              <SvgIconStyle
                src={`/icons/ic_plus.svg`}
                sx={{ width: 16, height: 16, color: 'text.secondary' }}
              />
            </IconButton>
          )}
        </Stack>
      </Stack>

      {!collapsed && (
        <IconButton onClick={handleRemoveSets} tabIndex={-1} sx={{ m: 0, p: 0.2 }}>
          <SvgIconStyle
            src={show ? `/icons/ic_trash.svg` : ''}
            sx={{
              width: 16,
              height: 16,
              color: 'text.secondary',
              ':hover': {
                backgroundColor: 'text.primary',
              },
            }}
          />
        </IconButton>
      )}
    </Stack>
  );
}

export default memo(SetsInput);
