import { WorkoutDragItem, WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  movedItem: WorkoutDragItem;
  destinationItem: WorkoutDragItem;
  movedDown: boolean;
  movedUp: boolean;
};

// If solo exercise was moved to an existing group
const handleMoveGroupToExistingGroup = ({
  state,
  movedItem,
  destinationItem,
  movedDown,
  movedUp,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [
    { id: movedItem.workoutId, changes: {} },
    { id: destinationItem.workoutId, changes: {} },
  ];
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutDragItems = {};
  changes.workoutExercises = {};

  // Find the exercise ids belonging to the moved group
  const exerciseIds = Object.values(state.workoutExercises.entities)
    .filter(
      (exercise): exercise is WorkoutExercise =>
        !!exercise && exercise.workoutExerciseGroupId === movedItem.groupId
    )
    .map((exercise) => exercise.id);
  const numberOfNewExercises = exerciseIds.length;

  const destinationGroupIndex =
    (destinationItem?.groupIndex !== undefined
      ? destinationItem.groupIndex
      : destinationItem.index) - (movedUp ? 1 : 0);

  const from = movedItem.dragIndex;
  let to = destinationItem.dragIndex;

  // Change the to destination if combining with group header and moving down
  // Or if combining with group footer and moving up
  if (movedUp && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER) {
    to -= 1;
  }
  if (movedDown && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
    to += 1;
  }

  let newDragIndex = to + (movedUp ? -2 : 0); // -2 to account for the removed header + footer

  // Update newDragIndex
  newDragIndex = newDragIndex + (movedUp ? -numberOfNewExercises : 0);

  const exerciseAboveDestinationDragItem = Object.values(state.workoutDragItems.entities).find(
    (item) =>
      !!item &&
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.workoutId === destinationItem.workoutId &&
      item.groupId === destinationItem.groupId &&
      item.dragIndex === (movedDown ? to - 1 : to)
  );

  // If there is no above exercise it must have been placed at the top of the group
  const newIndex = exerciseAboveDestinationDragItem
    ? exerciseAboveDestinationDragItem.index + 1
    : 0;

  // Update moved drag items
  const movedExerciseDragItemChanges: UpdateType[] = exerciseIds.map((exerciseId, i) => {
    const changes: Partial<WorkoutDragItem> = {
      index: newIndex + i,
      groupId: destinationItem.groupId,
      groupIndex: destinationGroupIndex,
      dragIndex: newDragIndex + i,
    };

    // If the exercise above our drag destination wasn't the last in the group
    // All of the exercises in the group we dragged will not be last in group
    if (!exerciseAboveDestinationDragItem?.lastInGroup) {
      changes.lastInGroup = false;
    }
    // If destination item was last in group
    // Update movedItem to be last in group
    if (exerciseAboveDestinationDragItem?.lastInGroup && i === exerciseIds.length - 1) {
      changes.lastInGroup = true;
    }

    return {
      id: exerciseId,
      changes: changes,
    };
  });

  // Update moved exercises
  const movedExercisesChanges: UpdateType[] = exerciseIds.map((exerciseId, i) => {
    const changes: Partial<WorkoutExercise> = {
      index: newIndex + i,
      workoutExerciseGroupId: destinationItem.groupId,
    };

    return {
      id: exerciseId,
      changes: changes,
    };
  });

  // Remove movedItem group header and footer
  const groupHeaderKey = movedItem.groupId;
  const groupFooterKey = `${movedItem.groupId}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`;

  // Remove movedItem group
  const removedGroupKey = movedItem.groupId;

  // Remove exercise options
  const exerciseOptionsKey = `${movedItem.groupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;

  // Push the changes
  changes.workoutDragItems.updated = [...movedExerciseDragItemChanges];
  changes.workoutExercises.updated = [...movedExercisesChanges];
  changes.workoutDragItems.removed = [groupHeaderKey, groupFooterKey, exerciseOptionsKey];
  changes.workoutExerciseGroups.removed = [removedGroupKey];

  // Find all the affected drag items
  // If between to and from
  // If moved item group index > item group index
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      // If the item is undefined or the workout id isn't the same then skip
      if (!item || item.workoutId !== movedItem.workoutId) {
        return false;
      }

      // Exclude items from the moved group
      if (item.groupId === movedItem.groupId) {
        return false;
      }

      // Include exercise above the destination item if that item was last in group
      if (
        exerciseAboveDestinationDragItem?.lastInGroup &&
        item.id === exerciseAboveDestinationDragItem?.id
      ) {
        return true;
      }

      // Find all items below the destination item
      if (movedDown && item.dragIndex >= to) {
        return true;
      }
      // Find all items below the moved item
      else if (movedUp && item.dragIndex > from) {
        return true;
      }

      return false;
    }
  );

  const workoutExerciseGroupUpdates: UpdateType[] = [];
  const workoutDragItemUpdates: UpdateType[] = [];
  const workoutExerciseUpdates: UpdateType[] = [];
  // Update the affected items
  affectedItems.forEach((item) => {
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseChanges: Partial<WorkoutExercise> = {};

    // If dragIndex is greater than the removed exercise options (movedItem.dragIndex + 1)
    if (item.dragIndex > movedItem.dragIndex + numberOfNewExercises + 1) {
      dragItemChanges.dragIndex = item.dragIndex - 3; // -3 to account for the removed exercise options and header + footer
    }

    // If moved down and the index is lesser than from and greater than or equal to to
    if (movedDown && item.dragIndex < from && item.dragIndex >= to) {
      dragItemChanges.dragIndex =
        (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) +
        numberOfNewExercises;
    }
    // If moved up and the index is less than the from index and greater than the to index
    else if (movedUp && item.dragIndex > from && item.dragIndex <= to) {
      dragItemChanges.dragIndex =
        (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) -
        numberOfNewExercises;
    }

    // Find exercises in the destination group below the moved item
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.groupId === destinationItem.groupId &&
      ((movedDown && item.dragIndex >= to) || (movedUp && item.dragIndex > to))
    ) {
      dragItemChanges.index = item.index + numberOfNewExercises;
      exerciseChanges.index = item.index + numberOfNewExercises;
    }

    // Update any groups below the removed moved item group
    const itemGroupIndex = item.groupIndex !== undefined ? item.groupIndex : item.index;
    if (itemGroupIndex > movedItem.index) {
      // Update group indexes
      if (item.groupIndex !== undefined) {
        dragItemChanges.groupIndex = item.groupIndex - 1;
      }
      // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
      if (
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
      ) {
        dragItemChanges.index = item.index - 1;
      }

      // If the item is a group header or a solo exercise
      // Update the exercise group index
      if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
        exerciseGroupChanges.index = item.index - 1;
      } else if (
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
        !item.inGroup &&
        item.groupIndex !== undefined
      ) {
        exerciseGroupChanges.index = item.groupIndex - 1;
      }
    }

    // If above item was last in group
    // Update the item to not be last in group
    if (
      exerciseAboveDestinationDragItem?.lastInGroup &&
      item.id === exerciseAboveDestinationDragItem?.id
    ) {
      dragItemChanges.lastInGroup = false;
    }

    // Push updates
    workoutDragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });
    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
    if (exerciseChanges?.index !== undefined) {
      workoutExerciseUpdates.push({
        id: item.id,
        changes: exerciseChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...workoutDragItemUpdates);
  changes.workoutExercises.updated.push(...workoutExerciseUpdates);

  if (workoutExerciseGroupUpdates.length) {
    changes.workoutExerciseGroups.updated = [...workoutExerciseGroupUpdates];
  }

  return changes;
};

export default handleMoveGroupToExistingGroup;
