// @mui
import { styled } from '@mui/material/styles';
import { Chip, Stack, Button } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import { ExerciseFilter as ExerciseFilterType } from 'src/@types/program';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const WrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'stretch',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.divider}`,
}));

const LabelStyle = styled('span')(({ theme }) => ({
  ...theme.typography.subtitle2,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  borderRight: `solid 1px ${theme.palette.divider}`,
}));

// ----------------------------------------------------------------------

type Props = {
  filters: ExerciseFilterType;
  isShowReset: boolean;
  onResetAll: VoidFunction;
  onRemoveMuscleTag: (value: string) => void;
  onRemoveMovementTag: (value: string) => void;
};

export default function ExerciseTagFiltered({
  filters,
  isShowReset,
  onRemoveMuscleTag,
  onRemoveMovementTag,
  onResetAll,
}: Props) {
  const { movementTags, muscleTags } = filters;

  return (
    <RootStyle>
      {muscleTags.length > 0 && (
        <WrapperStyle>
          <LabelStyle>Muscles:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {muscleTags.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                size="small"
                onDelete={() => onRemoveMuscleTag(tag.id)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}

      {movementTags.length > 0 && (
        <WrapperStyle>
          <LabelStyle>Movement Types:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {movementTags.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                size="small"
                onDelete={() => onRemoveMovementTag(tag.id)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}

      {isShowReset && (
        <Button
          color="error"
          size="small"
          onClick={onResetAll}
          startIcon={<Iconify icon={'ic:round-clear-all'} />}
        >
          Clear All
        </Button>
      )}
    </RootStyle>
  );
}
