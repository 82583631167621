// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';
import { UnsavedPromptProvider } from './contexts/UnsavedPromptContext';
import { ProgramClientProvider } from './contexts/ProgramClientContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <UnsavedPromptProvider>
              <ProgramClientProvider>
                <ProgressBarStyle />
                <ScrollToTop />
                <Router />
              </ProgramClientProvider>
            </UnsavedPromptProvider>
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
