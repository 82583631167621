// @mui
import { Stack } from '@mui/material';
import { SkeletonWorkout } from '.';

// ----------------------------------------------------------------------

export default function SkeletonWeek() {
  return (
    <Stack direction="row" spacing={4} sx={{ pl: 5 }}>
      <SkeletonWorkout />
      <SkeletonWorkout />
      <SkeletonWorkout />
    </Stack>
  );
}
