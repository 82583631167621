import { ReactNode } from 'react';

import LoadingScreen from 'src/components/LoadingScreen';
import { Navigate } from 'react-router';
import { PATH_PAGE } from 'src/routes/paths';
import useCurrentUser from 'src/hooks/useCurrentUser';

// ----------------------------------------------------------------------
type Props = {
  children: ReactNode;
};

export default function ClientSubscriptionGuard({ children }: Props) {
  const { user, initialized, isSubscribed } = useCurrentUser();

  if (!initialized || user?.needsStripeSetup || user?.pendingStripeCreation) {
    return <LoadingScreen />;
  }

  // If the user still doesn't have a client subscription provide a screen with a button to try agian
  if (!isSubscribed) {
    // pass the try again function to the page
    return <Navigate to={PATH_PAGE.accountIssues} />;
  }

  return <>{children}</>;
}
