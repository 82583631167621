// types
import { WorkoutExercise } from 'src/@types/program';
import { Changes } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
// functions
import { handleDuplicateWorkoutExercise } from '../duplicate';

type Props = {
  exercise: WorkoutExercise;
  newWorkoutId: string;
  state: RootState;
  changes: Changes;
};

/**
 * Duplicates a Exercise to a new destination in the program currently in use
 *
 * @param exercise - Exercise
 * @param newWorkoutId - The id of the workout to add the exercise to.
 * @param state - The current state of the store
 * @param changes - The changes to be made to the current program
 *
 */
const handleCopyExerciseToCurrentProgram = ({ exercise, state, newWorkoutId, changes }: Props) => {
  handleDuplicateWorkoutExercise({
    state,
    changes,
    workoutExerciseId: exercise.id,
    copyToWorkoutId: newWorkoutId,
  });
};

export default handleCopyExerciseToCurrentProgram;
