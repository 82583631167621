import { useContext } from 'react';
import { StripeModalContext } from 'src/contexts/StripeModalContext';

// ----------------------------------------------------------------------

export default function useStripeModal() {
  const context = useContext(StripeModalContext);

  return context;
}
