import { Button, Hidden, Stack, useTheme } from '@mui/material';
import { memo } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { updateCurrentTab } from 'src/redux/slices/program/program';
import { addWeek, getWeekTabs, reorderWeeks } from 'src/redux/slices/program/programWeeks';
import { useDispatch, useSelector } from 'src/redux/store';
import WeekTab from './WeekTab';

function WeekTabs() {
  const dispatch = useDispatch();
  const tabs = useSelector(getWeekTabs);
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const handleAddWeek = () => {
    const newWeekId = dispatch(addWeek({ index: tabs.length }));
    if (newWeekId) {
      dispatch(updateCurrentTab(newWeekId));
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    dispatch(reorderWeeks({ from: result.source.index, to: result.destination.index }));
  };

  return (
    <Stack>
      <Scrollbar>
        <Stack direction="row" sx={{ px: 5, pb: 2, mb: 1, minHeight: 55 }} spacing={2}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable type="WEEK" droppableId={'weeks-droppable'} direction="horizontal">
              {(provided, snapshot) => (
                <Stack
                  direction="row"
                  spacing={2}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {tabs.map((tab) => (
                    <WeekTab key={tab.id} {...tab} />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            variant="outlined"
            sx={{
              minWidth: 150,
              minHeight: 40,
              color: isLight ? 'text.primary' : 'common.white',
              borderColor: isLight ? 'grey.500' : 'grey.300',
              backgroundColor: isLight ? 'rgba(223, 223, 223, 0.2)' : 'rgba(42, 42, 42, 0.2)',
            }}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            onClick={handleAddWeek}
          >
            <Hidden smDown>Add Week</Hidden>
          </Button>
        </Stack>
      </Scrollbar>
    </Stack>
  );
}

export default memo(WeekTabs);
