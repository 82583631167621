import { Unsubscribe } from 'firebase/firestore';
import { useReducer } from 'react';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { Program_WithID } from 'src/@types/firebase';

// ------------------------------------

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  loading = 'LOADING',
  succeeded = 'SUCCEEDED',
  failed = 'FAILED',
  startListener = 'START_LISTENER',
  stopListener = 'STOP_LISTENER',
  reset = 'RESET',
}

type Payload = {
  [Types.loading]: undefined;
  [Types.succeeded]: {
    upsertItems: Program_WithID[];
    removeItems: string[];
  };
  [Types.failed]: string;
  [Types.startListener]: Unsubscribe;
  [Types.stopListener]: undefined;
  [Types.reset]: undefined;
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

// ------------------------------------

const initialState = {
  entities: {},
  ids: [],
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  listener: null,
} as {
  entities: { [key: string]: Program_WithID };
  ids: string[];
  status: FETCH_STATUS_TYPES_ENUM;
  error: string | null;
  listener: null | Unsubscribe;
};

const reducer = (state: typeof initialState, action: Actions) => {
  // Call the firebase unsubscribe function
  const stopFirebaseListener = () => {
    if (state.listener) {
      state.listener();
    }
  };

  switch (action.type) {
    case Types.loading:
      return { ...state, status: FETCH_STATUS_TYPES_ENUM.LOADING };
    case Types.succeeded:
      const { upsertItems, removeItems } = action.payload;
      const newEntities = { ...state.entities };

      // If there are items to remove, remove them
      if (removeItems.length > 0) {
        removeItems.forEach((id) => delete newEntities[id]);
      }

      // If there are items to upsert, upsert them
      if (upsertItems.length > 0) {
        upsertItems.forEach((program) => (newEntities[program.id] = program));
      }

      return {
        ...state,
        status: FETCH_STATUS_TYPES_ENUM.SUCCEEDED,
        entities: newEntities,
        ids: Object.keys(newEntities),
      };
    case Types.failed:
      return {
        ...state,
        status: FETCH_STATUS_TYPES_ENUM.FAILED,
        error: action.payload,
      };
    case Types.startListener:
      return { ...state, listener: action.payload };
    case Types.stopListener:
      stopFirebaseListener();
      return { ...state, listener: null };
    case Types.reset:
      stopFirebaseListener();
      return initialState;
    default:
      return state;
  }
};

export default function useFeaturedProgramsReducer() {
  return useReducer(reducer, initialState);
}
