// ----------------------------------------------------------------------

import { EXERCISE_METRIC_ENUM } from 'src/@types/enums';

export function getExerciseMetricIconName(name: string) {
  switch (name) {
    case EXERCISE_METRIC_ENUM.PERCENTAGE:
      return 'ic_percentage';
    case EXERCISE_METRIC_ENUM.REPS:
      return 'ic_reps';
    case EXERCISE_METRIC_ENUM.TIME:
      return 'ic_time';
    case EXERCISE_METRIC_ENUM.WEIGHT:
      return 'ic_weights';
    case EXERCISE_METRIC_ENUM.RESISTANCE:
      return 'ic_resistance';
    case EXERCISE_METRIC_ENUM.REST:
      return 'ic_rest';
    case EXERCISE_METRIC_ENUM.CALORIES:
      return 'ic_calories';
    case EXERCISE_METRIC_ENUM.DISTANCE:
      return 'ic_distance';
    case EXERCISE_METRIC_ENUM.TEMPO:
      return 'ic_tempo';
    case EXERCISE_METRIC_ENUM.HEIGHT:
      return 'ic_height';
    case EXERCISE_METRIC_ENUM.RIR:
      return 'ic_rir';
    case EXERCISE_METRIC_ENUM.RPE:
      return 'ic_rpe';
    case EXERCISE_METRIC_ENUM.ASSISTANCE:
      return 'ic_assistance';
    case EXERCISE_METRIC_ENUM.SETS:
      return 'ic_set';
    default:
      return 'ic_set';
  }
}
