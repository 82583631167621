import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { query, collection, where, onSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { UserHabit_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';
import { AppDispatch, RootState } from '../store';
import { resetClientProfileAction } from './clientProfile';
import { saveHabitUser } from './habits';
// import { DB } from 'src/contexts/FirebaseContext';

const userHabitsAdapter = createEntityAdapter<UserHabit_WithID>({
  // Sort by dateCreated of type Date
  sortComparer: (a, b) => a.dateCreated.getTime() - b.dateCreated.getTime(),
});

const initialState = userHabitsAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  listener: null,
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null; listener: null | Function });

export const slice = createSlice({
  name: 'userHabits',
  initialState,
  reducers: {
    setUserHabits: (
      state,
      action: PayloadAction<{
        upsertItems: UserHabit_WithID[];
        removeItems: string[];
      }>
    ) => {
      userHabitsAdapter.upsertMany(state, action.payload.upsertItems);
      userHabitsAdapter.removeMany(state, action.payload.removeItems);
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
    },
    // --------------------------------------------------
    // Listener commands start
    // --------------------------------------------------
    fetchConnectionsRequestsLoading(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
    },
    fetchConnectionsRequestsSuccess(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
    },
    fetchConnectionsRequestsFailure(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.error = action.payload;
      console.error(action.payload);
    },
    startListener: (state, action) => {
      state.listener = action.payload;
    },
    stopListener: (state) => {
      if (state.listener) {
        state.listener();
      }
      state.listener = null;
    },
    // --------------------------------------------------
    reset: (state) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    },
  },
  extraReducers(builder) {
    // External
    builder.addCase(resetClientProfileAction, (state) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    });
    builder.addCase(saveHabitUser.fulfilled, (state, action) => {
      // Update the target for the updated habitUser
      const { habit, habitUser } = action.payload;
      const userHabitId = habit.id + '_' + habitUser.id;
      const userHabit = state.entities[userHabitId];

      if (userHabit) {
        const updatedUserhabit = _.cloneDeep(userHabit);
        updatedUserhabit.target = habitUser.target;
        userHabitsAdapter.upsertOne(state, updatedUserhabit);
      }
    });
  },
});

export const {
  setUserHabits,
  fetchConnectionsRequestsLoading,
  fetchConnectionsRequestsSuccess,
  fetchConnectionsRequestsFailure,
  startListener,
  stopListener,
  reset,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

export const startUserHabitsListener =
  (userId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { listener } = state.userHabits;

    if (listener) {
      console.error('User habits listener already exists');
      return;
    }

    dispatch(fetchConnectionsRequestsLoading());
    try {
      const q = query(
        collection(DB, 'userHabits'),
        where('client.id', '==', userId),
        where('active', '==', true)
      );
      const unsubscribe = await onSnapshot(
        q,
        (querySnapshot) => {
          const upsertItems: UserHabit_WithID[] = [];
          const removeItems: string[] = [];

          querySnapshot.docChanges().forEach((change) => {
            const { id } = change.doc;
            const data = change.doc.data() as any;
            convertFirebaseDataDates(data);

            const item = { ...data, id } as UserHabit_WithID;

            if (change.type === 'added' || change.type === 'modified') {
              upsertItems.push(item);
            }
            if (change.type === 'removed') {
              removeItems.push(id);
            }
          });
          dispatch(setUserHabits({ upsertItems, removeItems }));
        },
        (error) => {
          dispatch(fetchConnectionsRequestsFailure(error.message));
        }
      );
      dispatch(startListener(unsubscribe));
    } catch (error) {
      const err = error as Error;
      const errorMessage = err.message;
      dispatch(fetchConnectionsRequestsFailure(errorMessage));
    }
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllUserHabits,
  selectEntities: selectUserHabitEntities,
  selectById: selectUserHabitById,
} = userHabitsAdapter.getSelectors((state: RootState) => state.userHabits);

export const getUserHabitsFetchStatus = (state: RootState) => state.userHabits.status;
export const getUserHabitsFetchError = (state: RootState) => state.userHabits.error;
