import { useContext } from 'react';
import { ProgramClientContext } from 'src/contexts/ProgramClientContext';

// ----------------------------------------------------------------------

export default function useProgramClientModal() {
  const context = useContext(ProgramClientContext);

  return context;
}
