import { WorkoutDragItem, WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { createWorkoutExerciseGroup } from 'src/utils/createDragItems';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  exerciseOptionsDragItem: WorkoutDragItem;
  dragItemBelowExerciseOptions?: WorkoutDragItem;
  groupDragItems: WorkoutDragItem[];
};

const handleCombineTwoSoloGroups = ({
  state,
  exerciseOptionsDragItem,
  dragItemBelowExerciseOptions,
  groupDragItems,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [{ id: exerciseOptionsDragItem.workoutId, changes: {} }];
  if (dragItemBelowExerciseOptions) {
    workoutUpdates.push({ id: dragItemBelowExerciseOptions.workoutId, changes: {} });
  }
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutExercises = {};
  changes.workoutDragItems = {};

  // Merge to the group with the lowest index
  const groupOneExercise = groupDragItems.find(
    (item) => item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE
  );
  const groupTwoExercise = dragItemBelowExerciseOptions;

  if (!groupOneExercise || !groupTwoExercise) {
    console.error('handleCombineTwoSoloGroups: groupOneExercise or groupTwoExercise is undefined');
    return changes;
  }

  // Remove groupTwoExercise group
  const removedGroupId = groupTwoExercise.groupId;
  // Remove groupTwoExercise exercise options
  const removedExerciseOptionsId = `${removedGroupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;

  // Add new group header
  const newGroupHeader = createWorkoutExerciseGroup({
    id: groupOneExercise.groupId,
    index: groupOneExercise?.groupIndex !== undefined ? groupOneExercise.groupIndex : 0,
    dragIndex: groupOneExercise.dragIndex,
    workoutId: groupOneExercise.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER,
    inGroup: true,
  });

  // Update the exercises to be in group
  const groupOneExerciseDragItemUpdates: Partial<WorkoutDragItem> = {
    inGroup: true,
    lastInGroup: false,
    dragIndex: newGroupHeader.dragIndex + 1, // +1 because we add a new group header
  };
  const groupTwoExerciseDragItemUpdates: Partial<WorkoutDragItem> = {
    inGroup: true,
    groupId: groupOneExercise.groupId,
    groupIndex: groupOneExercise?.groupIndex,
    index: 1,
    lastInGroup: true,
    dragIndex: newGroupHeader.dragIndex + 2, // +2 because we add a new group header and groupOneExercise
  };

  // Update groupTwoExercise to be in groupOne
  const groupTwoExerciseUpdates: Partial<WorkoutExercise> = {
    workoutExerciseGroupId: groupOneExercise.groupId,
    index: 1,
  };

  // Add new group footer
  const newGroupFooter = createWorkoutExerciseGroup({
    id: groupOneExercise.groupId,
    index: groupOneExercise?.groupIndex !== undefined ? groupOneExercise.groupIndex : 0,
    dragIndex: newGroupHeader.dragIndex + 3, // +3 because we add a new group header, groupOneExercise, and groupTwoExercise
    workoutId: groupOneExercise.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER,
    inGroup: true,
  });

  // Update the drag index of groupOneExercise exercise options
  const groupOneExerciseOptionsId = `${groupOneExercise.groupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;
  const groupOneExerciseOptionsUpdates: Partial<WorkoutDragItem> = {
    inGroup: true,
    dragIndex: newGroupHeader.dragIndex + 4, // +4 because we add a new group header, groupOneExercise, groupTwoExercise, and group footer
  };

  // Apply the changes
  changes.workoutExerciseGroups.removed = [removedGroupId];
  changes.workoutDragItems.removed = [removedExerciseOptionsId];
  changes.workoutDragItems.updated = [
    { id: groupOneExercise.id, changes: groupOneExerciseDragItemUpdates },
    { id: groupTwoExercise.id, changes: groupTwoExerciseDragItemUpdates },
    { id: groupOneExerciseOptionsId, changes: groupOneExerciseOptionsUpdates },
  ];
  changes.workoutDragItems.added = [newGroupHeader, newGroupFooter];
  changes.workoutExercises.updated = [
    { id: groupTwoExercise.id, changes: groupTwoExerciseUpdates },
  ];

  // Find all the drag items below the removed group
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      if (!item) {
        return false;
      }

      if (item.workoutId !== groupOneExercise.workoutId) {
        return false;
      }

      if (item.dragIndex > groupTwoExercise.dragIndex + 1) {
        return true;
      }

      return false;
    }
  );

  // Update the drag indexes for all items below the removed group
  // Update all the group indexes for all items below removed group
  const workoutExerciseGroupUpdates: UpdateType[] = [];
  const dragItemUpdates: UpdateType[] = [];

  affectedItems.forEach((item) => {
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    const dragItemChanges: Partial<WorkoutDragItem> = {};

    dragItemChanges.dragIndex = item.dragIndex + 1; // +1 because we add 2 new items (group header and footer) and removed 1 (exercise options)

    // Update group indexes

    // Drag item changes
    if (item.groupIndex !== undefined) {
      dragItemChanges.groupIndex = item.groupIndex - 1;
    }
    // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    ) {
      dragItemChanges.index = item.index - 1;
    }

    // ExerciseGroup changes
    // If the item is a group header or a solo exercise
    // Update the exercise group index
    if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
      exerciseGroupChanges.index = item.index - 1;
    } else if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      !item.inGroup &&
      item.groupIndex !== undefined
    ) {
      exerciseGroupChanges.index = item.groupIndex - 1;
    }

    // Push the changes
    dragItemUpdates.push({ id: item.id, changes: dragItemChanges });
    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...dragItemUpdates);

  if (workoutExerciseGroupUpdates.length) {
    changes.workoutExerciseGroups.updated = [...workoutExerciseGroupUpdates];
  }

  return changes;
};

export default handleCombineTwoSoloGroups;
