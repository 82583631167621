export const mmToDistance = (totalDistanceInMillimeters: number) => {
  const millimeters = Math.floor(totalDistanceInMillimeters % 10),
    centimeters = Math.floor((totalDistanceInMillimeters / 10) % 100),
    meters = Math.floor((totalDistanceInMillimeters / 1000) % 1000),
    kilometers = Math.floor(totalDistanceInMillimeters / 1000000);

  return {
    millimeters: millimeters,
    centimeters: centimeters,
    meters: meters,
    kilometers: kilometers,
  };
};

export const prettyMillimeters = (
  totalDistanceInMillimeters: number,
  options?: { verbose: boolean; unitCount: number }
) => {
  /* ## Options ##
        -
        unitCount
        Type: number
        Default: Infinity

        Number of units to show. Setting compact to true overrides this option.
        -
        verbose
        Type: boolean
        Default: false

        Use full-length units: 5h 1m 45s → 5 hours 1 minute 45 seconds
    */
  const values = mmToDistance(totalDistanceInMillimeters);

  // Return all values with shorthands units by default

  // Else return number of unitCount

  const units = {
    millimeters: options?.verbose
      ? values.millimeters === 1
        ? ' millimeter'
        : ' millimeters'
      : 'mm',
    centimeters: options?.verbose
      ? values.centimeters === 1
        ? ' centimeter'
        : ' centimeters'
      : 'cm',
    meters: options?.verbose ? (values.meters === 1 ? ' meter' : ' meters') : 'm',
    kilometers: options?.verbose ? (values.kilometers === 1 ? ' kilometer' : ' kilometers') : 'km',
  };

  const fullMetrics = {
    millimeters: values.millimeters + units.millimeters,
    centimeters: values.centimeters + units.centimeters,
    meters: values.meters + units.meters,
    kilometers: values.kilometers + units.kilometers,
  };

  switch (options?.unitCount) {
    case 1:
      if (values.kilometers > 0) {
        return fullMetrics.kilometers;
      } else if (values.meters > 0) {
        return fullMetrics.meters;
      } else if (values.centimeters > 0) {
        return fullMetrics.centimeters;
      } else if (values.millimeters > 0) {
        return fullMetrics.millimeters;
      } else {
        return fullMetrics.millimeters;
      }
    case 2:
      if (values.kilometers > 0) {
        return fullMetrics.kilometers + (values.meters !== 0 ? ' ' + fullMetrics.meters : '');
      } else if (values.meters > 0) {
        return fullMetrics.meters + (values.centimeters !== 0 ? ' ' + fullMetrics.centimeters : '');
      } else if (values.centimeters > 0) {
        return (
          fullMetrics.centimeters + (values.millimeters !== 0 ? ' ' + fullMetrics.millimeters : '')
        );
      } else if (values.millimeters > 0) {
        return fullMetrics.millimeters;
      } else {
        return fullMetrics.millimeters;
      }
    case 3:
      if (values.kilometers > 0) {
        return (
          fullMetrics.kilometers +
          (values.meters !== 0 ? ' ' + fullMetrics.meters : '') +
          (values.centimeters !== 0 ? ' ' + fullMetrics.centimeters : '')
        );
      } else if (values.meters > 0) {
        return (
          fullMetrics.meters +
          (values.centimeters !== 0 ? ' ' + fullMetrics.centimeters : '') +
          (values.millimeters !== 0 ? ' ' + fullMetrics.millimeters : '')
        );
      } else if (values.centimeters > 0) {
        return (
          fullMetrics.centimeters + (values.millimeters !== 0 ? ' ' + fullMetrics.millimeters : '')
        );
      } else if (values.millimeters > 0) {
        return fullMetrics.millimeters;
      } else {
        return fullMetrics.millimeters;
      }
    case 4:
    default:
      if (values.kilometers > 0) {
        return (
          fullMetrics.kilometers +
          (values.meters !== 0 ? ' ' + fullMetrics.meters : '') +
          (values.centimeters !== 0 ? ' ' + fullMetrics.centimeters : '') +
          (values.millimeters !== 0 ? ' ' + fullMetrics.millimeters : '')
        );
      } else if (values.meters > 0) {
        return (
          fullMetrics.meters +
          ' ' +
          (values.centimeters !== 0 ? ' ' + fullMetrics.centimeters : '') +
          (values.millimeters !== 0 ? ' ' + fullMetrics.millimeters : '')
        );
      } else if (values.centimeters > 0) {
        return fullMetrics.centimeters + ' ' + fullMetrics.millimeters;
      } else if (values.millimeters > 0) {
        return fullMetrics.millimeters;
      } else {
        return fullMetrics.millimeters;
      }
  }
};
