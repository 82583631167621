import { Stack, Button, ButtonProps, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo, useCallback } from 'react';
// import { useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ExerciseModal } from 'src/@types/exerciseModal';
import { WorkoutDragItem as WorkoutDragItemType } from 'src/@types/program';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { isExerciseGroupCollapsed } from 'src/redux/slices/program/collapsedItems';
import { getExerciseGroupName } from 'src/redux/slices/program/workoutExerciseGroups';
import { openExerciseModal } from 'src/redux/slices/program/exerciseModal';
import { getGroupButtonTitle, handleGrouping } from 'src/redux/slices/program/workoutDragItems';
import { getWorkoutName } from 'src/redux/slices/program/workouts';
import { RootState, useDispatch, useSelector } from 'src/redux/store';

type Props = {
  workoutDragItem: WorkoutDragItemType;
  inExercise?: boolean;
};

type BootstrapButtonProps = ButtonProps & {
  inExercise: boolean;
};

const BootstrapButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'inExercise',
})<BootstrapButtonProps>(({ theme, inExercise }) => ({
  fontSize: 12,
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.text.disabled,
  backgroundColor: inExercise ? theme.palette.background.paper : theme.palette.background.default,
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: inExercise ? theme.palette.background.paper : theme.palette.background.default,
  },
}));

function WorkoutExerciseOptions({ workoutDragItem, inExercise = true }: Props) {
  const dispatch = useDispatch();
  const { id, lastInGroup, groupId, groupIndex, index, dragIndex, workoutId } = workoutDragItem;
  // If the exercise is not the last exercise in its group or if the exercise options are being shown outside an exercise, show the group button
  const showGroupButton = !lastInGroup || !inExercise;
  // const { name, cover, /*position*/ follower, totalPost, avatarUrl, following } = user;
  const collapsed = useSelector((state: RootState) => isExerciseGroupCollapsed(state, groupId));
  const groupName = useSelector((state: RootState) => getExerciseGroupName(state, groupId));
  const workoutName = useSelector((state: RootState) => getWorkoutName(state, workoutId));
  const groupButtonTitle = useSelector((state: RootState) =>
    getGroupButtonTitle(state, { workoutId, groupIndex })
  );
  // const [hover, setHover] = useState(false);

  const handleAddExercise = useCallback(() => {
    // Find the bottom most workoutDragItem
    const exerciseModalDetails: ExerciseModal = !inExercise
      ? // Below a group within a workout
        {
          visible: true,
          workoutId: workoutId,
          dragIndex: dragIndex,
          workoutName: workoutName,
          groupAboveIndex: groupIndex,
        }
      : // Below exercise within a group
        {
          visible: true,
          workoutId: workoutId,
          dragIndex: dragIndex,
          groupId: groupId,
          groupIndex: groupIndex,
          groupName: groupName,
          exerciseAboveId: id,
          exerciseAboveIndex: index,
          isExerciseAboveLastInGroup: lastInGroup,
        };
    dispatch(openExerciseModal(exerciseModalDetails));
  }, [
    dispatch,
    id,
    dragIndex,
    workoutId,
    groupId,
    groupIndex,
    index,
    inExercise,
    groupName,
    workoutName,
    lastInGroup,
  ]);

  const handleGroupClick = useCallback(() => {
    dispatch(handleGrouping({ dragItemId: id, combineGroups: !inExercise }));
  }, [dispatch, id, inExercise]);

  // const handleMouseEnter = useCallback(() => setHover(true), []);
  // const handleMouseLeave = useCallback(() => setHover(false), []);
  const renderExerciseOptions = () => (
    <Stack sx={{ py: 2, position: 'relative' }}>
      <Divider />
      <Box
        sx={{
          width: '100%',
          height: '50%',
          position: 'absolute',
          m: 'auto',
          top: -15,
          left: 0,
          right: 0,
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="row" justifyContent="center" spacing={4}>
          {!inExercise && (
            <BootstrapButton
              startIcon={
                <SvgIconStyle
                  src={`/icons/ic_add_exercise.svg`}
                  sx={{
                    width: 16,
                    height: 16,
                  }}
                />
              }
              inExercise={inExercise}
              onClick={handleAddExercise}
            >
              Add Exercise
            </BootstrapButton>
          )}
          {!!showGroupButton && (
            <BootstrapButton
              startIcon={
                <SvgIconStyle
                  src={inExercise ? `/icons/ic_superset_ungroup.svg` : `/icons/ic_superset.svg`}
                  sx={{
                    width: 16,
                    height: 16,
                  }}
                />
              }
              inExercise={inExercise}
              onClick={handleGroupClick}
            >
              {inExercise ? 'Ungroup' : groupButtonTitle}
            </BootstrapButton>
          )}
        </Stack>
      </Box>
    </Stack>
  );

  // Don't show the exercise options if the exercise is collapsed
  // Or if the exercise in "inExercise" and is not part of a group
  if (collapsed || (inExercise && !showGroupButton)) {
    return null;
  }

  if (!inExercise) {
    return (
      <Draggable key={id} draggableId={id} index={dragIndex} isDragDisabled={true}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            {renderExerciseOptions()}
          </div>
        )}
      </Draggable>
    );
  }

  return <Stack sx={{ pb: 2 }}>{renderExerciseOptions()}</Stack>;
}

export default memo(WorkoutExerciseOptions);
