import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { programResetAction } from './program';

export type ProgramImageModal = {
  visible: boolean;
};

const initialState: ProgramImageModal = {
  visible: false,
};

export const slice = createSlice({
  name: 'programImageModal',
  initialState,
  reducers: {
    openProgramImageModal: (state, action: PayloadAction<ProgramImageModal>) => action.payload,
    closeProgramImageModal: () => initialState,
  },
  extraReducers(builder) {
    builder
      // Reset case
      .addCase(programResetAction, () => initialState);
  },
});

export const { openProgramImageModal, closeProgramImageModal } = slice.actions;

export default slice.reducer;

// ################################################################
// SELECTORS
// ################################################################

export const getProgramImageModal = (state: RootState) => state.programImageModal;
export const getProgramImageModalVisible = (state: RootState) => state.programImageModal.visible;
