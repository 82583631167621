// @mui
import { Card, Skeleton, Stack } from '@mui/material';
import { WORKOUT_WIDTH } from 'src/constants';
import { randomNumberRange } from 'src/_mock/funcs';

const randomTextNumber = () => randomNumberRange(140, 300);

// ----------------------------------------------------------------------

function SkeletonWorkoutExercise() {
  const commonStyles = {
    p: 0,
    borderLeft: 3,
  };
  const soloStyles = {
    ...commonStyles,
    mt: 0,
    mb: 2,
    borderColor: 'primary.main',
  };
  // const groupStyles = { ...commonStyles, my: 0, borderRadius: 0, borderColor: 'primary.dark' };

  const hasNotes = randomNumberRange(0, 1) === 0;
  const numberOfNoteLines = randomNumberRange(2, 5);

  const renderNotes = (numberOfLines: number) => {
    // Loop over the number of lines and create a skeleton for each line
    const skeletonLines = [];
    for (let i = 0; i < numberOfLines; i++) {
      skeletonLines.push(<Skeleton key={i} variant="text" sx={{ width: randomTextNumber() }} />);
    }

    return skeletonLines;
  };

  return (
    <Card sx={soloStyles}>
      {/* Header */}
      <Stack direction="row" alignItems="flex-start" sx={{ backgroundColor: 'background.neutral' }}>
        <Skeleton variant="rectangular" sx={{ minWidth: 80, width: 80, height: 80, mr: 2 }} />
        <Stack direction="row" alignItems="flex-start" sx={{ flex: 1, p: 1 }}>
          <Skeleton variant="text" sx={{ width: 180 }} />
        </Stack>
      </Stack>

      {/* Exercise Metrics */}
      <Stack sx={{ p: 2 }}>
        <Skeleton variant="text" sx={{ width: randomTextNumber() }} />
        <Skeleton variant="text" sx={{ width: randomTextNumber() }} />
      </Stack>

      {/* Notes */}
      <Stack sx={{ p: 2, pt: 0 }}>{hasNotes && renderNotes(numberOfNoteLines)}</Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

export default function SkeletonWorkout() {
  const numberOfExercises = randomNumberRange(3, 5);
  // 50/50 chance
  const hasNotes = randomNumberRange(0, 1) === 0;
  const numberOfNoteLines = randomNumberRange(3, 10);

  const renderNotes = (numberOfLines: number) => {
    // Loop over the number of lines and create a skeleton for each line
    const skeletonLines = [];
    for (let i = 0; i < numberOfLines; i++) {
      skeletonLines.push(<Skeleton key={i} variant="text" sx={{ width: randomTextNumber() }} />);
    }

    return skeletonLines;
  };

  const renderWorkoutExercises = () => {
    // Loop over the number of lines and create a skeleton for each line
    const exercises = [];
    for (let i = 0; i < numberOfExercises; i++) {
      exercises.push(<SkeletonWorkoutExercise key={i} />);
    }

    return exercises;
  };

  return (
    <Stack spacing={2} sx={{ minWidth: WORKOUT_WIDTH, maxWidth: WORKOUT_WIDTH }}>
      {/* Workout Title */}
      <Card sx={{ pl: 2, pr: 1, py: 1, mb: 3 }}>
        <Skeleton variant="text" sx={{ width: 150 }} />
      </Card>

      {hasNotes && (
        <Card sx={{ p: 2, pr: 1, mb: 2, borderLeft: 3, borderColor: 'primary.light' }}>
          <Stack alignItems="flex-start">{renderNotes(numberOfNoteLines)}</Stack>
        </Card>
      )}

      {renderWorkoutExercises()}
    </Stack>
  );
}
