import { addDoc, collection } from 'firebase/firestore';
import { CLIENT_STATUS_ENUM } from 'src/@types/enums';
import { CoachClient, User_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';

const addSelfAsClient = async (user: User_WithID) => {
  // If the user is a coach, add the coach as a client to themselves

  const coachClient: CoachClient = {
    dateAdded: new Date(),
    status: CLIENT_STATUS_ENUM.CURRENT_COACH,
    coach: {
      id: user.id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      profilePictureUrl: user.profilePictureUrl,
      coachDescription: user.coachDescription ? user.coachDescription : '',
    },
    client: {
      id: user.id,
      dateCreated: new Date(),
      email: user.email,
      phone: user.phone,
      firstName: user.firstName,
      lastName: user.lastName,
      profilePictureUrl: user.profilePictureUrl,
      type: user?.type ? user.type : [],
    },
  };
  await addDoc(collection(DB, 'coachClients'), coachClient);
};

export default addSelfAsClient;
