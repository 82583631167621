import React, { memo, useState } from 'react';
// mui
import { styled } from '@mui/material/styles';
import { Dialog, Button, FormHelperText, Slide, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TransitionProps } from '@mui/material/transitions';
// compressor
import Compressor from 'compressorjs';
// components
import Scrollbar from 'src/components/Scrollbar';
import ImageFormButton from './ImageFormButton';
import { UploadSingleFile } from 'src/components/upload';
// redux
import { useSelector, useDispatch } from 'src/redux/store';
import {
  closeProgramImageModal,
  getProgramImageModal,
} from 'src/redux/slices/program/programImageModal';
// contstants
import { DEFAULT_PROGRAM_IMAGES } from './ProgramNewEditForm';
import { getProgramId, saveProgramImage } from 'src/redux/slices/program/program';

// ----------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

function ProgramImageModal() {
  const dispatch = useDispatch();
  const { visible } = useSelector(getProgramImageModal);
  const programId = useSelector(getProgramId);

  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const [saving, setSaving] = useState(false);

  const reset = () => {
    setFile(null);
    setFileError(false);
    setFileErrorMessage('');
  };

  const handleClose = () => {
    dispatch(closeProgramImageModal());
    reset();
  };

  const handleDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file) {
      new Compressor(file, {
        quality: 0.7,
        maxWidth: 1500,
        maxHeight: 1000,
        success: (result: any) => {
          // If size is greater than 300kb (300 * 1024) set error for file field
          if (result.size > 300 * 1024) {
            const fileSizeKb = Math.round(result.size / 1024);
            setFileError(true);
            setFileErrorMessage(
              `The size of your image once compressed (${fileSizeKb}kb / 300kb) was too big. Try reducing the size of your image before uploading again.`
            );
            // setFileError({
            //   type: 'maxSize',
            //   message: `The size of your image once compressed (${fileSizeKb}kb / 300kb) was too big. Try reducing the size of your image before uploading again.`,
            // });
          } else if (result) {
            setSelectedImage(null);

            setFile(
              Object.assign(result, {
                preview: URL.createObjectURL(result),
              })
            );
          }
        },
      });
    }
  };

  const handleSave = async () => {
    setSaving(true);
    await dispatch(
      saveProgramImage({
        programId,
        imageUrl: selectedImage ? selectedImage : undefined,
        file: file ? file : undefined,
      })
    );
    setSaving(false);
    handleClose();
  };

  return (
    <Dialog
      keepMounted={false}
      open={visible}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      {/* <IconButton
        onClick={handleClose}
        sx={{ zIndex: 9, position: 'absolute', top: 10, right: 10 }}
      >
        <Iconify
          icon={'fe:close'}
          color="text.primary"
          width={30}
          height={30}
          sx={{ opacity: 0.5, ':hover': { opacity: 1 } }}
        />
      </IconButton> */}
      <Stack>
        <Stack sx={{ px: 5, pt: 5 }}>
          <LabelStyle>Program Image</LabelStyle>

          <Typography variant="caption" color="text.secondary" sx={{ mb: 1, display: 'block' }}>
            Select an image to be a thumbnail for your program (clients will see this thumbnail on
            their mobile app)
          </Typography>
        </Stack>

        <Scrollbar>
          <Stack
            direction="row"
            alignItems="flex-start"
            spacing={2}
            sx={{ px: 5, pb: 2 }}
            // sx={{ overflowY: 'hidden' }}
          >
            {DEFAULT_PROGRAM_IMAGES.map((image) => (
              <ImageFormButton
                key={image}
                imageUrl={image}
                selected={selectedImage === image}
                onSelect={() => {
                  if (file) {
                    setFile(null);
                  }

                  const selected = selectedImage === image ? null : image;
                  setSelectedImage(selected);
                }}
              />
            ))}
          </Stack>
        </Scrollbar>

        <Stack sx={{ px: 5, pb: 5 }}>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ mt: 2, mb: 1, display: 'block' }}
          >
            Or upload your own image
          </Typography>

          <UploadSingleFile
            accept={{ 'image/jpeg': [] }}
            maxSize={5242880} // 5MB
            onDrop={handleDrop}
            file={file}
            error={fileError}
            helperText={
              fileError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {fileErrorMessage}
                </FormHelperText>
              )
            }
          />

          <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
            <LoadingButton variant="contained" onClick={handleSave} loading={saving}>
              Save
            </LoadingButton>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default memo(ProgramImageModal);
