import { IconButton, Stack } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { shallowEqual } from 'react-redux';
import { WorkoutExerciseMetric as WorkoutExerciseMetricType } from 'src/@types/program';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { getProgramDraggingStatus } from 'src/redux/slices/program/program';
import { removeWorkoutExerciseMetric } from 'src/redux/slices/program/workoutExerciseMetrics';
import { selectAllWorkoutExerciseMetricValuesByWorkoutExerciseMetricId } from 'src/redux/slices/program/workoutExerciseMetricValues';
import { getWorkoutExerciseSets } from 'src/redux/slices/program/workoutExercises';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { ExerciseMetricIcon, WorkoutExerciseMetricValue } from '.';

type Props = {
  workoutExerciseMetric: WorkoutExerciseMetricType;
  workoutExerciseId: string;
  collapsed: boolean;
};

function WorkoutExerciseMetric({ workoutExerciseMetric, workoutExerciseId, collapsed }: Props) {
  const dispatch = useDispatch();
  const { id, name, index } = workoutExerciseMetric;

  const [show, setShow] = useState(false);

  const draggingActive = useSelector(getProgramDraggingStatus);
  const sets = useSelector((state: RootState) => getWorkoutExerciseSets(state, workoutExerciseId));
  const workoutExerciseMetricValues = useSelector(
    (state: RootState) => selectAllWorkoutExerciseMetricValuesByWorkoutExerciseMetricId(state, id),
    shallowEqual
  );

  const handleMouseOver = useCallback(
    () => (draggingActive ? {} : setShow(true)),
    [draggingActive]
  );
  const handleMouseOut = useCallback(() => setShow(false), []);

  const handleExerciseMetricRemove = useCallback(() => {
    dispatch(
      removeWorkoutExerciseMetric({
        workoutExerciseId: workoutExerciseId,
        workoutExerciseMetricId: id,
      })
    );
  }, [dispatch, id, workoutExerciseId]);

  const renderExerciseMetricInputs = () => {
    const exerciseMetricInputs = [];
    // For each over sets
    if (sets) {
      const numberOfInputs = collapsed ? 1 : sets;

      for (let i = 0; i < numberOfInputs; i++) {
        const workoutExerciseMetricValue = workoutExerciseMetricValues.find(
          (emv) => emv.index === i
        );
        const value = workoutExerciseMetricValue?.value;
        const valueId = workoutExerciseMetricValue?.id;
        const workoutExerciseId = workoutExerciseMetricValue?.workoutExerciseId;
        const spacingRight = i === numberOfInputs - 1 ? 0 : 0.8;
        // Add a new ExerciseMetricInput
        exerciseMetricInputs.push(
          <WorkoutExerciseMetricValue
            key={valueId ? valueId : i}
            id={valueId}
            value={value}
            workoutExerciseId={workoutExerciseId}
            exerciseMetricName={name}
            spacingRight={spacingRight}
          />
        );
      }
    }

    return exerciseMetricInputs;
  };

  return (
    <Draggable key={id} draggableId={id} index={index} isDragDisabled={collapsed}>
      {(provided, snapshot) => (
        <Stack
          ref={provided.innerRef}
          {...provided.draggableProps}
          direction="row"
          alignItems="center"
          spacing={1}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          sx={{
            flex: 1,
            pr: collapsed ? 0 : 2,
            pl: 2,
            pb: 0.5,
            backgroundColor: snapshot.isDragging ? 'background.neutral' : 'transparent',
            borderRadius: snapshot.isDragging ? 1 : 0,
            ':hover': draggingActive
              ? {}
              : {
                  backgroundColor: 'background.neutral',
                },
          }}
        >
          <Stack
            {...{
              ...provided.dragHandleProps,
              tabIndex: -1,
            }}
            direction="row"
            flex={1}
            spacing={1}
          >
            <ExerciseMetricIcon key={id + 'icon'} exerciseMetricName={name} collapsed={collapsed} />
            <Stack direction="row" flexWrap="wrap" spacing={0}>
              {renderExerciseMetricInputs()}
            </Stack>
          </Stack>

          {!collapsed && (
            <IconButton
              tabIndex={-1}
              onClick={handleExerciseMetricRemove}
              sx={{ m: 0, ml: 0, p: 0.2 }}
            >
              <SvgIconStyle
                src={show ? `/icons/ic_trash.svg` : ''}
                sx={{
                  width: 16,
                  height: 16,
                  color: 'text.secondary',
                  ':hover': {
                    backgroundColor: 'text.primary',
                  },
                }}
              />
            </IconButton>
          )}
        </Stack>
      )}
    </Draggable>
  );
}

export default memo(WorkoutExerciseMetric);
