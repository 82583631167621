import { useContext, useEffect } from 'react';
import { HeaderContext } from 'src/contexts/HeaderContext';

// ----------------------------------------------------------------------

export default function useHeaderComponent() {
  const headerContext = useContext(HeaderContext);
  const { resetHeader } = headerContext;

  // Clean up when screen unmounts
  useEffect(() => {
    const unsubscribe = () => {
      resetHeader();
    };
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return headerContext;
}
