import { useEffect, useState } from 'react';
// components
import { Stack, Typography } from '@mui/material';
// hooks
import { useSnackbar } from 'notistack';
import useCurrentUser from 'src/hooks/useCurrentUser';
// stripe
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { FUNCTIONS } from 'src/contexts/FirebaseContext';
// firebase
import { httpsCallable } from 'firebase/functions';
// types
import { UserStripePaymentMethod } from 'src/@types/firebase';
import { LoadingButton } from '@mui/lab';
import { APP_NAMES_ENUM } from 'src/@types/enums';

type Props = {
  onSuccess: () => void;
};

const AddUpdateCardForm = ({ onSuccess }: Props) => {
  const { appName } = useCurrentUser();
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      console.log(error);
      alert(error);
    }
  }, [error]);

  // Handle the submission of card details
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      setLoading(true);
      event.preventDefault();

      // Throw an error if stripe or elements are not loaded
      if (!stripe || !elements) {
        throw new Error('Stripe not loaded');
      }

      const cardElement = elements.getElement(CardElement);

      // Check if card element is empty
      if (!cardElement) {
        throw new Error('Card element not loaded');
      }

      // Create Payment Method
      const { paymentMethod, error: stripeError } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      // Create Subscription on the Server
      const functionName =
        appName === APP_NAMES_ENUM.FITPROS
          ? 'events-stripeFitPros-updateStripePaymentDetails'
          : 'events-stripe-updateStripePaymentDetails';
      const updateStripePaymentDetails = httpsCallable<{ paymentMethod: UserStripePaymentMethod }>(
        FUNCTIONS,
        functionName
      );

      await updateStripePaymentDetails({
        paymentMethod: {
          id: paymentMethod.id,
          brand: paymentMethod.card?.brand ? paymentMethod.card.brand : null,
          last4: paymentMethod.card?.last4 ? paymentMethod.card.last4 : null,
          expMonth: paymentMethod.card?.exp_month ? paymentMethod.card.exp_month : null,
          expYear: paymentMethod.card?.exp_year ? paymentMethod.card.exp_year : null,
        },
      });

      enqueueSnackbar('Updated payment details', { variant: 'success' });
      onSuccess();

      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <Typography variant="h6">Add or update card details</Typography>
        <CardElement />
        <LoadingButton
          type="submit"
          disabled={!stripe}
          loading={loading}
          variant="contained"
          color="primary"
        >
          Add Payment Method
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default AddUpdateCardForm;
