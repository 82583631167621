import { Alert } from '@mui/material';
import {
  collection,
  deleteField,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import React, { useEffect } from 'react';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { Program, Program_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import useFeaturedProgramsReducer, { Actions, Types } from 'src/reducers/featuredProgramsReducer';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';
import useAuth from './useAuth';

// ----------------------------------------------------------------------

export default function useFeaturedPrograms() {
  const [state, dispatch] = useFeaturedProgramsReducer();
  const { userId } = useAuth();

  const { entities, status, error, listener } = state;
  const loading = status === FETCH_STATUS_TYPES_ENUM.LOADING;
  const finishedLoading =
    status === FETCH_STATUS_TYPES_ENUM.SUCCEEDED ||
    status === FETCH_STATUS_TYPES_ENUM.COMPLETED ||
    status === FETCH_STATUS_TYPES_ENUM.FAILED;

  // sort by featured index (if it exists)
  const items = Object.values(entities).sort(
    (a, b) => (a?.featured?.index || 0) - (b?.featured?.index || 0)
  );

  const reset = () => dispatch({ type: Types.stopListener });

  const addNewFeaturedProgram = async (programId: string) => {
    // Get the count of the current featured programs from firebase incase the state is not loaded
    const featuredProgramsLength = items.length;

    // Update the program
    const ref = doc(DB, 'programs', programId);
    await updateDoc(ref, {
      featured: {
        enabled: true,
        index: featuredProgramsLength,
      },
    });
  };

  const addNewFeaturedPrograms = async (programs: Program_WithID[]) => {
    const featuredProgramsLength = items.length;
    // Update the programs
    const batch = writeBatch(DB);
    programs.forEach((program, index) => {
      const ref = doc(DB, 'programs', program.id);
      batch.update(ref, {
        featured: {
          enabled: true,
          index: featuredProgramsLength + index,
        },
      });
    });
    await batch.commit();
  };

  const removeFeaturedProgram = async (id: string) => {
    const batch = writeBatch(DB);
    const ref = doc(DB, 'programs', id);
    // await updateDoc(ref, {
    //   featured: deleteField(),
    // });
    batch.update(ref, {
      featured: deleteField(),
    });

    // Update the indexes of the other featured programs
    items
      // Check if the program is not the one being removed
      .filter((program) => program.id !== id)
      .forEach((program, index) => {
        if (program.id !== id) {
          const programRef = doc(DB, 'programs', program.id);
          batch.update(programRef, {
            featured: {
              enabled: true,
              index,
            },
          });
        }
      });

    await batch.commit();
  };

  const setFeaturedPrograms = async (programs: Program_WithID[]) => {
    // Update the programs
    const batch = writeBatch(DB);
    programs.forEach((program) => {
      const ref = doc(DB, 'programs', program.id);
      batch.update(ref, {
        ...program,
      });
    });
    await batch.commit();
  };

  useEffect(() => {
    if (userId && status === FETCH_STATUS_TYPES_ENUM.IDLE) {
      startListener({ userId, listener, dispatch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, userId]);

  // Cleanup listener
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ErrorAlertComponent = () => (error ? <Alert severity="error">{error}</Alert> : null);

  return {
    featuredPrograms: items,
    status,
    loading,
    finishedLoading,
    reset,
    addNewFeaturedProgram,
    addNewFeaturedPrograms,
    removeFeaturedProgram,
    setFeaturedPrograms,
    ErrorAlert: ErrorAlertComponent,
  };
}

// ----------------------------------------------------------------------

type ListenerProps = {
  userId: string;
  listener: null | VoidFunction;
  dispatch: React.Dispatch<Actions>;
};

const startListener = async ({ userId, listener, dispatch }: ListenerProps) => {
  if (listener) {
    return;
  }

  dispatch({ type: Types.loading });
  try {
    const q = query(
      collection(DB, 'programs'),
      where('featured.enabled', '==', true),
      where('creatorIds', 'array-contains', userId)
    );
    // const q = query(collection(db, "programs"));
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const upsertItems: Program_WithID[] = [];
        const removeItems: string[] = [];

        querySnapshot.docChanges().forEach((change) => {
          const { id } = change.doc;
          const data = change.doc.data() as Program;

          // Convert firebase data dates to JS dates
          convertFirebaseDataDates(data);

          const item = { ...data, id } as Program_WithID;

          if (change.type === 'added' || change.type === 'modified') {
            upsertItems.push(item);
          }
          if (change.type === 'removed') {
            removeItems.push(id);
          }
        });
        dispatch({
          type: Types.succeeded,
          payload: { upsertItems, removeItems },
        });
      },
      (error) => {
        dispatch({ type: Types.failed, payload: error.message });
      }
    );

    dispatch({
      type: Types.startListener,
      payload: unsubscribe,
    });
  } catch (error) {
    const err = error as Error;
    const errorMessage = err.message;
    dispatch({ type: Types.failed, payload: errorMessage });
  }
};
