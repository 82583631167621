import { WorkoutDragItem, WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { createWorkoutExerciseOptions } from 'src/utils/createDragItems';
import uuidv4 from 'src/utils/uuidv4';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  movedItem: WorkoutDragItem;
  destinationItem: WorkoutDragItem;
  movedDown: boolean;
  movedUp: boolean;
};

// If grouped exercise was moved to become a solo exercise in the same workout
const handleMoveGroupedExerciseToSoloNewWorkout = ({
  state,
  movedItem,
  destinationItem,
  movedDown,
  movedUp,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [
    { id: movedItem.workoutId, changes: {} },
    { id: destinationItem.workoutId, changes: {} },
  ];
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutDragItems = {};
  changes.workoutExercises = {};

  let destinationGroupIndex =
    destinationItem?.groupIndex !== undefined ? destinationItem.groupIndex : destinationItem.index;

  // Possible destinations:
  // 1. destinationItem is a group header
  // 2. destinationItem is a group footer
  // 3. destinationItem is a solo exercise
  // 4. destinationItem is a exercise options

  // Possible scenarios:
  // 1. Moved item group becomes solo
  // 2. Moved item was last in group

  const exerciseAboveMovedItem = Object.values(state.workoutDragItems.entities).find(
    (item) =>
      !!item &&
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.workoutId === movedItem.workoutId &&
      item.groupId === movedItem.groupId &&
      item.index === movedItem.index - 1
  );

  const belowDestinationDragItem = Object.values(state.workoutDragItems.entities).find(
    (item) =>
      !!item &&
      item.workoutId === destinationItem.workoutId &&
      item.groupId === destinationItem.groupId &&
      item.dragIndex === destinationItem.dragIndex
  );

  const groupLength = Object.values(state.workoutDragItems.entities).filter(
    (item) =>
      !!item &&
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.workoutId === movedItem.workoutId &&
      item.groupId === movedItem.groupId
  ).length;

  const from = movedItem.dragIndex;
  const to = destinationItem.dragIndex;
  let newDragIndex = to;

  // If the group length was two
  // Remove group header and footer
  // If the group moved item came from became solo (e.g. 2 exercises to 1)
  let removedGroupHeader: WorkoutDragItem | undefined = undefined;
  let movedItemGroupBecameSolo = false;
  if (groupLength === 2) {
    movedItemGroupBecameSolo = true;
    const groupHeaderKey = movedItem.groupId;
    const groupFooterKey = `${movedItem.groupId}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`;
    // Find the group header
    removedGroupHeader = Object.values(state.workoutDragItems.entities).find(
      (item) =>
        !!item &&
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER &&
        item.workoutId === movedItem.workoutId &&
        item.groupId === movedItem.groupId
    );
    // Remove group header
    changes.workoutDragItems.removed = [groupHeaderKey, groupFooterKey];
  }

  // Adjustments to make when dragged above exercise options
  if (destinationItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS) {
    newDragIndex = to + 1;
    destinationGroupIndex = destinationGroupIndex + 1;
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('newDragIndex', newDragIndex);
    console.log('to', to);
  }

  // Create new exercise group
  const newExerciseGroup: WorkoutExerciseGroup = {
    id: uuidv4(),
    index: destinationGroupIndex,
    name: '',
    workoutId: destinationItem.workoutId,
  };

  // Update movedItem
  const movedDragItemChanges: Partial<WorkoutDragItem> = {
    index: 0,
    dragIndex: newDragIndex,
    inGroup: false,
    lastInGroup: true,
    groupId: newExerciseGroup.id,
    groupIndex: newExerciseGroup.index,
    workoutId: destinationItem.workoutId,
  };
  // Update exercise associated with movedItem
  const movedExerciseChanges: Partial<WorkoutExercise> = {
    index: 0,
    workoutExerciseGroupId: newExerciseGroup.id,
    workoutId: destinationItem.workoutId,
  };
  // Create exercise options
  const newExerciseOptions = createWorkoutExerciseOptions({
    id: newExerciseGroup.id,
    index: newExerciseGroup.index,
    dragIndex: newDragIndex + 1, // + 0 because we removed the group header and footer
    groupId: newExerciseGroup.id,
    workoutId: destinationItem.workoutId,
    inGroup: false,
    groupIndex: newExerciseGroup.index,
    lastInGroup: true,
  });

  // Push the changes
  changes.workoutDragItems.added = [newExerciseOptions];
  changes.workoutExerciseGroups.added = [newExerciseGroup];
  changes.workoutDragItems.updated = [{ id: movedItem.id, changes: movedDragItemChanges }];
  changes.workoutExercises.updated = [{ id: movedItem.id, changes: movedExerciseChanges }];

  // Find all the affected drag items
  // If between to and from
  // If moved item group index > item group index
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      // If the item is undefined or the workout id isn't the same as movedItem or destinationItem then skip
      if (
        !item ||
        (item.workoutId !== movedItem.workoutId && item.workoutId !== destinationItem.workoutId)
      ) {
        return false;
      }

      // Exclude the moved item
      if (item.id === movedItem.id) {
        return false;
      }

      // Include exercise above the moved item if moved item was last in group
      if (movedItem.lastInGroup && item.id === exerciseAboveMovedItem?.id) {
        return true;
      }

      // If group header and footer were removed from the removedGroupHeader
      if (
        removedGroupHeader &&
        item.workoutId === movedItem.workoutId &&
        item.dragIndex > removedGroupHeader.dragIndex
      ) {
        return true;
      }

      // Find all items below the destination item
      if (item.workoutId === destinationItem.workoutId && item.dragIndex >= to) {
        return true;
      }
      // Find all items below the moved item
      else if (item.workoutId === movedItem.workoutId && item.dragIndex > from) {
        return true;
      }

      return false;
    }
  );

  const workoutExerciseGroupUpdates: UpdateType[] = [];
  const workoutDragItemUpdates: UpdateType[] = [];
  const workoutExerciseUpdates: UpdateType[] = [];
  // Update the affected items
  affectedItems.forEach((item) => {
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseChanges: Partial<WorkoutExercise> = {};

    // If moved item was placed above destination items exercise options
    const newExerciseOptionsRelativeDragIndex = to;
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS &&
      item.id === destinationItem.id &&
      destinationItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    ) {
      dragItemChanges.dragIndex = item.dragIndex - 1;
    }
    // If item is below new exercise options
    else if (
      item.dragIndex >= newExerciseOptionsRelativeDragIndex && // + 1 to account for the new exercise options
      item.workoutId === destinationItem.workoutId
      // (movedUp && item.dragIndex >= newExerciseOptions.dragIndex) ||
      // (movedDown && item.dragIndex >= to)
    ) {
      dragItemChanges.dragIndex =
        (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) + 1;
    }

    // Handle updates for items in the group that became solo
    if (movedItemGroupBecameSolo && item.groupId === movedItem.groupId) {
      // If the group moved item came from became solo
      dragItemChanges.inGroup = false;
      if (item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS) {
        // Subtract 2 from the drag index
        dragItemChanges.dragIndex =
          (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) -
          2;
      } else if (item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE) {
        const incrementAmount = -1;
        // Add increment amount to the drag index
        dragItemChanges.dragIndex =
          (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) +
          incrementAmount;
      }
    }
    // If group header and footer were removed from the moved item group
    // Subtract 2 from the drag index
    else if (
      movedItemGroupBecameSolo &&
      item.dragIndex > from &&
      item.workoutId === movedItem.workoutId
    ) {
      dragItemChanges.dragIndex =
        (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) - 2;
    }

    // If item was below the moved item
    if (item.workoutId === movedItem.workoutId && item.dragIndex > from) {
      dragItemChanges.dragIndex =
        (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) - 1; // -1 because we are moving the item to a new group
    }

    // If item was below the destination item
    if (item.workoutId === destinationItem.workoutId && item.dragIndex >= to) {
      dragItemChanges.dragIndex =
        (dragItemChanges?.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) + 1; // +1 for the moved exercise + exercise options
    }

    // Find exercises in the moved item group below the moved item
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.groupId === movedItem.groupId &&
      item.dragIndex >= from
    ) {
      dragItemChanges.index = item.index - 1;
      exerciseChanges.index = item.index - 1;
    }

    // Update any groups below the added destination item group
    // Excluding exercise options below destination item
    if (
      movedItem.groupIndex !== undefined &&
      item.workoutId === destinationItem.workoutId &&
      !(
        belowDestinationDragItem?.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS &&
        belowDestinationDragItem?.id === item.id
      )
    ) {
      // Update group indexes from destination workout
      const incrementAmount = 1;
      // Update group indexes
      if (item.groupIndex !== undefined) {
        dragItemChanges.groupIndex = item.groupIndex + incrementAmount;
      }
      // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
      if (
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
      ) {
        dragItemChanges.index = item.index + incrementAmount;
      }

      // If the item is a group header or a solo exercise
      // Update the exercise group index
      if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
        exerciseGroupChanges.index = item.index + incrementAmount;
      } else if (
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
        !item.inGroup &&
        item.groupIndex !== undefined
      ) {
        exerciseGroupChanges.index = item.groupIndex + incrementAmount;
      }
    }

    // If moved item was last in group
    // Update exerciseAboveMovedItem to be last in group
    if (movedItem?.lastInGroup && item.id === exerciseAboveMovedItem?.id) {
      dragItemChanges.lastInGroup = true;
    }

    // Push updates
    workoutDragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });
    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
    if (exerciseChanges?.index !== undefined) {
      workoutExerciseUpdates.push({
        id: item.id,
        changes: exerciseChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...workoutDragItemUpdates);
  changes.workoutExercises.updated.push(...workoutExerciseUpdates);

  if (workoutExerciseGroupUpdates.length) {
    changes.workoutExerciseGroups.updated = [...workoutExerciseGroupUpdates];
  }

  return changes;
};

export default handleMoveGroupedExerciseToSoloNewWorkout;
