// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, BoxProps, Stack } from '@mui/material';
import { ProgramUser as ProgramUserType } from 'src/@types/user';
import Image from 'src/components/Image';
import useProgramClientModal from 'src/hooks/useProgramClientModal';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

const IconStyle = styled(Image)(({ theme }) => ({
  borderRadius: '50%',
  width: theme.spacing(3),
  height: theme.spacing(3),
  // border: `solid 2px ${theme.palette.background.paper}`,
  // boxShadow: `inset -1px 1px 2px ${alpha(theme.palette.common.black, 0.24)}`,
}));

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  programId: string;
  programUsers: ProgramUserType[];
  limit?: number;
}

export default function ProgramUsers({ programId, programUsers, limit = 3, sx }: Props) {
  const { openProgramClientModal } = useProgramClientModal();

  const showUsers = programUsers.slice(0, limit);
  const moreUsers = programUsers.length - limit;

  const handleOnClick = () => {
    openProgramClientModal(programId);
  };

  return (
    <Stack sx={{ cursor: 'pointer' }} onClick={handleOnClick}>
      <Typography variant="caption" color={'text.secondary'}>
        Program users:
      </Typography>

      <RootStyle component="span" sx={{ ...sx, mt: 0.5 }}>
        {showUsers.map((user, index) => (
          <IconStyle key={user.id} src={user.imageUrl} alt="program user" />
        ))}

        {programUsers.length === 0 && (
          <Typography variant="caption" color={'text.primary'}>
            None
          </Typography>
        )}

        {programUsers.length > limit && (
          <Typography
            variant="subtitle2"
            color={'text.primary'}
            sx={{ ml: 0.5 }}
          >{`+${moreUsers}`}</Typography>
        )}
      </RootStyle>
    </Stack>
  );
}
