import { Stack } from '@mui/material';
import React, { memo } from 'react';
import { SkeletonHistoryCard } from '.';

function SkeletonHistoryCardList({ length, sx }: { length: number; sx?: any }) {
  return (
    <Stack spacing={4}>
      {Array.from({ length: length }).map((_, index) => (
        <SkeletonHistoryCard key={index} sx={sx} />
      ))}
    </Stack>
  );
}

export default memo(SkeletonHistoryCardList);
