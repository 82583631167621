import { Box, Button, Stack } from '@mui/material';
import { memo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Iconify from 'src/components/Iconify';
import { SkeletonWeek } from 'src/components/skeleton';
import { getProgramCurrentTab, getProgramId } from 'src/redux/slices/program/program';
import { getProgramWeeksFetchStatus } from 'src/redux/slices/program/programWeeks';
import { addWorkout, selectAllWorkoutsByProgramWeekId } from 'src/redux/slices/program/workouts';
import { useDispatch, useSelector } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { Workout } from '.';
import { WORKOUT_WIDTH } from 'src/constants';

function Week() {
  const dispatch = useDispatch();
  const weekId = useSelector(getProgramCurrentTab);
  const programId = useSelector(getProgramId);
  const weekStatus = useSelector(getProgramWeeksFetchStatus);

  const workouts = useSelector((state) => selectAllWorkoutsByProgramWeekId(state, weekId));

  const spacer = <Box sx={{ width: 100, minWidth: 100, height: 20 }} />; // Workout to add space to the right

  if (
    weekStatus === FETCH_STATUS_TYPES_ENUM.LOADING ||
    weekStatus === FETCH_STATUS_TYPES_ENUM.IDLE
  ) {
    return <SkeletonWeek />;
  }

  const handleAddWorkout = () => {
    dispatch(addWorkout({ programId, weekId, index: workouts.length }));
  };

  return (
    <Droppable type="WORKOUT" droppableId={weekId} direction="horizontal">
      {(provided, snapshot) => (
        <Stack
          ref={provided.innerRef}
          {...provided.droppableProps}
          direction="row"
          spacing={4}
          sx={{ pl: 5, pb: 5, display: 'inline-flex' }}
        >
          {workouts.map((workout) => (
            <Workout key={workout.id} workout={workout} />
          ))}
          <Stack direction="row" sx={{ minWidth: WORKOUT_WIDTH, minHeight: 100 }}>
            {provided.placeholder}

            <Button
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              sx={{ minWidth: WORKOUT_WIDTH, height: 45 }}
              variant="outlined"
              onClick={handleAddWorkout}
            >
              Add Day
            </Button>
          </Stack>

          {spacer}
        </Stack>
      )}
    </Droppable>
  );
}

export default memo(Week);
