import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  Unsubscribe,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { HabitUser, Habit_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import { AppDispatch, RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';
import _ from 'lodash';
import generateDefaultHabitsForUser from '../functions/generateDefaultHabitsForUser';

const habitsAdapter = createEntityAdapter<Habit_WithID>({
  // Sort by index
  sortComparer: (a, b) => b.index - a.index,
});

// Save habit
export const saveHabit = createAsyncThunk<Habit_WithID, { habit: Habit_WithID; isEdit?: boolean }>(
  'habits/saveHabit',
  async ({ habit, isEdit = undefined }) => {
    const { id, ...habitData } = habit;

    // Update habit
    if (isEdit && id) {
      const update: Habit_WithID = { ...habit, lastUpdated: new Date() };
      const updateRef = doc(DB, 'habits', id);
      await updateDoc(updateRef, update);
    } else {
      const result = await addDoc(collection(DB, 'habits'), habitData);
      habit = { ...habitData, id: result.id } as Habit_WithID;
    }

    return habit;
  }
);

// Delete habit
export const deleteHabit = createAsyncThunk<string, string>('habits/deleteHabit', async (id) => {
  const deleteRef = doc(DB, 'habits', id);
  await deleteDoc(deleteRef);

  return id;
});

// Add habit user
export const saveHabitUser = createAsyncThunk<
  { habit: Habit_WithID; habitUser: HabitUser },
  { habitId: string; habitUser: HabitUser }
>('habits/saveHabitUser', async ({ habitId, habitUser }, { getState }) => {
  const { habits } = getState() as RootState;
  const habit = _.cloneDeep(habits.entities[habitId]);

  if (!habit) {
    throw new Error('Habit not found: ' + habitId);
  }

  // local update
  habit.users[habitUser.id] = habitUser;
  // Add to userIds array if not already there
  if (!habit.userIds.includes(habitUser.id)) {
    habit.userIds.push(habitUser.id);
  }
  habit.lastUpdated = new Date();

  // firebase update
  const update = {
    users: habit.users,
    lastUpdated: habit.lastUpdated,
    userIds: habit.userIds,
  };

  const updateRef = doc(DB, 'habits', habitId);
  await updateDoc(updateRef, update);

  return {
    habit,
    habitUser,
  };
});

// Remove habit user
export const removeHabitUser = createAsyncThunk<
  Habit_WithID,
  { habitId: string; habitUserId: string }
>('habits/removeHabitUser', async ({ habitId, habitUserId }, { getState }) => {
  const { habits } = getState() as RootState;
  const habit = _.cloneDeep(habits.entities[habitId]);

  if (!habit) {
    throw new Error('Habit not found');
  }

  // Local update
  delete habit.users[habitUserId];
  habit.userIds = habit.userIds.filter((id) => id !== habitUserId);
  habit.lastUpdated = new Date();

  // Firebase update
  const update = {
    users: habit.users,
    lastUpdated: habit.lastUpdated,
    userIds: habit.userIds,
  };

  const updateRef = doc(DB, 'habits', habitId);
  await updateDoc(updateRef, update);

  return habit;
});

const initialState = habitsAdapter.getInitialState({
  // Listener
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  listener: null,
  // Save
  saveStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  saveError: undefined,
  // Delete
  deleteStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  deleteError: undefined,
  // Add habit user
  saveHabitUserStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  saveHabitUserError: undefined,
  // Remove habit user
  removeHabitUserStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  removeHabitUserError: undefined,
} as {
  // Load
  status: FETCH_STATUS_TYPES_ENUM;
  error: string | null;
  listener: Unsubscribe | null;
  // Save
  saveStatus: FETCH_STATUS_TYPES_ENUM;
  saveError: string | undefined;
  // Delete
  deleteStatus: FETCH_STATUS_TYPES_ENUM;
  deleteError: string | undefined;
  // Add habit user
  saveHabitUserStatus: FETCH_STATUS_TYPES_ENUM;
  saveHabitUserError: string | undefined;
  // Remove habit user
  removeHabitUserStatus: FETCH_STATUS_TYPES_ENUM;
  removeHabitUserError: string | undefined;
});

export const slice = createSlice({
  name: 'habits',
  initialState,
  reducers: {
    setHabits: (
      state,
      action: PayloadAction<{
        upsertItems: Habit_WithID[];
        removeItems: string[];
      }>
    ) => {
      habitsAdapter.upsertMany(state, action.payload.upsertItems);
      habitsAdapter.removeMany(state, action.payload.removeItems);
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
    },
    // --------------------------------------------------
    // Listener commands start
    // --------------------------------------------------
    fetchConnectionsRequestsLoading(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
    },
    fetchConnectionsRequestsSuccess(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      state.listener = action.payload;
    },
    fetchConnectionsRequestsFailure(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.error = action.payload;
      console.error(action.payload);
    },
    startListener: (state, action) => {
      state.listener = action.payload;
    },
    stopListener: (state) => {
      if (state.listener) {
        state.listener();
      }
    },
    reset: (state) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    },
  },
  extraReducers(builder) {
    // --------------------------------------------------
    // Save commands start
    // --------------------------------------------------
    builder.addCase(saveHabit.pending, (state) => {
      state.saveStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
    });
    builder.addCase(saveHabit.fulfilled, (state, action) => {
      state.saveStatus = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      habitsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(saveHabit.rejected, (state, action) => {
      state.saveStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.saveError = action.error.message;
    });
    // --------------------------------------------------
    // --------------------------------------------------
    // Add habit user commands start
    // --------------------------------------------------
    builder.addCase(saveHabitUser.pending, (state) => {
      state.saveHabitUserStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
    });
    builder.addCase(saveHabitUser.fulfilled, (state, action) => {
      state.saveHabitUserStatus = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      habitsAdapter.upsertOne(state, action.payload.habit);
    });
    builder.addCase(saveHabitUser.rejected, (state, action) => {
      state.saveHabitUserStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.saveHabitUserError = action.error.message;
    });
    // --------------------------------------------------
    // --------------------------------------------------
    // Remove habit user commands start
    // --------------------------------------------------
    builder.addCase(removeHabitUser.pending, (state) => {
      state.removeHabitUserStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
    });
    builder.addCase(removeHabitUser.fulfilled, (state, action) => {
      state.removeHabitUserStatus = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      habitsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(removeHabitUser.rejected, (state, action) => {
      state.removeHabitUserStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.removeHabitUserError = action.error.message;
    });
  },
});

export const {
  setHabits,
  fetchConnectionsRequestsLoading,
  fetchConnectionsRequestsSuccess,
  fetchConnectionsRequestsFailure,
  startListener,
  stopListener,
  reset,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------
export const startHabitsListener =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const userId = state.user.id;
    const { listener } = state.habits;

    if (listener) {
      console.error('Habits listener already exists');
      return;
    }

    dispatch(fetchConnectionsRequestsLoading());
    try {
      const q = query(collection(DB, 'habits'), where('creator.id', '==', userId));
      const unsubscribe = await onSnapshot(
        q,
        (querySnapshot) => {
          const upsertItems: Habit_WithID[] = [];
          const removeItems: string[] = [];

          querySnapshot.docChanges().forEach((change) => {
            const { id } = change.doc;
            const data = change.doc.data() as any;
            convertFirebaseDataDates(data);

            const item = { ...data, id } as Habit_WithID;

            if (change.type === 'added' || change.type === 'modified') {
              upsertItems.push(item);
            }
            if (change.type === 'removed') {
              removeItems.push(id);
            }
          });
          dispatch(setHabits({ upsertItems, removeItems }));
        },
        (error) => {
          dispatch(fetchConnectionsRequestsFailure(error.message));
        }
      );
      dispatch(startListener(unsubscribe));
    } catch (error) {
      dispatch(fetchConnectionsRequestsFailure(error));
    }
  };

// ----------------------------------------------------------------------

export const createDefaultHabits =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const defualtHabits = generateDefaultHabitsForUser({ user: getState().user });

    defualtHabits.forEach((habit) => {
      // Save all to firebase
      const batch = writeBatch(DB);

      const habitRef = doc(collection(DB, 'habits'));
      batch.set(habitRef, habit);

      // Update the user to show that they have visited the habits screen
      const userRef = doc(collection(DB, 'users'), getState().user.id);
      // user.stripe.habitsSubscription.hasVisitedHabitsScreen
      batch.update(userRef, { 'stripe.habitsSubscription.hasVisitedHabitsScreen': true });

      batch.commit().catch((error) => {
        console.error('Error saving default habits', error);
      });
    });
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const { selectAll: selectAllHabits, selectById: selectHabitsById } =
  habitsAdapter.getSelectors((state: RootState) => state.habits);

// Listener
export const getHabitsFetchStatus = (state: RootState) => state.habits.status;
export const getHabitsFetchError = (state: RootState) => state.habits.error;
// Save
export const getHabitsSaveStatus = (state: RootState) => state.habits.saveStatus;
export const getHabitsSaveError = (state: RootState) => state.habits.saveError;
// Delete
export const getHabitsDeleteStatus = (state: RootState) => state.habits.deleteStatus;
export const getHabitsDeleteError = (state: RootState) => state.habits.deleteError;
// Add habit user
export const getHabitssaveHabitUserStatus = (state: RootState) => state.habits.saveHabitUserStatus;
export const getHabitssaveHabitUserError = (state: RootState) => state.habits.saveHabitUserError;
// Remove habit user
export const getHabitsRemoveHabitUserStatus = (state: RootState) =>
  state.habits.removeHabitUserStatus;
export const getHabitsRemoveHabitUserError = (state: RootState) =>
  state.habits.removeHabitUserError;

// Max index from habits
export const selectMaxHabitIndex = createSelector([selectAllHabits], (habits) => {
  if (habits.length === 0) {
    return 0;
  }
  return Math.max(...habits.map((habit) => habit.index));
});
