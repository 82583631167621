// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormControlLabelProps, Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

type Option = {
  id: string;
  name: string;
  imageUrl?: string;
};

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  label: string;
  options: Option[];
}

export default function RHFAutocomplete({ name, label, options, ...other }: RHFMultiCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const handleOnChange = (option: string | string[]) => {
          if (Array.isArray(option)) {
            return option;
          }
          return [option];
        };

        return (
          <Autocomplete
            {...field}
            multiple
            disablePortal
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            defaultValue={field.value}
            onChange={(_, value) => field.onChange(handleOnChange(value))}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  checked={!!field.value.find((value: Option) => value.id === option.id)}
                  onChange={() => field.onChange(handleOnChange(option))}
                  style={{ marginRight: 8 }}
                />
                {option.name}
              </li>
            )}
            // style={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} error={!!error} helperText={error?.message} label={label} />
            )}
            // {...other}
          />
        );
      }}
    />
  );
}
