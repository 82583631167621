import type { Transition } from 'history';
import { useCallback, useContext } from 'react';
import { UnsavedPromptContext } from 'src/contexts/UnsavedPromptContext';
import { useBlocker } from './useBlocker';

export function useUnsavedPrompt(when = true) {
  const showPrompt = useContext(UnsavedPromptContext);

  const blocker = useCallback(
    async (tx: Transition) => {
      try {
        await showPrompt();
        tx.retry();
      } catch (e) {
        // Do nothing
      }
    },
    [showPrompt]
  );

  return useBlocker(blocker, when);
}
