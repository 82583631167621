import { ReactNode } from 'react';
import { Navigate } from 'react-router';
import { STRIPE_SUBSCRIPTION_STATUS_ENUM } from 'src/@types/enums';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------
type Props = {
  children: ReactNode;
};

export default function OverduePaymentGuard({ children }: Props) {
  // If a user has failed invoices and their subscription is cancelled, they will be redirected to the payment page
  const { user } = useCurrentUser();

  const clientSubscriptionIsCancelled =
    user?.stripe?.clientSubscription?.status === STRIPE_SUBSCRIPTION_STATUS_ENUM.CANCELED;
  const hasFailedInvoices =
    user?.stripe?.failedInvoices && Object.values(user.stripe.failedInvoices).length > 0;
  const hasOverduePayment = clientSubscriptionIsCancelled && hasFailedInvoices;

  if (hasOverduePayment) {
    return <Navigate to={PATH_PAGE.overduePayment} />;
  }

  return <>{children}</>;
}
