import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';
import { WorkoutDragItem as WorkoutDragItemType } from 'src/@types/program';

export const createWorkoutExerciseGroup = ({
  id,
  index,
  dragIndex,
  workoutId,
  type,
  inGroup = true,
}: {
  id: string;
  index: number;
  dragIndex: number;
  workoutId: string;
  type: WORKOUT_DRAG_DATA_ENUM;
  inGroup?: boolean;
}) => ({
  key: id + '-' + type,
  index: index,
  dragIndex: dragIndex,
  type: type,
  id: type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ? id : id + '-' + type,
  groupId: id,
  workoutId: workoutId,
  inGroup: inGroup,
  groupIndex: index,
});

export const createWorkoutExercise = ({
  id,
  index,
  dragIndex,
  groupId,
  workoutId,
  inGroup,
  groupIndex,
  lastInGroup,
}: {
  id: string;
  index: number;
  dragIndex: number;
  groupId: string;
  workoutId: string;
  groupIndex: number;
  inGroup?: boolean;
  lastInGroup?: boolean;
}) =>
  ({
    key: id,
    index: index,
    dragIndex: dragIndex,
    type: WORKOUT_DRAG_DATA_ENUM.EXERCISE,
    id: id,
    groupId: groupId,
    workoutId: workoutId,
    inGroup: inGroup,
    groupIndex: groupIndex,
    lastInGroup: lastInGroup,
  } as WorkoutDragItemType);

export const createWorkoutExerciseOptions = ({
  id,
  index,
  dragIndex,
  groupId,
  workoutId,
  inGroup,
  groupIndex,
  lastInGroup,
}: {
  id: string;
  index: number;
  dragIndex: number;
  groupId: string;
  workoutId: string;
  groupIndex: number;
  inGroup?: boolean;
  lastInGroup?: boolean;
}) =>
  ({
    key: `${groupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`,
    index: groupIndex,
    dragIndex: dragIndex,
    type: WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS,
    id: `${groupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`,
    groupId: groupId,
    workoutId: workoutId,
    inGroup: inGroup,
    groupIndex: groupIndex,
    lastInGroup: lastInGroup,
  } as WorkoutDragItemType);
