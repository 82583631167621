import { WorkoutDragItem, WorkoutExercise } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { createWorkoutExerciseGroup } from 'src/utils/createDragItems';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  movedItem: WorkoutDragItem;
  destinationItem: WorkoutDragItem;
  movedDown: boolean;
  movedUp: boolean;
};

// If grouped exercise was moved to a new group (combined) in a new workout
const handleMoveGroupedExerciseToNewGroupNewWorkout = ({
  state,
  movedItem,
  destinationItem,
  movedDown,
  movedUp,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [
    { id: movedItem.workoutId, changes: {} },
    { id: destinationItem.workoutId, changes: {} },
  ];
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutDragItems = {};
  changes.workoutExercises = {};

  const destinationGroupIndex =
    destinationItem?.groupIndex !== undefined ? destinationItem.groupIndex : destinationItem.index;

  const exerciseAboveMovedItem = Object.values(state.workoutDragItems.entities).find(
    (item) =>
      !!item &&
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.workoutId === movedItem.workoutId &&
      item.groupId === movedItem.groupId &&
      item.index === movedItem.index - 1
  );

  const groupLength = Object.values(state.workoutDragItems.entities).filter(
    (item) =>
      !!item &&
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.workoutId === movedItem.workoutId &&
      item.groupId === movedItem.groupId
  ).length;

  const from = movedItem.dragIndex;
  const to = destinationItem.dragIndex;
  const newDragIndex = to;

  // If the group length was two
  // Remove group header and footer
  // If the group moved item came from became solo (e.g. 2 exercises to 1)
  let removedGroupHeader: WorkoutDragItem | undefined = undefined;
  let movedItemGroupBecameSolo = false;
  const groupHeaderKey = movedItem.groupId;
  const groupFooterKey = `${movedItem.groupId}-${WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER}`;
  if (groupLength === 2) {
    movedItemGroupBecameSolo = true;
    // Find the group header
    removedGroupHeader = Object.values(state.workoutDragItems.entities).find(
      (item) =>
        !!item &&
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER &&
        item.workoutId === movedItem.workoutId &&
        item.groupId === movedItem.groupId
    );
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('newDragIndex', newDragIndex);
    console.log('to', to);
  }
  // Create a group header
  const newGroupHeader = createWorkoutExerciseGroup({
    id: destinationItem.groupId,
    index: destinationGroupIndex,
    dragIndex: newDragIndex,
    workoutId: destinationItem.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER,
    inGroup: true,
  });

  // Update destinationItem
  const destinationDragItemChanges: Partial<WorkoutDragItem> = {
    dragIndex: newDragIndex + 1,
    inGroup: true,
    lastInGroup: false,
    groupIndex: destinationGroupIndex,
  };

  // Update movedItem
  const movedDragItemChanges: Partial<WorkoutDragItem> = {
    index: 1,
    dragIndex: newDragIndex + 2,
    inGroup: true,
    groupId: destinationItem.groupId,
    lastInGroup: true,
    groupIndex: destinationGroupIndex,
    workoutId: destinationItem.workoutId,
  };
  // Update exercise associated with movedItem
  const movedExerciseChanges: Partial<WorkoutExercise> = {
    index: 1,
    workoutExerciseGroupId: destinationItem.groupId,
    workoutId: destinationItem.workoutId,
  };

  // Create a group footer
  const newGroupFooter = createWorkoutExerciseGroup({
    id: destinationItem.groupId,
    index: destinationGroupIndex,
    dragIndex: newDragIndex + 3,
    workoutId: destinationItem.workoutId,
    type: WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER,
    inGroup: true,
  });

  // Update destinationItem exercise options
  const destinationExerciseOptionsKey = `${destinationItem.groupId}-${WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS}`;
  const destinationExerciseOptionChanges: Partial<WorkoutDragItem> = {
    dragIndex: newDragIndex + 4,
    inGroup: true,
  };

  // Push the changes
  changes.workoutDragItems.added = [newGroupHeader, newGroupFooter];
  changes.workoutDragItems.updated = [
    {
      id: destinationItem.id,
      changes: destinationDragItemChanges,
    },
    { id: movedItem.id, changes: movedDragItemChanges },
    { id: destinationExerciseOptionsKey, changes: destinationExerciseOptionChanges },
  ];
  changes.workoutExercises.updated = [{ id: movedItem.id, changes: movedExerciseChanges }];
  // Remove group header
  if (movedItemGroupBecameSolo) {
    changes.workoutDragItems.removed = [groupHeaderKey, groupFooterKey];
  }

  // Find all the affected drag items
  // If between to and from
  // If moved item group index > item group index
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      // If the item is undefined or the workout id isn't the same as movedItem or destinationItem then skip
      if (
        !item ||
        (item.workoutId !== movedItem.workoutId && item.workoutId !== destinationItem.workoutId)
      ) {
        return false;
      }

      // Exclude the moved item
      if (item.id === movedItem.id) {
        return false;
      }

      // Include exercise above the moved item if moved item was last in group
      if (movedItem.lastInGroup && item.id === exerciseAboveMovedItem?.id) {
        return true;
      }

      // If group header and footer were removed from the removedGroupHeader
      if (
        removedGroupHeader &&
        item.workoutId === movedItem.workoutId &&
        item.dragIndex > removedGroupHeader.dragIndex
      ) {
        return true;
      }

      // Find all items below the destination item
      if (item.workoutId === destinationItem.workoutId && item.dragIndex >= to) {
        return true;
      }
      // Find all items below the moved item
      else if (item.workoutId === movedItem.workoutId && item.dragIndex > from) {
        return true;
      }

      return false;
    }
  );

  const workoutDragItemUpdates: UpdateType[] = [];
  const workoutExerciseUpdates: UpdateType[] = [];
  // Update the affected items
  affectedItems.forEach((item) => {
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseChanges: Partial<WorkoutExercise> = {};

    // Handle updates for items in the group that became solo
    if (movedItemGroupBecameSolo && item.groupId === movedItem.groupId) {
      // If the group moved item came from became solo
      dragItemChanges.inGroup = false;
      if (item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS) {
        // Subtract 2 from the drag index
        dragItemChanges.dragIndex =
          (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) -
          2;
      } else if (item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE) {
        const incrementAmount = -1;
        // Add increment amount to the drag index
        dragItemChanges.dragIndex =
          (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) +
          incrementAmount;
      }
    }
    // If group header and footer were removed from the moved item group
    // Subtract 2 from the drag index
    else if (
      movedItemGroupBecameSolo &&
      item.dragIndex > from &&
      item.workoutId === movedItem.workoutId
    ) {
      dragItemChanges.dragIndex =
        (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) - 2;
    }

    // If item below newly created header and footer
    if (item.dragIndex > to && item.workoutId === destinationItem.workoutId) {
      dragItemChanges.dragIndex =
        (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) + 2;
    }

    // If item was below the moved item
    if (item.workoutId === movedItem.workoutId && item.dragIndex > from) {
      dragItemChanges.dragIndex =
        (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) - 1; // -1 because we are moving the item to a new group
    }

    // If item was below the destination item
    if (item.workoutId === destinationItem.workoutId && item.dragIndex >= to) {
      dragItemChanges.dragIndex =
        (dragItemChanges?.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) + 1; // +1 for the moved exercise + exercise options
    }

    // Find exercises in the moved item group below the moved item
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.groupId === movedItem.groupId &&
      item.dragIndex >= from
    ) {
      dragItemChanges.index = item.index - 1;
      exerciseChanges.index = item.index - 1;
    }

    // If moved item was last in group
    // Update the above item to be last in group
    if (movedItem?.lastInGroup && item.id === exerciseAboveMovedItem?.id) {
      dragItemChanges.lastInGroup = true;
    }

    // Push updates
    workoutDragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });
    if (exerciseChanges?.index !== undefined) {
      workoutExerciseUpdates.push({
        id: item.id,
        changes: exerciseChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...workoutDragItemUpdates);
  changes.workoutExercises.updated.push(...workoutExerciseUpdates);

  return changes;
};

export default handleMoveGroupedExerciseToNewGroupNewWorkout;
