import { Box } from '@mui/material';
import { memo, useCallback } from 'react';
import { updateWorkoutExerciseMetricValue } from 'src/redux/slices/program/workoutExerciseMetricValues';
import { useDispatch } from 'src/redux/store';
import { ExerciseMetricInput } from '.';

type Props = {
  id?: string;
  value?: string;
  workoutExerciseId?: string;
  exerciseMetricName?: string;
  spacingRight?: number;
};

function WorkoutExerciseMetricValue({
  id,
  value,
  workoutExerciseId,
  exerciseMetricName,
  spacingRight,
}: Props) {
  const dispatch = useDispatch();

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (id) {
        dispatch(updateWorkoutExerciseMetricValue({ id, updates: { value: e.target.value } }));
      }
    },
    [id, dispatch]
  );

  return (
    <Box sx={{ mr: spacingRight }}>
      <ExerciseMetricInput
        value={value}
        workoutExerciseId={workoutExerciseId}
        exerciseMetricName={exerciseMetricName}
        onChange={id ? handleValueChange : undefined}
      />
    </Box>
  );
}

export default memo(WorkoutExerciseMetricValue);
