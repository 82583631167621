import { Changes } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import {
  WorkoutExercise,
  WorkoutExerciseMetric,
  WorkoutExerciseMetricValue,
} from 'src/@types/program';

type Props = {
  state: RootState;
  changes: Changes;
  // workoutExerciseGroupId: string;
  newWorkoutId: string;
};

const applyNewWorkoutChanges = ({
  state,
  changes,
  // workoutExerciseGroupId,
  newWorkoutId,
}: Props) => {
  // All data for the workout exercise group
  changes.workoutExercises = changes.workoutExercises ? changes.workoutExercises : {};
  changes.workoutExerciseMetrics = changes.workoutExerciseMetrics
    ? changes.workoutExerciseMetrics
    : {};
  changes.workoutExerciseMetricValues = changes.workoutExerciseMetricValues
    ? changes.workoutExerciseMetricValues
    : {};

  // Check if there are any workout exercises updates
  if (!changes.workoutExercises?.updated) {
    return;
  }

  // Find any workoutExercises with "workoutId" in their change data
  const workoutExerciseIdsWithWorkoutIdUpdate = Object.values(changes.workoutExercises.updated)
    .filter((update) => {
      const workoutExerciseUpdate = update as {
        id: string;
        changes: Partial<WorkoutExercise>;
      };
      if (workoutExerciseUpdate.changes?.workoutId) {
        return true;
      }
      return false;
    })
    .map((update) => update.id);

  // Find associated data
  // const workoutExercises = Object.values(state.workoutExercises.entities).filter(
  //   (exercise): exercise is WorkoutExercise =>
  //     !!exercise && exercise.workoutExerciseGroupId === workoutExerciseGroupId
  // );
  // const workoutExerciseIds = workoutExercises.map((exercise) => exercise.id);
  const workoutExerciseMetrics = Object.values(state.workoutExerciseMetrics.entities).filter(
    (metric): metric is WorkoutExerciseMetric =>
      !!metric && workoutExerciseIdsWithWorkoutIdUpdate.includes(metric.workoutExerciseId)
  );

  const workoutExerciseMetricValues = Object.values(
    state.workoutExerciseMetricValues.entities
  ).filter(
    (metricValue): metricValue is WorkoutExerciseMetricValue =>
      !!metricValue && workoutExerciseIdsWithWorkoutIdUpdate.includes(metricValue.workoutExerciseId)
  );

  // Apply changes
  const update = {
    workoutId: newWorkoutId,
  };

  // workoutExercises.forEach((exercise) => {
  //   const updated = changes.workoutExercises?.updated ? changes.workoutExercises.updated : [];
  //   updated.push({
  //     id: exercise.id,
  //     changes: update,
  //   });

  //   if (changes?.workoutExercises) {
  //     changes.workoutExercises.updated = updated;
  //   }
  // });

  workoutExerciseMetrics.forEach((metric) => {
    const updated = changes.workoutExerciseMetrics?.updated
      ? changes.workoutExerciseMetrics.updated
      : [];
    updated.push({
      id: metric.id,
      changes: update,
    });

    if (changes?.workoutExerciseMetrics) {
      changes.workoutExerciseMetrics.updated = updated;
    }
  });

  workoutExerciseMetricValues.forEach((metricValue) => {
    const updated = changes.workoutExerciseMetricValues?.updated
      ? changes.workoutExerciseMetricValues.updated
      : [];
    updated.push({
      id: metricValue.id,
      changes: update,
    });

    if (changes?.workoutExerciseMetricValues) {
      changes.workoutExerciseMetricValues.updated = updated;
    }
  });
};

export default applyNewWorkoutChanges;
