// @mui
import { IconButton } from '@mui/material';
import { memo } from 'react';
import Iconify from 'src/components/Iconify';
//

// ----------------------------------------------------------------------

type Props = {
  active: boolean;
  collapsed: boolean;
  toggleCollapsed: () => void;
};

function CollapseButton({ active, collapsed, toggleCollapsed }: Props) {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    toggleCollapsed();
  };

  return (
    <IconButton onClick={handleClick} sx={{ m: 0, p: 0.5 }}>
      <Iconify
        // icon={active ? (collapsed ? 'eva:expand-fill' : 'eva:collapse-fill') : ''}
        icon={collapsed ? 'eva:expand-fill' : 'eva:collapse-fill'}
        color={active ? 'text.primary' : 'action.disabledBackground'}
        width={20}
        height={20}
        sx={{ opacity: 0.5, ':hover': { opacity: 1 } }}
      />
    </IconButton>
  );
}

export default memo(CollapseButton);
