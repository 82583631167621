import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { Alert } from '@mui/material';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import {
  fetchStripeInvoices,
  getStripeInvoicesFetchError,
  getStripeInvoicesFetchStatus,
  selectAllStripeInvoices,
} from 'src/redux/slices/stripeInvoices';
import useCurrentUser from './useCurrentUser';

// ----------------------------------------------------------------------

export default function useStripeInvoices() {
  const dispatch = useDispatch();
  const { user } = useCurrentUser();
  const invoices = useSelector(selectAllStripeInvoices);
  const fetchStatus = useSelector(getStripeInvoicesFetchStatus);
  const fetchError = useSelector(getStripeInvoicesFetchError);

  useEffect(() => {
    if (user.id && fetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(fetchStripeInvoices({ appName: user.appName }));
    }
  }, [fetchStatus, user, dispatch]);

  const ErrorAlert = () => (!!fetchError ? <Alert severity="error">{fetchError}</Alert> : null);

  return {
    invoices,
    fetchStatus,
    fetchError,
    ErrorAlert,
  };
}
