// contexts
import { DB } from 'src/contexts/FirebaseContext';
// types
import { doc, WriteBatch } from 'firebase/firestore';
import {
  WorkoutExerciseGroup as WorkoutExerciseGroupFB,
  WorkoutExercise as WorkoutExerciseFB,
} from 'src/@types/firebase';
import { WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { RootState } from 'src/redux/store';
// util
import uuidv4 from 'src/utils/uuidv4';

type Props = {
  destinationWorkoutId: string;
  exerciseGroup: WorkoutExerciseGroup;
  batch: WriteBatch;
  state: RootState;
};

/**
 * Duplicates an Exercise group to a workout
 *
 * @param destinationWorkoutId - The id of the workout to add the exercise group to.
 * @param exerciseGroup - Exercise group
 * @param batch - The Firestore batch to write to
 * @param state - The current state of the store
 *
 */
const handleCopyExerciseGroupToOtherProgram = ({
  destinationWorkoutId,
  exerciseGroup,
  batch,
  state,
}: Props) => {
  // Fetch the workout this exercise group belongs to
  const workout = state.copyToModal.workouts.find((workout) => workout.id === destinationWorkoutId);
  if (!workout) throw new Error(`Workout not found: ${destinationWorkoutId}`);

  // Duplicate the exercise group
  const duplicate: WorkoutExerciseGroupFB = {
    id: uuidv4(),
    index: 0,
    name: exerciseGroup.name,
  };

  // Update the indexes of any exercise groups after the duplicated one
  const workoutExerciseGroups = workout.workoutExerciseGroups.map((exerciseGroup) => {
    if (exerciseGroup.index >= duplicate.index) {
      return {
        ...exerciseGroup,
        index: exerciseGroup.index + 1,
      };
    }
    return exerciseGroup;
  });

  // Add the duplicated exercise group to the workout
  workoutExerciseGroups.push(duplicate);

  // sort the exercise groups by index
  workoutExerciseGroups.sort((a, b) => a.index - b.index);

  // Duplicate the exercises
  const duplicatedWorkoutExercises = Object.values(state.workoutExercises.entities)
    .filter(
      (exercise): exercise is WorkoutExercise =>
        !!exercise && exercise.workoutExerciseGroupId === exerciseGroup.id
    )
    .map((exercise) => {
      const duplicatedExercise: WorkoutExerciseFB = {
        id: uuidv4(),
        index: exercise.index,
        workoutExerciseGroupId: duplicate.id,
        sets: exercise.sets,
        setsVisible: exercise.setsVisible,
        coachNotes: exercise.coachNotes,
        header: exercise.header,
        exerciseId: exercise.exerciseId,
        name: exercise.name,
        youtubeId: exercise?.youtubeId ? exercise.youtubeId : '',
        thumbnailUrl: exercise?.thumbnailUrl ? exercise.thumbnailUrl : '',
      };
      return duplicatedExercise;
    });

  // Add the duplicated exercises to the workout
  const workoutExercises = [...workout.workoutExercises, ...duplicatedWorkoutExercises];

  // Update the workout
  const ref = doc(
    DB,
    'programs',
    workout.programId,
    'programWeeks',
    workout.programWeekId,
    'workouts',
    workout.id
  );
  batch.update(ref, { workoutExerciseGroups, workoutExercises });
};

export default handleCopyExerciseGroupToOtherProgram;
