import { Stack, Typography } from '@mui/material';
import { memo } from 'react';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { getExerciseMetricIconName } from 'src/utils/getExerciseMetricIconName';

type Props = {
  exerciseMetricName: string;
  collapsed: boolean;
};

function ExerciseMetricIcon({ exerciseMetricName, collapsed }: Props) {
  const exerciseMetricIconName = getExerciseMetricIconName(exerciseMetricName);

  // Shorten Percentage to Percent and Assistance to Assist
  if (exerciseMetricName === 'Percentage') {
    exerciseMetricName = 'Percent';
  }
  if (exerciseMetricName === 'Assistance') {
    exerciseMetricName = 'Assist';
  }
  if (exerciseMetricName === 'Resistance') {
    exerciseMetricName = 'Resist';
  }

  return (
    <Stack alignItems="center" direction="row" spacing={0.5} sx={{ minWidth: collapsed ? 0 : 60 }}>
      <SvgIconStyle
        src={`/icons/${exerciseMetricIconName}.svg`}
        sx={{ width: 16, height: 16, color: 'text.secondary' }}
      />
      <Typography variant="caption" color="text.secondary" sx={{ fontSize: 10 }}>
        {exerciseMetricName}:
      </Typography>
    </Stack>
  );
}

export default memo(ExerciseMetricIcon);
