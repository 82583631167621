import { ReactNode, createContext, useState, useCallback } from 'react';

// ----------------------------------------------------------------------

export type HeaderContextProps = {
  headerComponent?: ReactNode;
  setHeaderComponent: (headerComponent: ReactNode) => void;
  headerOptions?: ReactNode;
  setHeaderOptions: (headerOptions: ReactNode) => void;
  resetHeader: () => void;
};

const initialState: HeaderContextProps = {
  headerComponent: undefined,
  setHeaderComponent: () => {},
  headerOptions: undefined,
  setHeaderOptions: () => {},
  resetHeader: () => {},
};

const HeaderContext = createContext(initialState);

type HeaderProviderProps = {
  children: ReactNode;
};

function HeaderProvider({ children }: HeaderProviderProps) {
  const [headerComponent, setHeaderComponent] = useState<ReactNode>();
  const [headerOptions, setHeaderOptions] = useState<ReactNode>();

  const handleSetHeaderComponent = useCallback((headerComponent: ReactNode) => {
    setHeaderComponent(headerComponent);
  }, []);

  const handleSetHeaderOptions = useCallback((headerOptions: ReactNode) => {
    setHeaderOptions(headerOptions);
  }, []);

  const handleResetHeader = useCallback(() => {
    setHeaderComponent(initialState?.headerComponent);
    setHeaderOptions(initialState?.headerOptions);
  }, []);

  return (
    <HeaderContext.Provider
      value={{
        headerComponent: headerComponent,
        setHeaderComponent: handleSetHeaderComponent,
        headerOptions: headerOptions,
        setHeaderOptions: handleSetHeaderOptions,
        resetHeader: handleResetHeader,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}

export { HeaderProvider, HeaderContext };
