// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormControlLabelProps, Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

type Option = {
  id: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
};

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  label: string;
  options: Option[];
  disabled?: boolean;
}

export default function RHFAutocomplete({
  name,
  label,
  options,
  disabled,
  ...other
}: RHFMultiCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const onSelected = (option: string) =>
          field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];

        const handleOnChange = (option: Option) => option;

        return (
          <Autocomplete
            {...field}
            disablePortal
            disabled={disabled}
            options={options}
            getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            defaultValue={field.value}
            onChange={(_, value) => field.onChange(handleOnChange(value))}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={selected}
                  onChange={() => field.onChange(onSelected(option))}
                />
                {option.firstName + ' ' + option.lastName}
              </li>
            )}
            // style={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} error={!!error} helperText={error?.message} label={label} />
            )}
            // {...other}
          />
        );
      }}
    />
  );
}
