import { WorkoutDragItem, WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  movedItem: WorkoutDragItem;
  destinationItem: WorkoutDragItem;
  movedDown: boolean;
  movedUp: boolean;
};

// If solo exercise was moved within it's current workout
const handleMoveExerciseToNewWorkout = ({
  state,
  movedItem,
  destinationItem,
  movedDown,
  movedUp,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [
    { id: movedItem.workoutId, changes: {} },
    { id: destinationItem.workoutId, changes: {} },
  ];
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutExercises = {};
  changes.workoutDragItems = {};

  const from = movedItem.dragIndex;
  let to = destinationItem.dragIndex;

  let destinationGroupIndex =
    destinationItem?.groupIndex !== undefined ? destinationItem.groupIndex : destinationItem.index;

  if (destinationItem.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS) {
    to += 1;
    destinationGroupIndex += 1;
  }

  const exerciseOptions = Object.values(state.workoutDragItems.entities).find(
    (item) =>
      !!item &&
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS &&
      item.workoutId === movedItem.workoutId &&
      item.groupId === movedItem.groupId
  );

  let movedItemCount = 0;
  // Update movedItem index
  const movedDragItemChanges: Partial<WorkoutDragItem> = {
    groupIndex: destinationGroupIndex,
    dragIndex: to,
    workoutId: destinationItem.workoutId,
  };
  // Increment number of moved items
  movedItemCount += 1;
  const movedExerciseOptionsChanges: Partial<WorkoutDragItem> = {
    index: destinationGroupIndex,
    groupIndex: destinationGroupIndex,
    dragIndex: to + movedItemCount,
    workoutId: destinationItem.workoutId,
  };
  // Increment number of moved items
  movedItemCount += 1;

  // Update exercise group
  const movedExerciseGroupChanges: Partial<WorkoutExerciseGroup> = {
    index: destinationGroupIndex,
    workoutId: destinationItem.workoutId,
  };

  // Update exericse
  const movedExerciseChanges: Partial<WorkoutExercise> = {
    workoutId: destinationItem.workoutId,
  };

  // exercise metrics and exercise metric values are handled by the applyNewWorkoutChanges method

  // Push the changes
  changes.workoutDragItems.updated = [{ id: movedItem.id, changes: movedDragItemChanges }];
  if (exerciseOptions) {
    changes.workoutDragItems.updated.push({
      id: exerciseOptions.id,
      changes: movedExerciseOptionsChanges,
    });
  }
  changes.workoutExercises.updated = [{ id: movedItem.id, changes: movedExerciseChanges }];
  changes.workoutExerciseGroups.updated = [
    { id: movedItem.groupId, changes: movedExerciseGroupChanges },
  ];

  // Update the index for any item between the moved item and the destination
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      // If the item is undefined or the workout id isn't the same as movedItem or destinationItem then skip
      if (
        !item ||
        (item.workoutId !== movedItem.workoutId && item.workoutId !== destinationItem.workoutId)
      ) {
        return false;
      }

      // Exclude the moved item and moved item exercise options
      if (
        item.id === movedItem.id ||
        item.id === movedItem.groupId + '-' + WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
      ) {
        return false;
      }

      // Find all items below the destination item
      if (item.workoutId === destinationItem.workoutId && item.dragIndex >= to) {
        return true;
      }
      // Find all items below the moved item
      else if (item.workoutId === movedItem.workoutId && item.dragIndex > from) {
        return true;
      }

      return false;
    }
  );

  const workoutDragItemUpdates: UpdateType[] = [];
  const workoutExerciseGroupUpdates: UpdateType[] = [];
  // Update the affected items
  affectedItems.forEach((item) => {
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    const dragItemChanges: Partial<WorkoutDragItem> = {};

    // If item was below the moved item
    if (item.workoutId === movedItem.workoutId && item.dragIndex > from) {
      dragItemChanges.dragIndex = item.dragIndex - 2; // -2 because we are moving the item to a new group + its exercise options
    }

    // If item was below the destination item
    if (item.workoutId === destinationItem.workoutId && item.dragIndex >= to) {
      dragItemChanges.dragIndex =
        (dragItemChanges?.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) + 2; // +2 for the moved exercise + exercise options
    }

    // Update group indexes from moved item workout or destination workout
    const incrementAmount = item.workoutId === movedItem.workoutId ? -1 : 1;

    // Update group indexes
    if (item.groupIndex !== undefined) {
      dragItemChanges.groupIndex = item.groupIndex + incrementAmount;
    }
    // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
    ) {
      dragItemChanges.index = item.index + incrementAmount;
    }

    // If the item is a group header or a solo exercise
    // Update the exercise group index
    if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
      exerciseGroupChanges.index = item.index + incrementAmount;
    } else if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      !item.inGroup &&
      item.groupIndex !== undefined
    ) {
      exerciseGroupChanges.index = item.groupIndex + incrementAmount;
    }

    workoutDragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });

    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...workoutDragItemUpdates);
  changes.workoutExerciseGroups.updated.push(...workoutExerciseGroupUpdates);

  return changes;
};

export default handleMoveExerciseToNewWorkout;
