import { WorkoutExerciseMetric, WorkoutExerciseMetricValue } from 'src/@types/program';
import { Changes } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import uuidv4 from 'src/utils/uuidv4';
import { handleDuplicateWorkoutExerciseMetricValue } from '.';

type Props = {
  state: RootState;
  changes: Changes;
  workoutExerciseMetricId: string;
  newWorkoutExerciseId: string;
  newWorkoutId?: string; // If only a new workout is created
};

const handleDuplicateWorkoutExerciseMetric = ({
  state,
  changes,
  workoutExerciseMetricId,
  newWorkoutExerciseId,
  newWorkoutId,
}: Props) => {
  const item = state.workoutExerciseMetrics.entities[workoutExerciseMetricId];

  if (!item) {
    console.error(`No workout exercise metric with id ${workoutExerciseMetricId} found`);
    return;
  }

  // If changes for this item don't exist, create them
  if (changes.workoutExerciseMetrics === undefined) {
    changes.workoutExerciseMetrics = {};
  }
  if (changes.workouts === undefined) {
    changes.workouts = {
      updated: [
        {
          id: newWorkoutId ? newWorkoutId : item.workoutId,
          changes: {},
        },
      ],
    };
  }

  const duplicatedItem: WorkoutExerciseMetric = {
    ...item,
    id: uuidv4(),
    workoutExerciseId: newWorkoutExerciseId,
    workoutId: newWorkoutId ? newWorkoutId : item.workoutId,
  };

  // Push the changes
  changes.workoutExerciseMetrics?.added?.length
    ? changes.workoutExerciseMetrics.added.push(duplicatedItem)
    : (changes.workoutExerciseMetrics.added = [duplicatedItem]);

  // Duplicate all items associated with this item
  const associatedItems = Object.values(state.workoutExerciseMetricValues.entities).filter(
    (associatedItem): associatedItem is WorkoutExerciseMetricValue =>
      !!associatedItem && associatedItem.workoutExerciseMetricId === workoutExerciseMetricId
  );

  associatedItems.forEach((associatedItem) =>
    handleDuplicateWorkoutExerciseMetricValue({
      state,
      changes,
      workoutExerciseMetricValueId: associatedItem.id,
      newWorkoutExerciseId,
      newWorkoutExerciseMetricId: duplicatedItem.id,
      newWorkoutId,
    })
  );
};

export default handleDuplicateWorkoutExerciseMetric;
