import { WorkoutDragItem, WorkoutExercise, WorkoutExerciseGroup } from 'src/@types/program';
import { Changes, UpdateType } from 'src/@types/program_redux';
import { RootState } from 'src/redux/store';
import { WORKOUT_DRAG_DATA_ENUM } from 'src/@types/enums';

type Props = {
  state: RootState;
  movedItem: WorkoutDragItem;
  destinationItem: WorkoutDragItem;
  movedDown: boolean;
  movedUp: boolean;
};

// If solo exercise was moved to an existing group
const handleMoveExerciseToExistingGroup = ({
  state,
  movedItem,
  destinationItem,
  movedDown,
  movedUp,
}: Props) => {
  const changes: Changes = {};

  // Make sure updates get pushed to database
  const workoutUpdates = [
    { id: movedItem.workoutId, changes: {} },
    { id: destinationItem.workoutId, changes: {} },
  ];
  changes.workouts = {
    updated: workoutUpdates,
  };

  changes.workoutExerciseGroups = {};
  changes.workoutDragItems = {};
  changes.workoutExercises = {};

  const destinationGroupIndex =
    destinationItem?.groupIndex !== undefined ? destinationItem.groupIndex : destinationItem.index;

  const from = movedItem.dragIndex;
  let to = destinationItem.dragIndex;

  // Change the to destination if combining with group header and moving down
  // Or if combining with group footer and moving up
  if (movedUp && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER) {
    to -= 1;
  } else if (movedDown && destinationItem.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
    to += 1;
  }

  const exerciseAboveDestinationDragItem = Object.values(state.workoutDragItems.entities).find(
    (item) =>
      !!item &&
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.workoutId === destinationItem.workoutId &&
      item.groupId === destinationItem.groupId &&
      item.dragIndex === to - (movedDown ? 1 : 0)
  );

  const newDragIndex = movedUp ? to - 1 : to;
  // If there is no above exercise it must have been placed at the top of the group
  const newIndex = exerciseAboveDestinationDragItem
    ? exerciseAboveDestinationDragItem.index + 1
    : 0;

  // Update movedItem
  const movedDragItemChanges: Partial<WorkoutDragItem> = {
    index: newIndex,
    dragIndex: newDragIndex,
    inGroup: true,
    groupId: destinationItem.groupId,
    groupIndex: destinationGroupIndex - (movedUp ? 1 : 0),
  };

  // Update exercise associated with movedItem
  const movedExerciseChanges: Partial<WorkoutExercise> = {
    index: newIndex,
    workoutExerciseGroupId: destinationItem.groupId,
  };
  // Remove movedItem group
  changes.workoutExerciseGroups.removed = [movedItem.groupId];
  // Remove exercise options
  changes.workoutDragItems.removed = [
    movedItem.groupId + '-' + WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS,
  ];

  // If destination item was last in group
  // Update movedItem to be last in group
  if (exerciseAboveDestinationDragItem?.lastInGroup) {
    movedDragItemChanges.lastInGroup = true;
  } else {
    movedDragItemChanges.lastInGroup = false;
  }

  // Push the changes
  changes.workoutDragItems.updated = [{ id: movedItem.id, changes: movedDragItemChanges }];
  changes.workoutExercises.updated = [{ id: movedItem.id, changes: movedExerciseChanges }];

  // Find all the affected drag items
  // If between to and from
  // If moved item group index > item group index
  const affectedItems = Object.values(state.workoutDragItems.entities).filter(
    (item): item is WorkoutDragItem => {
      // If the item is undefined or the workout id isn't the same then skip
      if (!item || item.workoutId !== movedItem.workoutId) {
        return false;
      }

      // Exclude the moved item and moved item exercise options
      if (
        item.id === movedItem.id ||
        item.id === movedItem.groupId + '-' + WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
      ) {
        return false;
      }

      // Include exercise above the destination item if that item was last in group
      if (
        exerciseAboveDestinationDragItem?.lastInGroup &&
        item.id === exerciseAboveDestinationDragItem?.id
      ) {
        return true;
      }

      // Find all items below the destination item
      if (movedDown && item.dragIndex >= to) {
        return true;
      }
      // Find all items below the moved item
      else if (movedUp && item.dragIndex > from) {
        return true;
      }

      return false;
    }
  );

  const workoutExerciseGroupUpdates: UpdateType[] = [];
  const workoutDragItemUpdates: UpdateType[] = [];
  const workoutExerciseUpdates: UpdateType[] = [];
  // Update the affected items
  affectedItems.forEach((item) => {
    const exerciseGroupChanges: Partial<WorkoutExerciseGroup> = {};
    const dragItemChanges: Partial<WorkoutDragItem> = {};
    const exerciseChanges: Partial<WorkoutExercise> = {};

    // If dragIndex is greater than the removed exercise options (movedItem.dragIndex + 1)
    if (item.dragIndex > movedItem.dragIndex + 1) {
      dragItemChanges.dragIndex = item.dragIndex - 1;
    }

    // If moved down and the index is lesser than from and greater than or equal to to
    if (movedDown && item.dragIndex < from && item.dragIndex >= to) {
      dragItemChanges.dragIndex =
        (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) + 1;
    }
    // If moved up and the index is less than the from index and greater than the to index
    else if (movedUp && item.dragIndex > from && item.dragIndex <= to) {
      dragItemChanges.dragIndex =
        (dragItemChanges.dragIndex !== undefined ? dragItemChanges.dragIndex : item.dragIndex) - 1;
    }

    // Find exercises in the destination group below the moved item
    if (
      item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
      item.groupId === destinationItem.groupId &&
      ((movedDown && item.dragIndex >= to) || (movedUp && item.dragIndex > to))
    ) {
      dragItemChanges.index = item.index + 1;
      exerciseChanges.index = item.index + 1;
    }

    // Update any groups below the removed moved item group
    const itemGroupIndex = item.groupIndex !== undefined ? item.groupIndex : item.index;
    if (movedItem.groupIndex !== undefined && itemGroupIndex > movedItem.groupIndex) {
      // Update group indexes
      if (item.groupIndex !== undefined) {
        dragItemChanges.groupIndex = item.groupIndex - 1;
      }
      // If type is GROUP_HEADER, GROUP_FOOTER or EXERCISE_OPTIONS then update index
      if (
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER ||
        item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_FOOTER ||
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE_OPTIONS
      ) {
        dragItemChanges.index = item.index - 1;
      }

      // If the item is a group header or a solo exercise
      // Update the exercise group index
      if (item.type === WORKOUT_DRAG_DATA_ENUM.GROUP_HEADER) {
        exerciseGroupChanges.index = item.index - 1;
      } else if (
        item.type === WORKOUT_DRAG_DATA_ENUM.EXERCISE &&
        !item.inGroup &&
        item.groupIndex !== undefined
      ) {
        exerciseGroupChanges.index = item.groupIndex - 1;
      }
    }

    // If above item was last in group
    // Update the item to not be last in group
    if (
      exerciseAboveDestinationDragItem?.lastInGroup &&
      item.id === exerciseAboveDestinationDragItem?.id
    ) {
      dragItemChanges.lastInGroup = false;
    }

    // Push updates
    workoutDragItemUpdates.push({
      id: item.id,
      changes: dragItemChanges,
    });
    if (exerciseGroupChanges?.index !== undefined) {
      workoutExerciseGroupUpdates.push({
        id: item.groupId,
        changes: exerciseGroupChanges,
      });
    }
    if (exerciseChanges?.index !== undefined) {
      workoutExerciseUpdates.push({
        id: item.id,
        changes: exerciseChanges,
      });
    }
  });

  changes.workoutDragItems.updated.push(...workoutDragItemUpdates);
  changes.workoutExercises.updated.push(...workoutExerciseUpdates);

  if (workoutExerciseGroupUpdates.length) {
    changes.workoutExerciseGroups.updated = [...workoutExerciseGroupUpdates];
  }

  return changes;
};

export default handleMoveExerciseToExistingGroup;
